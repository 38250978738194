import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconClose = ({ className, color, onClick }) => (
  <svg
    onClick={onClick}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    color={color}
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.77317 20.595C6.13486 21.206 6.13486 22.1968 6.77317 22.8078C7.41148 23.4189 8.44639 23.4189 9.08471 22.8078L14.929 17.213L20.9153 22.9437C21.5536 23.5548 22.5885 23.5548 23.2268 22.9437C23.8652 22.3327 23.8652 21.3419 23.2268 20.7309L17.2406 15.0001L23.2268 9.26932C23.8652 8.65825 23.8652 7.66751 23.2268 7.05645C22.5885 6.44538 21.5536 6.44538 20.9153 7.05645L14.929 12.7872L9.0847 7.19235C8.44639 6.58128 7.41148 6.58128 6.77317 7.19235C6.13486 7.80342 6.13486 8.79416 6.77317 9.40522L12.6175 15.0001L6.77317 20.595Z"
      fill="white"
    />
  </svg>
);

IconClose.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconClose.defaultProps = {
  className: null,
  color: colors.white,
  onClick: () => {},
};
