import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import {
  LoyaltyPoints as LoyaltyPointsCore,
  FormattedTag,
  // RouteLink,
} from 'core/components';
import {
  withCoreComponent,
  withModalActions,
  withGlobalEvents,
  withUser,
  withLocale,
} from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { GLOBAL_EVENTS, USER_FIELDS, LOYALTY_POINTS_STATUSES } from 'core/constants';
import { ProfileItemsDropdown } from 'components/user-info-and-loyalty/profile-items-dropdown';

import { MODAL_IDS, SIDE_MODAL_IDS } from '../../constants';
import { getAccumulatedPointsPercentage } from '../../helpers/loyalty-points';
import { formatAmountWithCurrency } from '../../helpers/numbers';
import { withPixelRatio } from '../../hocs/with-pixel-ratio';

import './user-info-and-loyalty.scss';

export class UserLoyaltyUI extends Component {
  static propTypes = {
    className: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    globalEvents: PropTypes.shape().isRequired,
    loyaltyPointsAllData: PropTypes.shape(),
    accumulatedPoints: PropTypes.number,
    totalPoints: PropTypes.number,
    currentPoints: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    remainingPoints: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    nextLevel: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    currentLevel: PropTypes.string,
    isHighestLevel: PropTypes.bool,
    userInfoIsFetched: PropTypes.bool,
    withNewLevelEventSubscription: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    userBalance: PropTypes.shape(),
    userData: PropTypes.shape(),
    isSignUpCompleted: PropTypes.bool.isRequired,
    isSideModal: PropTypes.bool,
    isWithinSidebar: PropTypes.bool,
    signOut: PropTypes.func.isRequired,
    isSportsPage: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    loyaltyPointsAllData: null,
    currentLevel: null,
    nextLevel: null,
    accumulatedPoints: null,
    totalPoints: null,
    currentPoints: null,
    remainingPoints: null,
    isHighestLevel: false,
    userInfoIsFetched: false,
    withNewLevelEventSubscription: true,
    userBalance: {},
    userData: {},
    isSideModal: false,
    isWithinSidebar: false,
    isSportsPage: false,
  };

  componentDidMount() {
    const {
      globalEvents,
      withNewLevelEventSubscription,
    } = this.props;

    if (withNewLevelEventSubscription) {
      globalEvents.on(
        GLOBAL_EVENTS.NEW_LEVEL,
        this.openNewLevelModal,
      );
    }
  }

  componentWillUnmount() {
    const {
      globalEvents,
      withNewLevelEventSubscription,
    } = this.props;

    if (withNewLevelEventSubscription) {
      globalEvents.off(
        GLOBAL_EVENTS.NEW_LEVEL,
        this.openNewLevelModal,
      );
    }
  }

  openNewLevelModal = () => {
    const { openModal } = this.props;
    openModal(MODAL_IDS.NEW_LEVEL);
  };

  openDepositModal = () => {
    const { openModalAndCloseOthers, isSignUpCompleted } = this.props;
    openModalAndCloseOthers(isSignUpCompleted ? MODAL_IDS.DEPOSIT : MODAL_IDS.COMPLETE_SIGN_UP);
  };

  closeProfileModal = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.PROFILE);
    closeModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
  };

  render() {
    const {
      className,
      loyaltyPointsAllData,
      accumulatedPoints,
      totalPoints,
      isHighestLevel,
      locale,
      userBalance,
      userData,
      userInfoIsFetched,
      signOut,
      closeModal,
      isSideModal,
      isWithinSidebar,
      isSportsPage,
      currentLevel,
      nextLevel,
    } = this.props;

    const {
      firstName,
      vipStatus = '',
    } = userData || {};

    const {
      totalBalance,
      bonusBalance,
      withdrawableBalance,
      currency,
    } = userBalance;

    const targetVipStatus = R.compose(
      R.toLower,
      R.trim,
    )(currentLevel || vipStatus || LOYALTY_POINTS_STATUSES.BRONZE);

    const currentProgress = isHighestLevel
      ? '100'
      : getAccumulatedPointsPercentage(accumulatedPoints, totalPoints);

    return (
      <div className={classNames('d-flex flex-column align-items-center user-info-and-loyalty w-100', className)}>
        <>
          {!isSportsPage && (
            <ProfileItemsDropdown
              accumulatedPoints={accumulatedPoints}
              currentProgress={currentProgress}
              closeModal={closeModal}
              firstName={firstName}
              signOut={signOut}
              isSideModal={isSideModal}
              isWithinSidebar={isWithinSidebar}
              loyaltyPointsAllData={loyaltyPointsAllData}
              targetVipStatus={targetVipStatus}
              totalPoints={totalPoints}
              closeProfileModal={this.closeProfileModal}
              isSportsPage={isSportsPage}
              nextLevel={nextLevel}
            />
          )}

          <div className={classNames('balance-item d-flex justify-content-between w-100 mb-1_25', {
            'mt-2_5': isSportsPage,
          })}
          >
            <FormattedTag
              id="sidebar.logged-in.balance"
              className="text-white"
            />

            {!isEmptyOrNil(loyaltyPointsAllData) && (
              <span className="h4">
                {formatAmountWithCurrency({ amount: totalBalance, currency, locale })}
              </span>
            )}
          </div>

          <div className="balance-item d-flex justify-content-between w-100 mb-1_25">
            <FormattedTag
              id="sidebar.logged-in.bonus-balance"
              className="text-white"
            />

            {!isEmptyOrNil(loyaltyPointsAllData) && (
              <span className="h4">
                {formatAmountWithCurrency({ amount: withdrawableBalance, currency, locale })}
              </span>
            )}
          </div>

          <div className="balance-item d-flex justify-content-between w-100 mb-2_75">
            <FormattedTag
              id="sidebar.logged-in.real-balance"
              className="text-white"
            />

            {!isEmptyOrNil(loyaltyPointsAllData) && (
              <span className="h4">
                {formatAmountWithCurrency({ amount: bonusBalance, currency, locale })}
              </span>
            )}
          </div>
        </>

        <FormattedTag
          tag={Button}
          disabled={!userInfoIsFetched}
          className="mb-1_5 bg-green text-primary-dark"
          id="deposit"
          color="primary"
          onClick={this.openDepositModal}
          block
        />
      </div>
    );
  }
}

export const UserInfoAndLoyalty = withCoreComponent(
  LoyaltyPointsCore,
  withModalActions(
    withGlobalEvents(
      withLocale(
        withPixelRatio(
          withUser(
            UserLoyaltyUI, [
              USER_FIELDS.USER_DATA,
              USER_FIELDS.IS_SIGN_UP_COMPLETED,
              'vipStatus',
            ],
          ),
        ),
      ),
    ),
  ),
);
