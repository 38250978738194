import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { withImagesData } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { isEmptyOrNil, getRemoteConfig } from 'core/helpers';
import { Carousel } from 'components/carousel/carousel';

import { IMAGES_DATA_CATEGORIES } from '../../../../constants';

import { DepositBonus, DEFAULT_BONUS_OPTION } from '../deposit-bonus/deposit-bonus';
import './bonus-selection.scss';

const firstDepositBonuses = getRemoteConfig('DEPOSIT.FIRST_BONUSES') || [];
const { BONUS_SELECTION } = IMAGES_DATA_CATEGORIES;

export class BonusSelectionUI extends Component {
  responsiveSlidesToShow = [
    {
      breakpoint: 719,
      settings: {
        slidesToShow: 2.7,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.8,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1.2,
      },
    }];

  state = {
    isShouldGoToSlide: true,
  }

  sliderRef = createRef();

  componentDidUpdate(prevProps) {
    const { isInProgress: prevIsInProgress } = prevProps;
    const { isInProgress, updateModalAlignment } = this.props;

    if (!isInProgress && prevIsInProgress) {
      if (updateModalAlignment) {
        updateModalAlignment();
      }

      this.toggleForceGoToSlide(true);
    }
  }

  forceGoToSlide = () => {
    const { bonusTypes, bonusActive } = this.props;
    const slickIndex = R.findIndex(R.propEq('value', bonusActive))(bonusTypes);
    this.sliderRef.current.slickGoTo(slickIndex);
    this.toggleForceGoToSlide(false);
  }

  toggleForceGoToSlide = (val) => {
    this.setState({ isShouldGoToSlide: val });
  }

  defineCurrentBonus = (bonus) => {
    const { depositBonuses } = this.props;

    if (isEmptyOrNil(depositBonuses) || bonus === DEFAULT_BONUS_OPTION) {
      return null;
    }

    return R.find(R.propEq('bonusPlanId', bonus))(depositBonuses);
  }

  render() {
    const {
      className,
      bonusTypes,
      bonusActive,
      changeBonusHandler,
      currency,
      isInProgress,
      isModal,
      imagesData
    } = this.props;

    const { isShouldGoToSlide } = this.state;
    const isIncludeFirstBonus = !R.compose(
      R.isEmpty,
      R.filter(({ value }) => R.includes(Number(value), firstDepositBonuses))
    )(bonusTypes);

    const imagesDataItems = R.propOr([], BONUS_SELECTION)(imagesData || {});

    if (isShouldGoToSlide && this.sliderRef.current && bonusActive) { this.forceGoToSlide(); }

    return (
      <div
        className={classNames('bonus-selection', className)}
      >
        <FormattedTag
          id="profile.deposit-new.available.bonuses"
          className="bonus-headline font-weight-medium pb-1_5 d-block h4 font-weight-bold"
          isHtml
        />
        <div className="w-100 bonus-selection-items rounded">
          {isInProgress
            ? [0, 1].map(id => <div key={id} className="deposit-bonus deposit-bonus-preloader preloader rounded bg-white-4 mb-1 mr-1 flex-grow-1" />)
            : bonusTypes.map(({
              value, label,
            }) => (
              <DepositBonus
                key={value}
                currency={currency}
                isActive={value === bonusActive}
                bonusType={value}
                bonusLabel={label}
                handleClick={changeBonusHandler}
                currentBonus={this.defineCurrentBonus(value)}
                isLargeSize={isIncludeFirstBonus}
                imagesDataItems={imagesDataItems}
              />
            ))}
        </div>
        <div className="d-sm-none mr-n3">
          <Carousel
            sliderRef={this.sliderRef}
            settings={{
              lazy: true,
              arrows: false,
              responsive: this.responsiveSlidesToShow,
              focusOnSelect: true,
            }}
          >
            {isInProgress
              ? [0, 1].map(id => (
                <div
                  key={id}
                  className={classNames('deposit-bonus preloader rounded mb-1 flex-grow-1 mr-1', {
                    'deposit-bonus-preloader bg-white-4': isModal,
                    'bg-white-8': !isModal
                  })}
                />
              ))
              : bonusTypes.map(({
                value, label,
              }) => (
                <DepositBonus
                  className="mr-2"
                  key={value}
                  currency={currency}
                  isActive={value === bonusActive}
                  bonusType={value}
                  bonusLabel={label}
                  handleClick={changeBonusHandler}
                  currentBonus={this.defineCurrentBonus(value)}
                  isLargeSize={isIncludeFirstBonus}
                  isCarousel
                  imagesDataItems={imagesDataItems}
                />
              ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

BonusSelectionUI.propTypes = {
  currency: PropTypes.string.isRequired,
  className: PropTypes.string,
  bonusTypes: PropTypes.arrayOf(PropTypes.shape({
    label: '',
    value: '',
  })).isRequired,
  depositBonuses: PropTypes.arrayOf(PropTypes.shape({})),
  bonusActive: PropTypes.string,
  changeBonusHandler: PropTypes.func,
  isInProgress: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  updateModalAlignment: PropTypes.func,
  imagesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

BonusSelectionUI.defaultProps = {
  className: '',
  bonusActive: '',
  depositBonuses: null,
  isModal: false,
  updateModalAlignment: null,
  changeBonusHandler: () => {},
};

export const BonusSelection = withImagesData(BonusSelectionUI);
