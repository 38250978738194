/* eslint-disable react/jsx-curly-newline */
import React, { Component, createRef } from 'react';
import { fromEvent, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import classNames from 'classnames';
import {
  withCoreComponent,
  withModalActions,
  withUserAgent,
  withNotificationsActions,
  withRecaptcha,
} from 'core/hocs';
import { FormSignUp as FormSignUpCore, FormattedTag } from 'core/components';
// import { setEmailHidden } from 'core/helpers';
import { FORM_ACTIONS } from 'core/constants';

// import { StatusNotificationContent } from 'components/status-notification-content/status-notification-content';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';
// import { IconMail } from 'components/icons/icon-mail/icon-mail';
// import { IconLock } from 'components/icons/icon-lock/icon-lock';
// import { IconCurrency } from 'components/icons/icon-currency/icon-currency';
// import { IconUser } from 'components/icons/icon-user/icon-user';
import { GA } from 'helpers/ga';
import { YM } from 'helpers/ym';
// import { SocialAuthNetworksList } from 'components/social-auth-networks-list/social-auth-networks-list';
import { checkIsMobileLengthValidate } from 'helpers/form';
import { SignUpBanner } from './sign-up-banner/sign-up-banner';
import { MODAL_IDS } from '../../../constants';

import './sign-up.scss';

const SIGN_UP_SCROLL_DEBOUNCE = 500;

export class FormSignUpUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    confirmPhoneIsInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isPhoneConfirmed: PropTypes.bool,
    email: PropTypes.string,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    sendSms: PropTypes.func.isRequired,
    currentModal: PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.any,
    }).isRequired,
    isSocialAuth: PropTypes.bool.isRequired,
    os: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    isSmsConfirmationActive: PropTypes.bool,
    showErrorNotification: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    executeRecaptcha: PropTypes.func.isRequired,
    checkPasswordFieldErrors: PropTypes.func.isRequired,
  };

  static defaultProps = {
    email: null,
    isPhoneConfirmed: false,
    mobile: '',
    isSmsConfirmationActive: false,
  };

  state = {
    isEmailDisabled: false,
    isFormElementsDisabled: true,
  };

  focusedElementRef = createRef();

  scrollSubscription = null;

  componentDidMount() {
    const {
      isSocialAuth, email, os
    } = this.props;

    if (isSocialAuth && !!email) {
      this.setState({ isEmailDisabled: true });
    }

    if (os === 'ios') {
      this.subscribeToScroll();
    }

    const focusedElement = this.focusedElementRef.current;

    if (focusedElement) {
      focusedElement.blur();
    }

    setTimeout(() => {
      this.setState({ isFormElementsDisabled: false });

      if (focusedElement && focusedElement.clientHeight && os !== 'ios') {
        focusedElement.focus();
      }
    }, 300);
  }

  componentDidUpdate({ isSuccess: prevIsSuccess }) {
    const { isSuccess, onSuccess } = this.props;

    if (isSuccess) {
      onSuccess(isSuccess);

      this.closeSignUpModal();
    }

    const modalBody = document.querySelector('.modal-body');

    if (isSuccess !== prevIsSuccess && modalBody) {
      modalBody.classList.toggle('h-100', isSuccess);
    }
  }

  componentWillUnmount() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  closeSignUpModal = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.SIGN_UP);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      onSubmit, isSmsConfirmationActive, isPhoneConfirmed, showErrorNotification, executeRecaptcha
    } = this.props;

    if (isSmsConfirmationActive && !isPhoneConfirmed) {
      showErrorNotification('mobile-phone-not-confirmed');

      return;
    }

    GA.event({ category: 'signup', action: 'click', label: 'SignDone' });
    YM.event('SignDone');

    onSubmit(e, executeRecaptcha);
  };

  subscribeToScroll = () => {
    this.scrollSubscription = fromEvent(document.querySelector('.modal-content'), 'scroll')
      .pipe(
        debounce(() => {
          const { isCaretHidden } = this.state;

          if (!isCaretHidden) {
            this.setState({ isCaretHidden: true });
          }

          return timer(SIGN_UP_SCROLL_DEBOUNCE);
        }),
      ).subscribe(() => {
        this.setState({ isCaretHidden: false });
      });
  };

  sendVerifySms = () => {
    const { sendSms, intl: { formatMessage } } = this.props;

    sendSms({
      sourceFormName: FORM_ACTIONS.SIGN_UP,
      template: formatMessage({ id: 'form.confirm-phone.message-template' })
    });
  };

  render() {
    const {
      fields,
      isInProgress,
      isSuccess,
      // email,
      isSocialAuth,
      isPhoneConfirmed,
      mobile,
      confirmPhoneIsInProgress,
      isSmsConfirmationActive,
      checkPasswordFieldErrors
    } = this.props;

    const { isEmailDisabled, isCaretHidden, isFormElementsDisabled } = this.state;

    return (
      <div className={classNames('d-flex flex-column flex-md-row sign-up-container justify-content-center h-100', {
        'hide-caret': isCaretHidden,
      })}
      >

        {/* {isSuccess && (
          <StatusNotificationContent
            headerIntlKey="form.sign-up.welcome-title"
            descIntlKey="form.sign-up.welcome-description"
            onClick={this.closeSignUpModal}
            email={setEmailHidden(email)}
          />
        )} */}

        {!isSuccess && (
          <>
            <SignUpBanner className="d-none d-md-flex" />

            <div className={classNames('sign-up-inner', {
              'pb-5 position-relative': isSuccess,
            })}
            >
              <Form
                autoComplete="off"
                onSubmit={this.onSubmit}
                className={classNames('form-sign-up d-flex flex-column', {
                  invisible: isSuccess,
                })}
              >
                <FormattedTag
                  id="sign-up"
                  className="align-self-center modal-custom-title mb-2_5 text-white"
                />

                <FormElement
                  coreProps={{ item: fields.email }}
                  // icon={!isSuccess && IconMail}
                  autoFocus
                  autoComplete="off"
                  isDisabled={isEmailDisabled}
                  isReadOnly={isFormElementsDisabled}
                  innerRef={this.focusedElementRef}
                />

                {/* <FormElement
                  coreProps={{ item: fields.username }}
                  // icon={IconUser}
                /> */}

                {!isSocialAuth && (
                  <FormElement
                    coreProps={{ item: fields.password }}
                    autoComplete="off"
                    isReadOnly={isFormElementsDisabled}
                    isSpecificPasswordRule
                    checkPasswordFieldErrors={checkPasswordFieldErrors}
                  />
                )}

                <FormElement
                  coreProps={{ item: fields.currency }}
                />

                <FormElement
                  coreProps={{ item: fields.promoCode }}
                />

                {isSmsConfirmationActive && (
                  <FormElement
                    coreProps={{ item: fields.mobile }}
                    disabled={isSmsConfirmationActive && isPhoneConfirmed}
                    rightContent={isSmsConfirmationActive && (
                      <FormattedTag
                        tag={ButtonWithLoader}
                        isLoading={confirmPhoneIsInProgress}
                        color="primary"
                        onClick={this.sendVerifySms}
                        id="verify"
                        disabled={!checkIsMobileLengthValidate(mobile)
                          || isSmsConfirmationActive && isPhoneConfirmed}
                      />
                    )}
                  />
                )}

                <FormElement
                  formGroupClassName="mt-1_5 mb-2 sign-up-checkbox-container font-weight-medium"
                  coreProps={{ item: fields.terms }}
                  id="sign-up-modal"
                />

                <FormElement
                  formGroupClassName="sign-up-checkbox-container font-weight-medium"
                  coreProps={{ item: fields.receiveEmail }}
                  id="sign-up-modal"
                />

                <FormattedTag
                  tag={ButtonWithLoader}
                  isLoading={isInProgress}
                  color="primary"
                  className="mt-0_75"
                  type="submit"
                  id="create-account"
                />

                <SignUpBanner
                  isMobile
                  className="d-flex d-md-none mt-2_5 rounded"
                />

                <div className="w-100 d-flex justify-content-center mt-2_75 mb-1_25">
                  <FormattedTag
                    id="have-account"
                    className="sign-up-link-hint flex-grow-0 font-weight-medium"
                  />

                  <FormattedTag
                    tag={Button}
                    onClick={this.openSignInModal}
                    onKeyPress={this.openSignInModal}
                    id="sign-in"
                    color="link-secondary"
                    className="ml-1 justify-content-center d-flex font-weight-medium text-primary"
                  />
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export const FormSignUp = withCoreComponent(
  FormSignUpCore,
  withRecaptcha(
    withModalActions(
      withUserAgent(
        withNotificationsActions(
          injectIntl(FormSignUpUI)
        )
      )
    )
  )
);
