import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconEPS = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="42"
    viewBox="0 0 80 42"
    fill="none"
  >
    <mask
      id="mask0_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask0_8822_28346)">
      <path
        d="M51.6419 18.0962H48.3787C48.016 18.0962 47.7206 17.808 47.7206 17.4473C47.7206 17.0866 48.016 16.7691 48.3787 16.7691H53.3469V14.3269H48.3787C46.645 14.3269 45.2338 15.7357 45.2338 17.4596C45.2338 19.1836 46.6442 20.5924 48.3787 20.5924H51.5978C51.9605 20.5924 52.2558 20.879 52.2558 21.2405C52.2558 21.6019 51.9605 21.867 51.5978 21.867H44.6956C44.1095 22.9822 43.5396 23.9378 42.3828 24.4156H51.6419C53.3469 24.3909 54.7411 22.9529 54.7411 21.2451C54.7411 19.5373 53.3469 18.1224 51.6419 18.0977"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask1_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask1_8822_28346)">
      <path
        d="M39.1318 14.3269C36.3658 14.3269 34.1133 16.5849 34.1133 19.3601V29.8302H36.6171V24.4148H39.1264C41.8923 24.4148 44.1371 22.1267 44.1371 19.3516C44.1371 16.5765 41.8977 14.3269 39.1318 14.3269ZM39.1318 21.8663H36.6171V19.3516C36.6171 17.9459 37.7399 16.803 39.1318 16.803C40.5236 16.803 41.6557 17.9467 41.6557 19.3516C41.6557 20.7565 40.5236 21.8663 39.1318 21.8663Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask2_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask2_8822_28346)">
      <path
        d="M26.1889 24.4148C23.8212 24.4148 21.8308 22.7333 21.2926 20.5369C21.2926 20.5369 21.1371 19.8109 21.1371 19.3323C21.1371 18.8538 21.2841 18.1201 21.2841 18.1201C21.8269 15.9291 23.8142 14.2976 26.1781 14.2976C28.958 14.2976 31.2391 16.5495 31.2391 19.32V20.5384H23.8536C24.2898 21.3877 25.1721 21.8655 26.1889 21.8655H32.8282L32.8375 14.6806C32.8375 13.6094 31.9582 12.7332 30.8834 12.7332H21.4735C20.3987 12.7332 19.5195 13.5832 19.5195 14.6544V24.0326C19.5195 25.1038 20.3987 26.007 21.4735 26.007H30.8834C31.8477 26.007 32.6526 25.3165 32.8081 24.4141L26.1889 24.4148Z"
        fill="#C8036F"
      />
    </g>
    <mask
      id="mask3_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask3_8822_28346)">
      <path
        d="M26.1759 16.6813C25.1629 16.6813 24.2815 17.2993 23.8438 18.0962H28.5089C28.0712 17.3001 27.1889 16.6813 26.1759 16.6813Z"
        fill="#C8036F"
      />
    </g>
    <mask
      id="mask4_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask4_8822_28346)">
      <path
        d="M30.4381 9.68367C30.4381 7.39481 28.5297 5.53906 26.1751 5.53906C23.8205 5.53906 21.9763 7.33393 21.9144 9.57038C21.9136 9.57963 21.9144 9.58887 21.9144 9.59889V10.8443C21.9144 10.993 22.0358 11.1402 22.1897 11.1402H23.7579C23.911 11.1402 24.0455 10.993 24.0455 10.8443V9.68367C24.0455 8.54078 25.0005 7.61059 26.1767 7.61059C27.3528 7.61059 28.3078 8.54078 28.3078 9.68367V10.8443C28.3078 10.993 28.4323 11.1402 28.5854 11.1402H30.1536C30.3067 11.1402 30.4389 10.993 30.4389 10.8443L30.4381 9.68367Z"
        fill="#C8036F"
      />
    </g>
    <mask
      id="mask5_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask5_8822_28346)">
      <path
        d="M41.9407 27.6532L41.7142 28.9325C41.6005 29.579 41.176 29.875 40.5991 29.875C40.1112 29.875 39.733 29.5582 39.8436 28.9332L40.0702 27.6524H40.4197L40.1931 28.9317C40.1235 29.3301 40.3037 29.5575 40.6432 29.5575C40.9826 29.5575 41.2919 29.3501 41.3646 28.9325L41.5912 27.6516H41.9407V27.6532ZM40.5674 27.2524C40.5674 27.3588 40.6494 27.4096 40.7375 27.4096C40.8481 27.4096 40.9672 27.331 40.9672 27.1838C40.9672 27.0806 40.8976 27.0266 40.8063 27.0266C40.6934 27.0266 40.5674 27.0991 40.5674 27.2524ZM41.3229 27.2493C41.3229 27.3588 41.4079 27.4096 41.4992 27.4096C41.5966 27.4096 41.7257 27.331 41.7257 27.1869C41.7257 27.0836 41.65 27.0266 41.5649 27.0266C41.4512 27.0266 41.3229 27.0991 41.3229 27.2493Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask6_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask6_8822_28346)">
      <path
        d="M42.7856 27.6531L42.6278 28.5371C42.7631 28.3552 43.0338 28.2589 43.2232 28.2589C43.6137 28.2589 43.8844 28.487 43.8844 28.9109C43.8844 29.5104 43.4653 29.8711 42.9302 29.8711C42.7222 29.8711 42.5458 29.794 42.4391 29.5998L42.3796 29.8302H42.0742L42.4616 27.6531H42.7856ZM42.5404 29.1451C42.5281 29.4025 42.6951 29.569 42.9371 29.569C43.2426 29.569 43.5387 29.3332 43.5573 28.9756C43.5697 28.7151 43.3995 28.561 43.1637 28.561C42.8613 28.561 42.559 28.803 42.5404 29.1451Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask7_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask7_8822_28346)">
      <path
        d="M44.5215 29.1929C44.5092 29.4056 44.6762 29.579 44.9592 29.579C45.117 29.579 45.3242 29.5174 45.4379 29.411L45.6142 29.6145C45.4224 29.7902 45.1386 29.8772 44.8997 29.8772C44.4527 29.8772 44.1914 29.6106 44.1914 29.2022C44.1914 28.6588 44.6198 28.2542 45.1672 28.2542C45.6683 28.2542 45.9513 28.5556 45.7812 29.1929H44.5215ZM45.5167 28.9278C45.5423 28.6627 45.3845 28.554 45.1231 28.554C44.8873 28.554 44.67 28.6619 44.5749 28.9278H45.5167Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask8_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask8_8822_28346)">
      <path
        d="M46.6495 28.2905L46.6341 28.4955C46.7856 28.2974 46.9681 28.2573 47.1351 28.2573C47.2859 28.2573 47.422 28.3105 47.4939 28.3891L47.2991 28.6719C47.2295 28.6095 47.1607 28.5779 47.0408 28.5779C46.8173 28.5779 46.5969 28.7112 46.5467 28.9972L46.399 29.831H46.0742L46.3479 28.2912H46.6503L46.6495 28.2905Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask9_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask9_8822_28346)">
      <path
        d="M49.0534 28.2905L49.2174 29.5081L49.8089 28.2905H50.1716L49.3589 29.8302H48.9777L48.8238 28.8145L48.5748 29.3193L48.3041 29.8302H47.926L47.6523 28.2905H48.0173L48.1781 29.5081L48.7727 28.2905H49.0534Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask10_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask10_8822_28346)">
      <path
        d="M50.588 29.1929C50.5756 29.4056 50.7426 29.579 51.0256 29.579C51.1834 29.579 51.3906 29.5174 51.5043 29.411L51.6806 29.6145C51.4888 29.7902 51.205 29.8772 50.9661 29.8772C50.5191 29.8772 50.2578 29.6106 50.2578 29.2022C50.2578 28.6588 50.6862 28.2542 51.2344 28.2542C51.7355 28.2542 52.0185 28.5556 51.8484 29.1929H50.588ZM51.5832 28.9278C51.6087 28.6627 51.4509 28.554 51.1896 28.554C50.9537 28.554 50.7364 28.6619 50.6413 28.9278H51.5832Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask11_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask11_8822_28346)">
      <path
        d="M52.7391 28.2905L52.4654 29.8302H52.1406L52.4143 28.2905H52.7391ZM52.4522 27.8712C52.4522 27.9807 52.5342 28.0377 52.6223 28.0377C52.7484 28.0377 52.8651 27.956 52.8651 27.8019C52.8651 27.6978 52.7863 27.6385 52.7012 27.6385C52.5844 27.6385 52.4522 27.7147 52.4522 27.8712Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask12_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask12_8822_28346)">
      <path
        d="M54.1766 28.6712C54.0761 28.5579 53.9469 28.5271 53.7923 28.5271C53.5781 28.5271 53.4273 28.5995 53.4273 28.7344C53.4273 28.8477 53.5502 28.8947 53.7327 28.9101C54.0165 28.9355 54.3784 29.0295 54.3026 29.428C54.2493 29.717 53.9593 29.8865 53.5564 29.8865C53.3043 29.8865 53.0623 29.8302 52.8984 29.6006L53.1095 29.3717C53.2262 29.532 53.4304 29.5975 53.6136 29.6006C53.7683 29.6006 53.9508 29.5443 53.9786 29.3994C54.0072 29.2615 53.8843 29.2083 53.6577 29.186C53.3933 29.1605 53.1033 29.0634 53.1033 28.7714C53.1033 28.3853 53.5224 28.2504 53.8371 28.2504C54.0761 28.2504 54.2531 28.3036 54.3977 28.4577L54.1766 28.6712Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask13_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask13_8822_28346)">
      <path
        d="M55.1994 28.2905L55.0517 29.1197C55.0045 29.3833 55.1244 29.5659 55.395 29.5659C55.6502 29.5659 55.8675 29.3555 55.9116 29.0981L56.0531 28.2912H56.3778L56.1041 29.831H55.811L55.8327 29.6083C55.6502 29.7809 55.4708 29.8626 55.2535 29.8626C54.8723 29.8626 54.6457 29.5906 54.7277 29.1228L54.8754 28.292H55.1994V28.2905Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask14_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask14_8822_28346)">
      <path
        d="M57.8027 29.8302L57.9504 29.0064C57.9976 28.7398 57.9063 28.5625 57.6102 28.5625C57.3519 28.5625 57.1346 28.7698 57.0905 29.0234L56.949 29.8295H56.625L56.8988 28.2897H57.1949L57.1733 28.5147C57.3527 28.3483 57.5382 28.2666 57.7401 28.2666C58.1182 28.2666 58.3641 28.5286 58.279 29.0026L58.1306 29.8295L57.8027 29.8302Z"
        fill="#71706F"
      />
    </g>
    <mask
      id="mask15_8822_28346"
      style={{ 'mask-type': 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="16"
      y="2"
      width="48"
      height="32"
    >
      <path d="M63.6813 2.43335H16.3203V33.0686H63.6813V2.43335Z" fill="white" />
    </mask>
    <g mask="url(#mask15_8822_28346)">
      <path
        d="M58.9036 30.0422C58.882 30.2549 59.0451 30.3404 59.3196 30.3404C59.5593 30.3404 59.8175 30.2071 59.8771 29.868L59.9243 29.6037C59.7828 29.8017 59.5246 29.8796 59.3328 29.8796C58.9361 29.8796 58.6585 29.6437 58.6585 29.2168C58.6585 28.608 59.1115 28.2566 59.625 28.2566C59.8423 28.2566 60.0379 28.3598 60.1067 28.5355L60.1602 28.2905H60.4748L60.2042 29.8757C60.1036 30.463 59.6661 30.6448 59.2655 30.6448C58.7869 30.6448 58.5223 30.4136 58.588 30.0422H58.9036ZM58.9948 29.1551C58.9948 29.4187 59.168 29.579 59.417 29.579C60.0874 29.579 60.2668 28.5525 59.5964 28.5525C59.2786 28.5525 58.9948 28.7875 58.9948 29.1551Z"
        fill="#71706F"
      />
    </g>
    <path
      d="M33.6129 31.6421C33.5882 31.6297 33.5642 31.6297 33.5271 31.6174C33.3067 31.5565 32.7453 31.5442 32.5736 31.6914C32.5373 31.7284 32.5249 31.7407 32.5002 31.7893C32.5002 31.7893 31.6326 32.9414 31.0712 33.9456L29.7272 31.7893V31.7769C29.7025 31.74 29.6909 31.7161 29.6538 31.6791L29.6414 31.6667C29.4829 31.5319 29.0189 31.5442 28.7754 31.5812C28.7019 31.5935 28.6408 31.6059 28.6045 31.6182C28.4823 31.6791 28.4336 31.79 28.4336 31.8633V36.397C28.4336 36.4826 28.4699 36.6421 28.7506 36.6907C28.8241 36.703 28.9215 36.703 29.0321 36.703C29.2277 36.703 29.6306 36.703 29.6306 36.397V33.7984C29.7164 33.9332 29.8873 34.203 30.0698 34.5467C30.3265 35.0245 30.4974 35.3304 30.5825 35.4784L30.6312 35.5763C30.6675 35.6372 30.7294 35.6988 30.8021 35.7597C30.9119 35.8452 31.1315 35.8699 31.2784 35.8329C31.3148 35.8206 31.3519 35.8083 31.3882 35.772C31.498 35.6865 31.5591 35.6248 31.5839 35.5886C31.6086 35.5516 31.645 35.4784 31.706 35.3805C31.7671 35.2827 31.8893 35.0376 32.0965 34.6446C32.3045 34.2523 32.4638 33.971 32.5729 33.786V36.4094C32.5729 36.7153 32.9757 36.7153 33.1714 36.7153C33.367 36.7153 33.7699 36.7153 33.7699 36.4094V31.874C33.7823 31.8008 33.7451 31.6906 33.6106 31.6413L33.6129 31.6421Z"
      fill="#2D9DD3"
    />
    <path
      d="M35.079 33.2712C34.8834 33.2712 34.4805 33.2712 34.4805 33.5772V36.4695C34.4805 36.7754 34.8834 36.7754 35.079 36.7754C35.2747 36.7754 35.6775 36.7754 35.6775 36.4695V33.5772C35.6775 33.2712 35.2747 33.2712 35.079 33.2712Z"
      fill="#2D9DD3"
    />
    <path
      d="M39.476 31.6289H36.9598C36.7286 31.6289 36.3984 31.6412 36.3984 31.9348V36.4686C36.3984 36.7746 36.8013 36.7746 36.9969 36.7746C37.1926 36.7746 37.5955 36.7746 37.5955 36.4686V34.7408H39.2193C39.4026 34.7408 39.6098 34.5936 39.6098 34.1774C39.6098 33.7613 39.4018 33.6141 39.2193 33.6141H37.5955V32.7556H39.4768C39.6601 32.7556 39.8673 32.6084 39.8673 32.1922C39.8673 31.7761 39.6585 31.6289 39.476 31.6289Z"
      fill="#2D9DD3"
    />
    <path
      d="M46.0451 31.6167C45.8618 31.6167 45.4465 31.6167 45.4465 31.935V34.3857L43.6881 31.7871L43.6634 31.7501C43.4801 31.5782 42.8445 31.6399 42.7223 31.6892C42.6125 31.7385 42.5391 31.8364 42.5391 31.9466V36.468C42.5391 36.7863 42.9419 36.7863 43.1376 36.7863C43.3332 36.7863 43.7361 36.7863 43.7361 36.468V34.0173L45.4829 36.616C45.5316 36.6892 45.6422 36.7755 46.0203 36.7755C46.1549 36.7755 46.325 36.7632 46.4232 36.7385C46.5577 36.7015 46.6436 36.5913 46.6436 36.4688V31.9358C46.6436 31.6175 46.2283 31.6167 46.0451 31.6167Z"
      fill="#2D9DD3"
    />
    <path
      d="M48.0516 31.6289C47.8559 31.6289 47.4531 31.6289 47.4531 31.9348V36.4686C47.4531 36.7746 47.8559 36.7746 48.0516 36.7746C48.2472 36.7746 48.6501 36.7746 48.6501 36.4686V31.9356C48.6501 31.6296 48.2472 31.6289 48.0516 31.6289Z"
      fill="#2D9DD3"
    />
    <path
      d="M41.0985 31.6289C40.9029 31.6289 40.5 31.6289 40.5 31.9348V36.4686C40.5 36.7746 40.9029 36.7746 41.0985 36.7746C41.2941 36.7746 41.697 36.7746 41.697 36.4686V31.9356C41.697 31.6296 41.2941 31.6289 41.0985 31.6289Z"
      fill="#2D9DD3"
    />
    <path
      d="M53.1309 31.6289H49.6133C49.3086 31.6289 49.3086 32.0335 49.3086 32.2292C49.3086 32.425 49.3086 32.8296 49.6133 32.8296H50.7732V36.4694C50.7732 36.7753 51.176 36.7753 51.3717 36.7753C51.5673 36.7753 51.9702 36.7753 51.9702 36.4694V32.8296H53.1301C53.4347 32.8296 53.4348 32.425 53.4348 32.2292C53.4348 32.0335 53.4355 31.6289 53.1309 31.6289Z"
      fill="#2D9DD3"
    />
    <path
      d="M58.212 31.6913C58.0651 31.6304 57.3444 31.5688 57.1496 31.8138L56.1853 33.3212L55.221 31.8262L55.2087 31.8138C55.0254 31.5688 54.3055 31.6304 54.1462 31.679C54.0116 31.7283 53.9258 31.8385 53.9258 31.961V32.0466L55.5744 34.6198V36.4578C55.5744 36.7884 56.026 36.7884 56.1729 36.7884C56.3322 36.7884 56.7838 36.7884 56.7838 36.4455V34.6075L58.4324 32.0342V31.961C58.4324 31.8385 58.3466 31.7406 58.212 31.6913Z"
      fill="#2D9DD3"
    />
    <path
      d="M35.2231 31.679H34.8937C34.637 31.679 34.4297 31.8871 34.4297 32.1445V32.4875C34.4297 32.7449 34.6377 32.9529 34.8937 32.9529H35.2231C35.4798 32.9529 35.6871 32.7449 35.6871 32.4875V32.1445C35.6871 31.8871 35.4791 31.679 35.2231 31.679Z"
      fill="#2D9DD3"
    />
    <path
      d="M22.3997 35.3704L21.6172 33.4607H22.1392L22.631 34.7832H22.6387L23.1274 33.4607H23.6161L22.8366 35.3704H22.3997Z"
      fill="#2D9DD3"
    />
    <path
      d="M24.1578 33.0933C24.1029 33.0933 24.0519 33.0795 24.0047 33.0525C23.9575 33.0255 23.9204 32.9893 23.8926 32.9446C23.8655 32.8999 23.8516 32.8506 23.8516 32.7958C23.8516 32.7411 23.8655 32.6957 23.8926 32.651C23.9197 32.6063 23.9568 32.5708 24.0047 32.5454C24.0519 32.5192 24.1029 32.5061 24.1578 32.5061C24.2127 32.5061 24.2669 32.5192 24.3125 32.5454C24.3581 32.5716 24.3952 32.6063 24.4223 32.651C24.4493 32.6957 24.4633 32.7442 24.4633 32.7958C24.4633 32.8475 24.4493 32.8999 24.4223 32.9446C24.3952 32.9893 24.3581 33.0247 24.3125 33.0525C24.2661 33.0795 24.215 33.0933 24.1578 33.0933ZM23.9227 35.3483V33.4609H24.3921V35.3483H23.9227Z"
      fill="#2D9DD3"
    />
    <path
      d="M25.3853 35.3783C25.2832 35.3783 25.1865 35.3591 25.0945 35.3205C25.0025 35.282 24.9283 35.2234 24.8711 35.144C24.8138 35.0647 24.7852 34.966 24.7852 34.8466C24.7852 34.6878 24.8363 34.5622 24.9383 34.4697C25.0404 34.3765 25.1842 34.3117 25.3706 34.2747L25.9413 34.1561V34.1484C25.9413 34.0397 25.908 33.9603 25.8407 33.911C25.7735 33.8617 25.6706 33.837 25.5314 33.837C25.4518 33.837 25.3706 33.8416 25.2871 33.8517C25.2036 33.8617 25.1262 33.874 25.0543 33.8886L24.9685 33.9071L24.8904 33.5989L24.984 33.5657C25.1084 33.521 25.2244 33.4879 25.3327 33.4656C25.441 33.4432 25.5484 33.4324 25.6551 33.4324C25.8786 33.4324 26.0603 33.4918 26.1995 33.6104C26.3387 33.7291 26.4083 33.9087 26.4083 34.1491V34.8404C26.4083 34.9175 26.4261 34.9683 26.4624 34.9945C26.4988 35.0207 26.5738 35.0385 26.6882 35.0485L26.6619 35.3567C26.6295 35.3645 26.5985 35.3698 26.5684 35.3737C26.5382 35.3776 26.5127 35.3791 26.4903 35.3791C26.3611 35.3791 26.2583 35.3498 26.1825 35.292C26.1067 35.2342 26.0503 35.1625 26.0132 35.0785L25.9273 35.1564C25.8454 35.2304 25.7564 35.2866 25.6606 35.3236C25.5647 35.3606 25.4734 35.3791 25.3868 35.3791L25.3853 35.3783ZM25.5268 34.9876C25.5763 34.9876 25.6296 34.9776 25.6869 34.9575C25.7441 34.9375 25.8013 34.9082 25.8585 34.8681L25.9405 34.8127V34.4296L25.4371 34.5707C25.3652 34.5907 25.3141 34.6192 25.284 34.6562C25.2538 34.6932 25.2391 34.7364 25.2391 34.7865C25.2391 34.8512 25.2639 34.9005 25.3134 34.9352C25.3629 34.9699 25.434 34.9868 25.526 34.9868L25.5268 34.9876Z"
      fill="#2D9DD3"
    />
  </svg>
);

IconEPS.propTypes = {
  className: PropTypes.string,
};

IconEPS.defaultProps = {
  className: '',
};
