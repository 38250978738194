/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
// import Button from 'reactstrap/lib/Button';
import { isEmptyOrNil } from 'core/helpers';
import { withModalActions } from 'core/hocs';
// import { FormattedTag } from 'core/components';
import { UserInfoAndLoyalty } from 'components/user-info-and-loyalty/user-info-and-loyalty';
// import { SidebarMenu } from '../sidebar-menu/sidebar-menu';

const LoggedInUI = ({
  userData,
  userBalance,
  loyaltyPoints,
  signOut,
  // pageName,
  isWithinSidebar
}) => {
  const userInfoIsFetched = R.none(isEmptyOrNil, [userData, userBalance, loyaltyPoints]);

  return (
    <div className="casino-sidebar-logged-in position-relative w-100 d-flex flex-column my-2_5 border-bottom border-white-15">
      <UserInfoAndLoyalty
        userBalance={userBalance}
        userInfoIsFetched={userInfoIsFetched}
        signOut={signOut}
        isWithinSidebar={isWithinSidebar}
      />

      {/* <SidebarMenu
        pageName={pageName}
        isGridView
      /> */}

      {/* <FormattedTag
        tag={Button}
        id="sign-out"
        color="white-20"
        className="no-effects btn-outline-gray-5"
        onClick={signOut}
        block
        outline
      /> */}
    </div>
  );
};

LoggedInUI.propTypes = {
  userData: PropTypes.shape(),
  userBalance: PropTypes.shape(),
  loyaltyPoints: PropTypes.shape(),
  signOut: PropTypes.func.isRequired,
  pageName: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  isWithinSidebar: PropTypes.bool,
};

LoggedInUI.defaultProps = {
  userData: null,
  userBalance: null,
  loyaltyPoints: null,
  pageName: null,
  isWithinSidebar: false,
};

export const LoggedIn = withModalActions(LoggedInUI);
