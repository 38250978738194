import React from 'react';
import PropTypes from 'prop-types';

export const IconMiniGames = ({ className }) => (
  <svg className={className} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip-header-icon-mini-games)">
      <path
        d="M15.7356 25.6162C15.7356 25.6162 13.8326 19.5092 13.8326 16.9757C13.8326 15.8359 13.9612 14.8218 14.1613 13.9012H22.8306C23.0321 14.8225 23.1613 15.8372 23.1613 16.9764C23.1613 19.4714 21.2638 25.6162 21.2638 25.6162H15.7356ZM18.4932 15.2976C17.5183 15.2976 16.7277 16.0882 16.7277 17.0637C16.7277 18.0386 17.5183 18.8299 18.4932 18.8299C19.4681 18.8299 20.2594 18.0393 20.2594 17.0637C20.2601 16.0882 19.4688 15.2976 18.4932 15.2976ZM18.1137 7.40986C18.1137 7.40986 18.1137 7.14379 18.1137 7.00007C18.1137 6.73158 18.8012 6.73158 18.8012 7.00007C18.8012 7.17432 18.8012 7.40986 18.8012 7.40986C19.587 7.98186 21.7135 9.7398 22.6753 13.244H14.3153C15.2448 9.83605 17.2688 8.03686 18.1137 7.40986Z"
        fill="white"
      />
      <path
        d="M22.3774 24.2287C23.7978 25.9179 23.5675 27.3012 23.5675 27.3012C23.5675 27.3012 26.7926 25.0737 26.7933 20.2742C26.7933 17.472 23.9518 16.8188 23.9518 16.8188C23.9518 16.8188 23.76 20.3505 22.3774 24.2287Z"
        fill="white"
      />
      <path
        d="M10.2074 20.2742C10.2074 25.0737 13.4325 27.3012 13.4325 27.3012C13.4325 27.3012 13.2015 25.9186 14.6225 24.2287C13.2406 20.3505 13.0488 16.8188 13.0488 16.8188C13.0488 16.8188 10.2074 17.472 10.2074 20.2742Z"
        fill="white"
      />
      <path
        d="M19.0865 27.0798L19.6626 27.656L20.4677 26.2727H16.5132L17.2433 28.0774L17.7424 27.0798L18.4726 29L19.0865 27.0798Z"
        fill="white"
      />
    </g>
  </svg>
);

IconMiniGames.propTypes = {
  className: PropTypes.string,
};

IconMiniGames.defaultProps = {
  className: null,
};
