import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const IconProviderTurboGames = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.9328 41.6545L45.7783 29.5L57.9328 17.3455C59.4522 15.8262 59.4522 13.4387 57.9328 11.9193L47.0806 1.13949C45.5612 -0.379831 43.1738 -0.379831 41.6545 1.13949L29.5723 13.294L17.4178 1.13949C15.8985 -0.379831 13.5111 -0.379831 11.9917 1.13949L1.13949 11.9193C-0.379828 13.4387 -0.379828 15.8262 1.13949 17.3455L13.294 29.5L1.13949 41.6545C-0.379828 43.1742 -0.379828 45.5613 1.13949 47.081L11.9194 57.8603C13.4387 59.3799 15.8262 59.3799 17.3455 57.8603L29.5 45.7064L41.6545 57.8603C43.1738 59.3799 45.5612 59.3799 47.0806 57.8603L57.9328 47.081C59.3798 45.5613 59.3798 43.1742 57.9328 41.6545Z"
      fill="#FF003A"
    />
  </svg>
);

IconProviderTurboGames.propTypes = {
  className: PropTypes.string,
};

IconProviderTurboGames.defaultProps = {
  className: '',
};
