import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'reactstrap/lib/Collapse';
import { FormattedTag } from 'core/components';
import classNames from 'classnames';
import { IconArrowDown } from '../../icons/icon-arrow-down/icon-arrow-down';

export class FooterCollapse extends Component {
  state = {
    isOpen: false,
  }

  static propTypes = {
    togglerTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }

  toggle =() => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  }

  render() {
    const { isOpen } = this.state;

    const { togglerTitle, children } = this.props;

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={this.toggle}
          className="d-flex font-weight-normal d-sm-none justify-content-between align-items-center py-1_25 border-bottom border-white-15 cursor-pointer"
        >
          <FormattedTag className={classNames({ 'text-white font-weight-bold': !isOpen, 'text-white-50 font-weight-medium': isOpen })} id={`footer-collapse.title-${togglerTitle}`} />
          <IconArrowDown color="white" className={classNames({ 'icon-down': isOpen })} />
        </div>
        <Collapse isOpen={isOpen} className="pt-1_25 d-sm-none">
          {children}
        </Collapse>
        <div className="d-none d-sm-block">
          {children}
        </div>
      </>
    );
  }
}
