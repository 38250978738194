import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderAmusnetEgt = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12981 12.1835C5.14818 10.0447 7.12694 7.29398 10.5001 5.79081C16.4364 3.14407 19.8361 5.58459 20.7161 7.89219C21.536 10.043 19.9146 13.8179 17.1043 16.6317C19.1098 15.1643 20.7478 13.5896 21.885 12.0591C23.3929 10.0276 24.0174 8.07625 23.4447 6.55944C22.5964 4.32002 19.2901 3.60082 15.0954 4.20413C10.9025 4.80744 7.68133 5.85898 5.09474 7.20025C2.2176 8.69149 0.73311 10.4639 0.52104 12.4869C0.212118 15.4251 3.29632 19.3722 10.3514 19.8597C14.0584 20.1153 17.6153 20.2005 24.5 18.4945C23.6801 18.5218 5.05634 20.8822 5.13148 12.1835H5.12981Z"
      fill="#A6CE39"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4992 16C13.7019 16 15.5 14.2031 15.5 12C15.5 9.79683 13.7035 8 11.4992 8C9.2965 8 7.5 9.79683 7.5 12C7.5 14.2031 9.2965 16 11.4992 16Z"
      fill="#A6CE39"
    />
  </svg>
);

IconProviderAmusnetEgt.propTypes = {
  className: PropTypes.string,
};

IconProviderAmusnetEgt.defaultProps = {
  className: '',
};

