import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconMenu = ({ className, color, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.19998 7.59995C3.19998 6.82675 3.82678 6.19995 4.59998 6.19995H24.6C25.3732 6.19995 26 6.82675 26 7.59995C26 8.37315 25.3732 8.99995 24.6 8.99995H4.59998C3.82678 8.99995 3.19998 8.37315 3.19998 7.59995ZM3.19995 14.5999C3.19995 13.8267 3.82675 13.1999 4.59995 13.1999H24.6C25.3731 13.1999 26 13.8267 26 14.5999C26 15.3731 25.3731 15.9999 24.6 15.9999H4.59995C3.82675 15.9999 3.19995 15.3731 3.19995 14.5999ZM4.59995 20.1999C3.82675 20.1999 3.19995 20.8267 3.19995 21.5999C3.19995 22.3731 3.82675 22.9999 4.59995 22.9999H24.6C25.3731 22.9999 26 22.3731 26 21.5999C26 20.8267 25.3731 20.1999 24.6 20.1999H4.59995Z"
      fill={color || 'url(#icon-menu-paint0_linear_1673_32217)'}
    />
  </svg>
);

IconMenu.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconMenu.defaultProps = {
  className: '',
  color: colors.white,
  onClick: () => {},
};
