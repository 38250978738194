import React from 'react';
import PropTypes from 'prop-types';

export const IconLangDe = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M0.746338 16.1739C2.44185 20.7434 6.84037 24 11.9999 24C17.1595 24 21.558 20.7434 23.2536 16.1739L11.9999 15.1304L0.746338 16.1739Z"
        fill="#FFDA44"
      />
      <path
        d="M11.9999 0C6.84037 0 2.44185 3.2565 0.746338 7.82611L11.9999 8.86955L23.2536 7.82606C21.558 3.2565 17.1595 0 11.9999 0Z"
        fill="black"
      />
      <path
        d="M0.746391 7.82611C0.264047 9.1261 0 10.5322 0 12C0 13.4678 0.264047 14.8739 0.746391 16.1739H23.2537C23.736 14.8739 24 13.4678 24 12C24 10.5322 23.736 9.1261 23.2536 7.82611H0.746391Z"
        fill="#D80027"
      />
    </g>
  </svg>
);

IconLangDe.propTypes = {
  className: PropTypes.string,
};

IconLangDe.defaultProps = {
  className: null,
};
