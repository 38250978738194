import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlatipusBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="135"
    height="49"
    viewBox="0 0 135 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-provider-platipus-big-clip-0)">
      <path
        d="M135 0.609558V44.2949C135 46.191 133.483 47.7837 131.511 47.7837H3.48876C1.51685 47.7837 0 46.191 0 44.2949V11.8343C0 9.93821 1.51685 8.42136 3.48876 8.42136H127.112L134.317 0.609558H135Z"
        fill="#FF403F"
      />
      <path
        d="M26.014 23.6657C26.014 27.6854 22.9803 30.6433 18.8848 30.6433H16.0028V35.9522H10.6938V16.764H18.8848C22.9803 16.764 26.014 19.6461 26.014 23.6657ZM20.5534 23.6657C20.5534 22.3006 19.6433 21.3904 18.2781 21.3904H16.0028V26.0168H18.2781C19.6433 26.0168 20.5534 25.0309 20.5534 23.6657Z"
        fill="white"
      />
      <path d="M28.1376 15.7781H33.2949V35.9522H28.1376V15.7781Z" fill="white" />
      <path
        d="M52.2556 21.0871V35.9522H48.3118L47.8567 34.8904C46.6432 35.8764 45.1264 36.4073 43.382 36.4073C38.9073 36.4073 35.6461 33.1461 35.6461 28.5197C35.6461 23.8933 38.9073 20.632 43.382 20.632C45.1264 20.632 46.7191 21.2388 47.9326 22.2247L48.4635 21.0871H52.2556ZM47.4017 28.5197C47.4017 26.6236 46.0365 25.1826 44.1404 25.1826C42.1685 25.1826 40.8034 26.6236 40.8034 28.5197C40.8034 30.4916 42.2444 31.9326 44.1404 31.9326C46.0365 31.9326 47.4017 30.4916 47.4017 28.5197Z"
        fill="white"
      />
      <path
        d="M65.4523 31.4775V35.9522H61.9635C58.7022 35.9522 56.7303 33.9803 56.7303 30.6433V25.0309H54V23.8933L60.6742 16.764H61.6601V21.1629H65.3764V25.0309H61.8118V29.809C61.8118 30.8708 62.4185 31.4775 63.5562 31.4775H65.4523Z"
        fill="white"
      />
      <path
        d="M67.8792 21.1629H72.9607V35.9523H67.8792V21.1629ZM67.8792 14.9438H72.9607V19.2669H67.8792V14.9438Z"
        fill="white"
      />
      <path
        d="M92.6039 28.5197C92.6039 33.1461 89.4185 36.4831 84.868 36.4831C83.5028 36.4831 82.2893 36.1039 81.2275 35.4972V41.3371H76.1461V21.1629H79.6348L80.3174 22.3006C81.5309 21.2388 83.0477 20.632 84.868 20.632C89.4185 20.632 92.6039 23.8933 92.6039 28.5197ZM87.5225 28.5197C87.5225 26.6236 86.0814 25.1826 84.1854 25.1826C82.2893 25.1826 80.8483 26.6236 80.8483 28.5197C80.8483 30.4916 82.2893 31.9326 84.1854 31.9326C86.0814 31.9326 87.5225 30.4916 87.5225 28.5197Z"
        fill="white"
      />
      <path
        d="M94.8034 29.5814V21.1629H99.8848V29.3539C99.8848 30.9466 100.719 31.8567 102.084 31.8567C103.449 31.8567 104.284 30.9466 104.284 29.3539V21.1629H109.441V29.5814C109.441 33.7528 106.483 36.4831 102.084 36.4831C97.6854 36.4073 94.8034 33.7528 94.8034 29.5814Z"
        fill="white"
      />
      <path
        d="M118.163 36.4073C114.067 36.4073 111.64 34.2837 111.565 31.0983H116.494C116.494 32.1601 117.253 32.691 118.239 32.691C118.997 32.691 119.68 32.3118 119.68 31.5534C119.68 30.6433 118.542 30.4157 117.101 30.1882C114.826 29.809 111.792 29.1264 111.792 25.5618C111.792 22.5281 114.295 20.632 118.163 20.632C121.879 20.632 124.382 22.6798 124.382 25.6376H119.604C119.604 24.7275 118.997 24.2725 118.011 24.2725C117.253 24.2725 116.646 24.6517 116.646 25.3343C116.646 26.1685 117.784 26.3961 119.225 26.6236C121.576 27.0786 124.685 27.5337 124.685 31.3258C124.685 34.4354 122.031 36.4073 118.163 36.4073Z"
        fill="white"
      />
    </g>
  </svg>
);

IconProviderPlatipusBig.propTypes = {
  className: PropTypes.string,
};

IconProviderPlatipusBig.defaultProps = {
  className: '',
};
