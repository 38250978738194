import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withInfo } from 'core/hocs';
import {
  addScriptElementToDOM,
  getCasinoLicenseId,
  getCasinoLicenseKey,
  isProjectEnvProdMode,
  getValidationLicenseLink,
  isEmptyOrNil,
} from 'core/helpers';

import './license-seal.scss';

const casinoLicenseKey = getCasinoLicenseKey();
const casinoLicenseId = getCasinoLicenseId();
const apgLicenseScriptLink = getValidationLicenseLink();
const cegLicenseScriptLink = `https://${casinoLicenseId}.curacao-egaming.com/ceg-seal.js`;

export const GC_LICENSE_BY_DOMAIN_LINK = 'https://verification.curacao-egaming.com/validateview.aspx?domain=crasher.com';
export const GC_LICENSE_BY_DOMAIN_IMAGE = 'https://verification.curacao-egaming.com/validate.ashx?domain=crasher.com';

export const LICENSE_TYPES = {
  GC: 'GC', // Gaming Curacao
  APG: 'APG', // Abudantia B.V.
  CEG: 'CEG', // Curacao e-Gaming
};

class LicenseSealUI extends Component {
  static propTypes = {
    licenseType: PropTypes.oneOf([
      LICENSE_TYPES.GC,
      LICENSE_TYPES.APG,
      LICENSE_TYPES.CEG,
    ]),
    scriptLink: PropTypes.string,
    className: PropTypes.string,
    countriesWithMockLicense: PropTypes.arrayOf(PropTypes.string),
    countryInfo: PropTypes.shape().isRequired,
    isGCLicenceTypeByDomain: PropTypes.bool,
  };

  static defaultProps = {
    licenseType: LICENSE_TYPES.GC,
    scriptLink: '',
    className: '',
    countriesWithMockLicense: [],
    isGCLicenceTypeByDomain: false,
  };

  componentDidMount = () => {
    const {
      licenseType,
      scriptLink,
      countriesWithMockLicense,
      countryInfo: {
        iso2Code,
      } = {},
    } = this.props;

    const isMockLicense = !isProjectEnvProdMode() || (countriesWithMockLicense || []).includes(iso2Code);

    const targetScriptLink = R.when(
      isEmptyOrNil,
      () => (
        R.cond([
          [R.equals(LICENSE_TYPES.APG), R.always(apgLicenseScriptLink)],
          [R.equals(LICENSE_TYPES.CEG), R.always(cegLicenseScriptLink)],
          [R.T, R.always('')],
        ])(licenseType)
      ),
    )(scriptLink);

    if (!isMockLicense && !isEmptyOrNil(targetScriptLink)) {
      addScriptElementToDOM({
        src: targetScriptLink,
        async: true,
        onLoadEventListener: this.invokeLicenseValidation,
      });
    }
  };

  invokeLicenseValidation = () => {
    const licenseInitFunc = R.path([
      casinoLicenseKey,
      'init',
    ], window);

    R.when(
      R.is(Function),
      R.call,
    )(licenseInitFunc);
  };

  render() {
    const {
      licenseType,
      className,
      // countriesWithMockLicense,
      isGCLicenceTypeByDomain,
      // countryInfo: {
      // iso2Code,
      // } = {},
    } = this.props;

    // We need to use the mock icon on this brand due to ticket: MA-28
    const isMockLicense = true; // !isProjectEnvProdMode() || (countriesWithMockLicense || []).includes(iso2Code);
    const isGCLicense = licenseType === LICENSE_TYPES.GC;
    let commonClassName = 'license-seal license-seal-gc flex-shrink-0';

    if (isGCLicense) {
      return (
        <>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isMockLicense
            ? (
              isGCLicenceTypeByDomain ? (
                <div className={classNames(commonClassName, 'license-seal-dev position-relative align-self-baseline', className)}>
                  <img
                    src="/assets/public/images/license/gc-logo-by-domain.png"
                    alt="license-main"
                    className="license-seal-gc-by-domain w-100"
                  />
                </div>
              ) : (
                <div className={classNames(commonClassName, 'license-seal-dev position-relative align-self-baseline', className)}>
                  <img
                    src="/assets/public/images/license/gc-logo.png"
                    alt="license-main"
                    className="license-seal-gc-main"
                  />

                  <img
                    src="/assets/public/images/license/verified.png"
                    alt="license-verified"
                    className="license-seal-gc-verified position-absolute"
                  />
                </div>
              )
            ) : (
              isGCLicenceTypeByDomain ? (
                <a
                  href={GC_LICENSE_BY_DOMAIN_LINK}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  <img
                    src={GC_LICENSE_BY_DOMAIN_IMAGE}
                    alt=""
                    className={classNames(commonClassName, 'license-seal-prod license-seal-gc-by-domain', className)}
                  />
                </a>
              ) : (
                <iframe
                  title="gaming-curacao"
                  className={classNames(commonClassName, 'license-seal-prod', className)}
                  src={`https://licensing.gaming-curacao.com/validator/?lh=${casinoLicenseId}&template=tseal`}
                  width="150"
                  height="50"
                  style={{ border: 'none' }}
                />
              )
            )}
        </>
      );
    }

    const targetLicenseType = (licenseType || '').toLowerCase();
    commonClassName = `license-seal license-seal-common license-seal-${targetLicenseType} flex-shrink-0`;

    const licenseDataAttrsProps = {
      [`data-${targetLicenseType}-seal-id`]: casinoLicenseId,
      [`data-${targetLicenseType}-image-size`]: 128,
      [`data-${targetLicenseType}-image-type`]: 'basic-small',
    };

    return (
      <>
        {isMockLicense ? (
          <img
            src="/assets/public/images/license/license.png"
            alt="license-main"
            className={classNames(commonClassName, 'license-seal-dev', className)}
          />
        ) : (
          <div
            role="button"
            id={casinoLicenseKey.replace(/_/g, '-')}
            className={classNames(commonClassName, 'license-seal-prod', className)}
            {...licenseDataAttrsProps}
          />
        )}
      </>
    );
  }
}

export const LicenseSeal = withInfo(LicenseSealUI);

