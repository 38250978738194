import React from 'react';
import PropTypes from 'prop-types';

export const IconPlayCircle = ({ className, isSmall }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={isSmall ? '38' : '64'}
    height={isSmall ? '39' : '64'}
    viewBox="0 0 64 64"
    fill="none"
  >
    <circle opacity="0.2" cx="32" cy="32" r="32" fill="white" />
    <circle cx="32" cy="31.9999" r="27.6364" fill="url(#icon-play-circle-1)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 58.1817C46.4598 58.1817 58.1818 46.4597 58.1818 31.9999C58.1818 17.5401 46.4598 5.81812 32 5.81812C17.5402 5.81812 5.81818 17.5401 5.81818 31.9999C5.81818 46.4597 17.5402 58.1817 32 58.1817ZM31.6518 49.4545C45.5006 49.4545 56.7273 39.6943 56.7273 27.6546C56.7273 20.1914 50.9112 23.67 44.1221 27.7305C39.9589 30.2204 35.4299 32.9292 31.6518 33.4679C28.1098 33.9729 23.7974 32.6115 19.79 31.3464C13.1074 29.2368 7.27279 27.3949 7.27279 34.9212C7.27279 46.961 17.803 49.4545 31.6518 49.4545Z"
      fill="#3674DA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3428 29.1624C44.0429 30.5818 44.0733 33.293 42.3979 34.7478C39.0639 37.6426 33.5121 41.9338 28.3085 43.5227C26.3831 44.1106 24.7273 42.3357 24.7273 40.132V23.8936C24.7273 21.6179 26.4825 19.8302 28.4515 20.5098C33.5359 22.2649 39.0158 26.3848 42.3428 29.1624Z"
      fill="white"
    />
  </svg>
);

IconPlayCircle.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};

IconPlayCircle.defaultProps = {
  className: null,
  isSmall: false,
};
