import React, { Component } from 'react';
import store from 'store';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { parse as parseQueryString } from 'qs';
import { withRouter } from 'react-router-dom';

import { FormattedTag } from 'core/components';

import { withPwaInstallContext } from 'hocs/with-pwa-install';
import { IconPwa } from 'components/icons/icon-pwa/icon-pwa';
import { IconPwaClose } from 'components/icons/icon-pwa-close/icon-pwa-close';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { PwaInstallFromMobileModal } from 'components/modals/pwa-install-modal/pwa-install-from-mobile-modal';
import { PwaInstallFromDesktopModal } from 'components/modals/pwa-install-modal/pwa-install-from-desktop-modal';
import { GA } from 'helpers/ga';

import { IS_PWA_INSTALL_GUIDE_CLOSED_LS_KEY, MODAL_IDS } from '../../constants';

import './pwa-install-guide.scss';

class PwaInstallGuideUI extends Component {
  static propTypes = {
    location: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    /* eslint-disable react/no-unused-prop-types */
    /* eslint-disable react/require-default-props */
    onInstallClick: PropTypes.func,
    openModal: PropTypes.func,
    isPwaGuideReady: PropTypes.bool,
    isDeferredPromptReady: PropTypes.bool,
    isIncognito: PropTypes.bool,
    os: PropTypes.string,
    browserName: PropTypes.string,
  };

  static contextType = withPwaInstallContext;

  state = {
    isFromQr: false,
    isInstallModalFromQrOpened: false,
    isPwaInstallClose: false,
  };

  componentDidMount() {
    const { location: { search }, history } = this.props;

    const queryParams = parseQueryString(search, { ignoreQueryPrefix: true });
    const isFromQr = queryParams.fromQr;

    if (isFromQr) {
      this.setState({ isFromQr });
      history.replace(queryParams, '');
    }

    const isPwaInstallClose = store.get(IS_PWA_INSTALL_GUIDE_CLOSED_LS_KEY) || false;
    this.setState({ isPwaInstallClose });
  }

  componentDidUpdate() {
    const { os, browserName, isDeferredPromptReady } = this.context;
    const { isFromQr, isInstallModalFromQrOpened, isIncognito } = this.state;

    if (!isInstallModalFromQrOpened && isFromQr) {
      if (isDeferredPromptReady || os !== 'android' || browserName !== 'Chrome' || isIncognito) {
        this.openInstallModalFromQr();
      }
    }
  }

  openInstallModalFromQr = () => {
    const { openModal } = this.context;
    openModal(MODAL_IDS.PWA_INSTALL_MOBILE);

    this.setState({ isInstallModalFromQrOpened: true });
  }

  onMobileInstallClick = () => {
    const { onInstallClick } = this.context;

    GA.event({ category: 'App', action: 'click', label: 'App' });

    onInstallClick(MODAL_IDS.PWA_INSTALL_MOBILE);
  }

  onDesktopInstallClick = () => {
    this.context.openModal(MODAL_IDS.PWA_INSTALL);
  };

  onPwaInstallClose = () => {
    store.set(IS_PWA_INSTALL_GUIDE_CLOSED_LS_KEY, true);
    this.setState({ isPwaInstallClose: true });
  }

  render() {
    const { isPwaGuideReady, isIncognito } = this.context;

    const { isFromQr, isPwaInstallClose } = this.state;

    if (!isPwaGuideReady) {
      return null;
    }

    return (
      <>
        <div className={classNames('pwa-install-guide bg-blue-light px-2 pt-1 pb-2 py-sm-0_75 d-none justify-content-between align-items-center flex-column flex-sm-row flex-md-row',
          { 'd-flex': !isPwaInstallClose })}
        >
          <div className="d-flex align-self-start align-self-sm-center align-items-center ml-md-5">
            <IconPwa className="icon-pwa mr-2" />
            <div className="d-flex flex-column d-md-block">
              <FormattedTag id="pwa.title.app.name" className="h4 text-uppercase letter-spacing-default text-white" />
              <FormattedTag id="pwa.promo" className="pwa-desc text-white-90 mt-0_25 mt-md-0 ml-md-1 text-small line-height-1 letter-spacing-default" />
            </div>
          </div>

          <div className="align-self-end d-flex align-items-center">
            <FormattedTag
              id="pwa.not-now"
              role="button"
              className="btn p-0 d-md-none text-white"
              onClick={this.onPwaInstallClose}
            />

            <FormattedTag
              id="install"
              tag={Button}
              onClick={this.onMobileInstallClick}
              color="primary"
              className="d-md-none px-2_75 px-md-3_5 py-1 ml-2 ml-md-0"
            />

            {/* DESKTOP */}
            <FormattedTag
              id="install"
              tag={Button}
              color="primary"
              className="d-none d-md-inline px-2_75 px-md-3_5 py-1 ml-2 ml-md-0"
              onClick={this.onDesktopInstallClick}
            />

            <IconPwaClose className="d-none d-md-inline ml-md-3 ml-lg-4_5 mr-lg-1 cursor-pointer" onClick={this.onPwaInstallClose} />
          </div>
        </div>

        <SkipServerRender skip>
          {/* MOBILE */}
          <PwaInstallFromMobileModal
            coreProps={{ id: MODAL_IDS.PWA_INSTALL_MOBILE }}
            isFromQr={isFromQr}
            isIncognito={isIncognito}
            onInstallClick={this.onMobileInstallClick}
          />

          {/* DESKTOP */}
          <PwaInstallFromDesktopModal isFromQr={isFromQr} />
        </SkipServerRender>

      </>
    );
  }
}

export const PwaInstallGuide = withRouter(PwaInstallGuideUI);
