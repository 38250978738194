import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale, withUser } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { filterValuesInTheInterval, getRemoteConfig, isEmptyOrNil } from 'core/helpers';

import { formatAmountWithCurrency } from '../../../../helpers/numbers';
import { CURRENCY_SYMBOLS } from '../../../../constants';

import './payment-sum.scss';

const DEFAULT_PRESET = [20, 50, 100, 200, 500];

export class PaymentSumUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    sumValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number]).isRequired,
    currency: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    className: PropTypes.string,
    sumChangeHandler: PropTypes.func.isRequired,
    minimumPayment: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    maximumPayment: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    isDeposit: PropTypes.bool,
    userBalance: PropTypes.shape(),
  };

  static defaultProps = {
    className: '',
    currency: '',
    minimumPayment: null,
    maximumPayment: null,
    isDeposit: false,
    userBalance: null,
  };

  state = {
    stateCurrency: '',
    minPayment: null,
    maxPayment: null,
    paymentValues: [],
  };

  static getDerivedStateFromProps(props, prevState) {
    const {
      currency,
      minimumPayment,
      maximumPayment,
    } = props;
    const { stateCurrency, minPayment, maxPayment } = prevState;

    if (stateCurrency !== currency || minimumPayment !== minPayment || maximumPayment !== maxPayment) {
      const depositPresets = getRemoteConfig('DEPOSIT.PRESETS') || {};
      const depositAmounts = depositPresets[currency] || depositPresets.EUR || DEFAULT_PRESET;

      return {
        stateCurrency: currency,
        minPayment: minimumPayment,
        maxPayment: maximumPayment,
        paymentValues: filterValuesInTheInterval(depositAmounts, minimumPayment, maximumPayment),
      };
    }

    return null;
  }

  changeSumValue = (event) => {
    const { sumChangeHandler } = this.props;
    sumChangeHandler(event.target.id);
  };

  getFormattedAmount = (amount) => {
    const { userBalance: { currency }, locale } = this.props;

    return formatAmountWithCurrency({
      currency,
      locale,
      amount,
    });
  }

  render() {
    const {
      className,
      sumValue,
      isDeposit,
      userBalance: { currency },
    } = this.props;
    const { paymentValues, minPayment, maxPayment } = this.state;

    if (isEmptyOrNil(paymentValues)) {
      return null;
    }

    return (
      <div
        className={classNames('payment-sum', className)}
      >
        <div className="payment-headline font-weight-medium d-flex justify-content-between align-items-center">
          <FormattedTag className="h4 font-weight-bold" id={`profile.${isDeposit ? 'deposit' : 'withdrawal'}.sum`} isHtml />
          <div className="d-inline caption pl-1 font-weight-normal h6">
            <FormattedTag id="min" isHtml />:
            <span className="font-weight-semi-bold mr-2_75">&#32;{this.getFormattedAmount(minPayment)}</span>
            <FormattedTag id="max" isHtml />:
            <span className="font-weight-semi-bold">&#32;{this.getFormattedAmount(maxPayment)}</span>
          </div>
        </div>
        <div className="d-flex align-items-center mt-1_5">

          {/* <div className="font-weight-semi-bold mr-0_5 mr-sm-1"> */}
          {/* {CURRENCY_SYMBOLS[currency] || currency} */}
          {/* </div> */}

          <div className="d-flex flex-nowrap w-100 justify-content-between text-center payment-sum-cell-wrapper">
            {paymentValues.map(value => (
              <div
                key={value}
                id={value}
                className={classNames('font-weight-semi-bold payment-value-cell d-flex justify-content-center align-items-center flex-grow-1 py-1_5 position-relative rounded-pill', { active: String(sumValue) === String(value) })}
                tabIndex="0"
                role="button"
                onClick={this.changeSumValue}
                onKeyPress={this.changeSumValue}
              >
                {CURRENCY_SYMBOLS[currency]}
                {' '}
                {value}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export const PaymentSum = withLocale(
  withUser(PaymentSumUI)
);
