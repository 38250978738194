import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconRedirect = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="69" height="68" viewBox="0 0 69 68" fill="none">
    <path
      d="M34.5 68C15.7514 68 0.5 52.7486 0.5 34C0.5 15.2514 15.7514 0 34.5 0C53.2486 0 68.5 15.2514 68.5 34C68.5 52.7486 53.2486 68 34.5 68Z"
      fill={colors.bgSecondaryLight}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 61C49.9117 61 62 48.9117 62 34C62 19.0883 49.9117 7 35 7C20.0883 7 8 19.0883 8 34C8 48.9117 20.0883 61 35 61ZM35 57.8235C21.8426 57.8235 11.1765 47.1574 11.1765 34C11.1765 20.8426 21.8426 10.1765 35 10.1765C48.1574 10.1765 58.8235 20.8426 58.8235 34C58.8235 47.1574 48.1574 57.8235 35 57.8235ZM35.4659 40.8181C34.8456 41.4384 34.8456 42.444 35.4659 43.0642C36.0861 43.6845 37.0917 43.6845 37.712 43.0642L44.859 35.9172C45.4793 35.2969 45.4793 34.2913 44.859 33.6711L37.712 26.524C37.0917 25.9038 36.0861 25.9038 35.4659 26.524C34.8456 27.1443 34.8456 28.1499 35.4659 28.7701L39.9016 33.2059H26.2654C25.3882 33.2059 24.6772 33.917 24.6772 34.7941C24.6772 35.6713 25.3882 36.3824 26.2654 36.3824H39.9016L35.4659 40.8181Z"
      fill="#01FA6C"
    />
  </svg>
);

IconRedirect.propTypes = {
  className: PropTypes.string,
};

IconRedirect.defaultProps = {
  className: null,
};
