import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderEzugiBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100" height="41" viewBox="0 0 100 41">
    <g fill="none" fillRule="nonzero">
      <path fill="#fcfcfd" d="M29.444 12.028l-.31 14.357s-.464-5.663.31-14.357zM71.837 30.05a.358.358 0 0 1-.349.357c-.946.026-1.808.128-2.832.18-.988.048-1.947.086-2.709.246a.357.357 0 0 1-.431-.348v-1.952a.357.357 0 0 0-.563-.29 24.89 24.89 0 0 1-2.494 1.647c-1.198.669-2.065.985-3.18.985-2.769 0-5.125-1.615-5.125-5.871v-7.72c0-2.09-.33-2.535-1.363-2.77l-.891-.183a.357.357 0 0 1-.278-.325l-.032-.453a.356.356 0 0 1 .34-.382c1.174-.042 4.042-.127 5.202-.217a.353.353 0 0 1 .381.379c-.087 1.212-.087 2.938-.087 5.29v5.319c0 3.507 1.776 4.452 3.635 4.452 1.445 0 2.97-.558 4.342-1.783a.361.361 0 0 0 .12-.269v-9.057c0-2.091-.371-2.575-1.736-2.772l-1.2-.192a.357.357 0 0 1-.3-.353v-.439c-.001-.192.15-.35.342-.357 1.326-.041 4.57-.13 5.762-.223a.354.354 0 0 1 .38.356v12.761c0 1.97.33 3.093 1.948 3.206l.772.028a.356.356 0 0 1 .343.357l.003.393zM50.765 25.91a408.919 408.919 0 0 1-1.612 4.175.5.5 0 0 1-.467.318H35.744a.502.502 0 0 1-.45-.28l-.02-.041a.503.503 0 0 1 .042-.512c3.313-4.675 6.449-9.388 9.61-14.265a.506.506 0 0 0-.417-.78h-2.751c-2.975 0-3.512.592-4.669 2.799l-.376.696a.502.502 0 0 1-.603.236l-.171-.058a.503.503 0 0 1-.317-.624c.386-1.254.822-2.598 1.12-3.98a.502.502 0 0 1 .49-.4h11.971a.504.504 0 1 1 .416.785 786.717 786.717 0 0 0-9.424 14.345.506.506 0 0 0 .417.778h3.171c3.134 0 3.962-.824 5.526-3.175l.02-.032.297-.523a.502.502 0 0 1 .652-.205l.235.112a.504.504 0 0 1 .252.631zM89.764 29.866c0-.276.209-.506.482-.534 2.285-.231 3.458-.598 3.458-3.107v-8.117c0-2.287-.109-3.044-2.159-3.422a.54.54 0 0 1-.443-.528v-.452c0-.29.23-.528.52-.536.894-.025 2.792-.09 4.51-.216a.533.533 0 0 1 .575.535v12.736c0 2.51.482 2.876 2.712 3.106a.536.536 0 0 1-.051 1.07h-9.069a.536.536 0 0 1-.535-.535z" />
      <path fill="url(#icon-provider-ezugi-big-a)" d="M4.13 7.555L32.207 0l-1.022 40.276L0 34.353z" />
      <path fill="url(#icon-provider-ezugi-big-b)" d="M32.206 0l1.519 1.104-.988 37.404-1.553 1.768z" />
      <path fill="#fcfcfd" d="M4.13 7.555L3.726 9.47c0 .09-.007.179-.007.27 0 11.662 9.296 21.117 21.528 21.117 2.095 0 4.18-.284 6.198-.845L32.206 0 4.13 7.555z" opacity=".15" />
      <path fill="#fcfcfd" d="M28.751 27.825c-.503 1.57-2.07 5.944-2.7 7.34L2.836 31.78l.083-.911c4.276.278 4.682.034 4.971-2.87l1.428-14.2c.327-3.206-.06-3.26-3.93-2.626l.09-.943L28.565 4.74c-.14 1.171-.265 4.302-.397 6.667l-1.559.442c-.243-1.847-.652-2.751-1.228-3.564-.624-.836-2.015-.825-5.791.01l-3.93.863c-1.59.345-1.703.483-1.823 1.598l-.934 8.732 4.705-.337c4.63-.334 4.927-.697 5.895-3.718l1.51-.176-1.028 8.623h-1.476c-.296-3.107-.623-3.345-5.03-3.138l-4.728.232-.768 7.184c-.187 1.781.013 2.648.835 3.206.86.613 2.553.824 4.99 1.13 3.743.474 5.178.295 6.419-.754.966-.836 2.125-2.376 3.06-4.169l1.464.255zM89.328 13.906a.731.731 0 0 1-.681.736c-.76.057-1.483.244-1.8.779a.376.376 0 0 0 0 .493c.553.88.843 1.902.835 2.943 0 4.255-3.72 6.304-7.233 6.304a12.351 12.351 0 0 1-1.669-.126 1.046 1.046 0 0 0-.85.25c-.428.387-.834.916-.834 1.333 0 .749.744 1.22 2.604 1.22 1.53 0 2.973-.131 4.32-.01 2.546.229 5.445 2.239 5.445 5.397 0 3.314-4.007 5.777-9.503 5.777-4.627 0-7.45-2.284-7.45-4.53 0-2.773 2.976-3.647 3.951-4.389a.311.311 0 0 0-.074-.54c-1.197-.476-2.164-.635-2.514-1.233-1.283-2.188 2.192-2.76 2.879-3.451a.31.31 0 0 0-.088-.503c-1.71-.837-3.246-2.615-3.246-5.106 0-4.452 4.214-6.54 7.396-6.54h.042a7.902 7.902 0 0 1 2.526.418c.108.037.221.056.335.057h4.868c.4 0 .724.322.729.722l.012-.001zM75.791 33.21c0 2.168 2.135 4.033 5.192 4.033 3.786 0 5.41-1.565 5.689-3.032.335-1.772-.594-2.742-1.783-3.485-.934-.578-1.605-.836-3.42-.934-3.849-.202-5.678 2.314-5.678 3.418zm1.198-14.59c0 3.067 1.612 5.28 3.884 5.28 1.818-.039 3.43-1.576 3.43-4.689 0-3.066-1.53-5.28-3.925-5.28-1.735.007-3.388 1.655-3.388 4.69h-.001z" />
      <path fill="#3D91CF" d="M91.91 8.145c.278 1.218 1.606 1.902 2.968 1.533 1.361-.37 2.24-1.673 1.963-2.884-.276-1.211-1.606-1.903-2.968-1.533-1.361.369-2.24 1.665-1.963 2.884z" />
    </g>
  </svg>
);

IconProviderEzugiBig.propTypes = {
  className: PropTypes.string,
};
IconProviderEzugiBig.defaultProps = {
  className: null,
};
