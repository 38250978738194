import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { LangSwitcher as LangSwitcherCore, FormattedTag } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { LangLabel } from 'components/lang-label/lang-label';
import { LOCALE_KEYS } from 'core/constants';
import { IconLangEn } from 'components/icons/icon-lang-en/icon-lang-en';
import { IconLangDe } from 'components/icons/icon-lang-de/icon-lang-de';
import { IconLangFr } from 'components/icons/icon-lang-fr/icon-lang-fr';
import { IconLangDeAt } from 'components/icons/icon-lang-de-at/icon-lang-de-at';
import { IconLangIt } from 'components/icons/icon-lang-it/icon-lang-it';
import { IconLangDeCh } from 'components/icons/icon-lang-de-ch/icon-lang-de-ch';
import { IconLangNo } from 'components/icons/icon-lang-no/icon-lang-no';
import { IconLangFi } from 'components/icons/icon-lang-fi/icon-lang-fi';
import { IconLangSe } from 'components/icons/icon-lang-se/icon-lang-se';
import { IconLangEnCa } from 'components/icons/icon-lang-en-ca/icon-lang-en-ca';
import { IconLangPt } from 'components/icons/icon-lang-pt/icon-lang-pt';
import { IconLangMx } from 'components/icons/icon-lang-mx/icon-lang-mx';

import { IconArrowDown } from '../icons/icon-arrow-down/icon-arrow-down';
import { InputText } from '../input-text/input-text';

import './lang-switcher.scss';

const LANG_ICON = {
  [LOCALE_KEYS.EN]: IconLangEn,
  [LOCALE_KEYS.DE]: IconLangDe,
  [LOCALE_KEYS.FR]: IconLangFr,
  [LOCALE_KEYS.IT]: IconLangIt,
  [LOCALE_KEYS.DE_CH]: IconLangDeCh,
  [LOCALE_KEYS.NB]: IconLangNo,
  [LOCALE_KEYS.FI]: IconLangFi,
  [LOCALE_KEYS.SE]: IconLangSe,
  [LOCALE_KEYS.EN_CA]: IconLangEnCa,
  [LOCALE_KEYS.PT]: IconLangPt,
  [LOCALE_KEYS.ES]: IconLangMx,
  [LOCALE_KEYS.EN_GB]: IconLangEn,
  [LOCALE_KEYS.DE_AT]: IconLangDeAt,
};

const CASINO_LANGUAGES = {
  EN: LOCALE_KEYS.EN,
  DE: LOCALE_KEYS.DE,
  FR: LOCALE_KEYS.FR,
  IT: LOCALE_KEYS.IT,
  DE_CH: LOCALE_KEYS.DE_CH,
  NB: LOCALE_KEYS.NB,
  FI: LOCALE_KEYS.FI,
  SE: LOCALE_KEYS.SE,
  EN_CA: LOCALE_KEYS.EN_CA,
  PT: LOCALE_KEYS.PT,
  ES: LOCALE_KEYS.ES,
  EN_GB: LOCALE_KEYS.EN_GB,
  DE_AT: LOCALE_KEYS.DE_AT,
};

export class LangSwitcherUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    changeLocale: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    className: PropTypes.string,
    withText: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    withText: false,
  };

  state = {
    isDropdownOpen: false,
  };

  changeCurrentLocale = ({
    currentTarget: {
      dataset: { locale },
    },
  }) => {
    const { changeLocale, locale: currentLocale } = this.props;

    if (currentLocale !== locale) {
      changeLocale({ locale });
    }
  };

  toggle = () => {
    this.setState(({ isDropdownOpen }) => ({
      isDropdownOpen: !isDropdownOpen,
    }));
  };

  langImage = () => {
    const { locale } = this.props;

    return (
      <img className="lang-switcher-icon rounded-circle m-auto" src={`/assets/public/${locale}.png`} alt={locale} />
    );
  };

  render() {
    const {
      locale, intl, className, withText,
    } = this.props;
    // const CurrentLangIcon = LANG_ICON[locale];

    const { isDropdownOpen } = this.state;

    return (
      <div className={classNames('lang-switcher', className)}>
        <Dropdown className="text-small" isOpen={isDropdownOpen} toggle={this.toggle}>
          <DropdownToggle tag="span" role="button" className="d-flex align-items-center">
            {withText ? (
              <InputText
                name="lang-switcher"
                wrapperClassName="dropdown-input"
                icon={this.langImage}
                onChange={null}
                rightContent={<IconArrowDown className={classNames({ rotate: !isDropdownOpen })} />}
                value={intl.formatMessage({ id: `locale.${locale}` })}
                readOnly
              />
            )
              : (
                <div className="lang-switcher-icon-wrapper d-flex justify-content-center align-items-center rounded-circle">
                  {!!this.langImage && (
                    <LangLabel locale={locale} icon={this.langImage} isHeaderLabel />
                  )}
                </div>
              )}
          </DropdownToggle>
          <DropdownMenu className="lang-switcher-dropdown-menu bg-secondary-dark" right>
            {Object.values(CASINO_LANGUAGES).map((key) => {
              const LangIcon = LANG_ICON[key];

              return (
                <DropdownItem
                  key={key}
                  onClick={this.changeCurrentLocale}
                  data-locale={key}
                  className="d-flex align-items-center"
                >
                  <LangIcon className="mr-2 rounded" />
                  <FormattedTag
                    id={`locale.${key}`}
                    className={classNames('text-white text-uppercase', { 'font-weight-medium': locale === key })}
                  />
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export const LangSwitcher = withCoreComponent(LangSwitcherCore, injectIntl(LangSwitcherUI));
