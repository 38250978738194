import React from 'react';
import PropTypes from 'prop-types';

export const IconLangDeAt = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang-de-at)">
      <path
        d="M23.2536 16.174C23.736 14.874 24 13.4679 24 12.0001C24 10.5322 23.736 9.12623 23.2536 7.8262L12 6.78271L0.746391 7.8262C0.264047 9.12623 0 10.5322 0 12.0001C0 13.4679 0.264047 14.874 0.746391 16.174L12 17.2175L23.2536 16.174Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 23.9999C17.1595 23.9999 21.5581 20.7434 23.2536 16.1738H0.746338C2.4419 20.7434 6.84037 23.9999 12 23.9999Z"
        fill="#D80027"
      />
      <path d="M12 0C6.84037 0 2.4419 3.2565 0.746338 7.82611H23.2536C21.5581 3.2565 17.1595 0 12 0Z" fill="#D80027" />
    </g>
  </svg>
);

IconLangDeAt.propTypes = {
  className: PropTypes.string,
};

IconLangDeAt.defaultProps = {
  className: null,
};
