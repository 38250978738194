import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import classNames from 'classnames';

import './sticky-header.scss';

export const APPEAR_Y_COORDINATE = 100;

export class StickyHeader extends PureComponent {
  state = {
    isSticky: false,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  }

  scrollSubscription;

  componentDidMount() {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(map(() => window.pageYOffset >= APPEAR_Y_COORDINATE))
      .subscribe(this.setSticky);
  }

  componentWillUnmount() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  setSticky = isSticky => this.setState({ isSticky });

  render() {
    const { isSticky } = this.state;
    const { children, className } = this.props;

    return (
      <div
        className={classNames('position-fixed sticky-header bg-blue-light pwa-blur pl-md-2_25', {
          'sticky-header-show': isSticky,
        }, className)}
      >
        {children}
      </div>

    );
  }
}
