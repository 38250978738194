import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderNetentBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100" height="36" viewBox="0 0 100 36">
    <g fill="none" fillRule="nonzero">
      <path fill="#61C100" d="M52.158 0h-1.015v35.671h1.015v-6.863h12.827v-4.156h-9.87v-4.785h8.437V15.68h-8.436v-4.63h9.869V6.863H52.158z" />
      <path fill="#fcfcfd" d="M12.236 20.32L8.59 14.341 3.919 6.938l-.046-.075H0v21.945h4.297V15.32l3.205 5.255 5.154 8.233h3.904V6.863h-4.324zM18.977 28.808H33.17v-4.156H23.3v-4.785h8.436V15.68h-8.436v-4.63h9.869V6.863H18.977zM34.911 11.082h4.962v17.726h4.324V11.082h4.93V6.863H34.911z" />
      <path fill="#61C100" d="M79.382 20.356l-3.645-5.976-4.671-7.403-.047-.078h-3.872v21.944h4.293V15.355l3.205 5.255 5.158 8.233h3.904V6.899h-4.325zM85.475 6.899v4.218h4.961v17.726h4.325V11.117h4.93V6.899z" />
    </g>
  </svg>
);

IconProviderNetentBig.propTypes = {
  className: PropTypes.string,
};
IconProviderNetentBig.defaultProps = {
  className: null,
};
