import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse as parseQueryString } from 'qs';
import { withCheckResetPasswordKey } from 'core/hocs';
import { ChangeRoute } from 'core/components';
import { Main } from 'components/main/main';
import { FormCompleteResetPassword } from 'components/forms/form-complete-reset-password/form-complete-reset-password';
import { Logo } from 'components/logo/logo';
import { HeadHelmet } from 'components/head-helmet/head-helmet';

import { HISTORY_STATE_ACTIONS, MODAL_IDS, PAGE_NAMES } from '../../constants';

class ResetPassword extends Component {
  static propTypes = {
    location: PropTypes.shape().isRequired, // eslint-disable-line react/no-unused-prop-types
    checkResetPasswordKey: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    isResetPasswordKeyValid: PropTypes.bool,
    isResetPasswordKeyNotFound: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    isResetPasswordKeyValid: null,
    isResetPasswordKeyNotFound: null,
  };

  state = {};

  static getDerivedStateFromProps(props) {
    const {
      location: { search },
      checkResetPasswordKey,
      isResetPasswordKeyValid,
    } = props;
    const { confirmationCode, email } = parseQueryString(search, { ignoreQueryPrefix: true });

    if (R.isNil(isResetPasswordKeyValid)) {
      checkResetPasswordKey({ resetPasswordKey: confirmationCode, email });
    }

    return null;
  }

  render() {
    const { isResetPasswordKeyValid, isResetPasswordKeyNotFound } = this.props;

    if (R.isNil(isResetPasswordKeyValid)) {
      return null;
    }

    return isResetPasswordKeyValid
      ? (
        <Main className="h-100 w-100 d-flex flex-column align-items-center reset-password-page mb-lg-3">
          <HeadHelmet page={PAGE_NAMES.RESET_PASSWORD} />

          <Logo className="mt-4 mb-2" iconClassName="reset-password-logo" />
          <FormCompleteResetPassword />
        </Main>
      )
      : (
        <ChangeRoute
          to="/"
          state={{
            action: isResetPasswordKeyNotFound ? HISTORY_STATE_ACTIONS.OPEN_MODAL : null,
            modalId: MODAL_IDS.RESET_LINK_ERROR,
          }}
        />
      );
  }
}

export default withRouter(withCheckResetPasswordKey(ResetPassword));
