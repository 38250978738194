import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconVisa = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="42"
    viewBox="0 0 84 42"
    fill="none"
  >
    <path
      d="M0.9375 8.39905C0.9375 8.74116 1.2291 8.90081 2.2385 9.10607C3.8761 9.49379 6.9269 10.6569 7.7345 11.2271C8.071 11.478 8.4748 11.9569 8.6542 12.2762C8.8112 12.6183 10.1348 17.6359 11.6153 23.4517L14.2848 33.9885L17.8515 33.9429L21.4183 33.8745L26.6675 21.2166C29.5388 14.2604 31.8942 8.49028 31.8942 8.37625C31.8942 8.28502 30.3464 8.2166 28.4845 8.2166H25.0523L21.6875 16.8833C19.848 21.6499 18.2777 25.5499 18.2104 25.5499C18.1431 25.5499 17.4477 22.0833 16.685 17.8411C15.8775 13.485 15.0923 9.8587 14.8904 9.49379C14.6885 9.12888 14.1502 8.64993 13.7239 8.42186C13.0285 8.03414 12.378 7.98853 6.9493 7.98853C1.2515 7.98853 0.9375 8.01133 0.9375 8.39905Z"
      fill="#6A6A6A"
    />
    <path
      d="M34.9218 8.26221C34.8545 8.42186 33.6207 14.2833 32.1626 21.2622L29.5156 33.9885H32.8356C34.6526 33.9885 36.1556 33.9429 36.1556 33.8973C36.1556 33.7604 41.2926 9.1973 41.4721 8.49028L41.5843 7.98853H38.3091C35.8864 7.98853 34.9891 8.05695 34.9218 8.26221Z"
      fill="#6A6A6A"
    />
    <path
      d="M49.9524 8.30783C47.3726 9.15169 45.1967 10.885 44.2321 12.892C43.8283 13.7131 43.6489 14.5113 43.604 15.8113C43.5143 17.3622 43.5816 17.7271 44.0975 18.8218C44.8154 20.2815 46.1389 21.5359 48.0905 22.585C52.8013 25.0938 53.6762 26.2569 52.0162 27.9446C50.6029 29.3815 47.1483 29.4727 43.5816 28.1727C42.774 27.8762 42.0786 27.671 42.0113 27.7166C41.8991 27.8534 40.8897 32.392 40.8672 32.871C40.8672 33.1446 41.271 33.3727 42.2805 33.6464C43.4245 33.9429 44.5013 34.0113 48.3821 33.9429L53.0929 33.8745L54.5959 33.1218C57.6916 31.5938 59.531 28.8341 59.5535 25.7096C59.5759 22.7446 58.2075 20.8289 54.7529 18.9131C52.0162 17.385 50.8945 16.6096 50.5132 15.9025C50.2216 15.3552 50.2216 15.2183 50.5132 14.6482C51.3881 13.0517 54.8651 12.6183 58.0505 13.7131L59.6656 14.2832L60.1816 11.7517C60.4732 10.3832 60.7199 9.15169 60.7199 9.01485C60.7199 8.90081 60.1591 8.62713 59.4862 8.39906C57.9383 7.92011 51.3881 7.85169 49.9524 8.30783Z"
      fill="#6A6A6A"
    />
    <path
      d="M70.8155 8.30792C70.4566 8.46756 69.9855 8.80967 69.7836 9.03774C69.5593 9.28862 67.316 14.557 64.7587 20.7605C62.2015 26.964 59.9358 32.4833 59.7115 33.0079L59.2852 33.9886H62.7174H66.172L66.8001 32.1184L67.4506 30.2254L71.7352 30.157L75.9974 30.1114L76.446 31.9816L76.8947 33.8746L79.9679 33.943L83.0633 34.0114L82.9063 33.3728C82.8173 33.0307 81.6053 27.1921 80.2371 20.4184L77.7247 8.10265L74.6066 8.05704C72.3858 8.01142 71.2866 8.07985 70.8155 8.30792ZM73.9336 19.9623C74.4047 22.2202 74.8085 24.25 74.8758 24.4553C74.9879 24.8202 74.7636 24.8658 72.296 24.8658C70.7931 24.8658 69.5817 24.7974 69.5817 24.729C69.5817 24.2272 72.8344 15.5377 72.9466 15.6746C73.0587 15.7658 73.485 17.7044 73.9336 19.9623Z"
      fill="#6A6A6A"
    />
  </svg>
);

IconVisa.propTypes = {
  className: PropTypes.string,
};

IconVisa.defaultProps = {
  className: '',
};
