import React from 'react';
import PropTypes from 'prop-types';

export const IconLangMx = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M0 12C0 13.4678 0.264047 14.8739 0.746391 16.1739L12 17.2174L23.2536 16.1739C23.736 14.8739 24 13.4678 24 12C24 10.5322 23.736 9.12609 23.2536 7.82611L12 6.78262L0.746391 7.82611C0.264047 9.12609 0 10.5322 0 12H0Z"
        fill="#FFDA44"
      />
      <path
        d="M23.2536 7.82611C21.558 3.25655 17.1595 0 11.9999 0C6.84037 0 2.44185 3.25655 0.746338 7.82611H23.2536Z"
        fill="#D80027"
      />
      <path
        d="M0.746338 16.1739C2.44185 20.7435 6.84037 24 11.9999 24C17.1595 24 21.558 20.7435 23.2536 16.1739H0.746338Z"
        fill="#D80027"
      />
    </g>
  </svg>
);

IconLangMx.propTypes = {
  className: PropTypes.string,
};

IconLangMx.defaultProps = {
  className: null,
};
