import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { PAYMENT_METHODS_CATEGORIES } from 'core/constants';
import { PaymentsMenu } from 'components/payments-menu/payments-menu';
import { filterPaymentMethodsByDtoRules } from '../../../helpers/payments';
import { parseJson } from '../../../helpers/json';

import './deposit-options.scss';

const DepositOptionsUI = ({
  className,
  currency,
  items,
  isModal,
  intl,
  handleDepositOptions,
  realBalance,
  completedDepositTransactions,
}) => {
  const dto = parseJson({
    data: intl.formatMessage({ id: 'deposit-methods.filter-dto' }),
    fallback: [],
    errorMessage: 'Failed to load dto!'
  });

  const targetOtherItems = filterPaymentMethodsByDtoRules({
    paymentMethods: items[PAYMENT_METHODS_CATEGORIES.OTHER],
    transactions: completedDepositTransactions,
    filterDto: dto,
    currency,
    realBalance,
  });

  const targetRecentlyItems = filterPaymentMethodsByDtoRules({
    paymentMethods: items[PAYMENT_METHODS_CATEGORIES.RECENTLY],
    transactions: completedDepositTransactions,
    filterDto: dto,
    currency,
    realBalance,
  });

  return (
    <div className={classNames('deposit-options position-relative d-flex flex-column', className)}>
      <PaymentsMenu
        otherItems={targetOtherItems}
        recentlyItems={targetRecentlyItems}
        handleSelectedCard={handleDepositOptions}
        currency={currency}
        isModal={isModal}
      />
    </div>
  );
};

DepositOptionsUI.propTypes = {
  className: PropTypes.string,
  handleDepositOptions: PropTypes.func.isRequired,
  items: PropTypes.shape({
    recently: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        paymentMethod: PropTypes.string,
        minimumDeposit: PropTypes.number,
        feeRate: PropTypes.number,
        conversionRequired: PropTypes.bool,
        maximumDeposit: PropTypes.number,
        popupRequired: PropTypes.bool,
        method: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  intl: intlShape.isRequired,
  completedDepositTransactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  realBalance: PropTypes.number.isRequired,
};

DepositOptionsUI.defaultProps = {
  className: '',
  isModal: false,
};

export const DepositOptions = injectIntl(DepositOptionsUI);
