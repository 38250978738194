import React from 'react';
import PropTypes from 'prop-types';

export const IconMiniGames = ({ className }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip-icon-mini-games-1)">
      <circle opacity="0.15" cx="18" cy="18" r="18" fill="white" />
    </g>
    <g clipPath="url(#clip-icon-mini-games-2)">
      <path
        d="M15.7356 25.6162C15.7356 25.6162 13.8326 19.5091 13.8326 16.9757C13.8326 15.8358 13.9612 14.8218 14.1612 13.9012H22.8306C23.032 14.8224 23.1613 15.8372 23.1613 16.9764C23.1613 19.4713 21.2638 25.6162 21.2638 25.6162H15.7356ZM18.4932 15.2975C17.5183 15.2975 16.7277 16.0881 16.7277 17.0637C16.7277 18.0386 17.5183 18.8299 18.4932 18.8299C19.468 18.8299 20.2593 18.0393 20.2593 17.0637C20.26 16.0881 19.4687 15.2975 18.4932 15.2975ZM18.1137 7.40982C18.1137 7.40982 18.1137 7.14374 18.1137 7.00002C18.1137 6.73154 18.8012 6.73154 18.8012 7.00002C18.8012 7.17427 18.8012 7.40982 18.8012 7.40982C19.587 7.98182 21.7134 9.73975 22.6752 13.2439H14.3152C15.2447 9.836 17.2687 8.03682 18.1137 7.40982Z"
        fill="white"
      />
      <path
        d="M22.3775 24.2287C23.7979 25.9179 23.5676 27.3012 23.5676 27.3012C23.5676 27.3012 26.7926 25.0737 26.7933 20.2742C26.7933 17.472 23.9519 16.8188 23.9519 16.8188C23.9519 16.8188 23.7601 20.3505 22.3775 24.2287Z"
        fill="white"
      />
      <path
        d="M10.2074 20.2742C10.2074 25.0737 13.4325 27.3012 13.4325 27.3012C13.4325 27.3012 13.2015 25.9186 14.6225 24.2287C13.2406 20.3505 13.0488 16.8188 13.0488 16.8188C13.0488 16.8188 10.2074 17.472 10.2074 20.2742Z"
        fill="white"
      />
      <path
        d="M19.0864 27.0798L19.6626 27.656L20.4676 26.2727H16.5131L17.2432 28.0774L17.7424 27.0798L18.4725 29L19.0864 27.0798Z"
        fill="white"
      />
    </g>
  </svg>
);

IconMiniGames.propTypes = {
  className: PropTypes.string,
};

IconMiniGames.defaultProps = {
  className: null,
};
