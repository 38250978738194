import React from 'react';
import { FormattedTag } from 'core/components';
import { ModalWindow } from 'components/modal-window/modal-window';
import { QrCode } from 'components/icons/qr-code/qr-code';
import { MODAL_IDS } from '../../../constants';

import './pwa-install-from-desktop-modal.scss';

export const PwaInstallFromDesktopModal = () => {
  const qrCodeUrl = `${window.location.protocol}//${window.location.hostname}?fromQr=true`;

  return (
    <ModalWindow
      id={MODAL_IDS.PWA_INSTALL}
      coreProps={{ id: MODAL_IDS.PWA_INSTALL }}
      className="pwa-install-from-desktop-modal"
      isCentered
      fade
    >
      <div className="pwa-install-from-desktop">
        <div className="text-center mb-2_5">
          <FormattedTag
            tag="div"
            id="pwa.modal-desktop.title"
            className="pwa-install-from-desktop-title h2 font-family-oswald font-weight-bold line-height-1_5 text-uppercase mx-auto mb-1"
            isHtml
          />

          <FormattedTag
            tag="div"
            id="pwa.modal-desktop.description"
            className="text-base"
            isHtml
          />
        </div>

        <div className="pwa-install-from-desktop-qr-wrapper d-flex justify-content-center align-items-center position-relative rounded mx-auto">
          <QrCode domain={qrCodeUrl} />
          <img src="/assets/public/pwa/icons/icon-72x72.png" alt="pwa-logo" className="pwa-install-from-desktop-container-icon position-absolute" />

        </div>

        <FormattedTag
          tag="div"
          id="pwa.modal-desktop.warning"
          className="text-small text-center mt-2"
          isHtml
        />
      </div>
    </ModalWindow>
  );
};
