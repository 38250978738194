import { PureComponent } from 'react';
import * as R from 'ramda';
import store from 'store';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { withRouter } from 'react-router-dom';
import {
  withGlobalEvents,
  withModalActions,
  withLocale,
  withUser,
  withUserAgent,
  withNotificationsActions,
} from 'core/hocs';
import {
  GLOBAL_EVENTS,
  USER_FIELDS,
  DEVICES_TYPES,
  NOTIFICATION_TYPES,
  RESPONSE_STATUSES,
} from 'core/constants';
import { wrapRoute2Locale, safeJsonParse, getNotificationMessageByStatus } from 'core/helpers';

import {
  MODAL_IDS, LS_ACTIONS_KEY, LS_ACTIONS, HISTORY_STATE_ACTIONS, MODAL_SIGN_IN_CONTENT_CLASS, PAGE_NAMES,
} from '../../constants';

const {
  OPEN_SIGN_IN,
  OPEN_SIGN_UP,
  OPEN_COMPLETE_SIGN_UP,
  OPEN_GAME,
  OPEN_FREE_SPINS,
  SIGN_OUT,
  SIGN_IN: SIGN_IN_EVENT,
  SIGN_IN_INCORRECT_USERNAME_OR_PASSWORD,
  SESSION_EXPIRED,
  HIDE_PWA_PRELOADER,
  SIGN_IN_ACCOUNT_LOCKED,
} = GLOBAL_EVENTS;

const { FREE_SPINS } = MODAL_IDS;

export class GlobalEventsHandlerUI extends PureComponent {
  static propTypes = {
    globalEvents: PropTypes.shape().isRequired,
    openModal: PropTypes.func.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeAllModals: PropTypes.func.isRequired,
    fetchAllUserData: PropTypes.func.isRequired,
    clearAllUserData: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    isPwa: PropTypes.bool.isRequired,
    showNotifications: PropTypes.func.isRequired,
  };

  isCurrentTabEmitter;

  componentDidMount() {
    const { globalEvents } = this.props;

    // For browser tabs sync
    this.storeSubscription = fromEvent(window, 'storage').pipe(
      filter(R.propEq('key', LS_ACTIONS_KEY))
    ).subscribe(this.checkStoreAction);

    globalEvents
      .on(OPEN_SIGN_IN, this.openSignIn)
      .on(OPEN_SIGN_UP, this.openSignUp)
      .on(OPEN_COMPLETE_SIGN_UP, this.openCompleteSignUp)
      .on(OPEN_GAME, this.openGame)
      .on(OPEN_FREE_SPINS, this.openFreeSpins)
      .on(SIGN_IN_EVENT, this.onSignIn)
      .on(SIGN_OUT, this.onSignOut)
      .on(SIGN_IN_INCORRECT_USERNAME_OR_PASSWORD, this.onSignInIncorrectUsernameOrPassword)
      .on(SESSION_EXPIRED, this.onSessionExpired)
      .on(HIDE_PWA_PRELOADER, this.hidePwaPreloader)
      .on(SIGN_IN_ACCOUNT_LOCKED, this.showLockedAccountError);

    this.execHistoryAction();
  }

  componentDidUpdate(prevProps) {
    this.execHistoryAction(prevProps.location);
  }

  componentWillUnmount() {
    const { globalEvents } = this.props;
    globalEvents.removeAllListeners();

    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  openSignIn = () => {
    const { openModal } = this.props;
    openModal({ id: MODAL_IDS.SIGN_IN });
  };

  openSignUp = () => {
    const { openModal } = this.props;

    openModal({ id: MODAL_IDS.SIGN_UP });
  };

  openCompleteSignUp = () => {
    const { openModal } = this.props;
    openModal({ id: MODAL_IDS.COMPLETE_SIGN_UP });
  };

  openGame = ({
    payload: {
      slugUrl, deviceType,
    },
  }) => {
    const {
      history, locale, isPwa, pageName,
    } = this.props;
    const isMobile = deviceType === DEVICES_TYPES.MOBILE;

    // Prevent redirect to the game page for pwa.
    // And for case when ‘openGame’ action is triggered again in Game component’s mount phase.
    if (isPwa || isMobile && pageName === PAGE_NAMES.GAME) {
      return;
    }

    history.push(wrapRoute2Locale(`/game/${slugUrl}${isPwa ? '?launch_from=pwa' : ''}`, locale));
  };

  openFreeSpins = () => {
    const { openModal } = this.props;

    openModal({ id: FREE_SPINS });
  };

  onSignIn = () => {
    store.set(LS_ACTIONS_KEY, { action: LS_ACTIONS.SIGN_IN });
  };

  onSignOut = () => {
    const { closeAllModals } = this.props;
    store.set(LS_ACTIONS_KEY, { action: LS_ACTIONS.SIGN_OUT });
    closeAllModals();
  };

  onSignInIncorrectUsernameOrPassword = () => {
    let modalForm;
    const modalContent = document.querySelector(`.${MODAL_SIGN_IN_CONTENT_CLASS}`);

    if (modalContent) {
      let targetNode = modalContent;
      modalForm = modalContent.querySelector('form');

      if (window.matchMedia('(max-width: 719.98px)').matches && modalForm) {
        targetNode = modalForm;
      }

      targetNode.classList.add('shake');
      setTimeout(() => {
        targetNode.classList.remove('shake');
      }, 1000);
    }
  };

  onSessionExpired = () => {
    const { closeAllModals } = this.props;
    closeAllModals();
  };

  checkStoreAction = ({ newValue, oldValue }) => {
    const {
      clearAllUserData,
      fetchAllUserData,
      isUserLoggedIn,
      closeAllModals,
    } = this.props;

    if (newValue && newValue !== oldValue) {
      const { action } = safeJsonParse(newValue);

      if (action === LS_ACTIONS.SIGN_OUT && isUserLoggedIn) {
        clearAllUserData();
        closeAllModals();
      } else if (action === LS_ACTIONS.SIGN_IN || action === LS_ACTIONS.SET_CHARACTER) {
        fetchAllUserData();
      }
    }
  };

  execHistoryAction = (prevLocation) => {
    const { location: { state } } = this.props;
    const prevState = R.prop('state', prevLocation);

    if (!state || R.equals(state, prevState)) {
      return;
    }

    const { openModalAndCloseOthers } = this.props;

    const {
      action,
      modalId
    } = state || {};

    if (action === HISTORY_STATE_ACTIONS.OPEN_MODAL && modalId) {
      openModalAndCloseOthers(modalId);
    } else if (action === HISTORY_STATE_ACTIONS.LS_ACTION_SIGN_IN) {
      this.onSignIn();
    }
  };

  hidePwaPreloader = () => {
    const pwaPreloader = document.querySelector('.pwa-preloader');

    if (pwaPreloader) {
      const onPwaLoaded = () => {
        document.body.classList.remove('is-pwa-loading', 'is-pwa-loaded');
        pwaPreloader.removeEventListener('transitionend', onPwaLoaded);
      };

      document.body.classList.add('is-pwa-loaded');
      pwaPreloader.addEventListener('transitionend', onPwaLoaded);
    }
  };

  showLockedAccountError = ({ payload: { date } }) => {
    const { showNotifications } = this.props;

    showNotifications({
      type: NOTIFICATION_TYPES.ERROR,
      message: getNotificationMessageByStatus(RESPONSE_STATUSES.FAIL_TEMP_LOCK),
      values: { date },
    });
  }

  render() {
    return null;
  }
}

export const GlobalEventsHandler = withRouter(
  withNotificationsActions(
    withGlobalEvents(
      withModalActions(
        withLocale(
          withUserAgent(
            withUser(GlobalEventsHandlerUI, [
              USER_FIELDS.IS_USER_LOGGED_IN,
            ])
          )
        )
      )
    )
  )
);
