import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconBankTransfer = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="42"
    viewBox="0 0 80 42"
    fill="none"
  >
    <path
      d="M20.4612 7.28638H25.1604C26.9848 7.28638 28.3392 7.57119 29.2238 8.14081C30.1083 8.71044 30.5506 9.56488 30.5506 10.7611C30.5506 11.5301 30.3571 12.2136 29.9701 12.7548C29.5831 13.2959 29.0856 13.6662 28.4498 13.8086V13.894C29.2791 14.1219 29.8872 14.4921 30.2742 15.0333C30.6612 15.5744 30.827 16.258 30.827 17.1124C30.827 18.3656 30.3571 19.3624 29.4449 20.0745C28.5327 20.7865 27.2888 21.1568 25.6856 21.1568H20.4336V7.28638H20.4612ZM24.11 12.6124H25.2157C25.7409 12.6124 26.1279 12.4985 26.4319 12.2706C26.7083 12.0428 26.8742 11.7295 26.8742 11.2738C26.8742 10.5048 26.2937 10.106 25.1604 10.106H24.1376V12.6124H24.11ZM24.11 15.3751V18.2802H25.3815C26.5148 18.2802 27.0953 17.796 27.0953 16.7991C27.0953 16.3434 26.9295 15.9732 26.6531 15.7453C26.349 15.489 25.9067 15.3751 25.3262 15.3751H24.11Z"
      fill="#858585"
    />
    <path
      d="M41.2496 21.185L40.5862 18.5932H36.2187L35.5277 21.185H31.5195L35.9423 7.22925H40.8073L45.2301 21.185H41.2496ZM39.8398 15.4888L39.2594 13.2103C39.1211 12.6976 38.9553 12.0425 38.7618 11.2451C38.5683 10.4476 38.4301 9.87801 38.3748 9.50775C38.3195 9.82104 38.2089 10.3621 38.0431 11.1027C37.8772 11.8432 37.5179 13.2957 36.965 15.4888H39.8398Z"
      fill="#858585"
    />
    <path
      d="M59.3264 21.1852H54.5443L49.6239 11.4162H49.541C49.6516 12.9542 49.7069 14.1219 49.7069 14.9478V21.2137H46.4727V7.28638H51.2272L56.1199 16.9415H56.1751C56.0922 15.546 56.0369 14.4067 56.0369 13.5807V7.31486H59.2711V21.1852H59.3264Z"
      fill="#858585"
    />
    <path
      d="M73.5616 21.1852H69.4429L66.7893 15.8877L65.7112 16.5428V21.1852H62.0625V7.28638H65.7112V13.3244C65.9047 12.9542 66.2641 12.3561 66.8169 11.5586L69.6364 7.31486H73.6169L69.36 13.5238L73.5616 21.1852Z"
      fill="#858585"
    />
    <path d="M9.12802 34.6284H7.88412V24.8023H4.51172V23.6631H12.4727V24.8023H9.12802V34.6284Z" fill="#858585" />
    <path
      d="M15.1814 30.0712V34.6282H13.9375V23.6914H16.8399C18.1391 23.6914 19.1066 23.9477 19.7423 24.4604C20.3781 24.973 20.6822 25.742 20.6822 26.7958C20.6822 28.2484 19.9635 29.2167 18.5537 29.7294L21.4285 34.6567H19.9635L17.3927 30.0997H15.1814V30.0712ZM15.1814 28.9889H16.8675C17.7521 28.9889 18.3879 28.818 18.7749 28.4477C19.1895 28.0775 19.383 27.5648 19.383 26.8528C19.383 26.1408 19.1895 25.5996 18.7749 25.2863C18.3602 24.973 17.6968 24.8021 16.7846 24.8021H15.1814V28.9889Z"
      fill="#858585"
    />
    <path
      d="M29.6659 34.6283L28.3391 31.1536H24.0822L22.783 34.6283H21.5391L25.7407 23.6345H26.7911L30.9651 34.6283H29.6659ZM27.9521 30.0143L26.7358 26.5966C26.57 26.1694 26.4041 25.6282 26.2382 25.0301C26.1277 25.5143 25.9895 26.0269 25.7683 26.5966L24.5244 30.0143H27.9521Z"
      fill="#858585"
    />
    <path
      d="M40.5582 34.6282H39.1485L33.3436 25.4287H33.2883C33.3713 26.511 33.3989 27.5079 33.3989 28.3908V34.6282H32.2656V23.6914H33.6753L39.4802 32.8339H39.5355C39.5355 32.6914 39.5078 32.2642 39.4802 31.5237C39.4525 30.7832 39.4249 30.2705 39.4525 29.9572V23.6914H40.6135V34.6282H40.5582Z"
      fill="#858585"
    />
    <path
      d="M49.3496 31.7233C49.3496 32.6917 49.0179 33.4322 48.3269 33.9733C47.6358 34.5145 46.7236 34.7708 45.5627 34.7708C44.2911 34.7708 43.3236 34.5999 42.6602 34.2581V33.0335C43.1025 33.2328 43.5724 33.3752 44.0976 33.4892C44.6228 33.6031 45.1204 33.66 45.6179 33.66C46.4472 33.66 47.0553 33.4892 47.47 33.1759C47.8846 32.8626 48.1057 32.4069 48.1057 31.8372C48.1057 31.467 48.0228 31.1537 47.8846 30.8974C47.7464 30.641 47.4976 30.4417 47.1383 30.2138C46.7789 29.986 46.2537 29.7866 45.5627 29.5303C44.5675 29.16 43.8765 28.7328 43.4342 28.2201C43.0196 27.7075 42.7984 27.0809 42.7984 26.2549C42.7984 25.4005 43.1025 24.7454 43.7106 24.2327C44.3188 23.7201 45.148 23.4922 46.1431 23.4922C47.1936 23.4922 48.161 23.6916 49.0456 24.0903L48.6586 25.2011C47.774 24.8308 46.9171 24.6315 46.0879 24.6315C45.4244 24.6315 44.9269 24.7739 44.5399 25.0587C44.1805 25.3435 43.9871 25.7422 43.9871 26.2549C43.9871 26.6252 44.0423 26.9385 44.1805 27.1948C44.3188 27.4511 44.5399 27.6505 44.8716 27.8499C45.2033 28.0492 45.7009 28.2771 46.3643 28.5334C47.47 28.9322 48.244 29.3878 48.6586 29.8436C49.1285 30.3562 49.3496 30.9828 49.3496 31.7233Z"
      fill="#858585"
    />
    <path
      d="M52.6111 34.6282H51.3672V23.6914H57.2826V24.8306H52.5835V28.8465H56.9786V29.9857H52.5835V34.6282H52.6111Z"
      fill="#858585"
    />
    <path
      d="M64.8295 34.6282H58.9141V23.6914H64.8295V24.8306H60.1303V28.3623H64.5255V29.4731H60.1303V33.5174H64.8295V34.6282Z"
      fill="#858585"
    />
    <path
      d="M68.283 30.0712V34.6282H67.0391V23.6914H69.9415C71.2407 23.6914 72.2082 23.9477 72.844 24.4604C73.4797 24.973 73.7838 25.742 73.7838 26.7958C73.7838 28.2484 73.0651 29.2167 71.6553 29.7294L74.5301 34.6567H73.0651L70.4944 30.0997H68.283V30.0712ZM68.283 28.9889H69.9692C70.8537 28.9889 71.4895 28.818 71.8765 28.4477C72.2911 28.0775 72.4846 27.5648 72.4846 26.8528C72.4846 26.1408 72.2635 25.5996 71.8765 25.2863C71.4619 24.973 70.7984 24.8021 69.8862 24.8021H68.283V28.9889Z"
      fill="#858585"
    />
    <path d="M17.1722 9.09852V7.3042H4.48438V9.09852H17.1722Z" fill="#C6C6C6" />
    <path d="M17.1566 14.5239V11.6758H4.46875V14.5239H17.1566Z" fill="#444444" />
    <path d="M17.1566 21.2155V17.0288H4.46875V21.2155H17.1566Z" fill="#858585" />
  </svg>
);

IconBankTransfer.propTypes = {
  className: PropTypes.string,
};

IconBankTransfer.defaultProps = {
  className: '',
};
