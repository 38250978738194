import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { FormattedTag, RouteLink } from 'core/components';
import { isEmptyOrNil } from 'core/helpers';
import { withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { profileMenuItems } from 'components/sidebar/sidebar-menu/sidebar-menu';
import { IconArrowRight } from 'components/icons/icon-arrow-right/icon-arrow-right';
import { IconArrowRightSmall } from 'components/icons/icon-arrow-right-small/icon-arrow-right-small';
import colors from 'customizations/js/color-variables';

import { MODAL_IDS, SIDE_MODAL_IDS } from '../../constants';

class ProfileItemsDropdownUI extends Component {
  static propTypes = {
    firstName: PropTypes.string,
    signOut: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isWithinSidebar: PropTypes.bool,
    // loyaltyPointsAllData: PropTypes.shape().isRequired,
    // accumulatedPoints: PropTypes.number.isRequired,
    // currentProgress: PropTypes.string.isRequired,
    // targetVipStatus: PropTypes.string.isRequired,
    // closeProfileModal: PropTypes.func.isRequired,
    // totalPoints: PropTypes.number.isRequired,
    isSportsPage: PropTypes.bool,
    // nextLevel: PropTypes.string,
  };

  static defaultProps = {
    firstName: null,
    isWithinSidebar: false,
    isSportsPage: false,
    // nextLevel: '',
  };

  state = {
    isDropdownOpen: false,
  };

  closeSideModal = () => {
    const { closeModal } = this.props;

    closeModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
    closeModal(MODAL_IDS.PROFILE);
  };

  toggle = () => this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }));

  handleLoyaltyLevelClick = (e) => {
    e.stopPropagation();

    this.closeSideModal();
  };

  render() {
    const {
      firstName,
      signOut,
      isWithinSidebar,
      // loyaltyPointsAllData,
      // currentProgress,
      // accumulatedPoints,
      // targetVipStatus,
      // totalPoints,
      isSportsPage,
      // nextLevel,
    } = this.props;

    const { isDropdownOpen } = this.state;

    return (
      <Dropdown
        toggle={this.toggle}
        isOpen={isDropdownOpen}
        className={classNames('profile-items-dropdown', {
          'profile-items-dropdown-sidebar': isWithinSidebar,
        })}
      >
        <DropdownToggle
          tag="span"
          role="button"
          className={classNames('d-flex align-items-center menu', { 'active rounded': isDropdownOpen })}
        >
          <div className="d-flex align-items-center pt-1 pb-1_25 cursor-pointer">
            {/* <div className="user-info-and-loyalty-progress position-relative mr-1_75 ml-1"> */}
            {/* <svg viewBox="0 0 34.91549431 34.91549431" width="72" height="72" xmlns="http://www.w3.org/2000/svg"> */}
            {/* <circle */}
            {/* stroke={isSportsPage ? colors.blueDark : colors.white20} */}
            {/* fill="transparent" */}
            {/* strokeWidth="2" */}
            {/* cx="16.91549431" */}
            {/* cy="16.91549431" */}
            {/* r="15.91549431" */}
            {/* transform="translate(0.5, .4)" */}
            {/* /> */}

            {/* {!isEmptyOrNil(loyaltyPointsAllData) && ( */}
            {/* <circle */}
            {/* stroke="#01FA6C" */}
            {/* strokeWidth="3" */}
            {/* strokeDasharray={`${currentProgress > 100 ? 100 : currentProgress},100`} */}
            {/* strokeLinecap="round" */}
            {/* fill="none" */}
            {/* cx="16.91549431" */}
            {/* cy="16.91549431" */}
            {/* r="15.91549431" */}
            {/* transform="rotate(270 16.91549431 16.91549431) translate(-0.4, 0.5)" */}
            {/* /> */}
            {/* )} */}

            {/* <g> */}
            {/* <text */}
            {/* x={`${nextLevel ? '16.91549431' : '17.91549431'}`} */}
            {/* y="16.5" */}
            {/* alignmentBaseline="central" */}
            {/* textAnchor="middle" */}
            {/* fill={isSportsPage ? colors.blueDark : colors.white} */}
            {/* fontSize="6.5" */}
            {/* fontWeight="bold" */}
            {/* transform={`${nextLevel ? 'translate(0, -1.5)' : ''}`} */}
            {/* > */}
            {/* {Math.floor(Number(accumulatedPoints) || 0)}LP */}
            {/* </text> */}

            {/* {nextLevel && ( */}
            {/* <text */}
            {/* x="16.91549431" */}
            {/* y="16.5" */}
            {/* alignmentBaseline="central" */}
            {/* textAnchor="middle" */}
            {/* fill={colors.gray10} */}
            {/* fontSize="4.5" */}
            {/* transform=" translate(0, 6)" */}
            {/* > */}
            {/* / {totalPoints} */}
            {/* </text> */}
            {/* )} */}
            {/* </g> */}
            {/* </svg> */}
            {/* </div> */}

            <img
              width={60}
              height={60}
              className="mr-2"
              alt="user profile icon"
              src="/assets/public/images/user-profile-image.png"
            />

            <div>
              <span className="d-block h4 mb-0_25">
                {/* <FormattedTag */}
                {/* id="sidebar.logged-in.greeting.title" */}
                {/* values={{ */}
                {/* username: !isEmptyOrNil(firstName) ? firstName : 'new user', */}
                {/* }} */}
                {/* /> */}
                {!isEmptyOrNil(firstName) ? firstName : 'new user'}
              </span>

              <span className="font-weight-medium">
                {/* <FormattedTag */}
                {/* id="sidebar.logged-in.greeting.description" */}
                {/* className={classNames({ */}
                {/* 'text-gray-20 text-base': isSportsPage, */}
                {/* 'text-white-70': !isSportsPage, */}
                {/* })} */}
                {/* />{' '} */}
                {/* {!isEmptyOrNil(loyaltyPointsAllData) && ( */}
                {/* <FormattedTag */}
                {/* tag={RouteLink} */}
                {/* to="/loyalty" */}
                {/* id={`loyalty-program.level-type.${targetVipStatus}`} */}
                {/* className={classNames('loyalty-level-link font-weight-medium', { */}
                {/* 'text-blue-dark': isSportsPage, */}
                {/* })} */}
                {/* onClick={this.handleLoyaltyLevelClick} */}
                {/* /> */}
                {/* )} */}
                {isSportsPage && (
                  <>
                    {' '}
                    <IconArrowRightSmall />
                  </>
                )}
              </span>
            </div>

            <IconArrowRight
              className={classNames('icon-arrow-down ml-auto mr-0_5 mr-md-0', { active: isDropdownOpen })}
              color={isDropdownOpen ? colors.white : colors.white60}
            />
          </div>
        </DropdownToggle>
        <DropdownMenu className="rounded px-2_25 bg-secondary-dark profile-menu-item">
          {profileMenuItems.map(({ id, tab }) => (
            <DropdownItem
              key={id}
              data-locale={id}
              tag={RouteLink}
              className="px-0 py-1_25"
              to={`/profile/${tab}`}
              onClick={this.closeSideModal}
            >
              <FormattedTag id={id} className="dropdown-menu-item text-white text-capitalize" />
            </DropdownItem>
          ))}

          <FormattedTag
            tag={DropdownItem}
            id="log-out"
            onClick={signOut}
            className="text-capitalize text-white px-0"
          />
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export const ProfileItemsDropdown = withUser(ProfileItemsDropdownUI, [USER_FIELDS.IS_SIGN_UP_COMPLETED]);
