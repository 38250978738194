import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGamzixBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} fill="none" width="120" height="38" viewBox="0 0 64 14" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-provider-gamzix-clip0_2_2)">
      <path
        d="M14.4469 6.89889H7.87408V9.23489H11.6578C11.2959 10.0046 10.8206 10.5704 10.2306 10.9313C9.64048 11.2927 8.916 11.4732 8.05627 11.4732C6.73446 11.4732 5.62299 11.035 4.72187 10.1586C3.82117 9.28268 3.37061 8.21345 3.37061 6.95222C3.37061 5.73238 3.80453 4.69601 4.6728 3.84353C5.54107 2.99062 6.61797 2.56438 7.90437 2.56438C9.38021 2.56438 10.6722 3.15062 11.7802 4.3231L13.5317 2.59126C12.7321 1.72684 11.915 1.1167 11.0801 0.761715C10.091 0.341449 9.02821 0.131104 7.89115 0.131104C6.02064 0.131104 4.45307 0.695582 3.18757 1.82326C3.19355 1.86038 3.188 1.90433 3.16795 1.93548C3.16752 1.9359 3.16752 1.93633 3.16709 1.93676C3.15984 2.02422 3.08944 2.08822 3.00155 2.09206C2.99771 2.09206 2.99344 2.09206 2.9896 2.09164L2.98875 2.09249C2.80272 2.24737 2.64912 2.43212 2.50704 2.62668C2.47547 2.92918 2.17723 3.14678 1.98565 3.37505C1.94896 3.4463 1.91227 3.51756 1.87301 3.58625C1.76805 3.77185 1.70704 3.9694 1.64517 4.17292C1.58757 4.36193 1.51291 4.54241 1.44379 4.72716C1.30085 5.1103 1.24453 5.5118 1.12251 5.90092C1.05765 6.10828 0.974454 6.31521 0.960801 6.53409C0.947574 6.74956 0.954401 6.96161 0.917281 7.17537C0.915147 7.18902 0.910454 7.20097 0.904907 7.21206C1.11141 8.59916 1.56027 9.96108 2.39483 11.102C3.13979 12.1204 4.25168 12.8214 5.40539 13.2929C6.60773 13.784 7.98715 13.8647 9.2616 13.6564C10.4977 13.4546 11.6889 12.9418 12.529 11.9877C13.3823 11.0184 13.9532 9.85612 14.269 8.60812C14.2856 8.54241 14.3291 8.50657 14.379 8.4942C14.4358 8.15158 14.4644 7.80342 14.4644 7.44972L14.4469 6.89889Z"
        fill="#fcfcfd"
      />
      <path
        d="M14.409 8.29582C14.2981 9.13081 14.0203 9.93252 13.5762 10.7005C12.9839 11.7249 12.2172 12.5066 11.2756 13.0455C10.3339 13.5844 9.2297 13.8536 7.9625 13.8536C6.60655 13.8536 5.39695 13.5588 4.33412 12.97C3.72271 12.6312 3.18468 12.2096 2.72047 11.7049C2.62148 11.5974 3.6233 11.9413 5.18191 12.0659C6.42692 12.1653 8.07684 12.1038 9.44346 11.7198C10.8335 11.3294 12.1353 10.532 13.0164 9.77508C13.9478 8.97508 14.4184 8.2267 14.409 8.29582Z"
        fill="url(#icon-provider-gamzix-paint0_linear_2_2)"
      />
      <path
        d="M6.05352 13.6308C5.4963 13.5191 4.80936 13.3019 4.10792 12.8752C3.31091 12.3905 2.82664 11.8367 2.49854 11.4553C2.28435 11.2061 1.95582 10.8208 1.63966 10.2388C1.31198 9.63511 1.08286 8.97804 0.95315 8.26679C0.730003 7.04524 0.826856 5.84759 1.24328 4.67298C1.53811 3.84226 1.95624 3.11863 2.49811 2.50124C2.85566 2.09377 3.21704 1.7981 3.21704 1.7981C3.21704 1.7981 2.9508 2.9838 2.7443 4.48396C2.4516 6.61004 2.95166 8.52791 2.95166 8.52791C3.29811 9.92225 3.85747 10.9087 4.09512 11.3021C4.75518 12.3939 5.50483 13.153 6.05352 13.6308Z"
        fill="url(#icon-provider-gamzix-paint1_linear_2_2)"
      />
      <path
        d="M18.286 2.02637H20.526L24.9894 13.6308H22.6939L21.786 11.2407H17.0508L16.1066 13.6308H13.8112L18.286 2.02637ZM19.4201 5.10306L17.8632 9.08685H20.9681L19.4201 5.10306Z"
        fill="#fcfcfd"
      />
      <path
        d="M27.8229 2.02637H29.975L32.6651 10.1202L35.3796 2.02637H37.5304L39.4773 13.6308H37.3414L36.0972 6.30199L33.632 13.6308H31.6851L29.2416 6.30199L27.9675 13.6308H25.8137L27.8229 2.02637Z"
        fill="#fcfcfd"
      />
      <path
        d="M40.6212 2.02637H47.6424L43.5106 11.5086H47.374V13.6308H40.282L44.3951 4.21176H40.6212V2.02637Z"
        fill="#fcfcfd"
      />
      <path d="M49.1562 2.02637H51.3493V13.6308H49.1562V2.02637Z" fill="#fcfcfd" />
      <path
        d="M53.0816 2.02637H55.5665L58.0053 5.75202L60.4497 2.02637H62.9316L59.2499 7.65282L63.1633 13.6308H60.6784L58.0074 9.55106L55.3374 13.6308H52.8367L56.7616 7.64813L53.0816 2.02637Z"
        fill="#fcfcfd"
      />
      <path
        d="M52.1771 1.81424C52.1557 1.56208 51.849 0.130615 50.2242 0.130615C48.6665 0.130615 48.3247 1.81424 48.3247 1.81424C48.4757 1.63206 49.1725 1.17509 50.2511 1.17509C51.3293 1.17509 52.0265 1.63206 52.1771 1.81424Z"
        fill="#DEA329"
      />
    </g>
  </svg>
);

IconProviderGamzixBig.propTypes = {
  className: PropTypes.string,
};

IconProviderGamzixBig.defaultProps = {
  className: '',
};
