import React from 'react';
import PropTypes from 'prop-types';

export const IconLangDeCh = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#D80027"
      />
      <path
        d="M18.261 9.91307H14.0871V5.73914H9.91315V9.91307H5.73926V14.087H9.91315V18.2609H14.0871V14.087H18.261V9.91307Z"
        fill="#F0F0F0"
      />
    </g>
  </svg>
);

IconLangDeCh.propTypes = {
  className: PropTypes.string,
};

IconLangDeCh.defaultProps = {
  className: null,
};
