import React from 'react';
import PropTypes from 'prop-types';

export const IconError = ({ className }) => (
  <svg className={className} width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48 96C21.5314 96 0 74.4686 0 48C0 21.5314 21.5314 0 48 0C74.4686 0 96 21.5314 96 48C96 74.4686 74.4686 96 48 96Z"
      fill="#213743"
    />
    <path
      d="M48.0001 84.2872C27.9916 84.2872 11.7129 68.0086 11.7129 48C11.7129 27.9914 27.9916 11.7128 48.0001 11.7128C68.0087 11.7128 84.2874 27.9914 84.2874 48C84.2874 68.0086 68.0087 84.2872 48.0001 84.2872Z"
      stroke="#E9114C"
      strokeWidth="3"
    />
    <path
      d="M48.0043 31.1863C49.8615 31.1863 51.3671 32.6918 51.3671 34.549V48C51.3671 49.8572 49.8615 51.3628 48.0043 51.3628C46.1472 51.3628 44.6416 49.8572 44.6416 48V34.549C44.6416 32.6918 46.1472 31.1863 48.0043 31.1863Z"
      fill="#E9114C"
    />
    <path
      d="M48.0043 58.0878C46.1472 58.0878 44.6416 59.5933 44.6416 61.4505C44.6416 63.3077 46.1472 64.8133 48.0043 64.8133H48.0366C49.8938 64.8133 51.3994 63.3077 51.3994 61.4505C51.3994 59.5933 49.8938 58.0878 48.0366 58.0878H48.0043Z"
      fill="#E9114C"
    />
  </svg>
);

IconError.propTypes = {
  className: PropTypes.string,
};

IconError.defaultProps = {
  className: null,
};
