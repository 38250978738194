import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './lang-label.scss';
import colors from 'src/customizations/js/color-variables';

export const LangLabel = ({
  // locale,
  className,
  isHeaderLabel,
  icon: Icon,
  value
}) => (
  <div className={classNames(className, 'lang-label bg-white-10 d-flex justify-content-center align-items-center rounded-circle', {
    'lang-label-header': isHeaderLabel
  })}
  >
    <div className="lang-label-content rounded-circle">
      <div className="lang-label-text user-select-none text-uppercase text-white font-weight-bold w-100 h-100 d-flex justify-content-center align-items-center">
        {!!Icon && (
          <span className="d-flex input-icon position-absolute justify-content-center">
            {' '}
            <Icon color={value ? colors.gray40 : colors.gray10} />
          </span>
        )}
      </div>
    </div>
  </div>
);

LangLabel.propTypes = {
  className: PropTypes.string,
  // locale: PropTypes.string,
  isHeaderLabel: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LangLabel.defaultProps = {
  className: '',
  // locale: '',
  isHeaderLabel: false,
  icon: null,
  value: '',
};
