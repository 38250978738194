import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconDeal = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="42"
    viewBox="0 0 80 42"
    fill="none"
  >
    <path
      d="M28.0508 8.22179V26.9092C28.0508 27.8036 28.7813 28.5355 29.674 28.5355H40.8179C49.2429 28.5355 52.8953 23.8109 52.8953 17.541C52.8953 11.2711 49.2432 6.59546 40.8182 6.59546H29.6743C28.7816 6.59546 28.0508 7.32734 28.0508 8.22179Z"
      fill="white"
    />
    <path
      d="M40.8781 28.6902H29.508C28.4986 28.6902 27.6797 27.8698 27.6797 26.8584V8.28226C27.6797 7.27086 28.4986 6.45044 29.508 6.45044H40.8781C51.6673 6.45044 53.2767 13.4057 53.2767 17.5464C53.2767 24.7308 48.8676 28.6902 40.8781 28.6902ZM28.2893 8.28226V26.8584C28.2893 27.5359 28.8322 28.0795 29.508 28.0795H40.8781C48.4773 28.0795 52.6671 24.3395 52.6671 17.5464C52.6671 8.42549 45.2776 7.06115 40.8781 7.06115H29.508C28.8319 7.06115 28.2893 7.60507 28.2893 8.28226Z"
      fill="#858585"
    />
    <path
      d="M34.6484 10.0761V26.2766H41.6857C48.0754 26.2766 50.8465 22.6607 50.8465 17.5466C50.8465 12.4325 48.0754 8.85474 41.6857 8.85474H35.8672C35.191 8.85474 34.6484 9.40833 34.6484 10.0761Z"
      fill="#CC0066"
    />
    <path
      d="M37.124 15.3618C37.3716 15.3618 37.6002 15.3999 37.8191 15.4763C38.0381 15.5528 38.2191 15.6767 38.381 15.8293C38.5333 15.9915 38.6573 16.1919 38.7523 16.4209C38.838 16.6593 38.8856 16.9361 38.8856 17.2604C38.8856 17.5465 38.8476 17.8043 38.781 18.0427C38.7046 18.2811 38.6 18.4912 38.457 18.6628C38.3141 18.8344 38.1331 18.968 37.9142 19.0732C37.6952 19.1686 37.4379 19.2257 37.143 19.2257H35.4766V15.3521H37.124V15.3618ZM37.067 18.5198C37.1906 18.5198 37.3049 18.5007 37.4289 18.4627C37.5432 18.4246 37.6478 18.3579 37.7335 18.2624C37.8191 18.1669 37.8955 18.0523 37.9525 17.8997C38.0095 17.7471 38.0381 17.5753 38.0381 17.3559C38.0381 17.1652 38.0191 16.9838 37.9811 16.8309C37.9431 16.678 37.8765 16.5351 37.7908 16.4303C37.7051 16.3254 37.5908 16.2299 37.4479 16.1728C37.3049 16.1157 37.1336 16.087 36.924 16.087H36.3145V18.5294H37.0666L37.067 18.5198ZM42.3233 15.3618V16.0772H40.2853V16.9074H42.1613V17.5659H40.2853V18.5105H42.3709V19.2261H39.4378V15.3524H42.3233V15.3618ZM46.6847 19.2353H45.7992L45.504 18.3766H44.0566L43.752 19.2353H42.8948L44.3518 15.3618H45.2373L46.6847 19.2353ZM45.285 17.7374L44.7994 16.316H44.7898L44.2852 17.7374H45.285ZM48.0655 15.3618V18.5198H49.9511V19.2353H47.218V15.3618H48.0655Z"
      fill="white"
    />
    <path
      d="M32.8109 26.2764C31.3158 26.2764 30.1161 25.0647 30.1161 23.5764V21.4678C30.1161 20.7236 30.716 20.1132 31.4681 20.1132C32.2203 20.1132 32.8202 20.7142 32.8202 21.4678V26.2764H32.8109ZM31.4681 19.0827C30.4834 19.0827 29.6875 18.285 29.6875 17.2986C29.6875 16.3123 30.4837 15.5146 31.4681 15.5146C32.4526 15.5146 33.2488 16.3123 33.2488 17.2986C33.2488 18.285 32.4526 19.0827 31.4681 19.0827Z"
      fill="#858585"
    />
    <path
      d="M34.4622 30.6774C34.439 30.6656 34.4164 30.6656 34.3814 30.6541C34.1742 30.5967 33.6455 30.5849 33.4838 30.7242C33.4497 30.7593 33.438 30.771 33.4148 30.817C33.4148 30.817 32.598 31.9072 32.0696 32.8582L30.8041 30.817V30.8052C30.7809 30.7701 30.77 30.7478 30.735 30.7124L30.7233 30.7006C30.5743 30.5728 30.1373 30.5846 29.9074 30.6197C29.8384 30.6314 29.7808 30.6429 29.7467 30.6547C29.6318 30.7121 29.5859 30.8176 29.5859 30.8868V35.1789C29.5859 35.2599 29.62 35.411 29.8848 35.4569C29.9539 35.4687 30.0456 35.4687 30.1496 35.4687C30.3336 35.4687 30.7133 35.4687 30.7133 35.1789V32.7192C30.7941 32.847 30.9546 33.1024 31.1271 33.4272C31.3684 33.8799 31.5301 34.1694 31.61 34.309L31.6558 34.4017C31.6899 34.4592 31.7484 34.5178 31.8166 34.5752C31.9197 34.6562 32.1272 34.6794 32.2654 34.6444C32.2994 34.6326 32.3344 34.6211 32.3685 34.587C32.4717 34.506 32.5293 34.4474 32.5525 34.4135C32.5757 34.3785 32.6098 34.3093 32.6674 34.2165C32.725 34.1237 32.8396 33.8917 33.0353 33.5203C33.2311 33.1486 33.381 32.8824 33.4841 32.708V35.191C33.4841 35.4808 33.8638 35.4808 34.0478 35.4808C34.2318 35.4808 34.6115 35.4808 34.6115 35.191V30.898C34.6233 30.8288 34.5889 30.7242 34.4622 30.6774Z"
      fill="#2D9DD3"
    />
    <path
      d="M35.841 32.2202C35.657 32.2202 35.2773 32.2202 35.2773 32.51V35.2474C35.2773 35.5372 35.657 35.5372 35.841 35.5372C36.0249 35.5372 36.4047 35.5372 36.4047 35.2474V32.51C36.4047 32.2202 36.0249 32.2202 35.841 32.2202Z"
      fill="#2D9DD3"
    />
    <path
      d="M39.984 30.6658H37.6146C37.3966 30.6658 37.0859 30.6776 37.0859 30.9556V35.2477C37.0859 35.5375 37.4656 35.5375 37.6496 35.5375C37.8336 35.5375 38.2133 35.5375 38.2133 35.2477V33.6124H39.7424C39.9146 33.6124 40.1104 33.4727 40.1104 33.079C40.1104 32.6853 39.9146 32.5457 39.7424 32.5457H38.2133V31.7334H39.9849C40.1571 31.7334 40.3529 31.5938 40.3529 31.2001C40.3529 30.8063 40.1571 30.6667 39.9849 30.6667L39.984 30.6658Z"
      fill="#2D9DD3"
    />
    <path
      d="M46.1718 30.6538C45.9996 30.6538 45.6081 30.6538 45.6081 30.9551V33.2752L43.9523 30.8155L43.9291 30.7804C43.7569 30.6175 43.1582 30.6761 43.0433 30.723C42.9402 30.7698 42.8711 30.8626 42.8711 30.9668V35.2472C42.8711 35.5485 43.2508 35.5485 43.4348 35.5485C43.6188 35.5485 43.9985 35.5485 43.9985 35.2472V32.9271L45.6434 35.3868C45.6893 35.456 45.7933 35.5379 46.1495 35.5379C46.2762 35.5379 46.4366 35.5261 46.5292 35.5029C46.6559 35.4678 46.7364 35.3633 46.7364 35.2472V30.956C46.7364 30.6547 46.3452 30.6547 46.1727 30.6547L46.1718 30.6538Z"
      fill="#2D9DD3"
    />
    <path
      d="M48.0559 30.6658C47.8719 30.6658 47.4922 30.6658 47.4922 30.9556V35.2477C47.4922 35.5375 47.8719 35.5375 48.0559 35.5375C48.2399 35.5375 48.6196 35.5375 48.6196 35.2477V30.9565C48.6196 30.6667 48.2399 30.6658 48.0559 30.6658Z"
      fill="#2D9DD3"
    />
    <path
      d="M41.5129 30.6658C41.329 30.6658 40.9492 30.6658 40.9492 30.9556V35.2477C40.9492 35.5375 41.329 35.5375 41.5129 35.5375C41.6969 35.5375 42.0766 35.5375 42.0766 35.2477V30.9565C42.0766 30.6667 41.6969 30.6658 41.5129 30.6658Z"
      fill="#2D9DD3"
    />
    <path
      d="M52.8418 30.6658H49.5293C49.2422 30.6658 49.2422 31.049 49.2422 31.2345C49.2422 31.42 49.2422 31.8032 49.5293 31.8032H50.6214V35.249C50.6214 35.5388 51.0011 35.5387 51.1851 35.5387C51.3691 35.5387 51.7488 35.5388 51.7488 35.249V31.8032H52.8409C53.128 31.8032 53.128 31.42 53.128 31.2345C53.128 31.049 53.1289 30.6658 52.8418 30.6658Z"
      fill="#2D9DD3"
    />
    <path
      d="M57.6264 30.7241C57.4883 30.6667 56.8097 30.6081 56.6257 30.8401L55.7176 32.267L54.8094 30.8519L54.7977 30.8401C54.6255 30.6081 53.947 30.6667 53.7971 30.7123C53.6704 30.7592 53.5898 30.8634 53.5898 30.9794V31.0604L55.1424 33.4966V35.2366C55.1424 35.5496 55.5677 35.5496 55.7061 35.5496C55.856 35.5496 56.2813 35.5496 56.2813 35.2248V33.4848L57.8339 31.0486V30.9794C57.8339 30.8634 57.7528 30.7709 57.6264 30.7241Z"
      fill="#2D9DD3"
    />
    <path
      d="M35.9821 30.7124H35.6714C35.4301 30.7124 35.2344 30.9094 35.2344 31.1533V31.4781C35.2344 31.722 35.4301 31.919 35.6714 31.919H35.9821C36.2234 31.919 36.4191 31.722 36.4191 31.4781V31.1533C36.4191 30.9094 36.2234 30.7124 35.9821 30.7124Z"
      fill="#2D9DD3"
    />
    <path
      d="M23.9012 34.2076L23.1641 32.3997H23.6557L24.119 33.6519H24.1259L24.5858 32.3997H25.0458L24.312 34.2076H23.9012Z"
      fill="#2D9DD3"
    />
    <path
      d="M25.5612 32.0516C25.5096 32.0516 25.4616 32.0389 25.4173 32.0129C25.3727 31.9872 25.3377 31.9531 25.312 31.9108C25.2864 31.8685 25.2734 31.8216 25.2734 31.7699C25.2734 31.7183 25.2864 31.6751 25.312 31.6328C25.3377 31.5905 25.373 31.5572 25.4173 31.5324C25.4616 31.508 25.5096 31.4956 25.5612 31.4956C25.6127 31.4956 25.6634 31.508 25.7068 31.5324C25.75 31.5572 25.7847 31.5905 25.8103 31.6328C25.8359 31.6751 25.8489 31.7207 25.8489 31.7699C25.8489 31.8192 25.8359 31.8685 25.8103 31.9108C25.7847 31.9531 25.75 31.9869 25.7068 32.0129C25.6634 32.0389 25.6149 32.0516 25.5612 32.0516ZM25.3401 34.1865V32.3997H25.7825V34.1865H25.3401Z"
      fill="#2D9DD3"
    />
    <path
      d="M26.7136 34.2145C26.6177 34.2145 26.5263 34.1963 26.4397 34.1601C26.3532 34.1238 26.2829 34.0682 26.2292 33.993C26.1752 33.918 26.1484 33.8241 26.1484 33.7116C26.1484 33.5618 26.1964 33.4427 26.2923 33.3548C26.3882 33.2668 26.5239 33.2052 26.6994 33.1701L27.2366 33.0577V33.0508C27.2366 32.9477 27.2049 32.8725 27.1419 32.8257C27.0785 32.7788 26.9814 32.7552 26.8505 32.7552C26.7757 32.7552 26.6988 32.7601 26.6207 32.7694C26.5423 32.7788 26.469 32.7906 26.4011 32.8045L26.3203 32.822L26.2467 32.5301L26.3345 32.4984C26.4515 32.4561 26.561 32.4247 26.6626 32.4035C26.7646 32.3824 26.8656 32.3718 26.9663 32.3718C27.1769 32.3718 27.3479 32.428 27.4788 32.5407C27.61 32.6534 27.6754 32.8232 27.6754 33.0508V33.705C27.6754 33.7778 27.6923 33.8265 27.7264 33.851C27.7602 33.8754 27.831 33.8927 27.9387 33.902L27.9143 34.1939C27.8838 34.2009 27.8546 34.2063 27.8265 34.2096C27.7985 34.213 27.7737 34.2148 27.7529 34.2148C27.6311 34.2148 27.5346 34.1873 27.4634 34.132C27.3919 34.077 27.3388 34.0096 27.3035 33.9298L27.2227 34.0036C27.1455 34.074 27.0617 34.1265 26.9718 34.1619C26.8816 34.1969 26.7956 34.2145 26.7136 34.2145ZM26.8469 33.8452C26.8937 33.8452 26.944 33.8358 26.9977 33.8171C27.0514 33.7984 27.1054 33.7703 27.1591 33.7328L27.2363 33.6799V33.3176L26.7625 33.4512C26.6946 33.4699 26.6466 33.4968 26.6186 33.5322C26.5906 33.5672 26.5764 33.6083 26.5764 33.6551C26.5764 33.7162 26.5996 33.763 26.6466 33.796C26.6934 33.8289 26.7604 33.8452 26.8469 33.8452Z"
      fill="#2D9DD3"
    />
  </svg>
);

IconDeal.propTypes = {
  className: PropTypes.string,
};

IconDeal.defaultProps = {
  className: '',
};
