import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './casino-header.scss';

export const CasinoHeader = ({ className, children }) => (
  <div className={classNames(className, 'd-none d-md-flex bg-blue-light align-items-center position-relative')}>
    {children}
  </div>
);

CasinoHeader.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
