import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderFugasoBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="112" height="39" viewBox="0 0 112 39" fill="none">
    <path d="M16.2634 27.553L19.6657 21.8062L23.0365 27.6135L29.8976 16.0443H26.5155L29.8888 10.2622H39.8954L23.01 39.0001L16.2634 27.553Z" fill="#fcfcfd" />
    <path d="M12.7603 21.6085L16.2633 27.553L19.6656 21.8062L19.6669 21.8036L16.3238 16.0455H23.1069L26.4802 10.2634H12.8107L10.1539 5.78458L29.1521 5.81606L32.5242 0.0339998L0 0L9.518 16.0455H9.53312L12.7603 21.6085Z" fill="#D71A20" />
    <path d="M51.594 12.3662H46.3887V15.2384H50.6534V17.3626H46.3887V21.7319H44.0391V10.2433H51.594V12.3675V12.3662Z" fill="#fcfcfd" />
    <path d="M63.0123 16.9786C63.0123 20.0598 60.9234 21.9397 58.1381 21.9397C55.3529 21.9397 53.2639 20.0598 53.2639 16.9786V10.2421H55.6135V17.1524C55.6135 18.8762 56.7102 19.7639 58.1381 19.7639C59.566 19.7639 60.6628 18.8762 60.6628 17.1524V10.2421H63.0123V16.9786Z" fill="#fcfcfd" />
    <path d="M75.8934 12.5224L74.2225 13.8974C73.4569 12.836 72.4295 12.2089 71.0192 12.2089C68.7905 12.2089 67.3286 13.7929 67.3286 15.9864C67.3286 18.1799 68.8434 19.8331 71.1577 19.8331C72.4975 19.8331 73.5602 19.2766 74.186 18.4052V17.4647H71.0179V15.4286H76.5356V18.8233C75.5257 20.634 73.5073 21.9397 71.0519 21.9397C67.6056 21.9397 64.9072 19.3635 64.9072 15.9864C64.9072 12.6093 67.588 10.0331 71.0519 10.0331C73.1056 10.0331 74.7942 10.956 75.8909 12.5224H75.8934Z" fill="#fcfcfd" />
    <path d="M85.1883 20.2336H80.4879L79.8444 21.7307H77.3198L82.4547 10.0331H83.2379L88.3551 21.7307H85.8305L85.1858 20.2336H85.1883ZM82.8387 14.42L81.3063 18.302H84.3699L82.8375 14.42H82.8387Z" fill="#fcfcfd" />
    <path d="M97.2529 12.2089L95.6512 13.4454C95.0947 12.6093 94.3984 12.122 93.4578 12.122C92.6922 12.122 92.0299 12.4696 92.0299 13.2364C92.0299 13.8974 92.5348 14.1757 93.3696 14.5598L94.6754 15.1339C96.4861 15.9348 97.5123 16.8577 97.5123 18.4934C97.5123 20.634 95.7721 21.9397 93.4918 21.9397C91.5073 21.9397 89.9057 20.9299 89.0532 19.3987L90.6725 18.1799C91.2996 19.0499 92.1168 19.8508 93.493 19.8508C94.5029 19.8508 95.1287 19.3811 95.1287 18.5803C95.1287 17.8323 94.5721 17.5704 93.6668 17.1524L92.4303 16.5958C90.7934 15.8479 89.645 14.9073 89.645 13.288C89.645 11.3036 91.2983 10.0331 93.4578 10.0331C95.2861 10.0331 96.5742 10.9561 97.2529 12.2089Z" fill="#fcfcfd" />
    <path d="M111.16 15.9864C111.16 19.3635 108.41 21.9397 105.033 21.9397C101.656 21.9397 98.9399 19.3635 98.9399 15.9864C98.9399 12.6093 101.656 10.0331 105.033 10.0331C108.41 10.0331 111.16 12.6093 111.16 15.9864ZM101.36 15.9864C101.36 18.2151 102.909 19.7639 105.051 19.7639C107.192 19.7639 108.741 18.2151 108.741 15.9864C108.741 13.7577 107.157 12.2089 105.051 12.2089C102.944 12.2089 101.36 13.7753 101.36 15.9864Z" fill="#fcfcfd" />
    <path d="M46.0199 25.401H44.383V26.5104H45.7416V26.8579H44.383V28.2845H44.0015V25.0598H46.0199V25.4023V25.401Z" fill="#fcfcfd" />
    <path d="M49.1375 27.004C49.1375 27.864 48.5898 28.3374 47.862 28.3374C47.1342 28.3374 46.5764 27.864 46.5764 27.004V25.0598H46.9579V27.0291C46.9579 27.6789 47.3584 27.9874 47.862 27.9874C48.3657 27.9874 48.756 27.6801 48.756 27.0291V25.0598H49.1375V27.004Z" fill="#fcfcfd" />
    <path d="M52.3471 25.4011H51.2089V28.2833H50.8273V25.4011H49.6941V25.0586H52.3471V25.4011Z" fill="#fcfcfd" />
    <path d="M55.4586 27.004C55.4586 27.864 54.9109 28.3374 54.1831 28.3374C53.4553 28.3374 52.8975 27.864 52.8975 27.004V25.0598H53.279V27.0291C53.279 27.6789 53.6794 27.9874 54.1831 27.9874C54.6867 27.9874 55.0771 27.6801 55.0771 27.0291V25.0598H55.4586V27.004Z" fill="#fcfcfd" />
    <path d="M58.439 28.2833L57.438 26.9297H56.7492V28.2833H56.3677V25.0586H57.5009C58.1355 25.0586 58.5322 25.4489 58.5322 25.9916C58.5322 26.426 58.2778 26.7635 57.8535 26.8856L58.8847 28.2833H58.4403H58.439ZM56.7479 26.5885H57.4858C57.9114 26.5885 58.1355 26.3442 58.1355 25.9967C58.1355 25.6491 57.9114 25.4011 57.4858 25.4011H56.7479V26.5885Z" fill="#fcfcfd" />
    <path d="M61.5312 25.401H59.8451V26.3882H61.1345V26.7307H59.8451V27.942H61.5891V28.2845H59.4636V25.0598H61.5299V25.4023L61.5312 25.401Z" fill="#fcfcfd" />
    <path d="M66.4951 25.6655L66.2068 25.8909C65.9676 25.5585 65.62 25.3583 65.1806 25.3583C64.4326 25.3583 63.9 25.925 63.9 26.6679C63.9 27.4108 64.4427 27.9962 65.1995 27.9962C65.683 27.9962 66.0595 27.757 66.2748 27.4196V26.9852H65.1755V26.6426H66.6563V27.5027C66.373 28.0013 65.8253 28.3375 65.1806 28.3375C64.2375 28.3375 63.5046 27.6097 63.5046 26.6716C63.5046 25.7335 64.2425 25.0057 65.1806 25.0057C65.7321 25.0057 66.1967 25.2551 66.4951 25.6655Z" fill="#fcfcfd" />
    <path d="M69.3331 27.6285H67.7503L67.4771 28.2833H67.0767L68.4743 25.0044H68.6154L70.013 28.2833H69.6076L69.3343 27.6285H69.3331ZM68.5411 25.6982L67.8964 27.286H69.1858L68.5411 25.6982Z" fill="#fcfcfd" />
    <path d="M73.6334 25.0057V28.2846H73.2519V25.9741L72.2206 27.3717H72.0833L71.0471 25.964V28.2846H70.6655V25.0057H70.8028L72.1463 26.8668L73.4999 25.0057H73.6321H73.6334Z" fill="#fcfcfd" />
    <path d="M74.9616 28.2833H74.5801V25.0586H74.9616V28.2833Z" fill="#fcfcfd" />
    <path d="M78.4745 25.0586V28.3375H78.3373L76.2849 25.8658V28.2846H75.9033V25.0057H76.0406L78.093 27.4825V25.0586H78.4745Z" fill="#fcfcfd" />
    <path d="M82.2019 25.6655L81.9136 25.8909C81.6744 25.5585 81.3268 25.3583 80.8874 25.3583C80.1394 25.3583 79.6068 25.925 79.6068 26.6679C79.6068 27.4108 80.1495 27.9962 80.9063 27.9962C81.3898 27.9962 81.7663 27.757 81.9816 27.4196V26.9852H80.8823V26.6426H82.3631V27.5027C82.0798 28.0013 81.5321 28.3375 80.8874 28.3375C79.9443 28.3375 79.2114 27.6097 79.2114 26.6716C79.2114 25.7335 79.9493 25.0057 80.8874 25.0057C81.4389 25.0057 81.9035 25.2551 82.2019 25.6655Z" fill="#fcfcfd" />
    <path d="M86.4958 25.5522L86.2176 25.7625C86.0463 25.5031 85.7731 25.357 85.4797 25.357C85.1863 25.357 84.908 25.5081 84.908 25.8267C84.908 26.1201 85.1322 26.2271 85.4255 26.3694L85.7819 26.5407C86.2516 26.7661 86.5349 27.0053 86.5349 27.4397C86.5349 27.9824 86.0904 28.3388 85.4935 28.3388C84.9811 28.3388 84.5555 28.0706 84.3062 27.645L84.5895 27.4347C84.7846 27.7331 85.073 27.9862 85.4885 27.9862C85.87 27.9862 86.1382 27.7759 86.1382 27.4485C86.1382 27.1501 85.9179 27.033 85.6006 26.8819L85.2392 26.7106C84.8627 26.5293 84.5114 26.3002 84.5114 25.8456C84.5114 25.3319 84.9609 25.0057 85.4835 25.0057C85.9229 25.0057 86.2944 25.2349 86.4946 25.5535L86.4958 25.5522Z" fill="#fcfcfd" />
    <path d="M90.4733 26.6716C90.4733 27.6147 89.7203 28.3375 88.7974 28.3375C87.8744 28.3375 87.1265 27.6097 87.1265 26.6716C87.1265 25.7335 87.8694 25.0057 88.7974 25.0057C89.7254 25.0057 90.4733 25.7285 90.4733 26.6716ZM87.5218 26.6666C87.5218 27.4196 88.0645 27.9862 88.8024 27.9862C89.5403 27.9862 90.0779 27.4196 90.0779 26.6666C90.0779 25.9136 89.5302 25.357 88.8024 25.357C88.0746 25.357 87.5218 25.9187 87.5218 26.6666Z" fill="#fcfcfd" />
    <path d="M91.5917 27.942H93.1216V28.2845H91.2114V25.0598H91.593V27.942H91.5917Z" fill="#fcfcfd" />
    <path d="M96.2086 27.004C96.2086 27.864 95.6609 28.3374 94.9331 28.3374C94.2053 28.3374 93.6475 27.864 93.6475 27.004V25.0598H94.029V27.0291C94.029 27.6789 94.4294 27.9874 94.9331 27.9874C95.4367 27.9874 95.8271 27.6801 95.8271 27.0291V25.0598H96.2086V27.004Z" fill="#fcfcfd" />
    <path d="M99.4182 25.4011H98.2799V28.2833H97.8984V25.4011H96.7651V25.0586H99.4182V25.4011Z" fill="#fcfcfd" />
    <path d="M100.396 28.2833H100.014V25.0586H100.396V28.2833Z" fill="#fcfcfd" />
    <path d="M104.475 26.6716C104.475 27.6147 103.722 28.3375 102.799 28.3375C101.876 28.3375 101.128 27.6097 101.128 26.6716C101.128 25.7335 101.871 25.0057 102.799 25.0057C103.727 25.0057 104.475 25.7285 104.475 26.6716ZM101.523 26.6666C101.523 27.4196 102.066 27.9862 102.804 27.9862C103.542 27.9862 104.079 27.4196 104.079 26.6666C104.079 25.9136 103.532 25.357 102.804 25.357C102.076 25.357 101.523 25.9187 101.523 26.6666Z" fill="#fcfcfd" />
    <path d="M107.782 25.0586V28.3375H107.644L105.592 25.8658V28.2846H105.21V25.0057H105.348L107.4 27.4825V25.0586H107.782Z" fill="#fcfcfd" />
    <path d="M110.674 25.5522L110.396 25.7625C110.225 25.5031 109.951 25.357 109.658 25.357C109.365 25.357 109.086 25.5081 109.086 25.8267C109.086 26.1201 109.31 26.2271 109.604 26.3694L109.96 26.5407C110.43 26.7661 110.713 27.0053 110.713 27.4397C110.713 27.9824 110.269 28.3388 109.672 28.3388C109.159 28.3388 108.734 28.0706 108.484 27.645L108.768 27.4347C108.963 27.7331 109.251 27.9862 109.667 27.9862C110.048 27.9862 110.316 27.7759 110.316 27.4485C110.316 27.1501 110.096 27.033 109.779 26.8819L109.417 26.7106C109.041 26.5293 108.69 26.3002 108.69 25.8456C108.69 25.3319 109.139 25.0057 109.662 25.0057C110.101 25.0057 110.473 25.2349 110.673 25.5535L110.674 25.5522Z" fill="#fcfcfd" />
  </svg>
);

IconProviderFugasoBig.propTypes = {
  className: PropTypes.string,
};

IconProviderFugasoBig.defaultProps = {
  className: '',
};
