import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconBitcoin = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="42"
    viewBox="0 0 80 42"
    fill="none"
  >
    <path
      d="M56.0333 24.9237C55.2426 28.0356 53.5294 30.8475 51.1103 33.0039C48.6912 35.1603 45.6749 36.5642 42.4428 37.0383C39.2107 37.5123 35.9079 37.0351 32.9521 35.667C29.9963 34.299 27.5202 32.1015 25.837 29.3524C24.1538 26.6033 23.339 23.4261 23.4956 20.2226C23.6523 17.0191 24.7734 13.9331 26.7171 11.3549C28.6609 8.77666 31.34 6.82197 34.4157 5.73799C37.4914 4.65401 40.8256 4.48943 43.9965 5.26505C48.2489 6.30574 51.906 8.96147 54.1633 12.6481C56.4206 16.3347 57.0932 20.7504 56.0333 24.9237Z"
      fill="url(#paint0_linear_8822_28343)"
    />
    <path
      d="M47.2862 18.6895C47.6144 16.5316 45.9395 15.3711 43.6502 14.5967L44.393 11.6727L42.5792 11.2297L41.8554 14.0722C41.3798 13.9555 40.8906 13.8461 40.4035 13.7366L41.1315 10.8715L39.3188 10.4275L38.5761 13.3505C38.1815 13.2617 37.7944 13.175 37.4188 13.0831V13.0738L34.918 12.4615L34.4351 14.3623C34.4351 14.3623 35.7807 14.669 35.7544 14.6917C36.4908 14.8713 36.6224 15.3484 36.5961 15.7242L35.7544 19.055C35.8183 19.0712 35.8812 19.0912 35.9427 19.1149L35.7523 19.0684L34.5655 23.7343C34.4751 23.9532 34.2499 24.2815 33.7344 24.1576C33.7523 24.1834 32.4162 23.8344 32.4162 23.8344L31.5156 25.8715L33.8764 26.4487C34.3152 26.5571 34.7455 26.6697 35.1684 26.776L34.413 29.732L36.2247 30.176L36.9685 27.2499C37.463 27.381 37.9427 27.5029 38.413 27.6175L37.6765 30.5302L39.4903 30.9731L40.2404 28.0222C43.3325 28.5963 45.6586 28.364 46.636 25.6206C47.4251 23.4101 46.5981 22.1349 44.9706 21.3038C46.1552 21.0343 47.0474 20.2713 47.2851 18.6895H47.2862ZM43.1452 24.3909C42.5845 26.6015 38.7928 25.4069 37.5629 25.1075L38.5592 21.1892C39.7891 21.4907 43.7302 22.0864 43.1463 24.3899L43.1452 24.3909ZM43.706 18.6575C43.1947 20.6678 40.0384 19.6466 39.0148 19.3957L39.9175 15.8429C40.9359 16.0928 44.2362 16.5605 43.7028 18.6575H43.706Z"
      fill="white"
    />
  </svg>
);

IconBitcoin.propTypes = {
  className: PropTypes.string,
};

IconBitcoin.defaultProps = {
  className: '',
};
