import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTurboGamesBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="70%"
    height="70%"
    viewBox="0 0 368 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_260)">
      <path
        d="M52.5 105C81.4949 105 105 81.4949 105 52.5C105 23.5051 81.4949 0 52.5 0C23.5051 0 0 23.5051 0 52.5C0 81.4949 23.5051 105 52.5 105Z"
        fill="white"
      />
      <path
        d="M91.5 68.8001L74.7 52.0001L91.5 35.2001C93.6 33.1001 93.6 29.8001 91.5 27.7001L76.6 12.8001C74.5 10.7001 71.2 10.7001 69.1 12.8001L52.3 29.6001L35.5 12.8001C33.4 10.7001 30.1 10.7001 28 12.8001L13.1 27.7001C11 29.8001 11 33.1001 13.1 35.2001L29.9 52.0001L13.1 68.8001C11 70.9001 11 74.2001 13.1 76.3001L28 91.2001C30.1 93.3001 33.4 93.3001 35.5 91.2001L52.3 74.4001L69.1 91.2001C71.2 93.3001 74.5 93.3001 76.6 91.2001L91.5 76.3001C93.6 74.2001 93.6 70.9001 91.5 68.8001Z"
        fill="#FF003A"
      />
      <path
        d="M343 0C329.2 0 318 11.2 318 25C318 38.8 329.2 50 343 50C356.8 50 368 38.8 368 25C368 11.2 356.8 0 343 0ZM354.5 30C355.1 30.6 355.1 31.6 354.5 32.2L350.1 36.6C349.5 37.2 348.5 37.2 347.9 36.6L343 31.7L338.1 36.6C337.5 37.2 336.5 37.2 335.9 36.6L331.5 32.2C330.9 31.6 330.9 30.6 331.5 30L336.4 25.1L331.5 20.2C330.9 19.6 330.9 18.6 331.5 18L335.9 13.6C336.5 13 337.5 13 338.1 13.6L343 18.5L347.9 13.6C348.5 13 349.5 13 350.1 13.6L354.5 18C355.1 18.6 355.1 19.6 354.5 20.2L349.6 25.1L354.5 30Z"
        fill="white"
      />
      <path
        d="M186.4 62H200.4C201 62 201.6 62.4 201.8 63L217.4 104.7C217.8 105.7 217 106.7 216 106.7H203C202.4 106.7 201.8 106.3 201.6 105.7L200.3 102.1C200.1 101.5 199.5 101.1 198.9 101.1H187.9C187.3 101.1 186.7 101.5 186.5 102.1L185.2 105.7C185 106.3 184.4 106.7 183.8 106.7H170.9C169.9 106.7 169.1 105.7 169.5 104.7L185 62.9C185.2 62.4 185.8 62 186.4 62ZM196.4 90.9C197.4 90.9 198.1 89.9 197.8 89L193.5 75.7H193.2L189 89C188.7 90 189.4 90.9 190.4 90.9H196.4Z"
        fill="white"
      />
      <path
        d="M224 62H234.3C234.8 62 235.2 62.2 235.5 62.6L247.8 79.2C248.4 80 249.6 80 250.2 79.2L262.5 62.6C262.8 62.2 263.2 62 263.7 62H273.9C274.7 62 275.4 62.7 275.4 63.5V105.2C275.4 106 274.7 106.7 273.9 106.7H261.8C261 106.7 260.3 106 260.3 105.2V96.4L260.4 87.2H260.2L253.6 96.2C253.3 96.6 252.9 96.8 252.4 96.8H245.4C244.9 96.8 244.5 96.6 244.2 96.2L237.6 87.1L237.4 87.2L237.5 96.4V105.2C237.5 106 236.8 106.7 236 106.7H224C223.2 106.7 222.5 106 222.5 105.2V63.5C222.5 62.6 223.1 62 224 62Z"
        fill="white"
      />
      <path
        d="M353.3 79.6999H339C339 79.6999 335.9 79.8999 335.9 77.1999C335.9 74.4999 339 74.4999 339 74.4999H366.5C367.3 74.4999 368 73.7999 368 72.9999V63.3999C368 62.5999 367.3 61.8999 366.5 61.8999H338.1C330.8 61.8999 323.3 61.8999 323.3 76.6999C323.3 90.0999 330.5 88.8999 337.9 88.8999H352.2C352.2 88.8999 355.4 88.8999 355.4 91.3999C355.4 93.8999 352.3 93.9999 352.3 93.9999H316.4C316.4 93.9999 316.4 93.9999 316.3 93.9999H293V90.3999C293 89.5999 293.7 88.8999 294.5 88.8999H308.9C309.7 88.8999 310.4 88.1999 310.4 87.3999V80.9999C310.4 80.1999 309.7 79.4999 308.9 79.4999H293V75.9999C293 75.1999 293.7 74.4999 294.5 74.4999H316.3C317.1 74.4999 317.8 73.7999 317.8 72.9999V63.3999C317.8 62.5999 317.1 61.8999 316.3 61.8999H282C281.2 61.8999 280.5 62.5999 280.5 63.3999V105.1C280.5 105.9 281.2 106.6 282 106.6H313.7H316.4H353.2C360.8 106.6 367.9 106.6 367.9 91.7999C368 78.4999 360.4 79.6999 353.3 79.6999Z"
        fill="white"
      />
      <path
        d="M251.6 48.9001L243.2 34.9001C242.8 34.3001 242.2 33.9001 241.5 33.9001H237C235.9 33.9001 235 34.8001 235 35.9001V47.8001C235 48.9001 234.1 49.8001 233 49.8001H221.5C220.4 49.8001 219.5 48.9001 219.5 47.8001V2.1001C219.5 1.0001 220.4 0.100098 221.5 0.100098H250.1C261.6 0.100098 267.4 8.6001 267.4 17.0001C267.4 22.8001 264.7 28.6001 259.3 31.7001C259.2 31.8001 259.1 32.0001 259.2 32.1001L267.7 46.9001C268.5 48.2001 267.5 49.9001 265.9 49.9001H253.3C252.6 49.9001 252 49.6001 251.6 48.9001ZM235 22.2001C235 22.3001 235.1 22.4001 235.2 22.4001H246.5C250.3 22.4001 252.2 19.7001 252.2 17.0001C252.2 14.3001 250.3 11.6001 246.5 11.6001H237C235.9 11.6001 235 12.5001 235 13.6001V22.2001Z"
        fill="white"
      />
      <path
        d="M310.4 24.3C310.3 24.2 310.3 24.2 310.3 24.1C310.3 24 310.3 24 310.4 23.9C312.8 21.4 314.2 17.4 314.2 13.4C314.2 4.1 308.2 0 302.2 0H274.7C273.3 0 272.2 1.1 272.2 2.5V47.5C272.2 48.9 273.3 50 274.7 50H302.2C311.3 50 315.4 42.6 315.4 35.8C315.4 31.1 313.5 26.9 310.4 24.3ZM296.8 21H286V12.9C286 12.1 286.7 11.4 287.5 11.4H296.8C300.3 11.4 301.9 13.8 301.9 16.2C301.9 18.6 300.3 21 296.8 21ZM287.5 29.8H296.8C300.3 29.8 301.9 32.2 301.9 34.6C301.9 36.9 300.3 39.4 296.8 39.4H286V31.3C286 30.5 286.7 29.8 287.5 29.8Z"
        fill="white"
      />
      <path
        d="M165.2 79.9002H149C147.7 79.9002 146.7 80.9002 146.7 82.2002V87.8002C146.7 89.1002 147.7 90.1002 149 90.1002H154.4C154.6 90.1002 154.8 90.3002 154.8 90.5002V93.4002C154.8 93.5002 154.7 93.7002 154.6 93.8002C153.1 94.6002 151.4 95.0002 149.2 95.0002H148.9C144.2 95.0002 141.8 94.9002 139.6 94.8002C135 94.5002 131.6 90.2002 131.6 84.6002V84.5002C131.6 79.7002 134.6 75.7002 139.2 74.4002C140.3 74.1002 158 74.0002 164.7 74.1002C165.8 74.1002 166.6 73.2002 166.6 72.2002C166.6 69.3002 166.6 64.1002 166.6 64.1002C166.6 63.0002 165.7 62.2002 164.7 62.2002C157.9 62.2002 139.5 62.2002 139.1 62.3002C126.9 63.2002 118.1 72.6002 118.1 84.5002V84.6002C118.1 97.7002 124.7 105.8 136.2 106.7C136.7 106.7 140.5 106.8 146.3 106.8C148.6 106.8 150.4 106.8 150.7 106.8C150.9 106.8 151.1 106.8 151.4 106.8C157.5 106.8 166.9 100.7 167.6 98.9002C167.7 98.7002 167.6 82.4002 167.6 82.4002C167.5 80.9002 166.5 79.9002 165.2 79.9002Z"
        fill="white"
      />
      <path
        d="M159 0H120.9C119.8 0 118.9 0.9 118.9 2V13.4C118.9 14.5 119.8 15.4 120.9 15.4H130.2C131.3 15.4 132.2 16.3 132.2 17.4V48C132.2 49.1 133.1 50 134.2 50H145.6C146.7 50 147.6 49.1 147.6 48V17.5C147.6 16.4 148.5 15.5 149.6 15.5H158.9C160 15.5 160.9 14.6 160.9 13.5V2C161 0.9 160.1 0 159 0Z"
        fill="white"
      />
      <path
        d="M200.7 0.100098C199.6 0.100098 198.7 1.0001 198.7 2.1001V32.7001C198.7 34.8001 197.9 35.6001 195.7 35.6001H184.8C182.6 35.6001 181.8 34.8001 181.8 32.7001V2.1001C181.8 1.0001 180.9 0.100098 179.8 0.100098H168C166.9 0.100098 166 1.0001 166 2.1001V34.7001C166 40.0001 167.3 43.9001 169.8 46.3001C172.3 48.8001 176.4 50.0001 181.8 50.0001H198.5C203.9 50.0001 208 48.7001 210.5 46.3001C213 43.8001 214.3 39.9001 214.3 34.7001V2.1001C214.3 1.0001 213.4 0.100098 212.3 0.100098H200.7Z"
        fill="white"
      />
    </g>
  </svg>

);

IconProviderTurboGamesBig.propTypes = {
  className: PropTypes.string,
};
IconProviderTurboGamesBig.defaultProps = {
  className: null,
};
