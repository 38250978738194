import { IconGamblersAnonymous } from 'components/icons/icons-footer/icon-gamblers-anonymous';
import { IconGamecare } from 'components/icons/icons-footer/icon-gamecare';
// import { IconVisa } from 'components/icons/icons-footer/icon-visa';
// import { IconAstropay } from 'components/icons/icons-footer/icon-astropay';
// import { IconBitcoin } from 'components/icons/icons-footer/icon-bitcoin';
// import { IconEcopayz } from 'components/icons/icons-footer/icon-ecopayz';
// import { IconMastercard } from 'components/icons/icons-footer/icon-mastercard';
// import { IconSkrill } from 'components/icons/icons-footer/icon-skrill';
// import { IconNeteller } from 'components/icons/icons-footer/icon-neteller';
// import { IconMultibanco } from 'components/icons/icons-footer/icon-multibanco';
// import { IconFacebook } from 'components/icons/icons-social/icon-facebook';
// import { IconInstagram } from 'components/icons/icons-social/icon-instagram';
// import { IconTwitter } from 'components/icons/icons-social/icon-twitter';
// import { IconTiktok } from 'components/icons/icons-social/icon-tiktok';
// import { IconPinterest } from 'components/icons/icons-social/icon-pinterest';
// import { IconTelegram } from 'components/icons/icons-social/icon-telegram';
// import { IconYoutube } from 'components/icons/icons-social/icon-youtube';
// import { IconWhatsapp } from 'components/icons/icons-social/icon-whatsapp';

import { MODAL_IDS } from '../../constants';
import { IconMastercard } from '../icons/icons-footer/icon-mastercard';
import { IconVisa } from '../icons/icon-visa/icon-visa';
import { IconBancontact } from '../icons/icons-footer/icon-bancontact';
import { IconBankTransfer } from '../icons/icons-footer/icon-bank-transfer';
import { IconBitcoin } from '../icons/icons-footer/icon-bitcoin';
import { IconGiropay } from '../icons/icons-footer/icon-giropay';
import { IconDeal } from '../icons/icons-footer/icon-deal';
import { IconInteractETransfer } from '../icons/icons-footer/interact-e-transfer';
import { IconMifinity } from '../icons/icons-footer/icon-mifinity';
import { IconMifinityDirect } from '../icons/icons-footer/icon-mifinity-direct';
import { IconMultibanco } from '../icons/icons-footer/icon-multibanco';
import { IconEPS } from '../icons/icons-footer/icon-eps';

export const footerMenu = [
  {
    title: 'footer.link.games',
    link: '/games',
  },
  {
    title: 'footer.link.live',
    link: '/games/live',
  },
  {
    title: 'footer.link.sportsbook',
    link: '/sportsbook',
  },
  {
    title: 'header.in-play',
    link: '/in-play?page=/live',
  },
  {
    title: 'footer.link.promos',
    link: '/promos',
  },
  // {
  //   title: 'footer.link.loyalty',
  //   link: '/loyalty',
  // },
  // {
  //   title: 'footer.link.support',
  //   modalId: MODAL_IDS.SUPPORT,
  // },
  // {
  //   title: 'footer.link.anti-money-laundering',
  //   link: '/anti-money-laundering',
  // },
];

export const footerInfo = [
  [
    {
      title: 'footer.link.payments',
      link: '/payments',
    },
    {
      title: 'footer.link.support',
      modalId: MODAL_IDS.SUPPORT,
    },
    {
      title: 'footer.link.about',
      link: '/about',
    },
    {
      title: 'footer.link.terms',
      link: '/terms',
    },
    {
      title: 'footer.link.bonus-terms',
      link: '/bonus-terms',
    },
    {
      title: 'footer.link.aml',
      link: '/anti-money-laundering',
    },
    {
      title: 'footer.link.refund-policy',
      link: '/refund-policy',
    },
    {
      title: 'footer.link.privacy-policy',
      link: '/privacy-policy',
    },

    {
      title: 'footer.link.affiliates',
      link: '/affiliate',
    },
    {
      title: 'footer.link.responsible-gaming',
      link: '/responsible-gaming',
    },
    // {
    //   title: 'footer.link.faq',
    //   link: '/faq',
    // },
    {
      title: 'footer.link.self-exclusion',
      link: '/self-exclusion',
    },
    {
      title: 'footer.link.dispute-resolution',
      link: '/dispute-resolution',
    },
    {
      title: 'footer.link.fairness-and-rng',
      link: '/fairness-and-rng',
    },
    {
      title: 'footer.link.kyc',
      link: '/kyc',
    },
    // {
    //   title: 'footer.link.loyalty',
    //   link: '/loyalty',
    // },
    // {
    //   title: 'footer.link.anti-money-laundering',
    //   link: '/anti-money-laundering',
    // },
    // {
    //   title: 'footer.link.license',
    //   link: '/license',
    // },
  ],
];

export const footerPaymentIcons = [
  {
    title: 'Bancontact',
    icon: IconBancontact,
  },
  {
    title: 'Bank Transfer',
    icon: IconBankTransfer,
  },
  {
    title: 'Bitcoin',
    icon: IconBitcoin,
  },
  {
    title: 'EPS',
    icon: IconEPS,
  },
  {
    title: 'Giropay',
    icon: IconGiropay,
  },
  {
    title: 'Deal',
    icon: IconDeal,
  },
  {
    title: 'Interact E-transfer',
    icon: IconInteractETransfer,
  },
  {
    title: 'MasterCard',
    icon: IconMastercard,
  },
  {
    title: 'Mifinity',
    icon: IconMifinity,
  },
  {
    title: 'Mifinity Direct',
    icon: IconMifinityDirect,
  },
  {
    title: 'Multibanco',
    icon: IconMultibanco,
  },
  {
    title: 'Visa',
    icon: IconVisa,
  },
  //
  // Old Categories
  //
  /*   {
      title: 'AstroPay',
      icon: IconAstropay,
    },
    {
      title: 'Bitcoin',
      icon: IconBitcoin,
    },
    {
      title: 'Ecopayz',
      icon: IconEcopayz,
    },
    {
      title: 'Visa',
      icon: IconVisa,
    },
    {
      title: 'Mastercard',
      icon: IconMastercard,
    },
    {
      title: 'Skrill',
      icon: IconSkrill,
    },
    {
      title: 'Neteller',
      icon: IconNeteller,
    },
    {
      title: 'Multibanco',
      icon: IconMultibanco,
    } */
];

export const footerIconsWithLink = [
  {
    title: 'Gamecare',
    Icon: IconGamecare,
    link: 'https://www.gamblingtherapy.org/en',
  },
  {
    title: 'GamblersAnonymous',
    Icon: IconGamblersAnonymous,
    link: 'https://www.gamblersanonymous.org.uk',
  },
];

// eslint-disable-next-line no-unused-vars
export const getFooterSocialIconsWithLink = formatMessage => [
  // {
  //   id: 'facebook',
  //   Icon: IconFacebook,
  //   title: 'footer.social.title.facebook',
  //   link: formatMessage({
  //     id: 'footer.social.url.facebook',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'instagram',
  //   Icon: IconInstagram,
  //   title: 'footer.social.title.instagram',
  //   link: formatMessage({
  //     id: 'footer.social.url.instagram',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'tiktok',
  //   Icon: IconTiktok,
  //   title: 'footer.social.title.tiktok',
  //   link: formatMessage({
  //     id: 'footer.social.url.tiktok',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'twitter',
  //   Icon: IconTwitter,
  //   link: formatMessage({
  //     id: 'footer.social.url.twitter',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'pinterest',
  //   Icon: IconPinterest,
  //   link: formatMessage({
  //     id: 'footer.social.url.pinterest',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'telegram',
  //   Icon: IconTelegram,
  //   link: formatMessage({
  //     id: 'footer.social.url.telegram',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'youtube',
  //   Icon: IconYoutube,
  //   link: formatMessage({
  //     id: 'footer.social.url.youtube',
  //     defaultMessage: 'none',
  //   }),
  // },
  // {
  //   id: 'whatsapp',
  //   Icon: IconWhatsapp,
  //   link: formatMessage({ id: 'footer.social.url.whatsapp', defaultMessage: 'none' }),
  // },
];
