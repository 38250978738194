import { addLocaleData } from 'react-intl';
// import * as R from 'ramda';
// import dayjs from 'dayjs';

import intlEN from 'react-intl/locale-data/en';
import intlES from 'react-intl/locale-data/es';
import intlDE from 'react-intl/locale-data/de';
import intlFR from 'react-intl/locale-data/fr';
import intlIT from 'react-intl/locale-data/it';
import intlNB from 'react-intl/locale-data/nb';
import intlFI from 'react-intl/locale-data/fi';
import intlSE from 'react-intl/locale-data/se';
import intlPT from 'react-intl/locale-data/pt';

import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/nb';
import 'dayjs/locale/fi';
import 'dayjs/locale/se';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/pt';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de-at';

// import dayjsES from 'dayjs/locale/es';

// Hack! In order to add unsupported locales to `react-intl`
// example:
// const intlESLocaleEsMx = R.find(R.propEq('locale', 'es-MX'))(intlES || {});
// const intlESTarget = R.append({ ...intlESLocaleEsMx, locale: 'mx' })(intlES);

addLocaleData([
  ...intlEN,
  ...intlES,
  ...intlDE,
  ...intlFR,
  ...intlIT,
  ...intlNB,
  ...intlFI,
  ...intlSE,
  ...intlPT,
]);

// Hack! In order to add unsupported locales to `dayjs`
// example:
// dayjs.locale('mx', { ...dayjsES, name: 'mx' });
