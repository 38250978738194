import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconGiropay = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="42"
    viewBox="0 0 81 42"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0078 9.87844C16.0078 7.83068 17.6739 6.17017 19.7283 6.17017H60.2753C62.3305 6.17017 63.9958 7.83068 63.9958 9.87844V23.1305C63.9958 25.1772 62.3305 26.838 60.2753 26.838H19.7283C17.6739 26.838 16.0078 25.1775 16.0078 23.1308V9.87844Z"
      fill="#848484"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5312 10.0053V23.0049C17.5312 24.2843 18.5728 25.322 19.8576 25.322H41.1003V7.68799H19.8576C18.573 7.68799 17.5312 8.72591 17.5312 10.0053ZM46.5891 16.4403C46.5891 17.2681 46.1805 17.8368 45.5037 17.8368C44.9058 17.8368 44.4074 17.2681 44.4074 16.5101C44.4074 15.7521 44.8457 15.1538 45.5037 15.1538C46.2012 15.1538 46.5891 15.7523 46.5891 16.4403ZM42.6159 21.1871H44.4074V18.3646H44.4274C44.7668 18.9831 45.4443 19.2123 46.0715 19.2123C47.6146 19.2123 48.441 17.9361 48.441 16.4001C48.441 15.1439 47.6548 13.7767 46.2204 13.7767C45.4046 13.7767 44.6477 14.1062 44.2882 14.8344H44.2682V13.8973H42.6159V21.1871ZM50.8885 17.4472C50.8885 16.9489 51.3661 16.7602 51.9743 16.7602C52.243 16.7602 52.5022 16.7791 52.731 16.7898C52.731 17.3979 52.303 18.0162 51.6254 18.0162C51.2071 18.0162 50.8885 17.807 50.8885 17.4472ZM54.5024 19.0928C54.4232 18.6842 54.4034 18.2746 54.4034 17.8664V15.9314C54.4034 14.3457 53.2589 13.7767 51.9342 13.7767C51.1678 13.7767 50.5008 13.8866 49.8631 14.1461L49.8936 15.3635C50.3897 15.0838 50.9685 14.9738 51.546 14.9738C52.1922 14.9738 52.72 15.1639 52.7309 15.8721C52.502 15.8319 52.1827 15.8016 51.8941 15.8016C50.9389 15.8016 49.216 15.9916 49.216 17.5768C49.216 18.7042 50.1316 19.212 51.1574 19.212C51.894 19.212 52.3926 18.9238 52.8008 18.2746H52.8206C52.8206 18.5448 52.8498 18.8131 52.8607 19.0928H54.5024ZM55.309 21.1871C55.6776 21.2672 56.0457 21.3077 56.424 21.3077C58.0673 21.3077 58.4551 20.0408 58.9629 18.734L60.8652 13.8973H59.0723L58.007 17.2877H57.9871L56.8717 13.8973H54.9409L57.0415 19.212C56.9119 19.6711 56.5737 19.9309 56.1352 19.9309C55.8854 19.9309 55.6672 19.9003 55.4281 19.821L55.309 21.1871Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9102 16.4508C21.9102 15.7426 22.2591 15.1536 22.9264 15.1536C23.7326 15.1536 24.0709 15.8017 24.0709 16.38C24.0709 17.1782 23.5631 17.7163 22.9264 17.7163C22.3888 17.7163 21.9102 17.2586 21.9102 16.4508ZM25.8038 13.8976H24.1807V14.8347H24.1618C23.7823 14.1962 23.1645 13.7771 22.3984 13.7771C20.7856 13.7771 20.0586 14.9351 20.0586 16.4806C20.0586 18.0261 20.9451 19.0931 22.3678 19.0931C23.0858 19.0931 23.6832 18.8134 24.111 18.2053H24.131V18.485C24.131 19.5017 23.5734 19.9903 22.5387 19.9903C21.7914 19.9903 21.3328 19.8309 20.7859 19.5618L20.6963 20.9783C21.1138 21.1281 21.8209 21.3078 22.6779 21.3078C24.769 21.3078 25.8038 20.6192 25.8038 18.4847V13.8976ZM28.8329 11.7336H27.0402V13.0499H28.8329V11.7336ZM27.0413 19.0929H28.8329V13.8974H27.0413V19.0929ZM33.8234 13.8369C33.644 13.8077 33.4248 13.7768 33.2162 13.7768C32.4394 13.7768 31.9917 14.196 31.6826 14.8547H31.6627V13.8974H30.03V19.0929H31.8219V16.8995C31.8219 15.8818 32.2906 15.2739 33.1266 15.2739C33.3363 15.2739 33.5345 15.2739 33.7338 15.3327L33.8234 13.8369ZM36.9094 17.9561C36.0829 17.9561 35.7449 17.2679 35.7449 16.5003C35.7449 15.7327 36.0832 15.0341 36.9094 15.0341C37.7355 15.0341 38.0751 15.7223 38.0751 16.5003C38.0751 17.2783 37.7366 17.9561 36.9094 17.9561ZM36.9094 19.2124C38.622 19.2124 39.9268 18.2159 39.9268 16.5006C39.9268 14.7852 38.622 13.7771 36.9094 13.7771C35.1967 13.7771 33.893 14.7746 33.893 16.5006C33.893 18.2265 35.1967 19.2124 36.9094 19.2124Z"
      fill="#FF0007"
    />
    <path
      d="M31.8543 29.2333C31.8227 29.2173 31.7924 29.2173 31.7448 29.2016C31.4639 29.1236 30.748 29.1079 30.5293 29.2966C30.4831 29.3443 30.4674 29.36 30.4358 29.422C30.4358 29.422 29.3296 30.8983 28.6137 32.1857L26.8997 29.422V29.406C26.8681 29.3584 26.8534 29.328 26.8061 29.2806L26.7902 29.2647C26.5885 29.0917 25.9964 29.1076 25.6853 29.155C25.5917 29.171 25.5139 29.1867 25.4676 29.2027C25.3122 29.2806 25.25 29.4233 25.25 29.517V35.3284C25.25 35.438 25.2962 35.6427 25.6547 35.7047C25.7482 35.7207 25.8723 35.7207 26.0132 35.7207C26.2624 35.7207 26.7766 35.7207 26.7766 35.3284V31.9983C26.8861 32.1713 27.1035 32.5173 27.3371 32.957C27.6639 33.5697 27.8829 33.962 27.991 34.1507L28.053 34.276C28.0992 34.354 28.1781 34.433 28.2706 34.511C28.4104 34.6207 28.6913 34.6524 28.8783 34.6047C28.9246 34.5887 28.9719 34.573 29.0181 34.5267C29.1579 34.4171 29.2358 34.338 29.2674 34.2917C29.299 34.2441 29.3452 34.1504 29.4228 34.025C29.5007 33.8997 29.6561 33.5854 29.9211 33.0823C30.186 32.5793 30.389 32.2187 30.5288 31.9823V35.3443C30.5288 35.7366 31.043 35.7366 31.2923 35.7366C31.5415 35.7366 32.0557 35.7366 32.0557 35.3443V29.5316C32.0717 29.4379 32.0241 29.2966 31.8527 29.2333H31.8543Z"
      fill="#2D9DD3"
    />
    <path
      d="M33.7243 31.3223C33.4751 31.3223 32.9609 31.3223 32.9609 31.7146V35.421C32.9609 35.8133 33.4751 35.8133 33.7243 35.8133C33.9736 35.8133 34.4878 35.8133 34.4878 35.421V31.7146C34.4878 31.3223 33.9736 31.3223 33.7243 31.3223Z"
      fill="#2D9DD3"
    />
    <path
      d="M39.3312 29.2173H36.1222C35.827 29.2173 35.4062 29.2333 35.4062 29.6096V35.421C35.4062 35.8133 35.9205 35.8133 36.1698 35.8133C36.419 35.8133 36.9332 35.8133 36.9332 35.421V33.2066H39.0044C39.2377 33.2066 39.5026 33.0179 39.5026 32.4843C39.5026 31.9506 39.2377 31.7619 39.0044 31.7619H36.9332V30.6619H39.3328C39.5661 30.6619 39.8311 30.4732 39.8311 29.9396C39.8311 29.406 39.5661 29.2173 39.3328 29.2173H39.3312Z"
      fill="#2D9DD3"
    />
    <path
      d="M47.7129 29.2015C47.4796 29.2015 46.9495 29.2015 46.9495 29.6095V32.7509L44.707 29.4208L44.6753 29.3731C44.442 29.1525 43.6313 29.2318 43.4755 29.2952C43.3358 29.3585 43.2422 29.4839 43.2422 29.6252V35.4206C43.2422 35.8286 43.7564 35.8286 44.0057 35.8286C44.2549 35.8286 44.7691 35.8286 44.7691 35.4206V32.2792L46.9973 35.6093C47.0592 35.703 47.2003 35.814 47.6829 35.814C47.8543 35.814 48.072 35.798 48.1971 35.7663C48.3685 35.7187 48.478 35.5776 48.478 35.4203V29.6103C48.478 29.2023 47.9481 29.2023 47.7145 29.2023L47.7129 29.2015Z"
      fill="#2D9DD3"
    />
    <path
      d="M50.2673 29.2173C50.018 29.2173 49.5039 29.2173 49.5039 29.6096V35.421C49.5039 35.8133 50.018 35.8133 50.2673 35.8133C50.5166 35.8133 51.0308 35.8133 51.0308 35.421V29.6109C51.0308 29.2186 50.5166 29.2186 50.2673 29.2186V29.2173Z"
      fill="#2D9DD3"
    />
    <path
      d="M41.4041 29.2173C41.1548 29.2173 40.6406 29.2173 40.6406 29.6096V35.421C40.6406 35.8133 41.1548 35.8133 41.4041 35.8133C41.6533 35.8133 42.1675 35.8133 42.1675 35.421V29.6109C42.1675 29.2186 41.6533 29.2186 41.4041 29.2186V29.2173Z"
      fill="#2D9DD3"
    />
    <path
      d="M56.7465 29.2173H52.2601C51.8711 29.2173 51.8711 29.7363 51.8711 29.987C51.8711 30.2377 51.8711 30.7567 52.2601 30.7567H53.7395V35.4218C53.7395 35.8141 54.2537 35.8141 54.5029 35.8141C54.7522 35.8141 55.2664 35.8141 55.2664 35.4218V30.7567H56.7457C57.1348 30.7567 57.1348 30.2377 57.1348 29.987C57.1348 29.7363 57.1356 29.2173 56.7465 29.2173Z"
      fill="#2D9DD3"
    />
    <path
      d="M63.2292 29.2965C63.0419 29.2185 62.123 29.1395 61.874 29.4535L60.6439 31.3853L59.4138 29.4692L59.3979 29.4533C59.1645 29.1389 58.2456 29.2183 58.0426 29.2803C57.8712 29.3436 57.7617 29.4849 57.7617 29.642V29.7516L59.8645 33.05V35.4057C59.8645 35.8297 60.4406 35.8297 60.628 35.8297C60.831 35.8297 61.4071 35.8297 61.4071 35.39V33.0343L63.5098 29.7359V29.6422C63.5098 29.4852 63.4006 29.3599 63.2292 29.2965Z"
      fill="#2D9DD3"
    />
    <path
      d="M33.9111 29.281H33.4905C33.1636 29.281 32.8984 29.5477 32.8984 29.878V30.3177C32.8984 30.6477 33.1633 30.9146 33.4905 30.9146H33.9111C34.238 30.9146 34.5032 30.648 34.5032 30.3177V29.878C34.5032 29.548 34.2382 29.281 33.9111 29.281Z"
      fill="#2D9DD3"
    />
    <path
      d="M17.5533 34.0125L16.5547 31.5647H17.2204L17.848 33.2601H17.8576L18.4805 31.5647H19.1034L18.1095 34.0125H17.5533Z"
      fill="#2D9DD3"
    />
    <path
      d="M19.7963 31.0935C19.7264 31.0935 19.6616 31.0762 19.6013 31.041C19.541 31.0062 19.4934 30.9601 19.4586 30.9029C19.4238 30.8457 19.4062 30.7823 19.4062 30.7123C19.4062 30.6423 19.4235 30.5838 19.4586 30.5266C19.4934 30.4693 19.541 30.4241 19.6013 30.3908C19.6613 30.3576 19.7264 30.3408 19.7963 30.3408C19.8662 30.3408 19.935 30.3576 19.9938 30.3908C20.0525 30.4241 20.099 30.4693 20.1341 30.5266C20.1689 30.5838 20.1864 30.6455 20.1864 30.7123C20.1864 30.7791 20.1689 30.8457 20.1341 30.9029C20.0993 30.9601 20.0525 31.0062 19.9938 31.041C19.935 31.0759 19.8694 31.0935 19.7963 31.0935ZM19.4966 33.9841V31.5648H20.0958V33.9841H19.4966Z"
      fill="#2D9DD3"
    />
    <path
      d="M21.3595 34.0221C21.2296 34.0221 21.106 33.9974 20.9886 33.9484C20.8714 33.8991 20.7762 33.8238 20.7032 33.7222C20.6301 33.6205 20.5938 33.4935 20.5938 33.3413C20.5938 33.1382 20.6585 32.9769 20.7887 32.858C20.9187 32.739 21.1027 32.6557 21.3405 32.608L22.0681 32.4555V32.446C22.0681 32.3062 22.0252 32.2048 21.9396 32.1412C21.8541 32.0779 21.7225 32.0459 21.545 32.0459C21.4435 32.0459 21.34 32.0523 21.2337 32.0651C21.1274 32.0779 21.0285 32.0936 20.9366 32.1127L20.827 32.1364L20.7271 31.7412L20.8459 31.6983C21.0042 31.6411 21.1525 31.5983 21.2904 31.5698C21.4284 31.5413 21.5655 31.5269 21.7018 31.5269C21.9872 31.5269 22.2185 31.6031 22.396 31.7556C22.5735 31.9081 22.6622 32.1383 22.6622 32.4462V33.332C22.6622 33.4305 22.685 33.4962 22.731 33.5297C22.777 33.563 22.8729 33.5861 23.0188 33.5987L22.9856 33.9939C22.9444 34.0035 22.9048 34.0107 22.8668 34.0155C22.8288 34.0202 22.7956 34.0226 22.7669 34.0226C22.6021 34.0226 22.4714 33.9854 22.3746 33.9106C22.2779 33.8361 22.2059 33.7448 22.1583 33.6367L22.0488 33.7368C21.9441 33.8321 21.8309 33.9034 21.709 33.951C21.587 33.9987 21.4703 34.0221 21.3595 34.0221ZM21.5402 33.522C21.6035 33.522 21.6718 33.5095 21.7446 33.4839C21.8174 33.4587 21.8905 33.4206 21.9633 33.3698L22.0681 33.2984V32.8079L21.426 32.9889C21.334 33.0145 21.2692 33.0509 21.2309 33.0986C21.1929 33.1462 21.1738 33.2018 21.1738 33.2652C21.1738 33.3477 21.2054 33.4113 21.2689 33.4557C21.3322 33.5002 21.4227 33.522 21.5402 33.522Z"
      fill="#2D9DD3"
    />
  </svg>
);

IconGiropay.propTypes = {
  className: PropTypes.string,
};

IconGiropay.defaultProps = {
  className: '',
};
