import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';

import { CookiesPolicy as CookiesPolicyCore, FormattedTag, RouteLink } from 'core/components';
import { withCoreComponent, withUserAgent, withUser } from 'core/hocs';
import { withSafariSearchBar } from 'hocs/with-safari-search-bar';

import './cookies-policy.scss';

const CookiesPolicyUI = ({
  isAccepted,
  acceptCookiesPolicy,
  hasNavigation,
  noSidebar,
  navBarHeight,
  isUserLoggedIn,
}) => (
  <div
    style={{ ...(!!navBarHeight && navBarHeight >= 0) && ({ marginBottom: navBarHeight }) }}
    className={classNames('cookies-policy bg-secondary-dark rounded position-fixed flex-column flex-md-row align-items-center justify-content-between justify-content-lg-center mx-1_25 px-2 mx-lg-auto', {
      'has-navigation': hasNavigation,
      'cookies-policy-no-sidebar': noSidebar,
      'd-none': isAccepted,
      'd-flex': !isAccepted,
      'not-logged-in': !isUserLoggedIn
    })}
  >
    <div className="d-md-flex align-items-md-center mr-md-2 pt-1_25 pt-md-0 pt-lg-1_25 mb-2 mb-sm-0">
      <div className="mb-n1 mb-sm-1_5 text-white">
        <FormattedMessage id="cookies.title" />
        {' '}
        <FormattedTag
          tag={RouteLink}
          to="/privacy-policy"
          id="cookies.policy"
          className="policy-link position-relative d-inline-block"
        />
        .
      </div>
    </div>

    <FormattedTag
      id="accept"
      tag={Button}
      onClick={acceptCookiesPolicy}
      className="cookies-btn text-primary ml-auto ml-sm-0 py-1 mb-2 mb-md-0"
      color="primary"
    />
  </div>
);

CookiesPolicyUI.propTypes = {
  isAccepted: PropTypes.bool.isRequired,
  acceptCookiesPolicy: PropTypes.func.isRequired,
  hasNavigation: PropTypes.bool,
  noSidebar: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool.isRequired,
  navBarHeight: PropTypes.number.isRequired,
};

CookiesPolicyUI.defaultProps = {
  hasNavigation: false,
  noSidebar: false,
};

export const CookiesPolicy = withCoreComponent(
  CookiesPolicyCore,
  withUser(withUserAgent(
    withSafariSearchBar(CookiesPolicyUI)
  ))
);
