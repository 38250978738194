import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { withModalActions, withUser, withImagesData } from 'core/hocs';
import { getStaticUrl, isEmptyOrNil } from 'core/helpers';
import { FormattedTag } from 'core/components';
import { USER_FIELDS } from 'core/constants';
import { withPixelRatio } from 'hocs/with-pixel-ratio';
import { createBasicPath } from 'helpers/check-pixel-ratio';

import { IMAGES_DATA_CATEGORIES } from '../../../../constants';

import './sign-up-banner.scss';

const staticUrl = getStaticUrl();
const { SIGN_UP } = IMAGES_DATA_CATEGORIES;

export class SignUpBannerUI extends Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    pixelRatio: PropTypes.string.isRequired,
    imagesData: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    isMobile: false,
    className: '',
  };

  getImageUrl = (isMobile = false) => {
    const {
      pixelRatio,
      imagesData,
    } = this.props;

    let result;
    const defaultBackgroundUrl = `${staticUrl}/images/sign-up/bg.png`;
    const backgroundUrl = R.pathOr(defaultBackgroundUrl, [SIGN_UP, 0, 'imgUrl'])(!isMobile && imagesData || {});
    const basicBackgroundPath = createBasicPath(backgroundUrl) || [];

    if (isMobile) {
      result = !isEmptyOrNil(basicBackgroundPath)
        ? `url("${basicBackgroundPath[0]}-mobile${pixelRatio}${basicBackgroundPath[1]}"), url("${basicBackgroundPath[0]}-mobile${basicBackgroundPath[1]}")`
        : '';
    } else {
      result = !isEmptyOrNil(basicBackgroundPath)
        ? `url("${basicBackgroundPath[0]}${pixelRatio}${basicBackgroundPath[1]}"), url("${backgroundUrl}")`
        : '';
    }

    return result;
  };

  render() {
    const {
      className,
      isMobile,
    } = this.props;

    const backgroundImage = this.getImageUrl(isMobile);

    return (
      <div
        className={classNames('sign-up-banner text-white', className)}
        style={{ backgroundImage }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100 justify-content-md-end pb-md-4_5 text-center">
          <FormattedTag
            id="sign-up.banner.title"
            className="text-capitalize h2 mb-md-0_5"
          />

          <FormattedTag
            id="sign-up.banner.description"
            className="sign-up-banner-text-small"
          />
        </div>
      </div>
    );
  }
}

export const SignUpBanner = withModalActions(
  withPixelRatio(
    withImagesData(
      withUser(SignUpBannerUI, [
        USER_FIELDS.USER_TRACKING_CODES,
      ]),
    ),
  ),
);
