import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Button from 'reactstrap/lib/Button';
import colors from 'customizations/js/color-variables';
import { withGlobalEvents, withLocale } from 'core/hocs';
import { GLOBAL_EVENTS } from 'core/constants';
import { IconCloseThin } from 'components/icons/icon-close-thin/icon-close-thin';
import { wrapRoute2Locale } from 'core/helpers';

import { getIsGameIframeScrollEnabled } from 'helpers/game-iframe';
import { MODAL_SHOWN_CLASS, MODAL_OPEN_CLASS } from '../../constants';

import './mobile-game-container.scss';

class MobileGameContainerUI extends Component {
  static propTypes = {
    globalEvents: PropTypes.shape().isRequired,
    isGamePage: PropTypes.bool,
    isPwa: PropTypes.bool,
    isMobile: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    history: PropTypes.shape().isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    isGamePage: false,
    isPwa: false,
    isMobile: false,
  }

  state = {
    gameUrl: null,
    producer: null,
  };

  componentDidMount() {
    const { globalEvents } = this.props;

    globalEvents.on(GLOBAL_EVENTS.OPEN_GAME, this.openGame);

    window.onpopstate = () => {
      const { gameUrl } = this.state;

      if (gameUrl) {
        document.body.classList.remove(MODAL_SHOWN_CLASS, MODAL_OPEN_CLASS);
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { gameUrl } = this.state;
    const {
      location: { pathname }, isGamePage, isPwa, isMobile
    } = this.props;

    const { location: { pathname: prevPathname }, isGamePage: prevIsGamePage } = prevProps;

    const isPwaRouteChanged = isPwa && pathname !== prevPathname && gameUrl;
    const isMobileRouteChanged = !isPwa && isMobile && !isGamePage && prevIsGamePage;

    if (isPwaRouteChanged || isMobileRouteChanged) {
      this.closeIframe();
    }
  }

  openGame = ({ payload: { gameUrl, producer } }) => {
    window.addEventListener('message', this.onIframeMessage);
    document.body.classList.add('is-mobile-game');

    this.setState({ gameUrl, producer });
  }

  onIframeMessage = ({ data }) => {
    const { type } = data || {};

    if (type === 'CLOSE_IFRAME') {
      this.closeIframe();
    }
  }

  closeIframe = () => {
    const {
      isGamePage, history, locale, isMobile
    } = this.props;
    const { location: { pathname } } = this.props;
    const { prevLocation } = history;

    window.removeEventListener('message', this.onIframeMessage);
    document.body.classList.remove('is-mobile-game');

    this.setState({ gameUrl: null });

    if (isGamePage && isMobile) {
      const isLocationChanged = prevLocation && prevLocation.pathname !== pathname;

      history.push(isLocationChanged ? prevLocation : wrapRoute2Locale('/', locale));
    }
  }

  render() {
    const { gameUrl, producer } = this.state;
    const isGameIframeScrollEnabled = getIsGameIframeScrollEnabled(producer);

    if (!gameUrl) {
      return null;
    }

    return (
      <div className="mobile-game-container position-fixed">
        <div className="d-flex justify-content-end p-2 mobile-game-container-header position-absolute">
          <Button
            onClick={this.closeIframe}
            color="transparent"
            className="mobile-game-container-close-button p-0"
            size="xs"
          >
            <IconCloseThin color={colors.white} />
          </Button>
        </div>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src={gameUrl}
          scrolling={isGameIframeScrollEnabled}
          allowFullScreen
        />
      </div>
    );
  }
}

export const MobileGameContainer = withGlobalEvents(withRouter(withLocale(MobileGameContainerUI)));
