import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from 'reactstrap/lib/Button';
import { FormattedTag, ModalWindow as ModalWindowCore } from 'core/components';
import { withCoreComponent, withUserAgent, withLocale } from 'core/hocs';
import { canUseDOM } from 'core/helpers';
import { LOCALE_KEYS } from 'core/constants';

import { IconPwaLogo } from 'components/icons/icon-pwa-logo/icon-pwa-logo';
import { IconAdd } from 'components/icons/icon-add/icon-add';
import { IconShareIos } from 'components/icons/icon-share-ios/icon-share-ios';
import { IconChrome } from 'components/icons/icon-chrome/icon-chrome';
import { IconCloseCircle } from 'components/icons/icon-close-circle/icon-close-circle';
import { IconIncognito } from 'components/icons/icon-incognito/icon-incognito';
import { areModalsEmpty } from 'helpers/modals';
import { MODAL_IDS, MODAL_OPEN_CLASS, MODAL_SHOWN_CLASS } from '../../../constants';

export class PwaInstallFromMobileModalUI extends Component {
  static propTypes = {
    os: PropTypes.string.isRequired,
    browserName: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    isFromQr: PropTypes.bool,
    isIncognito: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onInstallClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    isFromQr: false,
  };

  state = {
    isCopied: false,
  };

  static getDerivedStateFromProps(nextProps) {
    const { isOpen, modals } = nextProps;

    if (canUseDOM() && isOpen) {
      document.body.classList.add(MODAL_SHOWN_CLASS, MODAL_OPEN_CLASS);
    } else if (areModalsEmpty(modals)) {
      document.body.classList.remove(MODAL_SHOWN_CLASS, MODAL_OPEN_CLASS);
    }

    return null;
  }

  onCopyClick = () => {
    this.setState({ isCopied: true }, () => {
      setTimeout(() => this.setState({ isCopied: false }), 500);
    });
  }

  onCloseModal = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.PWA_INSTALL_MOBILE);
  }

  render() {
    const {
      os,
      isOpen,
      locale,
      isFromQr,
      isIncognito,
      browserName,
      onInstallClick,
    } = this.props;

    const { isCopied } = this.state;

    if (!isOpen) {
      return null;
    }

    const isIos = os === 'ios';
    const isAndroid = os === 'android';
    const isSafari = browserName === 'Mobile Safari';
    const isChrome = browserName === 'Chrome';
    const IconAndroidPopup = isIncognito ? IconIncognito : IconChrome;

    return (
      <div
        className="pwa-install-guide-modal pwa-install-guide-modal-mobile d-flex align-items-center flex-column-reverse flex-sm-row justify-content-sm-end align-items-sm-start position-fixed overflow-auto"
      >
        <div className="p-2">
          <div
            className={classNames('pwa-install-guide-modal-popup d-flex flex-column justify-content-center align-items-center px-2 pt-4_5 pb-5 w-100 position-relative bg-white text-center text-blue-dark', {
              'is-ios-safari': isIos && isSafari,
            })}
          >
            <IconPwaLogo className="mb-1" />
            <FormattedTag tag="div" id="pwa.guide.title" className="install-title h2 text-uppercase mb-1" />

            {isIos && <FormattedTag id="pwa.guide.ios.subtitle" className="mb-2_5 mt-1" />}
            {isAndroid && <FormattedTag id="pwa.guide.android.subtitle" className="mb-2_5 mt-1" />}

            {(!isFromQr || isIos || isAndroid) && (
              <div className={classNames('d-flex align-items-center rounded py-2 bg-old-light', {
                'pl-2_5 pr-0_5': isIos && isSafari && locale === LOCALE_KEYS.EN,
                'px-2': !(isIos && isSafari && locale === LOCALE_KEYS.EN),
                'bg-white': isIos && isSafari,
              })}
              >
                {isIos && (
                  <Fragment>
                    {isSafari
                      ? <IconAdd className="flex-shrink-0" />
                      : (
                        <img
                          src="/assets/public/images/safari.png"
                          alt="safari"
                          width="28"
                          height="28"
                          className="flex-shrink-0"
                        />
                      )}
                  </Fragment>
                )}

                {isAndroid && <IconAndroidPopup className="flex-shrink-0" />}

                {(isSafari && isIos || isAndroid && (isChrome && isIncognito || !isChrome)) && (
                  <div className="ml-1_5 text-left">
                    {isIos && (
                      <Fragment>
                        {isSafari
                          ? (
                            <Fragment>
                              <FormattedTag id="pwa.guide.ios.step.1" className="mr-1" />
                              <IconShareIos
                                className="pwa-install-guide-icon-share mr-1 mt-0_5 position-relative flex-shrink-0"
                              />
                              <FormattedHTMLMessage id="pwa.guide.ios.step.2" />
                            </Fragment>
                          )
                          : <FormattedHTMLMessage id="pwa.guide.ios.not-safari" />}
                      </Fragment>
                    )}

                    {isAndroid && (
                      <Fragment>
                        {isChrome && isIncognito && <FormattedHTMLMessage id="pwa.guide.android.incognito" />}
                        {!isChrome && <FormattedHTMLMessage id="pwa.guide.android.not-chrome" />}
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
            )}

            {(isIos && !isSafari || isAndroid && !isChrome) && (
              <CopyToClipboard text={window.location.href} onCopy={this.onCopyClick}>
                <FormattedTag
                  tag={Button}
                  id={isCopied ? 'copied' : 'copy-website-link'}
                  className="mt-2 py-1_5 px-4"
                  color="primary"
                  size="sm"
                />
              </CopyToClipboard>
            )}

            {isFromQr && isAndroid && isChrome && !isIncognito && (
              <FormattedTag
                tag={Button}
                id="install"
                className="mt-2 py-1_5 px-4"
                onClick={onInstallClick}
                color="primary"
                size="sm"
              />
            )}

            {isAndroid && isChrome && isIncognito && (
              <FormattedTag
                tag={Button}
                id="got-it"
                className="mt-2 py-1_5 px-4"
                color="primary"
                size="sm"
                onClick={this.onCloseModal}
              />
            )}

            <span
              role="button"
              tabIndex="0"
              onClick={this.onCloseModal}
              onKeyPress={this.onCloseModal}
              className="pwa-install-guide-close-button position-absolute"
            >
              <IconCloseCircle color="text-blue-dark" />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export const PwaInstallFromMobileModal = withCoreComponent(
  ModalWindowCore, withUserAgent(withLocale(PwaInstallFromMobileModalUI))
);
