import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSmartSoftBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="141"
    height="78"
    viewBox="0 0 141 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="11" width="51" height="56.4" fill="url(#icon-provider-smart-soft-big-pattern-0)" />
    <rect x="51" y="11" width="90" height="56.4" fill="url(#icon-provider-smart-soft-big-pattern-1)" />
  </svg>

);

IconProviderSmartSoftBig.propTypes = {
  className: PropTypes.string,
};

IconProviderSmartSoftBig.defaultProps = {
  className: '',
};
