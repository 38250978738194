import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormSignUp } from 'components/forms/form-sign-up/form-sign-up';
import { ModalWindow } from 'components/modal-window/modal-window';
import { withModalActions } from 'core/hocs';

import { MODAL_IDS } from '../../../constants';

import './sign-up-modal.scss';

export class SignUpModalUI extends Component {
  static propTypes = {
    currentModal: PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.any
    }).isRequired,
  };

  state = {
    isSuccess: false
  };

  onSuccess = val => this.setState({ isSuccess: val });

  onCloseSignUpModal = () => this.setState({ isSuccess: false });

  render() {
    const { isSuccess } = this.state;

    return (
      <ModalWindow
        id={MODAL_IDS.SIGN_UP}
        coreProps={{ id: MODAL_IDS.SIGN_UP }}
        bodyClassName={classNames({
          'auth-modal-body p-md-0': !isSuccess,
        })}
        onClosed={this.onCloseSignUpModal}
        className={classNames({ 'sign-up-modal': !isSuccess })}
        fade
      >
        <div className="modal-form-container">
          <FormSignUp onSuccess={this.onSuccess} />
        </div>
      </ModalWindow>
    );
  }
}

export const SignUpModal = withModalActions(SignUpModalUI);
