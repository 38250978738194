import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconSuccessBig = ({ className, color }) => (
  <svg className={className} width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48 96C21.5314 96 0 74.4686 0 48C0 21.5314 21.5314 0 48 0C74.4686 0 96 21.5314 96 48C96 74.4686 74.4686 96 48 96Z"
      fill={color}
    />
    <path
      d="M47.9999 84.2871C27.9913 84.2871 11.7126 68.0084 11.7126 47.9999C11.7126 27.9913 27.9913 11.7126 47.9999 11.7126C68.0085 11.7126 84.2871 27.9913 84.2871 47.9999C84.2871 68.0084 68.0085 84.2871 47.9999 84.2871Z"
      stroke="#01FA6C"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.5844 41.3979C64.5608 40.4216 64.5608 38.8387 63.5844 37.8624C62.6081 36.886 61.0252 36.886 60.0489 37.8624L44.5641 53.3472L36.2678 45.0503C35.2916 44.074 33.7086 44.0739 32.7323 45.0502C31.7559 46.0265 31.7559 47.6094 32.7322 48.5858L42.7952 58.6495C43.4538 59.3081 44.3884 59.5224 45.2267 59.2925C45.6321 59.1818 46.0151 58.9672 46.3336 58.6487L63.5844 41.3979Z"
      fill="white"
    />
  </svg>
);

IconSuccessBig.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconSuccessBig.defaultProps = {
  className: null,
  color: colors.bgSecondaryLight,
};
