import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Button from 'reactstrap/lib/Button';
import { injectIntl, intlShape } from 'react-intl';
import colors from 'customizations/js/color-variables';

import { FormattedTag, LoyaltyProgram as LoyaltyProgramCore } from 'core/components';
import {
  withUser, withModalActions, withGameActions,
  withUserAgent, withCoreComponent,
} from 'core/hocs';
import {
  USER_FIELDS, LOYALTY_POINTS_KEYS, LOYALTY_POINTS_STATUS_INDEXES, DEVICES_TYPES,
} from 'core/constants';
import { isEmptyOrNil } from 'core/helpers';
import { IconGift } from 'components/icons/icon-gift/icon-gift';
import { IconFreeSpins } from 'components/icons/icon-free-spins/icon-free-spins';
import { IconStarGift } from 'components/icons/icon-star-gift/icon-star-gift';
import { IconClose } from 'components/icons/icon-close/icon-close';
import { FREESPINS_INFO_MOBILE, FREESPINS_INFO_DESKTOP, CURRENCY_SYMBOLS } from '../../../../constants';
import { getCharacterImageUrl } from '../../../../helpers/characters';

const { CURRENT_VIP_LEVEL } = LOYALTY_POINTS_KEYS;

const SORT_ORDER_INDEX = 'sortOrderIndex';

export class NewLevelContentUI extends Component {
  static propTypes = {
    loyaltyPoints: PropTypes.shape().isRequired,
    loyaltyLevels: PropTypes.arrayOf(PropTypes.shape()),
    closeModal: PropTypes.func.isRequired,
    openGame: PropTypes.func.isRequired,
    modalId: PropTypes.string.isRequired,
    userData: PropTypes.shape({
      characterId: PropTypes.number,
      currency: PropTypes.string
    }).isRequired,
    deviceType: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    loyaltyLevels: [],
  };

  constructor(props) {
    super(props);

    const { deviceType, loyaltyLevels } = props;
    const [
      freespinsGameId,
      freespinsGameName,
      freespinsGameProducer
    ] = deviceType === DEVICES_TYPES.MOBILE
      ? FREESPINS_INFO_MOBILE
      : FREESPINS_INFO_DESKTOP;

    const userLevelIndex = this.getUserLevelIndex();
    const currentLevelInfo = R.find(R.propEq(SORT_ORDER_INDEX, userLevelIndex))(loyaltyLevels);

    if (!isEmptyOrNil(loyaltyLevels) && !isEmptyOrNil(currentLevelInfo)
     && R.prop('freespinsAmount', currentLevelInfo)) {
      const {
        [freespinsGameId]: gameId,
        [freespinsGameName]: gameName,
        [freespinsGameProducer]: producer,
        freespinsAmount,
        gift,
      } = currentLevelInfo;

      this.state = {
        gameId: gameId.intlKey,
        gameName: gameName.intlKey,
        producer: producer.intlKey,
        freespinsAmount,
        vipManager: userLevelIndex === 5, // TODO: add oportunity to mark 'vipManager' field in CMS
        gift: this.getValueFromCurrencies(gift),
      };
    } else {
      this.state = {};
    }
  }

  closeModal = () => {
    const { closeModal, modalId } = this.props;
    closeModal(modalId);
  };

  getUserLevelIndex = () => {
    const {
      loyaltyPoints: {
        [CURRENT_VIP_LEVEL]: currentLevel,
      },
    } = this.props;

    return LOYALTY_POINTS_STATUS_INDEXES[currentLevel];
  };

  openGame = () => {
    const { openGame, intl: { formatMessage } } = this.props;
    const { gameId, producer } = this.state;

    this.closeModal();

    openGame({
      gameId: formatMessage({ id: gameId }),
      producer: formatMessage({ id: producer })
    });
  };

  getValueFromCurrencies = (obj) => {
    const { userData } = this.props;

    return R.path(['currencies', userData.currency])(obj);
  };

  render() {
    const {
      loyaltyPoints: {
        [CURRENT_VIP_LEVEL]: currentLevel,
        currentLoyaltyPoint,
      },
      userData: { currency },
      intl: { formatMessage },
    } = this.props;

    const {
      freespinsAmount,
      gameName,
      gift,
      vipManager
    } = this.state;
    const currentLevelIndex = LOYALTY_POINTS_STATUS_INDEXES[currentLevel];

    return (
      <div className="new-level-modal-content position-relative d-flex flex-column text-center pt-2 pt-sm-0">
        <IconClose className="icon-close" color={colors.blueDark} />
        <FormattedTag id="promo.new-level.title" className="h3 mb-2_5 font-family-oswald text-uppercase" isHtml />
        <img
          src={getCharacterImageUrl({ characterId: 1, levelId: currentLevelIndex })}
          className="image-level mx-auto position-relative"
          alt=""
        />

        <FormattedTag
          id={`loyalty-program.level-type.${currentLevel}`}
          className="h3 mt-2 mb-0_5 font-family-oswald text-capitalize"
          isHtml
        />

        <FormattedTag
          id="promo.new-level.user-points"
          values={{ value: currentLoyaltyPoint }}
          className="new-level-modal-content-points font-weight-bold "
          isHtml
        />

        {!isEmptyOrNil(this.state) && (
          <div className="new-level-modal-content-bonuses h5 mt-2 d-flex align-items-center justify-content-center flex-column text-dark-violet-70">
            {!vipManager && (
              <div className="d-flex align-items-center mb-1">
                <IconStarGift className="flex-shrink-0 mr-1" />
                <FormattedTag id="loyalty-program.star-gift" className="font-weight-semi-bold" isHtml />
              </div>
            )}
            {freespinsAmount && (
              <div className="d-flex align-items-center mb-1">
                <IconFreeSpins className="flex-shrink-0" />

                <FormattedTag
                  id="promo.new-level.free-spins"
                  className="ml-0_75 mr-0_5 text-nowrap font-weight-semi-bold"
                  values={{ value: freespinsAmount }}
                />

                {gameName && (
                  <FormattedTag
                    tag="div"
                    role="button"
                    tabIndex="0"
                    id={formatMessage({ id: 'gameName' })}
                    onClick={this.openGame}
                    onKeyPress={this.openGame}
                    className="text-capitalize font-weight-semi-bold"
                    isHtml
                  />
                )}
              </div>
            )}

            {gift && (
              <div className="d-flex align-items-center">
                <IconGift className="mr-1" />
                <FormattedTag
                  className="font-weight-semi-bold"
                  id="loyalty-program.gift"
                  values={{ value: `${CURRENCY_SYMBOLS[currency]}${gift}` }}
                  isHtml
                />
              </div>
            )}
          </div>
        )}

        <FormattedTag
          tag={Button}
          onClick={this.closeModal}
          id="got-it"
          color="primary"
          className="mt-5 mx-auto"
          isHtml
        />
      </div>
    );
  }
}

export const NewLevelContent = withCoreComponent(
  LoyaltyProgramCore,
  withModalActions(
    withGameActions(
      withUserAgent(
        withUser(
          injectIntl(NewLevelContentUI),
          [USER_FIELDS.LOYALTY_POINTS, USER_FIELDS.USER_DATA],
        )
      )
    )
  )
);
