import React from 'react';
import PropTypes from 'prop-types';
import colors from 'src/customizations/js/color-variables';

export const IconSuccess = ({ className, color, outlined }) =>
  outlined ? (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="30.0714" transform="matrix(-1 0 0 1 32.0008 32)" stroke={color} strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.7016 25.5737C48.4826 24.7927 48.4826 23.5264 47.7016 22.7453C46.9206 21.9643 45.6542 21.9643 44.8732 22.7453L29.1442 38.4742L20.5588 29.8882C19.7778 29.1071 18.5114 29.1071 17.7304 29.8881C16.9493 30.6691 16.9492 31.9355 17.7303 32.7165L27.7303 42.7172C28.5113 43.4983 29.7776 43.4984 30.5587 42.7173C30.5693 42.7067 30.5798 42.696 30.5901 42.6853L47.7016 25.5737Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 36C12.7157 36 6 29.2843 6 21C6 12.7157 12.7157 6 21 6C29.2843 6 36 12.7157 36 21C36 29.2843 29.2843 36 21 36ZM29.2075 16.1767C29.598 16.5673 29.598 17.2004 29.2075 17.5909L20.2075 26.5909C20.0738 26.7246 19.9117 26.8125 19.7406 26.8547C19.4115 26.9363 19.049 26.8485 18.7918 26.5913L13.5418 21.3409C13.1513 20.9504 13.1513 20.3172 13.5418 19.9267C13.9324 19.5362 14.5655 19.5362 14.956 19.9268L19.4995 24.4705L27.7933 16.1767C28.1838 15.7862 28.8169 15.7862 29.2075 16.1767Z"
        fill="#01FA6C"
      />
    </svg>
  );

IconSuccess.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
};

IconSuccess.defaultProps = {
  className: null,
  color: colors.green2,
  outlined: false,
};
