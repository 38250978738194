import React from 'react';
import PropTypes from 'prop-types';

export const IconSlotMachine = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_6060_197267)">
      <circle opacity="0.15" cx="18" cy="18" r="18" fill="white" />
      <path
        d="M9.63198 27.3681C9.63198 27.7133 9.91134 27.9926 10.2565 27.9926H22.7473C23.0925 27.9926 23.3718 27.7132 23.3718 27.3681V22.9963H9.63198V27.3681ZM13.3792 24.8699H19.6246C19.9698 24.8699 20.2491 25.1493 20.2491 25.4945C20.2491 25.8396 19.9697 26.119 19.6246 26.119H13.3792C13.034 26.119 12.7547 25.8396 12.7547 25.4945C12.7547 25.1493 13.034 24.8699 13.3792 24.8699ZM27.7436 8.00732C26.7105 8.00732 25.87 8.84777 25.87 9.88094C25.87 10.6941 26.3937 11.3808 27.1191 11.6395V15.5019H25.87V19.2491H27.7436C28.0888 19.2491 28.3682 18.9697 28.3682 18.6246V11.6395C29.0936 11.3808 29.6173 10.6941 29.6173 9.88094C29.6173 8.84777 28.7768 8.00732 27.7436 8.00732ZM11.5056 18.6245H14.0037V14.8773H11.5056V18.6245ZM19.0001 18.6245H21.4982V14.8773H19.0001V18.6245ZM15.2528 18.6245H17.751V14.8773H15.2528V18.6245ZM23.9964 11.7546H22.2027C21.2134 9.49612 18.9757 8.00732 16.5019 8.00732C14.0281 8.00732 11.7904 9.49612 10.8011 11.7546H9.00733C8.66209 11.7546 8.38281 12.0339 8.38281 12.3791V21.1226C8.38281 21.4679 8.66216 21.7472 9.00733 21.7472H23.9963C24.3416 21.7472 24.6209 21.4678 24.6209 21.1226V12.3791C24.6209 12.0339 24.3416 11.7546 23.9964 11.7546ZM22.7473 19.2491C22.7473 19.5943 22.468 19.8736 22.1228 19.8736H10.881C10.5358 19.8736 10.2565 19.5943 10.2565 19.2491V14.2528C10.2565 13.9075 10.5359 13.6282 10.881 13.6282H22.1227C22.468 13.6282 22.7472 13.9075 22.7472 14.2528V19.2491H22.7473Z"
        fill="white"
      />
    </g>
  </svg>
);

IconSlotMachine.propTypes = {
  className: PropTypes.string,
};

IconSlotMachine.defaultProps = {
  className: null,
};
