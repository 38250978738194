import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEgtJackpot = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-provider-egt-jackpot-clip-0)">
      <path
        d="M21.9196 15.4931C19.763 16.7223 17.2246 17.8415 14.4425 18.7393C10.4991 20.0097 6.69221 20.6326 3.51782 20.6422C6.6309 23.6112 11.2487 24.8262 15.6423 23.4099C19.947 22.0209 22.9281 18.4651 23.7616 14.3551C23.1958 14.7347 22.5945 15.1068 21.9577 15.4715L21.9196 15.4931Z"
        fill="#E42314"
      />
      <path
        d="M16.6468 16.8621C17.898 16.4598 19.1261 15.9901 20.3256 15.455V9.82073H18.0685V8.50101H23.4682C23.4469 8.43171 23.4294 8.36203 23.4057 8.29273C22.8474 6.57824 21.9056 5.01117 20.6508 3.70874C18.2866 3.86812 15.6097 4.38246 12.8516 5.27139C10.1355 6.14722 7.69367 7.2733 5.70196 8.50101H9.77617V9.80071H6.06592V11.3202H9.34042V12.5795H6.06592V14.1991H9.90732V15.4988H4.44517V9.33257C1.42754 11.4777 -0.195157 13.8296 0.413646 15.6879C1.46169 18.8886 8.72968 19.4145 16.6468 16.8621ZM11.0593 10.1356C11.3913 9.58635 11.871 9.13974 12.4446 8.84595C13.0354 8.53617 13.7005 8.38128 14.4398 8.38128C15.0575 8.38128 15.6183 8.48458 16.1222 8.69119C16.6112 8.88703 17.0462 9.19541 17.3914 9.59089L16.3438 10.5503C15.8401 10.0239 15.2322 9.76067 14.5201 9.76067C14.07 9.76067 13.6703 9.85396 13.3211 10.0406C12.9789 10.2209 12.6961 10.495 12.5063 10.8302C12.3115 11.17 12.2141 11.5598 12.2141 11.9997C12.2141 12.4332 12.3115 12.8197 12.5063 13.1593C12.6963 13.4944 12.9772 13.7698 13.3172 13.9543C13.6633 14.144 14.058 14.2389 14.5011 14.2392C14.9429 14.2459 15.3793 14.1428 15.7707 13.9393V11.89H17.2618V14.7289C16.8611 15.0245 16.4109 15.2479 15.9321 15.3887C15.4394 15.5406 14.9266 15.6185 14.4107 15.6197C13.6781 15.6197 13.0198 15.4648 12.4357 15.1551C11.8663 14.8591 11.3904 14.4128 11.0605 13.8654C10.7278 13.3156 10.5615 12.6941 10.5615 12.0009C10.5615 11.3077 10.7278 10.6859 11.0605 10.1356H11.0593Z"
        fill="#E42314"
      />
      <path
        d="M23.7941 9.82074H21.9576V14.6715C22.6434 14.3168 23.2901 13.9499 23.8977 13.5709C24.0628 12.3232 24.0279 11.0575 23.7941 9.82074Z"
        fill="#E42314"
      />
      <path
        d="M20.1017 3.18023C16.9925 0.341751 12.4737 -0.797798 8.16936 0.590065C3.77659 2.00642 0.759354 5.68262 0 9.9001C2.57373 8.05642 6.02167 6.33978 9.96512 5.06857C13.5877 3.90053 17.0942 3.28071 20.1017 3.18023Z"
        fill="#E42314"
      />
    </g>
  </svg>
);

IconProviderEgtJackpot.propTypes = {
  className: PropTypes.string,
};

IconProviderEgtJackpot.defaultProps = {
  className: '',
};
