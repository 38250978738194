import React from 'react';
import PropTypes from 'prop-types';

export const IconLangIt = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9999 12C23.9999 6.84043 20.7434 2.44191 16.1738 0.746399V23.2537C20.7434 21.5581 23.9999 17.1596 23.9999 12Z"
        fill="#D80027"
      />
      <path d="M0 12C0 17.1596 3.2565 21.5581 7.82611 23.2536V0.746399C3.2565 2.44191 0 6.84043 0 12Z" fill="#6DA544" />
    </g>
  </svg>
);

IconLangIt.propTypes = {
  className: PropTypes.string,
};

IconLangIt.defaultProps = {
  className: '',
};
