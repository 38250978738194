import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderCandleBetsBig = ({ className }) => (
  <svg className={className} width="116" height="26" viewBox="0 0 116 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.3726 10.8978C28.3726 9.486 28.8229 8.327 29.7237 7.42082C30.631 6.50807 31.7864 6.0517 33.1898 6.0517C35.0697 6.0517 36.4045 6.82655 37.1943 8.37625C37.2074 8.44192 37.24 8.524 37.2922 8.6225C37.364 8.79323 37.3999 8.94754 37.3999 9.08544C37.3999 9.2496 37.3445 9.39078 37.2335 9.50898C37.1291 9.62718 36.9822 9.68628 36.7929 9.68628C36.5253 9.68628 36.3262 9.51883 36.1956 9.18394C35.6604 7.90346 34.6584 7.26322 33.1898 7.26322C32.0801 7.26322 31.1989 7.59155 30.5462 8.2482C29.9 8.89829 29.5769 9.78149 29.5769 10.8978V16.1379C29.5769 17.2542 29.9 18.1407 30.5462 18.7974C31.1989 19.4474 32.0801 19.7725 33.1898 19.7725C34.678 19.7725 35.7126 19.0994 36.2936 17.7533C36.4437 17.4184 36.646 17.2509 36.9006 17.2509C37.0899 17.2509 37.2368 17.3133 37.3412 17.4381C37.4456 17.5563 37.4979 17.6975 37.4979 17.8616C37.4979 18.0192 37.4652 18.1735 37.3999 18.3246C37.3347 18.4559 37.2988 18.5347 37.2922 18.561C37.0116 19.211 36.5187 19.7791 35.8138 20.265C35.1088 20.7443 34.2342 20.984 33.1898 20.984C31.7864 20.984 30.631 20.5309 29.7237 19.6247C28.8229 18.712 28.3726 17.5497 28.3726 16.1379V10.8978Z" fill="#fcfcfd" />
    <path d="M44.7516 16.2463H42.1472C41.5075 16.2463 41.0114 16.4006 40.659 16.7092C40.313 17.0178 40.14 17.4348 40.14 17.9601C40.14 18.4854 40.313 18.9024 40.659 19.211C41.0114 19.5197 41.5075 19.674 42.1472 19.674C42.8979 19.674 43.5441 19.4934 44.0859 19.1322C44.36 18.9681 44.5819 18.7777 44.7516 18.561V16.2463ZM42.4507 10.5925C43.456 10.5925 44.2915 10.9306 44.9573 11.607C45.6231 12.2768 45.9559 13.1173 45.9559 14.1285V20.0778C45.9559 20.242 45.9037 20.3832 45.7993 20.5014C45.6949 20.6196 45.548 20.6787 45.3587 20.6787C45.1694 20.6787 45.0193 20.6196 44.9083 20.5014C44.8039 20.3832 44.7516 20.242 44.7516 20.0778V19.871H44.6537C44.2882 20.1402 44.0271 20.3109 43.8704 20.3832C43.2634 20.7181 42.6498 20.8855 42.0297 20.8855C41.1485 20.8855 40.4109 20.6032 39.8169 20.0384C39.2295 19.4671 38.9357 18.7744 38.9357 17.9601C38.9357 17.1327 39.2327 16.44 39.8267 15.8818C40.4272 15.3171 41.2007 15.0347 42.1472 15.0347H44.7516V14.1285C44.7516 13.4128 44.546 12.8481 44.1348 12.4344C43.7236 12.0141 43.1622 11.804 42.4507 11.804C41.9481 11.804 41.5467 11.9025 41.2464 12.0995C40.9527 12.2899 40.6851 12.5624 40.4436 12.917C40.4175 12.9498 40.3816 12.9958 40.3359 13.0549C40.2967 13.114 40.2673 13.1567 40.2477 13.183C40.2347 13.2092 40.2086 13.2421 40.1694 13.2815C40.1368 13.3209 40.1041 13.3471 40.0715 13.3603C40.0454 13.3734 40.0128 13.3865 39.9736 13.3997C39.9344 13.4128 39.8887 13.4194 39.8365 13.4194C39.6472 13.4194 39.5004 13.3603 39.3959 13.2421C39.2915 13.1239 39.2393 12.9827 39.2393 12.8185C39.2393 12.7594 39.2523 12.6642 39.2784 12.5329L39.3372 12.4147C39.5591 11.9485 39.9507 11.5282 40.5121 11.1539C41.0735 10.7796 41.7197 10.5925 42.4507 10.5925Z" fill="#fcfcfd" />
    <path d="M49.8024 20.0778C49.8024 20.242 49.7502 20.3832 49.6458 20.5014C49.5413 20.6196 49.3945 20.6787 49.2052 20.6787C49.0159 20.6787 48.8658 20.6196 48.7548 20.5014C48.6504 20.3832 48.5981 20.242 48.5981 20.0778V11.4001C48.5981 11.236 48.6504 11.0948 48.7548 10.9766C48.8658 10.8584 49.0159 10.7993 49.2052 10.7993C49.3945 10.7993 49.5413 10.8584 49.6458 10.9766C49.7502 11.0948 49.8024 11.236 49.8024 11.4001V11.7055H49.9003C50.0962 11.4888 50.305 11.3082 50.527 11.1637C51.0492 10.7829 51.6758 10.5925 52.4069 10.5925C53.3729 10.5925 54.1823 10.9306 54.8351 11.607C55.4943 12.2833 55.824 13.1239 55.824 14.1285V20.0778C55.824 20.242 55.7685 20.3832 55.6575 20.5014C55.5531 20.6196 55.4062 20.6787 55.2169 20.6787C55.0276 20.6787 54.8808 20.6196 54.7763 20.5014C54.6719 20.3832 54.6197 20.242 54.6197 20.0778V14.1285C54.6197 13.4062 54.4075 12.8382 53.9832 12.4245C53.559 12.0108 52.9682 11.804 52.211 11.804C51.4539 11.804 50.8631 12.0108 50.4389 12.4245C50.0146 12.8382 49.8024 13.4062 49.8024 14.1285V20.0778Z" fill="#fcfcfd" />
    <path d="M65.4864 20.0778C65.4864 20.242 65.4341 20.3832 65.3297 20.5014C65.2253 20.6196 65.0784 20.6787 64.8891 20.6787C64.6998 20.6787 64.5497 20.6196 64.4387 20.5014C64.3343 20.3832 64.2821 20.242 64.2821 20.0778V19.7725H64.1841C64.0144 19.9892 63.8088 20.1763 63.5673 20.3339C63.0582 20.7017 62.4283 20.8855 61.6776 20.8855C60.6659 20.8855 59.8108 20.5276 59.1124 19.8119C58.4139 19.0896 58.0647 18.2031 58.0647 17.1524V14.3255C58.0647 13.2749 58.4139 12.3917 59.1124 11.6759C59.8108 10.9536 60.6659 10.5925 61.6776 10.5925C62.4152 10.5925 63.0451 10.7829 63.5673 11.1637C63.7762 11.2951 63.9818 11.4757 64.1841 11.7055H64.2821V7.26322C64.2821 7.09906 64.3343 6.95788 64.4387 6.83968C64.5497 6.72148 64.6998 6.66239 64.8891 6.66239C65.0784 6.66239 65.2253 6.72148 65.3297 6.83968C65.4341 6.95788 65.4864 7.09906 65.4864 7.26322V20.0778ZM61.7755 19.674C62.5719 19.674 63.1887 19.4671 63.626 19.0534C64.0634 18.6398 64.2821 18.0718 64.2821 17.3494V14.1285C64.2821 13.4062 64.0634 12.8382 63.626 12.4245C63.1887 12.0108 62.5719 11.804 61.7755 11.804C61.0118 11.804 60.4015 12.0338 59.9446 12.4935C59.4942 12.9466 59.269 13.5573 59.269 14.3255V17.1524C59.269 17.9207 59.4942 18.5347 59.9446 18.9943C60.4015 19.4474 61.0118 19.674 61.7755 19.674Z" fill="#fcfcfd" />
    <path d="M69.4405 20.0778C69.4405 20.242 69.3883 20.3832 69.2839 20.5014C69.1795 20.6196 69.0326 20.6787 68.8433 20.6787C68.654 20.6787 68.5039 20.6196 68.3929 20.5014C68.2885 20.3832 68.2362 20.242 68.2362 20.0778V7.26322C68.2362 7.09906 68.2885 6.95788 68.3929 6.83968C68.5039 6.72148 68.654 6.66239 68.8433 6.66239C69.0326 6.66239 69.1795 6.72148 69.2839 6.83968C69.3883 6.95788 69.4405 7.09906 69.4405 7.26322V20.0778Z" fill="#fcfcfd" />
    <path d="M78.212 15.1332V14.3255C78.212 13.5573 77.977 12.9466 77.507 12.4935C77.0436 12.0338 76.4104 11.804 75.6075 11.804C74.8047 11.804 74.1682 12.0338 73.6983 12.4935C73.2283 12.9466 72.9933 13.5573 72.9933 14.3255V15.1332H78.212ZM75.6075 20.8855C74.5109 20.8855 73.6004 20.5309 72.8758 19.8217C72.1513 19.106 71.789 18.2162 71.789 17.1524V14.3255C71.789 13.2618 72.1513 12.3753 72.8758 11.6661C73.6004 10.9503 74.5109 10.5925 75.6075 10.5925C76.7041 10.5925 77.6114 10.9503 78.3295 11.6661C79.054 12.3753 79.4163 13.2618 79.4163 14.3255V15.7341C79.4163 15.8982 79.3608 16.0427 79.2498 16.1675C79.1454 16.2857 78.9985 16.3448 78.8092 16.3448H72.9933V17.1524C72.9933 17.9207 73.2283 18.5347 73.6983 18.9943C74.1682 19.4474 74.8047 19.674 75.6075 19.674C76.1036 19.674 76.4953 19.5853 76.7825 19.408C77.0762 19.2307 77.3503 18.9812 77.6049 18.6595C77.6637 18.5872 77.742 18.4854 77.8399 18.3541C77.9443 18.2162 78.0194 18.1243 78.0651 18.0783C78.1108 18.0258 78.1728 17.9765 78.2511 17.9306C78.3295 17.8846 78.4143 17.8616 78.5057 17.8616C78.695 17.8616 78.8419 17.9207 78.9463 18.0389C79.0573 18.1571 79.1127 18.2983 79.1127 18.4625C79.1127 18.7974 78.8451 19.234 78.3099 19.7725C77.5723 20.5145 76.6715 20.8855 75.6075 20.8855Z" fill="#fcfcfd" />
    <path d="M83.1746 12.7102H86.5819C87.4174 12.7102 88.093 12.4705 88.6087 11.9911C89.1309 11.5052 89.392 10.9044 89.392 10.1886C89.392 9.47286 89.1309 8.87531 88.6087 8.39595C88.093 7.91003 87.4174 7.66707 86.5819 7.66707H83.1746V12.7102ZM83.1746 19.3686H86.5819C87.561 19.3686 88.3411 19.1125 88.922 18.6004C89.5029 18.0816 89.7934 17.4315 89.7934 16.6501C89.7934 15.8687 89.5029 15.2186 88.922 14.6998C88.3411 14.1811 87.561 13.9217 86.5819 13.9217H83.1746V19.3686ZM86.5819 6.45554C87.7699 6.45554 88.7327 6.81013 89.4703 7.51932C90.2144 8.22194 90.5865 9.1117 90.5865 10.1886C90.5865 11.0948 90.2862 11.8631 89.6857 12.4935C89.4181 12.7824 89.1178 13.0221 88.7849 13.2125V13.3209C89.1962 13.4785 89.5649 13.705 89.8913 14.0005C90.6224 14.6834 90.9879 15.5666 90.9879 16.6501C90.9879 17.7795 90.5832 18.7186 89.7738 19.4671C88.971 20.2092 87.907 20.5802 86.5819 20.5802H82.5676C82.3783 20.5802 82.2314 20.5211 82.127 20.4029C82.0225 20.2847 81.9703 20.1435 81.9703 19.9793V7.06623C81.9703 6.90206 82.0225 6.76088 82.127 6.64269C82.2314 6.51792 82.3783 6.45554 82.5676 6.45554H86.5819Z" fill="#fcfcfd" />
    <path d="M99.26 15.1332V14.3255C99.26 13.5573 99.025 12.9466 98.555 12.4935C98.0916 12.0338 97.4584 11.804 96.6556 11.804C95.8527 11.804 95.2163 12.0338 94.7463 12.4935C94.2763 12.9466 94.0413 13.5573 94.0413 14.3255V15.1332H99.26ZM96.6556 20.8855C95.559 20.8855 94.6484 20.5309 93.9238 19.8217C93.1993 19.106 92.837 18.2162 92.837 17.1524V14.3255C92.837 13.2618 93.1993 12.3753 93.9238 11.6661C94.6484 10.9503 95.559 10.5925 96.6556 10.5925C97.7522 10.5925 98.6595 10.9503 99.3775 11.6661C100.102 12.3753 100.464 13.2618 100.464 14.3255V15.7341C100.464 15.8982 100.409 16.0427 100.298 16.1675C100.193 16.2857 100.047 16.3448 99.8572 16.3448H94.0413V17.1524C94.0413 17.9207 94.2763 18.5347 94.7463 18.9943C95.2163 19.4474 95.8527 19.674 96.6556 19.674C97.1516 19.674 97.5433 19.5853 97.8305 19.408C98.1242 19.2307 98.3984 18.9812 98.6529 18.6595C98.7117 18.5872 98.79 18.4854 98.8879 18.3541C98.9924 18.2162 99.0674 18.1243 99.1131 18.0783C99.1588 18.0258 99.2208 17.9765 99.2992 17.9306C99.3775 17.8846 99.4623 17.8616 99.5537 17.8616C99.743 17.8616 99.8899 17.9207 99.9943 18.0389C100.105 18.1571 100.161 18.2983 100.161 18.4625C100.161 18.7974 99.8932 19.234 99.3579 19.7725C98.6203 20.5145 97.7195 20.8855 96.6556 20.8855Z" fill="#fcfcfd" />
    <path d="M112.495 11.804C111.907 11.804 111.441 11.9583 111.095 12.2669C110.755 12.569 110.585 12.9531 110.585 13.4194C110.585 13.7411 110.683 14.0136 110.879 14.2369C111.082 14.4602 111.343 14.6342 111.662 14.7589C111.982 14.8837 112.332 14.9986 112.71 15.1037C113.095 15.2087 113.48 15.3302 113.865 15.4681C114.251 15.5994 114.603 15.7603 114.923 15.9508C115.243 16.1346 115.501 16.3973 115.696 16.7387C115.899 17.0802 116 17.4873 116 17.9601C116 18.7415 115.677 19.4245 115.031 20.0089C114.384 20.5933 113.572 20.8855 112.593 20.8855C111.764 20.8855 111.055 20.6721 110.468 20.2453C109.881 19.8119 109.453 19.2833 109.185 18.6595L109.127 18.5413C109.094 18.3771 109.078 18.2819 109.078 18.2556C109.078 18.0914 109.13 17.9503 109.234 17.8321C109.345 17.7139 109.495 17.6548 109.685 17.6548C109.822 17.6548 109.933 17.6942 110.018 17.773C110.102 17.8518 110.191 17.9798 110.282 18.1571C110.785 19.1684 111.555 19.674 112.593 19.674C113.271 19.674 113.807 19.5098 114.198 19.1815C114.597 18.8466 114.796 18.4395 114.796 17.9601C114.796 17.6384 114.694 17.3658 114.492 17.1426C114.296 16.9193 114.038 16.7453 113.719 16.6205C113.399 16.4958 113.046 16.3809 112.661 16.2758C112.283 16.1707 111.901 16.0525 111.516 15.9212C111.13 15.7833 110.778 15.6224 110.458 15.4386C110.138 15.2481 109.877 14.9822 109.675 14.6407C109.479 14.2993 109.381 13.8921 109.381 13.4194C109.381 12.6445 109.675 11.9813 110.262 11.4297C110.856 10.8715 111.6 10.5925 112.495 10.5925C113.271 10.5925 113.918 10.773 114.433 11.1342C114.956 11.4954 115.344 11.9222 115.598 12.4147L115.638 12.5329C115.677 12.6905 115.696 12.7857 115.696 12.8185C115.696 12.9827 115.644 13.1239 115.54 13.2421C115.435 13.3603 115.288 13.4194 115.099 13.4194C114.962 13.4194 114.851 13.3832 114.766 13.311C114.688 13.2322 114.597 13.1009 114.492 12.917C114.042 12.175 113.376 11.804 112.495 11.804Z" fill="#fcfcfd" />
    <path d="M104.516 17.7533C104.516 18.2589 104.653 18.6562 104.928 18.9451C105.202 19.2275 105.567 19.3686 106.024 19.3686H107.728C107.917 19.3686 108.064 19.431 108.168 19.5558C108.279 19.674 108.335 19.8152 108.335 19.9793C108.335 20.1435 108.279 20.2847 108.168 20.4029C108.064 20.5211 107.917 20.5802 107.728 20.5802H106.024C105.267 20.5802 104.627 20.3109 104.105 19.7725C103.583 19.2275 103.322 18.5544 103.322 17.7533V8.87859C103.322 8.71443 103.374 8.57325 103.479 8.45505C103.583 8.33685 103.73 8.27775 103.919 8.27775C104.108 8.27775 104.255 8.33685 104.36 8.45505C104.464 8.57325 104.516 8.71443 104.516 8.87859V10.8978H107.023C107.212 10.8978 107.359 10.9569 107.464 11.0751C107.574 11.1933 107.63 11.3345 107.63 11.4986C107.63 11.6628 107.574 11.8073 107.464 11.932C107.359 12.0502 107.212 12.1093 107.023 12.1093H104.516V17.7533Z" fill="#fcfcfd" />
    <g clipPath="url(#candle-bets-big-a)">
      <path d="M15.6656 18.5625C17.4155 18.3297 19.1152 18.0378 20.552 16.8203C21.3291 16.1638 22.2461 16.8764 22.3048 18.0939C22.3803 19.6482 21.2603 21.6317 19.4039 22.5632C18.3245 23.1049 16.6272 23.1692 16.6272 23.1692C12.3877 23.6465 9.14939 23.1049 4.83169 18.7715C4.83169 18.7715 4.18193 18.1304 4.10645 17.8864C5.33924 17.8695 6.14352 16.4894 6.99054 15.5215C8.4246 13.8718 8.67156 12.4814 9.6891 10.6465C9.39134 11.0088 9.69427 13.59 9.68615 14.0596C9.35349 17.2018 12.2607 21.4774 15.277 22.2546C15.5398 22.322 15.9005 22.4425 16.1996 21.9937C14.8242 21.7552 14.3881 21.0342 14.6145 19.6707C14.7543 18.916 15.6545 19.219 15.6656 18.5625Z" fill="url(#candle-bets-big-b)" />
      <path d="M5.23545 6.90804C5.58487 6.81271 5.9399 6.73969 6.28374 6.62745C8.57879 5.83348 9.87102 6.56104 10.2288 9.02151C10.2288 9.02151 10.2288 9.56318 9.68912 10.6465C8.55065 12.9318 8.04957 15.1977 7.03291 16.3487C6.01626 17.4998 5.61768 17.8778 4.11448 17.8778C1.84179 14.5139 2.28626 10.1849 5.23545 6.90804Z" fill="url(#candle-bets-big-c)" />
      <path d="M5.23535 6.908C8.72966 3.06163 13.2303 2.05164 17.8149 4.10249C18.265 4.35534 18.7897 4.44082 19.2965 4.34375C20.6494 3.97618 21.4126 4.69443 22.0052 5.74656C22.7133 6.9678 22.9864 8.39444 22.7796 9.79216C22.6007 11.0097 21.9074 11.8318 20.6662 12.1011C19.425 12.3705 18.6311 11.7421 18.0721 10.6984C17.5856 9.79216 17.0656 8.92517 16.2326 8.27149C13.9963 6.52082 11.7599 6.84344 10.2672 9.15808C9.9289 6.67801 8.5787 5.83626 6.27246 6.62189C5.93981 6.73965 5.58478 6.81267 5.23535 6.908Z" fill="url(#candle-bets-big-d)" />
      <path d="M5.23535 6.908C8.72966 3.06163 13.2303 2.05164 17.8149 4.10249C18.265 4.35534 18.8642 4.68823 18.8642 4.68823C18.8642 4.68823 18.2246 4.33124 17.8149 4.10249C17.3822 4.13127 16.7053 4.14656 16.7053 4.14656C16.7053 4.14656 16.3757 4.13034 16.1656 4.14656C15.7205 4.18092 15.1477 4.39961 14.5465 4.68823C13.8609 5.0173 13.5154 5.28853 12.9273 5.77156C11.6287 6.83811 11.7599 6.84344 10.2672 9.15809C9.9289 6.67801 8.5787 5.83626 6.27246 6.6219C5.93981 6.73965 5.58478 6.81267 5.23535 6.908Z" fill="url(#candle-bets-big-e)" />
      <path d="M15.0201 18.4503C16.7057 18.4503 19.9437 16.6049 21.2891 16.5408C23.9021 16.4164 21.8504 21.7432 19.404 22.5633C17.8205 23.0941 16.1657 22.5633 14.6557 22.0611C11.8479 20.9383 9.14836 18.7716 9.14941 14.4383C9.14968 13.3549 9.68925 9.02155 9.68925 11.7299C9.68925 17.6377 13.3346 18.4503 15.0201 18.4503Z" fill="url(#candle-bets-big-f)" />
    </g>
  </svg>
);

IconProviderCandleBetsBig.propTypes = {
  className: PropTypes.string,
};
IconProviderCandleBetsBig.defaultProps = {
  className: null,
};
