import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEzugi = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0396 10.4595L25.8461 22.8549C25.6523 22.8549 25.2649 18.0128 26.0396 10.4595Z" fill="white" />
    <path d="M4.3479 6.58554L28.3643 0.194092L27.5896 34.8628L0.667969 29.6335L4.3479 6.58554Z" fill="url(#icon-ezugi-0)" />
    <path d="M28.3626 0.194092L29.7182 1.16251L28.7498 33.3135L27.5879 34.8628L28.3626 0.194092Z" fill="url(#icon-ezugi-1)" />
    <path opacity="0.15" d="M4.35035 6.58541L3.96289 8.32851V8.52201C3.96289 18.3999 11.9037 26.5344 22.3625 26.5344C24.1056 26.5344 25.849 26.3409 27.7856 25.7597L28.5602 0L4.35035 6.58541Z" fill="white" />
    <path d="M25.4586 24.0175C25.0714 25.3731 23.7155 29.2468 23.1346 30.2152L3.18555 27.5037V26.729C6.86548 26.9225 7.25294 26.729 7.44644 24.4047L8.80231 12.0093C9.18954 9.29776 8.80231 9.29776 5.5096 9.87871V9.10379L25.2651 4.26172C25.0714 5.23037 25.0714 7.94165 24.8777 9.87871L23.522 10.2659C23.3285 8.52284 22.9411 7.94165 22.3599 7.16696C21.7789 6.39204 20.6168 6.39204 17.3243 7.16696L14.2253 8.13538C12.8695 8.5226 12.676 8.5226 12.4822 9.49102L11.7076 17.0446L15.7747 16.6574C19.8421 16.2702 20.0356 16.0764 21.0043 13.5584L22.3599 13.3647L21.3915 20.7245H20.0356C19.8421 18.2067 19.4549 17.8195 15.7747 18.013L11.7076 18.2067L10.9329 24.211C10.7391 25.7603 10.9329 26.3415 11.7076 26.9222C12.4822 27.5032 13.8381 27.6972 15.9684 27.8909C19.0674 28.2781 20.4231 28.0844 21.3915 27.116C22.3599 26.3413 23.1346 25.1791 23.9095 23.6298L25.4586 24.0175Z" fill="white" />
    <defs>
      <linearGradient id="icon-ezugi-0" x1="26.027" y1="-0.84665" x2="9.82915" y2="33.8906" gradientUnits="userSpaceOnUse">
        <stop stopColor="#70ABDC" />
        <stop offset="0.17" stopColor="#67A6DA" />
        <stop offset="0.46" stopColor="#4D9AD4" />
        <stop offset="0.6" stopColor="#3E93D0" />
        <stop offset="0.99" stopColor="#1B7CC1" />
      </linearGradient>
      <linearGradient id="icon-ezugi-1" x1="29.0146" y1="0.265464" x2="28.3107" y2="34.7445" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3E93D0" />
        <stop offset="0.99" stopColor="#1B7CC1" />
      </linearGradient>
    </defs>
  </svg>
);

IconProviderEzugi.propTypes = {
  className: PropTypes.string
};

IconProviderEzugi.defaultProps = {
  className: ''
};

