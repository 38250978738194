import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Alert from 'reactstrap/lib/Alert';
import colors from 'customizations/js/color-variables';
import { NOTIFICATION_TYPES } from 'core/constants';
import { GlobalNotificationPopup as GlobalNotificationPopupCore, FormattedTag } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { IconSuccess } from '../icons/icon-notification/icon-success';
import { IconAlert } from '../icons/icon-alert/icon-alert';
import { IconClose } from '../icons/icon-close/icon-close';

const ICONS = {
  [NOTIFICATION_TYPES.SUCCESS]: <IconSuccess className="global-notification-type-icon" color="green" />,
  [NOTIFICATION_TYPES.ERROR]: <IconAlert className="global-notification-type-icon" />,
};

// TODO: Temp hack. In order not to show notifications which are triggered in the CORE
const FORBIDDEN_NOTIFICATIONS_INTL_KEYS = [
  'notification.password-updated.title',
  'notification.unknown.title',
];

export const GlobalNotificationPopupUI = ({
  type,
  message,
  values,
  visible,
  toggle,
}) => (
  !FORBIDDEN_NOTIFICATIONS_INTL_KEYS.includes(message.title) && (
    <Alert
      className="bg-transparent border-0 p-0 w-100 mb-1_5"
      isOpen={visible}
    >
      <div className={classNames(
        'global-notification d-flex justify-content-between shadow text-base text-white py-1_25 px-1_5 rounded', {
          'bg-success': type === NOTIFICATION_TYPES.SUCCESS,
          'bg-danger': type === NOTIFICATION_TYPES.ERROR,
        }
      )}
      >
        <div className="d-flex align-items-center">
          <div className="mr-1_25">
            {ICONS[type]}
          </div>
          <div className="d-flex flex-column justify-content-between font-weight-bold global-notification-text">
            {/* <FormattedMessage id={message.title} /> */}
            <FormattedTag className="font-weight-normal mt-0_5" values={values || {}} id={message.text} />
          </div>
        </div>

        <div
          role="button"
          tabIndex="0"
          onKeyPress={toggle}
          onClick={toggle}
          className="d-flex align-items-center cursor-pointer ml-1"
        >
          <IconClose color={colors.white} />
        </div>
      </div>
    </Alert>
  )
);

GlobalNotificationPopupUI.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  values: PropTypes.shape(),
  toggle: PropTypes.func.isRequired,
};

GlobalNotificationPopupUI.defaultProps = {
  message: null,
  values: null,
};

export const NotificationPopup = withCoreComponent(GlobalNotificationPopupCore, GlobalNotificationPopupUI);
