import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlaytechBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="120" height="28" viewBox="0 0 120 28">
    <path d="M27.729 9.97824C27.7101 9.93272 27.699 9.88827 27.6801 9.84165C26.5441 7.0567 22.2279 6.21277 17.252 7.48533L16.4359 6.97675C18.8855 5.91962 21.3251 5.36773 23.3694 5.51875C26.4186 5.74973 27.91 7.48532 27.729 9.97824ZM23.305 15.828C20.1147 19.8899 14.3494 22.7016 10.4262 22.4106C6.50974 22.1141 5.91565 18.5841 9.10703 14.5221C9.17033 14.4388 9.23918 14.3567 9.30582 14.2734L9.31589 19.3658C9.2948 20.6606 10.5418 21.268 11.4379 20.7539L23.1674 13.6005C24.0846 13.0064 23.988 11.6772 23.1241 11.1653L18.3681 8.42809C19.654 8.04166 20.8877 7.85955 21.9837 7.94061C25.9035 8.23709 26.4931 11.766 23.3051 15.828L23.305 15.828ZM8.38645 24.596C4.78644 24.325 3.35065 21.952 4.28785 18.7207C4.28218 19.2592 4.3667 19.7756 4.56658 20.2553C5.97349 23.7143 12.2896 24.1829 18.6724 21.3113C18.8034 21.2524 18.9333 21.188 19.0621 21.1303C15.4788 23.4433 11.4868 24.8258 8.38645 24.596V24.596ZM12.5339 6.5126L20.408 11.4751C20.9898 11.8238 21.0598 12.7321 20.4402 13.1352L12.4862 17.9856C11.8788 18.3354 11.0271 17.9189 11.0437 17.0339L11.006 7.40317C11.0404 6.59922 11.8355 6.11396 12.5339 6.5126H12.5339ZM30.7605 8.29929C29.1104 4.244 22.2569 3.3512 14.8403 5.97515L11.549 3.91309C10.524 3.32789 9.35366 4.04079 9.29703 5.22673L9.29925 8.57578C2.90761 12.4057 -0.79901 17.919 0.778914 21.7999C2.60779 26.2827 10.7994 26.8979 19.0766 23.1691C27.356 19.4425 32.5861 12.7843 30.7605 8.29929Z" fill="#fcfcfd" />
    <path d="M37.3394 14.1442L36.6243 16.777C36.5177 17.1779 36.4355 17.5432 36.37 17.8575C36.3023 18.1884 36.2745 18.4249 36.2745 18.5715C36.2745 18.8535 36.3511 19.069 36.5077 19.2311C36.672 19.3921 36.8897 19.4709 37.1573 19.4709C37.577 19.4709 38.0134 19.2344 38.4654 18.7614C38.9151 18.2872 39.3459 17.6098 39.7668 16.7215C40.2698 15.5855 40.6807 14.4673 40.9971 13.348C41.3025 12.242 41.4635 11.3715 41.4635 10.7518C41.4635 10.3021 41.3703 9.93899 41.1892 9.66916C41.0071 9.396 40.7739 9.25608 40.5008 9.25608C39.8778 9.25608 39.3104 9.65916 38.7896 10.452C38.2688 11.2537 37.7858 12.4808 37.3394 14.1442H37.3394ZM32.8466 8.02795C33.9581 7.94022 35.0108 7.82363 36.0213 7.69371C37.0273 7.56377 37.9967 7.41278 38.9328 7.22955L38.1455 10.0523C38.9317 8.9607 39.6813 8.15898 40.3997 7.66595C41.1048 7.15626 41.8377 6.91418 42.6084 6.91418C43.6566 6.91418 44.4706 7.24509 45.0358 7.90469C45.6099 8.57095 45.8908 9.51703 45.8908 10.7407C45.8908 11.7734 45.6987 12.8639 45.3067 14.0021C44.9125 15.1425 44.3584 16.2018 43.6666 17.1857C42.8527 18.3294 41.9943 19.1867 41.076 19.7541C40.1532 20.326 39.1816 20.6147 38.1455 20.6147C37.7203 20.6147 37.3238 20.5558 36.9474 20.4281C36.5787 20.296 36.2445 20.1172 35.9413 19.8751L35.1352 22.9355C35.0796 23.1187 35.0363 23.3152 35.0152 23.5073C34.9841 23.695 34.9764 23.8849 34.9764 24.0748C34.9764 24.4423 35.0819 24.7088 35.2995 24.8576C35.5183 25.0053 35.9602 25.0964 36.6243 25.1386V25.8537H29.1089V25.1075C29.1344 25.1075 29.1711 25.104 29.2188 25.0952C29.2688 25.0829 29.3076 25.0785 29.3265 25.0785C29.794 25.0785 30.1415 24.9586 30.3747 24.7132C30.6101 24.4689 30.8489 23.8326 31.101 22.7954L34.0924 10.6107C34.1446 10.4164 34.1846 10.2176 34.2168 10.0288C34.2467 9.8467 34.2646 9.69681 34.2646 9.59687C34.2646 9.26152 34.1635 9.03722 33.9681 8.91396C33.766 8.77625 33.3918 8.71409 32.8465 8.71409L32.8466 8.02795Z" fill="#fcfcfd" />
    <path d="M46.6366 1.30695C47.7603 1.21589 48.833 1.09376 49.8757 0.944949C50.9106 0.79726 51.8944 0.626253 52.8172 0.416382L48.6897 16.7542C48.663 16.8441 48.632 16.9518 48.5965 17.0939C48.5421 17.3049 48.5221 17.4404 48.5221 17.5126C48.5221 17.7047 48.5576 17.8579 48.6331 17.9634C48.7131 18.0734 48.8252 18.1267 48.9707 18.1267C49.1195 18.1267 49.2872 18.0245 49.4804 17.8146C49.6714 17.6092 50.0423 17.0684 50.5941 16.1923L51.2526 16.692C50.4242 18.0789 49.6258 19.0872 48.8752 19.7112C48.1245 20.3342 47.345 20.644 46.53 20.644C45.8115 20.644 45.2496 20.4452 44.8621 20.0399C44.4701 19.6346 44.278 19.0505 44.278 18.2965C44.278 17.9257 44.479 16.9418 44.8754 15.3472L44.9154 15.1862L47.6548 4.55275C47.6794 4.45505 47.7137 4.30956 47.7581 4.11857C47.9325 3.419 48.0091 2.96818 48.0091 2.77385C48.0091 2.50623 47.9191 2.31191 47.7326 2.18199C47.5505 2.05652 47.2662 1.98878 46.872 1.98878H46.6366L46.6366 1.30695Z" fill="#fcfcfd" />
    <path d="M59.5582 9.76803C59.5582 9.37716 59.476 9.06735 59.305 8.83416C59.134 8.60319 58.913 8.48882 58.6365 8.48882C58.2434 8.48882 57.8659 8.67982 57.4994 9.06846C57.133 9.46156 56.7133 10.11 56.2324 11.0184C55.6706 12.101 55.2297 13.156 54.9133 14.1964C54.5879 15.2458 54.4269 16.133 54.4269 16.8692C54.4269 17.3167 54.5168 17.6621 54.6945 17.9242C54.8733 18.1707 55.1187 18.2961 55.4174 18.2961C55.8604 18.2961 56.3124 18.033 56.781 17.4944C57.2485 16.9592 57.6904 16.2041 58.1091 15.2325C58.5488 14.1498 58.8953 13.0938 59.1607 12.0877C59.4238 11.0739 59.5582 10.3021 59.5582 9.76803H59.5582ZM64.5374 7.37061L62.3354 15.4523V15.4813C62 16.7261 61.8312 17.4501 61.8312 17.6577C61.8312 17.8498 61.8645 18.0031 61.9423 18.123C62.0145 18.244 62.1089 18.2962 62.2354 18.2962C62.5919 18.2962 63.0571 17.8676 63.6157 17.007C63.7845 16.7616 63.9144 16.5673 64.0032 16.4318L64.6428 16.9359C63.9244 18.1974 63.2082 19.1301 62.4775 19.7242C61.7468 20.3205 60.9573 20.6148 60.1167 20.6148C59.416 20.6148 58.8808 20.4593 58.5199 20.1518C58.1479 19.8331 57.9669 19.38 57.9669 18.7748C57.9669 18.5949 57.9802 18.3628 58.0191 18.0908C58.0513 17.8143 58.1091 17.4567 58.1857 17.0203C57.5206 18.2363 56.7954 19.1413 56.0126 19.7253C55.2331 20.3205 54.3614 20.6148 53.4008 20.6148C52.4081 20.6148 51.6275 20.3061 51.0534 19.6865C50.4771 19.0602 50.1895 18.2107 50.1895 17.1292C50.1895 15.8844 50.4615 14.5741 51.0201 13.1882C51.5709 11.8202 52.3115 10.5954 53.2454 9.5438C53.9816 8.69877 54.7434 8.04917 55.5362 7.59389C56.3346 7.13861 57.0975 6.91431 57.8315 6.91431C58.5333 6.91431 59.0774 7.08531 59.4827 7.42399C59.8869 7.76822 60.1523 8.29124 60.28 8.98747L60.6853 7.37069L64.5374 7.37061Z" fill="#fcfcfd" />
    <path d="M63.2544 8.48865V7.74243C64.3271 7.62251 65.4131 7.47372 66.5202 7.30715C67.6184 7.13947 68.2169 7.05842 68.3002 7.05842C68.7421 7.05842 69.3207 9.00278 70.0213 12.8982C70.3056 14.4483 70.5388 15.6976 70.722 16.6325C71.5571 14.9391 72.1278 13.7032 72.4365 12.9304C72.7441 12.162 72.8951 11.5901 72.8951 11.2248C72.8951 10.9416 72.6375 10.5974 72.1245 10.2054C71.6126 9.80895 71.3505 9.35368 71.3505 8.85066C71.3505 8.26768 71.5282 7.79796 71.8902 7.44151C72.2444 7.09062 72.7186 6.91406 73.3104 6.91406C73.8956 6.91406 74.3576 7.09061 74.6885 7.44151C75.0139 7.79796 75.1826 8.29654 75.1826 8.9306C75.1826 9.72789 74.7995 11.0127 74.0411 12.7794C73.2805 14.5427 72.2411 16.5282 70.9252 18.749C69.4362 21.2808 68.077 23.1186 66.8577 24.2823C65.6374 25.4449 64.4625 26.0235 63.3332 26.0235C62.5826 26.0235 61.9952 25.8569 61.5654 25.5227C61.1379 25.1862 60.9225 24.7265 60.9225 24.1402C60.9225 23.6072 61.1057 23.1752 61.4799 22.8243C61.8497 22.4768 62.3183 22.3046 62.8891 22.3046C63.3354 22.3046 63.8152 22.4834 64.3271 22.8354C64.8367 23.193 65.1843 23.3618 65.3531 23.3618C65.6607 23.3618 66.0071 23.1042 66.3991 22.59C66.8022 22.0692 67.0032 21.5806 67.0032 21.1065C67.0032 20.7611 66.971 20.3203 66.9099 19.7917C66.85 19.2576 66.7534 18.6058 66.629 17.8318C66.3692 16.1717 66.106 14.656 65.8439 13.2957C65.5752 11.9376 65.3365 10.865 65.1221 10.081C64.9334 9.40809 64.7435 8.97058 64.5514 8.76404C64.3593 8.55972 64.0484 8.45869 63.6042 8.45869H63.5698C63.4409 8.47631 63.3421 8.48859 63.2544 8.48859" fill="#fcfcfd" />
    <path d="M80.4264 16.9607C79.5969 18.13 78.7818 19.0272 77.9879 19.6468C77.2017 20.2731 76.4832 20.584 75.8303 20.584C75.304 20.584 74.8931 20.4097 74.5899 20.0732C74.2868 19.729 74.1324 19.2682 74.1324 18.6907C74.1324 18.4875 74.1535 18.2621 74.1935 18.0089C74.2302 17.7535 74.289 17.4704 74.3679 17.1717L76.7353 7.91511H74.2424L74.4745 7.11893C75.546 6.88462 76.4932 6.37383 77.3205 5.5832C78.1433 4.78925 78.8906 3.69326 79.5469 2.28967H80.3975L79.2848 6.93683H81.5623L81.3325 7.91511H78.985L76.712 16.8563C76.6598 17.0595 76.6198 17.2372 76.592 17.4049C76.5731 17.5681 76.5554 17.718 76.5554 17.8446C76.5554 18.1422 76.6198 18.3665 76.7542 18.5264C76.8774 18.6852 77.0651 18.7618 77.2927 18.7618C77.5903 18.7618 77.9401 18.5886 78.3543 18.2255C78.7674 17.8701 79.2904 17.286 79.9178 16.481L80.4264 16.9607Z" fill="#fcfcfd" />
    <path d="M82.7517 13.9134C84.5839 13.8257 86.1285 13.3238 87.3666 12.4188C88.6147 11.4994 89.2299 10.4678 89.2299 9.29737C89.2299 8.90539 89.1311 8.61113 88.9323 8.41347C88.7402 8.2236 88.4415 8.12587 88.0407 8.12587C86.9302 8.12587 85.8986 8.6289 84.9548 9.65493C84.0098 10.6876 83.278 12.1045 82.7517 13.9134H82.7517ZM82.5351 14.8939C82.4497 15.226 82.3952 15.5469 82.3564 15.8522C82.3108 16.1487 82.2986 16.4274 82.2986 16.6795C82.2986 17.4546 82.4863 18.0465 82.8727 18.454C83.2525 18.8626 83.8133 19.1647 84.5439 19.1647C85.2635 19.1647 86.0907 18.8226 87.0013 18.3285C87.923 17.8377 88.959 17.0937 90.1005 16.0999L90.7035 17.0126C89.341 18.2408 88.0584 19.1491 86.8525 19.7288C85.651 20.3206 84.474 20.6093 83.318 20.6093C82.1865 20.6093 81.2981 20.2662 80.6752 19.5922C80.0467 18.9093 79.7346 17.9532 79.7346 16.7062C79.7346 15.5147 79.9789 14.3021 80.4675 13.0739C80.9561 11.8502 81.6246 10.752 82.4685 9.80151C83.2869 8.88984 84.1875 8.19804 85.1569 7.71389C86.1407 7.23529 87.1434 6.98767 88.1761 6.98767C89.1278 6.98767 89.8729 7.21088 90.4237 7.65282C90.9733 8.09144 91.2509 8.69663 91.2509 9.46727C91.2509 10.8542 90.4425 12.059 88.8413 13.0717C87.2367 14.0822 85.1347 14.6852 82.5351 14.8939Z" fill="#fcfcfd" />
    <path d="M100.437 15.0841L100.402 16.8364C99.5391 18.0989 98.6041 19.0406 97.5859 19.6691C96.5676 20.292 95.456 20.6018 94.2523 20.6018C92.9343 20.6018 91.8571 20.161 91.0299 19.2682C90.2059 18.3899 89.7951 17.2195 89.7951 15.7659C89.7951 14.6977 90.0282 13.6083 90.4946 12.4968C90.9555 11.3808 91.604 10.3903 92.4312 9.50974C93.1874 8.71356 94.0358 8.09172 94.9763 7.66532C95.908 7.23114 96.8607 7.00793 97.8424 7.00793C98.8717 7.00793 99.689 7.24779 100.299 7.7175C100.907 8.18833 101.213 8.8135 101.213 9.6019C101.213 10.0694 101.057 10.4747 100.737 10.8078C100.422 11.1443 100.049 11.3086 99.6124 11.3086C99.2559 11.3086 98.9683 11.1965 98.7474 10.96C98.5275 10.7268 98.4176 10.4247 98.4176 10.0472C98.4176 9.78401 98.5031 9.48309 98.6841 9.13219C98.8584 8.78685 98.9428 8.57142 98.9428 8.48481C98.9428 8.32268 98.8573 8.19277 98.6741 8.09171C98.492 7.99067 98.2521 7.9407 97.9401 7.9407C97.3061 7.9407 96.6642 8.1339 96.0024 8.53366C95.3461 8.94008 94.772 9.48641 94.279 10.1804C93.6783 11.0221 93.2141 11.9183 92.8888 12.8643C92.5678 13.8082 92.4091 14.7254 92.4091 15.6338C92.4091 16.6598 92.6667 17.4804 93.1875 18.0789C93.7138 18.6786 94.42 19.085 95.3139 19.085C96.0191 19.085 96.712 18.7552 97.3927 18.3154C98.0778 17.8679 99.2115 16.9774 100.437 15.0841" fill="#fcfcfd" />
    <path d="M98.8842 20.6171L103.547 3.72413C103.575 3.62086 103.591 3.51647 103.604 3.40321C103.621 3.29994 103.626 3.19222 103.626 3.10563C103.626 2.80471 103.549 2.59817 103.404 2.48157C103.256 2.37829 102.833 2.32167 102.142 2.32167H101.081L101.245 1.55103C102.101 1.51659 102.901 1.41556 103.646 1.2701C104.383 1.11464 105.089 0.914761 105.743 0.653809H106.586L103.359 12.3222C105.012 10.6377 106.534 9.33404 107.919 8.39462C109.3 7.47185 110.404 7.00326 111.227 7.00326C111.661 7.00326 111.975 7.10653 112.184 7.31305C112.387 7.52624 112.497 7.84938 112.497 8.28356C112.497 8.41904 112.475 8.58005 112.435 8.77438C112.398 8.97536 112.348 9.19523 112.288 9.43175L109.933 17.3802C109.892 17.5323 109.853 17.7045 109.823 17.8888C109.791 18.0664 109.774 18.2508 109.774 18.4285C109.774 18.5306 109.823 18.6195 109.908 18.7016C109.992 18.7794 110.095 18.9181 110.206 18.9181C110.436 18.9181 110.759 18.665 111.162 18.3518C111.569 18.0387 111.995 17.6101 112.446 17.0682L112.926 17.4801C112.115 18.4862 111.333 19.2613 110.584 19.7887C109.831 20.3217 109.136 20.5905 108.503 20.5905C108.089 20.5905 107.762 20.4883 107.524 20.2884C107.28 20.0841 107.165 19.8131 107.165 19.4589C107.165 19.3345 107.175 19.2002 107.203 19.0569C107.227 18.9148 107.274 18.7605 107.33 18.5906L109.75 10.37C109.794 10.1901 109.823 10.0525 109.839 9.95918C109.853 9.86922 109.858 9.77928 109.858 9.70377C109.858 9.49388 109.801 9.33399 109.679 9.22073C109.563 9.11635 109.395 9.06193 109.172 9.06193C108.828 9.06193 108.32 9.27626 107.65 9.71153C106.975 10.1457 106.205 10.7664 105.319 11.5693C104.528 12.28 103.942 12.8851 103.575 13.386C103.207 13.8879 102.939 14.4076 102.772 14.9472L101.167 20.6171C100.984 20.5894 100.336 20.5682 100.147 20.556C99.9592 20.5428 100.234 20.5428 100.039 20.5428C99.8426 20.5428 99.6461 20.5428 99.4562 20.556C99.2719 20.5683 99.0809 20.5893 98.8843 20.6171" fill="#fcfcfd" />
    <path d="M71.191 25.2638V25.4837C71.1899 25.5969 71.202 25.7002 71.2332 25.799C71.2998 26.0656 71.5164 26.1988 71.8861 26.1988C72.0238 26.1988 72.1482 26.1799 72.2603 26.1411C72.5124 26.0645 72.6401 25.8746 72.6479 25.5781C72.6457 25.3749 72.5635 25.2161 72.3992 25.105C72.2815 25.0284 72.1427 24.954 71.9816 24.8774L71.4942 24.6564C71.301 24.5709 71.1399 24.4832 71.0122 24.3922C70.7435 24.2045 70.608 23.9369 70.608 23.586C70.6115 23.2018 70.7635 22.9308 71.06 22.7698C71.2821 22.6488 71.5841 22.5866 71.9716 22.5833C72.4425 22.5833 72.7834 22.6721 72.991 22.8509C73.1565 22.9941 73.2397 23.2762 73.2397 23.6959V23.7748H72.5702V23.6804L72.5569 23.476C72.5159 23.1696 72.3171 23.0197 71.9617 23.0274C71.5109 23.0274 71.2833 23.2084 71.2777 23.5693C71.2777 23.7992 71.4199 23.9868 71.7008 24.1312C71.8774 24.2234 72.0739 24.3133 72.2938 24.4066C72.5159 24.4977 72.6957 24.5854 72.8334 24.6686C73.1566 24.8663 73.3187 25.1617 73.3187 25.5603C73.3121 26.2788 72.8357 26.6408 71.8873 26.6408C71.4809 26.6464 71.1678 26.5775 70.949 26.4398C70.6925 26.2754 70.5537 26.0445 70.5315 25.738L70.5227 25.4215V25.2638L71.191 25.2638Z" fill="#fcfcfd" />
    <path d="M73.7662 23.9134C73.7684 23.5891 73.7995 23.3526 73.8562 23.2016C73.9472 22.964 74.1438 22.7929 74.448 22.6941C74.6679 22.6197 74.9044 22.5831 75.1587 22.5831C75.4085 22.5831 75.6451 22.6197 75.8683 22.6941C76.1681 22.793 76.3635 22.964 76.4557 23.2016C76.5123 23.3493 76.5445 23.5891 76.5489 23.9134V25.2359C76.5489 25.4735 76.5357 25.6812 76.509 25.8533C76.4346 26.3741 75.9904 26.6384 75.1776 26.6406C74.5879 26.6406 74.1926 26.5196 73.9916 26.2741C73.8384 26.0909 73.7629 25.7434 73.7662 25.2359L73.7662 23.9134ZM74.4369 25.2315C74.4391 25.5635 74.4636 25.7811 74.5091 25.8777C74.5546 25.9754 74.6423 26.0554 74.7667 26.1131C74.8944 26.1698 75.0243 26.1986 75.1587 26.1986C75.2797 26.1986 75.3974 26.1763 75.5085 26.1265C75.7217 26.0421 75.8383 25.891 75.8594 25.6745C75.8704 25.5513 75.8761 25.4047 75.8783 25.2314V23.91C75.8827 23.6235 75.8493 23.4214 75.7828 23.306C75.6773 23.1194 75.4674 23.025 75.1587 23.0272C75.0377 23.0272 74.9255 23.0449 74.82 23.0861C74.6012 23.1605 74.4791 23.3148 74.4536 23.5447C74.4391 23.6491 74.4347 23.7712 74.4369 23.91V25.2315Z" fill="#fcfcfd" />
    <path d="M77.1553 22.6398H77.8238V25.2726L77.8304 25.6202C77.8537 26.0033 78.0769 26.1965 78.4989 26.1987C78.9219 26.1987 79.154 26.0188 79.194 25.6568C79.2094 25.5302 79.2173 25.4025 79.2195 25.2726V22.6398H79.8891V25.3148C79.8869 25.5336 79.879 25.7023 79.8658 25.8234C79.8447 26.0066 79.7714 26.1598 79.6426 26.2831C79.3983 26.5218 79.0241 26.6384 78.5199 26.6406C77.9969 26.6384 77.6194 26.523 77.3895 26.2898C77.2607 26.1565 77.1886 26.0021 77.1719 25.8234C77.1609 25.7168 77.1552 25.5491 77.1552 25.3148L77.1553 22.6398Z" fill="#fcfcfd" />
    <path d="M81.6346 22.6398H82.0343C82.1887 22.6398 82.3308 22.6543 82.4596 22.6753C82.9537 22.7542 83.2036 23.0684 83.2114 23.6225C83.2114 23.9134 83.1459 24.1311 83.0159 24.2854C82.8893 24.4287 82.6417 24.5253 82.2786 24.5775V24.5753C82.5051 24.5976 82.6684 24.6319 82.7683 24.6785C82.9115 24.7496 83.0015 24.8651 83.037 25.0216C83.0603 25.1249 83.0737 25.2482 83.0803 25.387L83.1093 25.8511C83.1203 26.0277 83.1392 26.1665 83.1648 26.2676C83.1793 26.3397 83.2115 26.4452 83.2636 26.584H82.5663L82.4986 26.332C82.4797 26.2609 82.4674 26.1699 82.4619 26.0599L82.4352 25.7057L82.4128 25.3359C82.3817 25.0539 82.2451 24.8829 82.0042 24.8273C81.9031 24.8062 81.7821 24.7951 81.6544 24.7951H81.1913V26.584H80.5195V22.6398L81.6346 22.6398ZM81.1915 23.0839V24.3498H81.6701C81.8133 24.3498 81.9333 24.341 82.041 24.3253C82.2453 24.2931 82.3874 24.2109 82.4663 24.0777C82.5173 23.9899 82.5418 23.8445 82.5418 23.6401C82.5418 23.4214 82.4674 23.2681 82.3175 23.1838C82.2087 23.1194 82.0077 23.0861 81.7078 23.0838L81.1915 23.0839Z" fill="#fcfcfd" />
    <path d="M85.731 23.6801L85.7276 23.4969C85.7109 23.1815 85.5243 23.0249 85.1646 23.0272C84.8448 23.0283 84.6371 23.1115 84.5405 23.2759C84.4872 23.3547 84.4583 23.4369 84.4528 23.5179L84.4471 23.8422V25.399C84.4493 25.6811 84.477 25.8632 84.5282 25.9431C84.6392 26.113 84.8302 26.1985 85.1023 26.1985C85.4565 26.1963 85.6586 26.0286 85.703 25.6944L85.7307 25.4401V25.3046H86.4003V25.4423L86.3946 25.6888C86.3723 25.9831 86.2736 26.2085 86.0915 26.3662C85.9815 26.4628 85.8505 26.5294 85.7017 26.5683C85.524 26.6172 85.3319 26.6405 85.1221 26.6405C84.9422 26.6439 84.7734 26.6273 84.6079 26.5938C84.056 26.4906 83.7795 26.123 83.7762 25.4878V23.9166C83.7751 23.7445 83.7841 23.5868 83.8084 23.4391C83.833 23.2592 83.8928 23.1082 83.9883 22.9905C84.2015 22.724 84.5846 22.5874 85.1365 22.5829C85.9082 22.5874 86.3247 22.8328 86.3835 23.3169C86.3945 23.4102 86.4002 23.5135 86.4002 23.6279V23.7811H85.7306L85.731 23.6801Z" fill="#fcfcfd" />
    <path d="M86.9848 22.6398H89.1756V23.0839H87.6543V24.3343H89.0812V24.7785H87.6543V26.1387H89.2178V26.584H86.9848V22.6398Z" fill="#fcfcfd" />
    <path d="M90.9542 23.9134C90.9553 23.5891 90.9841 23.3526 91.043 23.2016C91.1319 22.964 91.3295 22.7929 91.6338 22.6941C91.8536 22.6197 92.0902 22.5831 92.3445 22.5831C92.5943 22.5831 92.8319 22.6197 93.0562 22.6941C93.3538 22.793 93.5504 22.964 93.6437 23.2016C93.7003 23.3493 93.7314 23.5891 93.7358 23.9134V25.2359C93.7358 25.4735 93.7226 25.6812 93.6981 25.8533C93.6192 26.3741 93.1784 26.6384 92.3633 26.6406C91.7726 26.6406 91.3773 26.5196 91.1785 26.2741C91.0253 26.0909 90.9509 25.7434 90.9542 25.2359V23.9134ZM91.6227 25.2315C91.6262 25.5635 91.6504 25.7811 91.6971 25.8777C91.7426 25.9754 91.8281 26.0554 91.9536 26.1131C92.0802 26.1698 92.2112 26.1986 92.3445 26.1986C92.4677 26.1986 92.5832 26.1763 92.6965 26.1265C92.9064 26.0421 93.0229 25.891 93.044 25.6745C93.0563 25.5513 93.0629 25.4047 93.0642 25.2314V23.91C93.0686 23.6235 93.0365 23.4214 92.9698 23.306C92.8632 23.1194 92.6533 23.025 92.3446 23.0272C92.2236 23.0272 92.1125 23.0449 92.007 23.0861C91.7883 23.1605 91.6661 23.3148 91.6417 23.5447C91.6263 23.6491 91.6206 23.7712 91.6228 23.91L91.6227 25.2315Z" fill="#fcfcfd" />
    <path d="M96.4813 22.6398V23.0839H95.0167V24.331H96.4259V24.774H95.0167V26.584H94.3459V22.6398H96.4813Z" fill="#fcfcfd" />
    <path d="M98.709 25.2638V25.4837C98.7055 25.5969 98.7178 25.7002 98.7489 25.799C98.8156 26.0656 99.0343 26.1988 99.4008 26.1988C99.5384 26.1988 99.6639 26.1799 99.7772 26.1411C100.027 26.0645 100.156 25.8746 100.165 25.5781C100.163 25.3749 100.081 25.2161 99.916 25.105C99.7983 25.0284 99.6584 24.954 99.4974 24.8774L99.0099 24.6564C98.8178 24.5709 98.6568 24.4832 98.5258 24.3922C98.2604 24.2045 98.1249 23.9369 98.1249 23.586C98.1306 23.2018 98.2793 22.9308 98.5779 22.7698C98.7978 22.6488 99.1021 22.5866 99.4874 22.5833C99.9582 22.5833 100.298 22.6721 100.509 22.8509C100.674 22.9941 100.756 23.2762 100.756 23.6959V23.7748H100.087V23.6804L100.074 23.476C100.032 23.1696 99.834 23.0197 99.4775 23.0274C99.0245 23.0274 98.8001 23.2084 98.7935 23.5693C98.7935 23.7992 98.9367 23.9868 99.2177 24.1312C99.392 24.2234 99.5908 24.3133 99.8106 24.4066C100.032 24.4977 100.213 24.5854 100.351 24.6686C100.672 24.8663 100.836 25.1617 100.836 25.5603C100.828 26.2788 100.351 26.6408 99.4042 26.6408C98.9967 26.6464 98.6836 26.5775 98.4659 26.4398C98.2083 26.2754 98.0695 26.0445 98.0495 25.738L98.0385 25.4215V25.2638L98.709 25.2638Z" fill="#fcfcfd" />
    <path d="M101.308 22.6398H101.976V25.2726L101.982 25.6202C102.005 26.0033 102.23 26.1965 102.65 26.1987C103.073 26.1987 103.305 26.0188 103.346 25.6568C103.36 25.5302 103.368 25.4025 103.37 25.2726V22.6398H104.04V25.3148C104.038 25.5336 104.03 25.7023 104.015 25.8234C103.997 26.0066 103.923 26.1598 103.795 26.2831C103.549 26.5218 103.173 26.6384 102.671 26.6406C102.148 26.6384 101.77 26.523 101.539 26.2898C101.412 26.1565 101.34 26.0021 101.323 25.8234C101.313 25.7168 101.308 25.5491 101.308 25.3148L101.308 22.6398Z" fill="#fcfcfd" />
    <path d="M106.649 23.6801L106.645 23.4969C106.63 23.1815 106.44 23.0249 106.08 23.0272C105.761 23.0283 105.555 23.1115 105.457 23.2759C105.405 23.3547 105.376 23.4369 105.37 23.5179L105.364 23.8422V25.399C105.367 25.6811 105.394 25.8632 105.448 25.9431C105.556 26.113 105.748 26.1985 106.019 26.1985C106.374 26.1963 106.575 26.0286 106.622 25.6944L106.649 25.4401V25.3046H107.318V25.4423L107.312 25.6888C107.293 25.9831 107.19 26.2085 107.009 26.3662C106.897 26.4628 106.768 26.5294 106.619 26.5683C106.44 26.6172 106.248 26.6405 106.04 26.6405C105.859 26.6439 105.688 26.6273 105.526 26.5938C104.974 26.4906 104.698 26.123 104.697 25.4878V23.9166C104.693 23.7445 104.703 23.5868 104.727 23.4391C104.749 23.2592 104.808 23.1082 104.905 22.9905C105.12 22.724 105.502 22.5874 106.055 22.5829C106.827 22.5874 107.244 22.8328 107.302 23.3169C107.312 23.4102 107.318 23.5135 107.318 23.6279V23.7811H106.649L106.649 23.6801Z" fill="#fcfcfd" />
    <path d="M109.883 23.6801L109.879 23.4969C109.862 23.1815 109.677 23.0249 109.315 23.0272C108.995 23.0283 108.789 23.1115 108.691 23.2759C108.638 23.3547 108.609 23.4369 108.605 23.5179L108.598 23.8422V25.399C108.6 25.6811 108.629 25.8632 108.68 25.9431C108.791 26.113 108.982 26.1985 109.253 26.1985C109.609 26.1963 109.809 26.0286 109.855 25.6944L109.882 25.4401V25.3046H110.552V25.4423L110.546 25.6888C110.524 25.9831 110.423 26.2085 110.243 26.3662C110.133 26.4628 110.002 26.5294 109.852 26.5683C109.677 26.6172 109.481 26.6405 109.273 26.6405C109.094 26.6439 108.922 26.6273 108.76 26.5938C108.208 26.4906 107.931 26.123 107.928 25.4878V23.9166C107.924 23.7445 107.937 23.5868 107.96 23.4391C107.983 23.2592 108.041 23.1082 108.138 22.9905C108.352 22.724 108.736 22.5874 109.288 22.5829C110.061 22.5874 110.476 22.8328 110.535 23.3169C110.546 23.4102 110.552 23.5135 110.552 23.6279V23.7811H109.882L109.883 23.6801Z" fill="#fcfcfd" />
    <path d="M111.135 22.6398H113.325V23.0839H111.805V24.3343H113.231V24.7785H111.805V26.1387H113.368V26.584H111.135V22.6398Z" fill="#fcfcfd" />
    <path d="M114.352 25.2638V25.4837C114.35 25.5969 114.363 25.7002 114.394 25.799C114.461 26.0656 114.677 26.1988 115.045 26.1988C115.183 26.1988 115.309 26.1799 115.421 26.1411C115.671 26.0645 115.801 25.8746 115.811 25.5781C115.807 25.3749 115.725 25.2161 115.56 25.105C115.443 25.0284 115.303 24.954 115.142 24.8774L114.655 24.6564C114.462 24.5709 114.301 24.4832 114.172 24.3922C113.906 24.2045 113.77 23.9369 113.77 23.586C113.775 23.2018 113.923 22.9308 114.222 22.7698C114.442 22.6488 114.745 22.5866 115.133 22.5833C115.604 22.5833 115.943 22.6721 116.154 22.8509C116.319 22.9941 116.401 23.2762 116.401 23.6959V23.7748H115.731V23.6804L115.718 23.476C115.677 23.1696 115.477 23.0197 115.122 23.0274C114.672 23.0274 114.443 23.2084 114.439 23.5693C114.439 23.7992 114.581 23.9868 114.861 24.1312C115.036 24.2234 115.235 24.3133 115.456 24.4066C115.677 24.4977 115.856 24.5854 115.996 24.6686C116.317 24.8663 116.481 25.1617 116.481 25.5603C116.473 26.2788 115.998 26.6408 115.048 26.6408C114.64 26.6464 114.329 26.5775 114.111 26.4398C113.852 26.2754 113.714 26.0445 113.695 25.738L113.683 25.4215V25.2638L114.352 25.2638Z" fill="#fcfcfd" />
    <path d="M117.457 25.2638V25.4837C117.453 25.5969 117.467 25.7002 117.497 25.799C117.563 26.0656 117.782 26.1988 118.149 26.1988C118.287 26.1988 118.412 26.1799 118.523 26.1411C118.775 26.0645 118.904 25.8746 118.914 25.5781C118.91 25.3749 118.827 25.2161 118.663 25.105C118.546 25.0284 118.405 24.954 118.244 24.8774L117.759 24.6564C117.566 24.5709 117.405 24.4832 117.276 24.3922C117.007 24.2045 116.873 23.9369 116.873 23.586C116.877 23.2018 117.028 22.9308 117.325 22.7698C117.546 22.6488 117.848 22.5866 118.236 22.5833C118.708 22.5833 119.046 22.6721 119.256 22.8509C119.421 22.9941 119.506 23.2762 119.506 23.6959V23.7748H118.836V23.6804L118.82 23.476C118.781 23.1696 118.581 23.0197 118.224 23.0274C117.776 23.0274 117.547 23.2084 117.542 23.5693C117.542 23.7992 117.682 23.9868 117.967 24.1312C118.14 24.2234 118.339 24.3133 118.558 24.4066C118.781 24.4977 118.959 24.5854 119.097 24.6686C119.42 24.8663 119.583 25.1617 119.583 25.5603C119.575 26.2788 119.099 26.6408 118.152 26.6408C117.746 26.6464 117.434 26.5775 117.215 26.4398C116.955 26.2754 116.817 26.0445 116.797 25.738L116.785 25.4215V25.2638L117.457 25.2638Z" fill="#fcfcfd" />
  </svg>
);

IconProviderPlaytechBig.propTypes = {
  className: PropTypes.string
};

IconProviderPlaytechBig.defaultProps = {
  className: ''
};
