import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import {
  withUser,
  withModalActions,
  withCoreComponent,
  withGameActions,
} from 'core/hocs';
import { USER_FIELDS, GAME_WINNER_LIST_PERIOD_TYPE } from 'core/constants';
import { FormattedTag, RecommendedGames as RecommendedGamesCore } from 'core/components';

import { Logo } from 'components/logo/logo';
import { LoggedIn } from 'components/sidebar/logged-in/logged-in';
import { NotLoggedIn } from 'components/sidebar/not-logged-in/not-logged-in';
// import { UserTabs } from 'components/user-tabs/user-tabs';
import { News } from 'components/news/news';
import colors from 'customizations/js/color-variables';
import { GameWinnerList } from 'components/game-winner-list/game-winner-list';
import { IconDesktopSearch } from 'components/icons/icon-desktop-search/icon-desktop-search';
import { GamesSearchPortal as GamesSearch } from 'components/games-search/games-search-portal';

import { MODAL_IDS } from '../../constants';

import './sidebar.scss';

const {
  USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS,
} = USER_FIELDS;

export class SidebarUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    userData: PropTypes.shape(),
    userBalance: PropTypes.shape(),
    loyaltyPoints: PropTypes.shape(),
    pageName: PropTypes.string,
    games: PropTypes.arrayOf(PropTypes.shape()),
    openGame: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userData: null,
    userBalance: null,
    loyaltyPoints: null,
    pageName: null,
    games: [],
  };

  state = {
    gameSearchIsOpen: false,
  };

  toggleGameSearch = () => this.setState(prevState => ({ gameSearchIsOpen: !prevState.gameSearchIsOpen }));

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);
  };

  render() {
    const {
      isUserLoggedIn, signOut, userData, userBalance,
      loyaltyPoints, pageName, games: recommendedGames,
      openGame,
    } = this.props;
    const { gameSearchIsOpen } = this.state;

    return (
      <div className={classNames('sidebar position-fixed d-none d-md-flex flex-column align-items-center pt-2_5 px-2_5 bg-blue-dark', { 'sidebar-logged': isUserLoggedIn })}>
        <Logo iconClassName="" />

        {isUserLoggedIn
          ? (
            <LoggedIn
              pageName={pageName}
              userData={userData}
              userBalance={userBalance}
              loyaltyPoints={loyaltyPoints}
              signOut={signOut}
              isWithinSidebar
            />
          ) : (
            <NotLoggedIn
              pageName={pageName}
              openSignInModal={this.openSignInModal}
              openSignUpModal={this.openSignUpModal}
            />
          )}

        <Button
          color="transparent"
          size="sm"
          onClick={this.toggleGameSearch}
          className="search-btn d-flex align-items-center w-100 text-base text-white-70 font-weight-normal bg-secondary-dark mb-2 py-1_25 pl-1"
        >
          <IconDesktopSearch
            color={colors.primaryHover}
            className="ml-0_5 icon-search"
          />
          <FormattedTag className="ml-1_5" id="sidebar.search.btn" />
        </Button>

        <div className="w-100">
          <FormattedTag id="news-games.title" className="news-title h3 d-block text-uppercase" />

          <News
            openGame={openGame}
            coreProps={{ size: 3 }}
          />

          <FormattedTag id="latest-big-wins.title" className="winners-title h3 d-block mt-2_5 text-uppercase" />

          <GameWinnerList
            coreProps={{ size: 6, periodType: GAME_WINNER_LIST_PERIOD_TYPE.DAILY, period: 1 }}
            isWithinSidebar
          />
        </div>

        {gameSearchIsOpen && (
          <GamesSearch
            recommendedGames={recommendedGames}
            onClose={this.toggleGameSearch}
            isModal
            className="d-none d-md-block"
          />
        )}
      </div>
    );
  }
}

export const Sidebar = withCoreComponent(
  RecommendedGamesCore,
  withModalActions(
    withGameActions(
      withUser(
        SidebarUI,
        [USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS]
      )
    )
  )
);
