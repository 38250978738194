import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withModalActions } from 'core/hocs';
import { getStaticUrl } from 'core/helpers';
import { FormattedTag, RouteLink } from 'core/components';
import { ModalWindow } from 'components/modal-window/modal-window';
import { createBasicPath } from 'helpers/check-pixel-ratio';
import { MODAL_IDS } from '../../../constants';
import 'src/components/modals/promo-info-modal/promo-info-modal.scss';

const staticUrl = getStaticUrl();

class PromoModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  onButtonClick = () => {
    const { closeModal } = this.props;

    closeModal(MODAL_IDS.PROMO_INFO);
  }

  render() {
    const imageUrl = `${staticUrl}/images/promo-info-popup/image.png`;
    const basicImagePath = createBasicPath(imageUrl);

    return (
      <ModalWindow
        id={MODAL_IDS.PROMO_INFO}
        coreProps={{ id: MODAL_IDS.PROMO_INFO }}
        bodyClassName="d-flex d-sm-block align-items-center flex-column justify-content-sm-center text-center"
        className="promo-info-modal"
        isCentered
        fade
      >
        <div className="d-flex justify-content-center">
          {/* DESKTOP & TABLET IMAGE */}
          <img className="w-100 d-none d-sm-block promo-info-modal-image" src={`${basicImagePath[0]}${basicImagePath[1]}`} alt="promo-info" />

          {/* MOBILE IMAGE */}
          <img className="w-100 d-sm-none promo-info-modal-image" src={`${basicImagePath[0]}-m${basicImagePath[1]}`} alt="promo-info" />
        </div>

        <FormattedTag
          tag="div"
          id="promo-info-modal.description"
          className="mx-auto promo-info-modal-description"
        />
        <FormattedTag
          tag="div"
          id="promo-info-modal.title"
          className="h5 mt-1 promo-info-modal-title font-family-oswald"
        />
        <div className="mt-3 w-100">
          <FormattedTag
            id="promo-info-modal.button"
            tag={RouteLink}
            type="primary"
            to="/promos"
            className="w-100 btn btn-primary btn-lg"
            onClick={this.onButtonClick}
          />
        </div>
      </ModalWindow>
    );
  }
}

export const PromoInfoModal = withModalActions(PromoModalUI);
