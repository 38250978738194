import React from 'react';
import PropTypes from 'prop-types';

export const IconLangEnCa = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9999 12C23.9999 7.24245 21.2312 3.1317 17.2173 1.1907V22.8092C21.2312 20.8683 23.9999 16.7575 23.9999 12Z"
        fill="#D80027"
      />
      <path d="M0 12C0 16.7575 2.76867 20.8683 6.78263 22.8093V1.1907C2.76867 3.1317 0 7.24245 0 12Z" fill="#D80027" />
      <path
        d="M14.087 13.5652L16.174 12.5217L15.1305 12V10.9565L13.0435 12L14.087 9.91301H13.0435L12.0001 8.34781L10.9566 9.91301H9.91309L10.9566 12L8.86961 10.9565V12L7.82617 12.5217L9.91309 13.5652L9.39138 14.6087H11.4783V16.1739H12.5218V14.6087H14.6088L14.087 13.5652Z"
        fill="#D80027"
      />
    </g>
  </svg>
);

IconLangEnCa.propTypes = {
  className: PropTypes.string,
};

IconLangEnCa.defaultProps = {
  className: null,
};
