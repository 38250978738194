import React from 'react';
import PropTypes from 'prop-types';
import { FormSignIn } from 'components/forms/form-sign-in/form-sign-in';
import { ModalWindow } from 'components/modal-window/modal-window';
import { withModalActions } from 'core/hocs';

import { MODAL_IDS } from '../../../constants';

import './sign-in-modal.scss';

export const SignInModalUI = () => (
  <ModalWindow
    id={MODAL_IDS.SIGN_IN}
    coreProps={{ id: MODAL_IDS.SIGN_IN }}
    bodyClassName="auth-modal-body"
    fade
  >
    <div className="modal-form-container h-100">
      <FormSignIn />
    </div>
  </ModalWindow>
);

SignInModalUI.propTypes = {
  currentModal: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.any
  }).isRequired,
};

export const SignInModal = withModalActions(SignInModalUI);
