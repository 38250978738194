import React, { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { FormattedTag } from 'core/components';
import { withInfo, withLocale, withUser } from 'core/hocs';
import { isEmptyOrNil, convertStringifiedBoolean } from 'core/helpers';
import {
  PAYMENT_ITEM_KEYS, USER_FIELDS, PAYMENT_METHODS, EXTRA_DATA_FIELDS
} from 'core/constants';
import classNames from 'classnames';

import { PaymentMethodCard } from 'components/payment-method-card/payment-method-card';

const {
  PAYMENT_METHOD,
  MINIMUM_WITHDRAWAL,
  MINIMUM_DEPOSIT,
  MAXIMUM_WITHDRAWAL,
  MAXIMUM_DEPOSIT,
} = PAYMENT_ITEM_KEYS;

// Countries in which all withdrawal methods (except all IS_CREDITCARD) are available before they were used for deposit
// const COUNTRIES_WITH_ALLOWED_ALL_WITHDRAWAL_METHODS = ['IN', 'TR', 'PT', 'JP', 'AU'];

// https://jira.gamingtec.com/browse/SCO-243
const preferredDepositMethods = {
  IN: [PAYMENT_METHODS.IS_PAYTM],
  TR: [PAYMENT_METHODS.IS_PAPARA_3], // maksipara
  JP: [PAYMENT_METHODS.IS_CREDITCARD_2], // jcb
  PT: [PAYMENT_METHODS.IS_MULTIBANCO],
};

export class PaymentsMenuUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    otherItems: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    recentlyItems: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    isWithdrawal: PropTypes.bool,
    handleSelectedCard: PropTypes.func.isRequired,
    addExtraData: PropTypes.func.isRequired,
    isModal: PropTypes.bool,
    userData: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
    currencies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
    isWithdrawal: false,
    recentlyItems: [],
    otherItems: [],
    isModal: false,
  };

  state = {};

  static getDerivedStateFromProps(props, prevState) {
    const { isWithdrawal, userData } = props;
    const {
      // country,
      extraData
    } = userData || {};

    if (isEmptyOrNil(prevState)) {
      // const isCountryMethodsAvailable = COUNTRIES_WITH_ALLOWED_ALL_WITHDRAWAL_METHODS.includes(country);
      const isCountryMethodsAvailable = true; // temporarily set to true to enable all withdrawal methods
      const isDepositWasOpened = (extraData || {})[EXTRA_DATA_FIELDS.IS_DEPOSIT_WAS_OPENED] || false;

      return {
        isDepositWasOpened: convertStringifiedBoolean(isDepositWasOpened),
        isCountryMethodsAvailable,
        isWithdrawalAllMethodsIsAvailable: isWithdrawal && isCountryMethodsAvailable,
      };
    }

    return null;
  }

  componentDidMount() {
    const { addExtraData } = this.props;
    const { isDepositWasOpened } = this.state;

    if (!isDepositWasOpened) {
      addExtraData({
        [EXTRA_DATA_FIELDS.IS_DEPOSIT_WAS_OPENED]: true,
      });
    }
  }

  getPreparedOtherItems = () => {
    const {
      otherItems,
      recentlyItems,
      isWithdrawal,
      userData: { country }
    } = this.props;

    const {
      isDepositWasOpened,
      isWithdrawalAllMethodsIsAvailable,
    } = this.state;

    let sortedDepositOtherItems;

    if (!isDepositWasOpened) {
      sortedDepositOtherItems = R.sort(
        method => (preferredDepositMethods[country] || []).includes(method.code) ? -1 : 1,
        otherItems
      );
    }

    const preparedWithdrawalOtherItems = isWithdrawalAllMethodsIsAvailable
      ? [...recentlyItems, ...otherItems]
      : otherItems;

    return isWithdrawal
      ? preparedWithdrawalOtherItems
      : sortedDepositOtherItems || otherItems;
  }

  render() {
    const {
      locale,
      recentlyItems,
      handleSelectedCard,
      currency,
      isWithdrawal,
      isModal,
      currencies,
    } = this.props;

    const {
      isCountryMethodsAvailable,
      isWithdrawalAllMethodsIsAvailable,
    } = this.state;

    const preparedOtherItems = this.getPreparedOtherItems();

    return (
      <>
        <FormattedTag tag="div" className="h4 not-responsive mb-1_5" id={`profile-wallet.${isWithdrawal ? 'withdrawal' : 'deposit'}.select-method`} isHtml />
        {(!isWithdrawal || (isWithdrawal && !isCountryMethodsAvailable)) && (
          <div className="d-flex flex-column">
            {!isEmptyOrNil(recentlyItems) && (
              <>
                <FormattedTag
                  id="profile-wallet.withdrawal.recently-used"
                  className={classNames('text-uppercase label mb-1 text-white-70', { 'text-blue-dark': isModal })}
                  isHtml
                />

                <div className="d-flex flex-wrap mb-2 payment-menu-card-wrapper">
                  {recentlyItems.map(method => (
                    <PaymentMethodCard
                      locale={locale}
                      className={classNames('mb-1', { 'is-modal': isModal })}
                      key={method[PAYMENT_METHOD]}
                      paymentMethod={method[PAYMENT_METHOD]}
                      currency={currency}
                      currencies={currencies}
                      onClick={handleSelectedCard}
                      minimum={method[isWithdrawal ? MINIMUM_WITHDRAWAL : MINIMUM_DEPOSIT]}
                      maximum={method[isWithdrawal ? MAXIMUM_WITHDRAWAL : MAXIMUM_DEPOSIT]}
                      isWithdrawal={isWithdrawal}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}

        <div className="d-flex flex-column">
          {!isEmptyOrNil(preparedOtherItems) && (
            <>
              <FormattedTag
                id={`profile-wallet.withdrawal.${isWithdrawalAllMethodsIsAvailable ? 'all-methods' : 'other-methods'}`}
                className={classNames('text-uppercase text-white-70 label mb-1 mb-md-0_5')}
                isHtml
              />

              {isWithdrawal && !isCountryMethodsAvailable && <FormattedTag id="profile-wallet.withdrawal.unused-systems-error" className="label mb-1_5" isHtml />}

              <div className="d-flex others flex-wrap payment-menu-card-wrapper">
                {preparedOtherItems.map(method => (
                  <PaymentMethodCard
                    locale={locale}
                    className={classNames('mb-1', { 'is-modal': isModal })}
                    key={method[PAYMENT_METHOD]}
                    paymentMethod={method[PAYMENT_METHOD]}
                    currency={currency}
                    currencies={currencies}
                    onClick={handleSelectedCard}
                    minimum={method[isWithdrawal ? MINIMUM_WITHDRAWAL : MINIMUM_DEPOSIT]}
                    maximum={method[isWithdrawal ? MAXIMUM_WITHDRAWAL : MAXIMUM_DEPOSIT]}
                    disabled={isWithdrawal && !isCountryMethodsAvailable}
                    isWithdrawal={isWithdrawal}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export const PaymentsMenu = withLocale(
  withInfo(
    withUser(
      PaymentsMenuUI,
      [USER_FIELDS.USER_DATA]
    )
  )
);
