import React from 'react';
import PropTypes from 'prop-types';

export const IconInPlay = ({ className }) => (
  <svg className={className} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6220_11413)">
      <path d="M14.5 25H22.5V27H14.5V25Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 10C9.39543 10 8.5 10.8954 8.5 12V20C8.5 21.1046 9.39543 22 10.5 22H26.5C27.6046 22 28.5 21.1046 28.5 20V12C28.5 10.8954 27.6046 10 26.5 10H10.5ZM16.25 19.4641L21.5 16L16.25 12.5359V19.4641Z"
        fill="white"
      />
    </g>
  </svg>
);

IconInPlay.propTypes = {
  className: PropTypes.string,
};

IconInPlay.defaultProps = {
  className: null,
};
