import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classNames from 'classnames';
import { Spinner } from 'reactstrap';
import { withCoreComponent, withLocale } from 'core/hocs';
import { isEmptyOrNil, getStaticUrl } from 'core/helpers';
import { GameWinnerList as GameWinnerListCore, FormattedTag } from 'core/components';
import { formatAmountWithCurrency } from 'helpers/numbers';
import { setUsernameHidden } from 'helpers/username';
import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';
import { IconPlayCircle } from 'components/icons/icon-play-circle/icon-play-circle';

import './game-winner-list.scss';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

export class GameWinnerListUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    openGame: PropTypes.func.isRequired,
    closeSidebar: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: [],
  };

  openGame = ({ currentTarget: { dataset: { id: gameId, producer } } }) => {
    const { openGame, closeSidebar } = this.props;

    openGame({ gameId, producer });
    closeSidebar();
  };

  render() {
    const { locale, items, isInProgress } = this.props;
    const targetItems = R.uniq(items || []);

    if (isEmptyOrNil(targetItems) && !isInProgress) {
      return (
        <div className="mt-2_5 px-1_25 text-center">
          <FormattedTag
            tag="div"
            id="sidebar.winners.empty"
            className="text-small text-white"
          />
        </div>
      );
    }

    if (isInProgress) {
      return (
        <div className="mt-2_5 px-1_25 text-center">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="game-winner-list pb-1">
        {targetItems.map(({
          gameId,
          producer,
          playerName,
          amountWon,
          currency,
          title,
          thumbPath,
        }) => (
          <div
            key={`${gameId}-${producer}`}
            className="d-flex align-items-start mt-0_75"
          >
            <div className="bg-gray-40 rounded">
              <ImageLazyLoad
                src="/assets/public/images/transparent-pixel.png"
                className="game-winner-list-image flex-shrink-0 rounded"
                classNameLoaded="game-winner-list-image-loaded"
                classNameError="game-winner-list-image-error"
                srcLazy={thumbPath}
                type={LAZY_LOAD_TYPES.BACKGROUND}
                srcFallback={DEFAULT_THUMB_PATH}
              >
                <div
                  className="game-winner-list-image-overlay d-flex justify-content-center align-items-center rounded"
                  role="button"
                  tabIndex="0"
                  data-id={gameId}
                  data-producer={producer}
                  onClick={this.openGame}
                  onKeyPress={this.openGame}
                >
                  <IconPlayCircle className="game-winner-list-play-icon" isSmall />
                </div>
              </ImageLazyLoad>
            </div>

            <div
              className={classNames(
                'd-flex flex-column justify-content-between overflow-hidden flex-grow-1 pb-0_75 ml-1_75'
              )}
            >
              <div>
                <div className="text-truncate">
                  <span className="text-truncate text-capitalize mr-0_5">
                    {setUsernameHidden(playerName)}
                  </span>

                  <FormattedTag
                    id="won"
                    className="mr-0_5"
                  />

                  <span className="font-weight-semi-bold rounded px-0_5 flex-shrink-0">
                    {formatAmountWithCurrency({
                      amount: amountWon,
                      currency,
                      locale,
                      isIsoFormat: true,
                    })}
                  </span>
                </div>
              </div>

              <div className="d-flex">
                <FormattedTag id="in" className="text-nowrap mr-0_5" isHtml />
                <span
                  className="game-winner-list-open-button font-weight-bold text-white"
                  title={title}
                  role="button"
                  tabIndex="0"
                  data-id={gameId}
                  data-producer={producer}
                  onClick={this.openGame}
                  onKeyPress={this.openGame}
                >
                  {title}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export const GameWinnerList = withCoreComponent(GameWinnerListCore, withLocale(GameWinnerListUI));
