import React from 'react';
import PropTypes from 'prop-types';

export const IconSlotMachine = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
    <path
      d="M10.132 27.3681C10.132 27.7133 10.4113 27.9926 10.7565 27.9926H23.2473C23.5925 27.9926 23.8718 27.7132 23.8718 27.3681V22.9963H10.132V27.3681ZM13.8792 24.8699H20.1246C20.4698 24.8699 20.7491 25.1493 20.7491 25.4945C20.7491 25.8396 20.4697 26.119 20.1246 26.119H13.8792C13.534 26.119 13.2547 25.8396 13.2547 25.4945C13.2547 25.1493 13.534 24.8699 13.8792 24.8699ZM28.2436 8.00732C27.2105 8.00732 26.37 8.84777 26.37 9.88094C26.37 10.6941 26.8937 11.3808 27.6191 11.6395V15.5019H26.37V19.2491H28.2436C28.5888 19.2491 28.8682 18.9697 28.8682 18.6246V11.6395C29.5936 11.3808 30.1173 10.6941 30.1173 9.88094C30.1173 8.84777 29.2768 8.00732 28.2436 8.00732ZM12.0056 18.6245H14.5037V14.8773H12.0056V18.6245ZM19.5001 18.6245H21.9982V14.8773H19.5001V18.6245ZM15.7528 18.6245H18.251V14.8773H15.7528V18.6245ZM24.4964 11.7546H22.7027C21.7134 9.49612 19.4757 8.00732 17.0019 8.00732C14.5281 8.00732 12.2904 9.49612 11.3011 11.7546H9.50733C9.16209 11.7546 8.88281 12.0339 8.88281 12.3791V21.1226C8.88281 21.4679 9.16216 21.7472 9.50733 21.7472H24.4963C24.8416 21.7472 25.1209 21.4678 25.1209 21.1226V12.3791C25.1209 12.0339 24.8416 11.7546 24.4964 11.7546ZM23.2473 19.2491C23.2473 19.5943 22.968 19.8736 22.6228 19.8736H11.381C11.0358 19.8736 10.7565 19.5943 10.7565 19.2491V14.2528C10.7565 13.9075 11.0359 13.6282 11.381 13.6282H22.6227C22.968 13.6282 23.2472 13.9075 23.2472 14.2528V19.2491H23.2473Z"
      fill="white"
    />
  </svg>
);

IconSlotMachine.propTypes = {
  className: PropTypes.string,
};

IconSlotMachine.defaultProps = {
  className: null,
};
