import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlatipus = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="24"
    height="9"
    viewBox="0 0 24 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-provider-platipus-clip-0)">
      <path
        d="M24 0V7.78617C24 8.12412 23.7303 8.40799 23.3798 8.40799H0.620225C0.269663 8.40799 0 8.12412 0 7.78617V2.00061C0 1.66267 0.269663 1.39232 0.620225 1.39232H22.5978L23.8787 0H24Z"
        fill="#FF403F"
      />
      <path
        d="M4.62472 4.10938C4.62472 4.82582 4.08539 5.35301 3.3573 5.35301H2.84494V6.29924H1.90112V2.87927H3.3573C4.08539 2.87927 4.62472 3.39294 4.62472 4.10938ZM3.65393 4.10938C3.65393 3.86606 3.49213 3.70385 3.24944 3.70385H2.84494V4.52843H3.24944C3.49213 4.52843 3.65393 4.3527 3.65393 4.10938Z"
        fill="white"
      />
      <path d="M5.00226 2.70355H5.91911V6.29925H5.00226V2.70355Z" fill="white" />
      <path
        d="M9.28988 3.64977V6.29923H8.58875L8.50785 6.10999C8.29212 6.28572 8.02246 6.38034 7.71235 6.38034C6.91684 6.38034 6.33707 5.79908 6.33707 4.9745C6.33707 4.14992 6.91684 3.56866 7.71235 3.56866C8.02246 3.56866 8.30561 3.67681 8.52134 3.85254L8.61572 3.64977H9.28988ZM8.42695 4.9745C8.42695 4.63656 8.18426 4.37972 7.84718 4.37972C7.49662 4.37972 7.25392 4.63656 7.25392 4.9745C7.25392 5.32596 7.5101 5.5828 7.84718 5.5828C8.18426 5.5828 8.42695 5.32596 8.42695 4.9745Z"
        fill="white"
      />
      <path
        d="M11.636 5.5017V6.29924H11.0157C10.436 6.29924 10.0854 5.94778 10.0854 5.35301V4.3527H9.60001V4.14993L10.7865 2.87927H10.9618V3.6633H11.6225V4.3527H10.9888V5.20431C10.9888 5.39356 11.0966 5.5017 11.2989 5.5017H11.636Z"
        fill="white"
      />
      <path
        d="M12.0674 3.66332H12.9708V6.29926H12.0674V3.66332ZM12.0674 2.55487H12.9708V3.32538H12.0674V2.55487Z"
        fill="white"
      />
      <path
        d="M16.4629 4.9745C16.4629 5.79908 15.8966 6.39386 15.0876 6.39386C14.8449 6.39386 14.6292 6.32627 14.4404 6.21813V7.25899H13.5371V3.66329H14.1573L14.2787 3.86605C14.4944 3.67681 14.764 3.56866 15.0876 3.56866C15.8966 3.56866 16.4629 4.14992 16.4629 4.9745ZM15.5596 4.9745C15.5596 4.63656 15.3034 4.37972 14.9663 4.37972C14.6292 4.37972 14.373 4.63656 14.373 4.9745C14.373 5.32596 14.6292 5.5828 14.9663 5.5828C15.3034 5.5828 15.5596 5.32596 15.5596 4.9745Z"
        fill="white"
      />
      <path
        d="M16.8539 5.16373V3.66327H17.7573V5.12318C17.7573 5.40705 17.9056 5.56926 18.1483 5.56926C18.391 5.56926 18.5393 5.40705 18.5393 5.12318V3.66327H19.4562V5.16373C19.4562 5.9072 18.9303 6.39384 18.1483 6.39384C17.3663 6.38032 16.8539 5.9072 16.8539 5.16373Z"
        fill="white"
      />
      <path
        d="M21.0067 6.38034C20.2787 6.38034 19.8472 6.00184 19.8337 5.4341H20.7101C20.7101 5.62335 20.8449 5.71797 21.0202 5.71797C21.1551 5.71797 21.2764 5.65039 21.2764 5.51521C21.2764 5.353 21.0742 5.31244 20.818 5.27189C20.4135 5.2043 19.8742 5.08264 19.8742 4.44731C19.8742 3.90661 20.3191 3.56866 21.0067 3.56866C21.6674 3.56866 22.1124 3.93364 22.1124 4.46083H21.2629C21.2629 4.29862 21.1551 4.21751 20.9798 4.21751C20.8449 4.21751 20.7371 4.2851 20.7371 4.40676C20.7371 4.55545 20.9393 4.59601 21.1955 4.63656C21.6135 4.71767 22.1663 4.79877 22.1663 5.47466C22.1663 6.02888 21.6944 6.38034 21.0067 6.38034Z"
        fill="white"
      />
    </g>
  </svg>
);

IconProviderPlatipus.propTypes = {
  className: PropTypes.string,
};

IconProviderPlatipus.defaultProps = {
  className: '',
};
