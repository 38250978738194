import React from 'react';
import PropTypes from 'prop-types';

export const IconSlotMachine = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
    <path
      d="M2.10212 20.898C2.10212 21.2627 2.39727 21.5578 2.76196 21.5578H15.9593C16.324 21.5578 16.6191 21.2627 16.6191 20.898V16.2789H2.10212V20.898ZM6.0613 18.2586H12.6599C13.0247 18.2586 13.3198 18.5537 13.3198 18.9184C13.3198 19.2831 13.0246 19.5782 12.6599 19.5782H6.0613C5.69654 19.5782 5.40146 19.2831 5.40146 18.9184C5.40146 18.5537 5.69654 18.2586 6.0613 18.2586ZM21.2382 0.442139C20.1466 0.442139 19.2586 1.33012 19.2586 2.42173C19.2586 3.28089 19.8119 4.00648 20.5784 4.27979V8.36058H19.2586V12.3198H21.2382C21.6029 12.3198 21.898 12.0246 21.898 11.6599V4.27979C22.6645 4.00648 23.2178 3.28089 23.2178 2.42173C23.2178 1.33012 22.3298 0.442139 21.2382 0.442139ZM4.08171 11.6599H6.72114V7.70067H4.08171V11.6599ZM12.0001 11.6599H14.6395V7.70067H12.0001V11.6599ZM8.04089 11.6599H10.6803V7.70067H8.04089V11.6599ZM17.279 4.40132H15.3838C14.3386 2.01514 11.9743 0.442139 9.36057 0.442139C6.74685 0.442139 4.38258 2.01514 3.33733 4.40132H1.44213C1.07737 4.40132 0.782288 4.69648 0.782288 5.06116V14.2993C0.782288 14.6641 1.07744 14.9591 1.44213 14.9591H17.2789C17.6437 14.9591 17.9388 14.664 17.9388 14.2993V5.06124C17.9389 4.69648 17.6437 4.40132 17.279 4.40132ZM15.9593 12.3198C15.9593 12.6845 15.6641 12.9796 15.2994 12.9796H3.4218C3.05703 12.9796 2.76196 12.6845 2.76196 12.3198V7.04083C2.76196 6.67607 3.05711 6.38092 3.4218 6.38092H15.2994C15.6641 6.38092 15.9592 6.67607 15.9592 7.04083V12.3198H15.9593Z"
      fill="white"
    />
  </svg>
);

IconSlotMachine.propTypes = {
  className: PropTypes.string,
};

IconSlotMachine.defaultProps = {
  className: null,
};
