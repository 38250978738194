import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderAmusnetEgtBig = ({ className }) => (
  <svg className={className} width="141" height="78" viewBox="0 0 141 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-provider-amusnet-egt-big-clip)">
      <mask id="mask0_1_2097" maskUnits="userSpaceOnUse" x="0" y="28" width="141" height="21">
        <path d="M141 28.9172H0V48.0836H141V28.9172Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_2097)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.57519 38.7204C5.59731 36.1582 7.97971 32.8631 12.0409 31.0625C19.1881 27.8919 23.2813 30.8154 24.3409 33.5797C25.328 36.1562 23.3759 40.6783 19.9923 44.0489C22.4068 42.2911 24.3791 40.4047 25.7482 38.5714C27.5637 36.1378 28.3156 33.8002 27.626 31.9832C26.6047 29.3006 22.624 28.439 17.5736 29.1618C12.5254 29.8845 8.64718 31.1441 5.53297 32.7509C2.06894 34.5372 0.281637 36.6605 0.0263081 39.0838C-0.345628 42.6035 3.3677 47.3318 11.8619 47.9157C16.3251 48.2219 20.6075 48.324 28.8966 46.2804C27.9094 46.313 5.48673 49.1406 5.5772 38.7204H5.57519Z"
          fill="#A6CE39"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8221 42.8154C16.4819 42.8154 18.6532 40.6125 18.6532 37.9115C18.6532 35.2105 16.4839 33.0076 13.8221 33.0076C11.1622 33.0076 8.99289 35.2105 8.99289 37.9115C8.99289 40.6125 11.1622 42.8154 13.8221 42.8154Z"
          fill="#A6CE39"
        />
        <path
          d="M40.5815 42.6436H34.936L33.8866 45.2772H30.0346L35.9293 31.7212H39.6486L45.5614 45.2793H41.633L40.5835 42.6456L40.5815 42.6436ZM39.4757 39.8161L37.7588 35.4777L36.0419 39.8161H39.4757Z"
          fill="#A6CE39"
        />
        <path
          d="M60.3425 45.2793L60.3042 38.0929L56.8704 43.9421H55.1916L51.7778 38.2869V45.2793H48.2877V31.7212H51.3958L56.0883 39.5465L60.6661 31.7212H63.7743L63.8125 45.2793H60.3404H60.3425Z"
          fill="#A6CE39"
        />
        <path
          d="M69.6769 43.9033C68.5771 42.8049 68.0263 41.2492 68.0263 39.2362V31.7212H71.802V39.1198C71.802 41.2901 72.6484 42.3742 74.3392 42.3742C76.03 42.3742 76.8563 41.2901 76.8563 39.1198V31.7212H80.5757V39.2362C80.5757 41.2512 80.0247 42.807 78.925 43.9033C77.8253 45.0016 76.2833 45.5488 74.2989 45.5488C72.3147 45.5488 70.7746 44.9996 69.6749 43.9033H69.6769Z"
          fill="#A6CE39"
        />
        <path
          d="M86.3377 45.1439C85.3203 44.8723 84.494 44.5111 83.8587 44.0598L85.0992 41.2323C85.6963 41.6324 86.3899 41.955 87.178 42.2C87.9661 42.4449 88.7422 42.5674 89.5042 42.5674C90.9536 42.5674 91.6774 42.2 91.6774 41.463C91.6774 41.0751 91.4703 40.7893 91.0583 40.6014C90.6441 40.4136 89.9806 40.2176 89.0658 40.0114C88.0606 39.7929 87.2222 39.5561 86.5487 39.3051C85.8752 39.0539 85.2962 38.6497 84.8137 38.0944C84.3311 37.5391 84.0899 36.7898 84.0899 35.8466C84.0899 35.0198 84.3131 34.2746 84.7574 33.609C85.2017 32.9435 85.8672 32.4188 86.7518 32.0309C87.6364 31.643 88.72 31.449 90.0027 31.449C90.8793 31.449 91.7458 31.5491 92.5962 31.7491C93.4487 31.9492 94.1983 32.2432 94.8479 32.6311L93.6839 35.4791C92.4133 34.7809 91.1789 34.4338 89.9826 34.4338C89.2327 34.4338 88.6859 34.5461 88.342 34.7727C87.9983 34.9993 87.8274 35.2933 87.8274 35.6547C87.8274 36.016 88.0304 36.2876 88.4386 36.4672C88.8447 36.6489 89.5001 36.8347 90.4028 37.0287C91.4201 37.2491 92.2625 37.4839 92.93 37.735C93.5974 37.9862 94.1767 38.3863 94.6646 38.9355C95.1534 39.4847 95.3987 40.2298 95.3987 41.1731C95.3987 41.9856 95.1759 42.7226 94.7313 43.38C94.2867 44.0394 93.6196 44.5641 92.7289 44.9582C91.8383 45.3522 90.7587 45.5482 89.486 45.5482C88.4044 45.5482 87.3569 45.4134 86.3396 45.1419L86.3377 45.1439Z"
          fill="#A6CE39"
        />
        <path
          d="M111.494 31.7212V45.2793H108.387L102.494 38.0542V45.2793H98.7949V31.7212H101.903L107.795 38.9463V31.7212H111.496H111.494Z"
          fill="#A6CE39"
        />
        <path
          d="M126.543 42.3149V45.2772H115.823V31.7212H126.294V34.6835H119.56V36.968H125.492V39.8344H119.56V42.3129H126.541L126.543 42.3149Z"
          fill="#A6CE39"
        />
        <path d="M133.143 34.7611H129.042V31.7212H141V34.7611H136.919V45.2772H133.143V34.7611Z" fill="#A6CE39" />
      </g>
    </g>
  </svg>
);

IconProviderAmusnetEgtBig.propTypes = {
  className: PropTypes.string,
};

IconProviderAmusnetEgtBig.defaultProps = {
  className: null,
};
