import React from 'react';
import PropTypes from 'prop-types';

export const IconSupport = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <circle opacity="0.15" cx="18" cy="18" r="18" fill="white" />
    <path
      d="M18.5882 14.4705C18.5882 12.8488 17.2693 11.5293 15.647 11.5293C14.0248 11.5293 12.7058 12.8488 12.7058 14.4705C12.7058 16.0922 14.0248 17.4117 15.647 17.4117C17.2693 17.4117 18.5882 16.0922 18.5882 14.4705ZM16.8235 20.9411C20.3909 20.9411 23.2941 18.0384 23.2941 14.4705C23.2941 14.0695 23.2562 13.6818 23.1792 13.2951C22.5153 9.60888 19.1006 7.49997 15.6471 7.49992C11.7772 7.49988 8 10.2053 8 14.4705C8 16.6465 8.85477 17.7546 9.54179 18.645C10.0128 19.2557 10.3529 19.6968 10.3529 20.3528C10.3529 21.1116 9.62795 22.009 9.34762 22.2905C9.18099 22.4593 9.13046 22.7121 9.22238 22.9316C9.31318 23.151 9.52682 23.2941 9.76464 23.2941C11.4052 23.2941 12.8494 22.3359 13.5066 20.8613C13.7893 20.9032 14.1247 20.9412 14.4705 20.9412L16.8235 20.9411ZM11.5294 14.4705C11.5294 12.2003 13.3768 10.3528 15.6471 10.3528C17.9173 10.3528 19.7647 12.2002 19.7647 14.4705C19.7647 16.7408 17.9173 18.5881 15.6471 18.5881C13.3768 18.5881 11.5294 16.7407 11.5294 14.4705ZM25.6471 25.0587C25.6471 24.4027 25.9872 23.9615 26.4582 23.3509C27.1452 22.4605 28 21.3524 28 19.1764C28 16.6578 26.5452 14.4559 24.3828 13.3955C24.4357 13.7439 24.4706 14.0992 24.4706 14.4705C24.4706 18.6869 21.04 22.1175 16.8236 22.1175C16.8236 22.1175 14.265 22.1147 14.1581 22.1095C14.0298 22.303 13.8879 22.4852 13.737 22.6583C14.8989 24.4686 16.9155 25.647 19.1766 25.647H21.5295C21.8305 25.647 22.1442 25.6205 22.4923 25.5642C23.1483 27.0405 24.5936 27.9999 26.2354 27.9999C26.4732 27.9999 26.687 27.8568 26.7777 27.6374C26.8696 27.418 26.8191 27.1652 26.6525 26.9963C26.372 26.7149 25.6471 25.8176 25.6471 25.0587Z"
      fill="white"
    />
  </svg>
);

IconSupport.propTypes = {
  className: PropTypes.string,
};

IconSupport.defaultProps = {
  className: '',
};
