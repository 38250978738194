import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderHacksawBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="108" height="56" viewBox="0 0 419.9 119">
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path fill="#fcfcfd" d="M27.8,48.1h12v9.8l19,0.5V3.5c0-0.6-0.3-1.1-0.8-1.1H40.6c-0.5,0-0.8,0.4-0.8,1.1v26h-12v-26    c0-0.6-0.3-1.1-0.8-1.1H9.8C9.3,2.4,9,2.8,9,3.5v53.7l18.8,0.5V48.1z" />
        <path fill="#fcfcfd" d="M102.8,3.3c-0.2-0.6-0.5-0.8-0.9-0.8H83.2c-0.4,0-0.7,0.2-0.9,0.8L66.4,58.6l52.3,1.4L102.8,3.3z M87.5,49.8    l4.9-31.3l4.8,31.3H87.5z" />
        <path fill="#fcfcfd" d="M192.2,59.2l8.3-12.1l6.3,15.1l19.5,0.5l-14.4-32.1l18.5-27c0.5-0.7,0.2-1.3-0.5-1.3h-19.6    c-0.4,0-0.8,0.3-0.9,0.6l-17.1,27.5v-27c0-0.6-0.3-1.1-0.8-1.1h-16c-0.5,0-0.8,0.4-0.8,1.1v57.9l17.6,0.5L192.2,59.2z" />
        <path fill="#fcfcfd" d="M284.6,52.5c0-28.8-32.4-18.5-32.4-29.9c0-3.3,2.8-4.7,6.5-4.7c4.4,0,8.8,2,13.1,6.5c0.2,0.3,0.6,0.4,0.9,0.2    c0.1-0.1,0.2-0.2,0.2-0.3l8.8-12.2c0.3-0.4,0.3-1.1-0.1-1.5C275.5,3.3,268.2,0,259.4,0c-12,0-24.9,5.9-24.9,24.8    c0,30.2,32.8,18.2,32.8,30.6c0,3.8-3.1,5.7-7.5,5.7c-5.5,0-11-2.8-16.5-10.3c-0.3-0.4-0.8-0.5-1.1,0l-8.6,12.2l48.7,1.3    C283.9,60.5,284.6,56.5,284.6,52.5z" />
        <path fill="#fcfcfd" d="M324.5,3.3c-0.2-0.6-0.5-0.8-0.9-0.8h-18.8c-0.4,0-0.7,0.2-0.9,0.8l-17.5,61l55.6,1.4L324.5,3.3z M309.2,49.8    l5-31.3l4.8,31.3H309.2z" />
        <path fill="#fcfcfd" d="M376.8,35.5l4.9,31.3l20.3,0.5l17.8-63.7c0.2-0.7-0.1-1.2-0.6-1.2h-16.7c-0.5,0-0.8,0.3-0.9,1l-8.9,37    l-7.2-37c-0.1-0.5-0.4-1-0.9-1H369c-0.5,0-0.8,0.4-0.9,1l-7.2,37l-8.9-37c-0.1-0.6-0.4-1-0.9-1h-16.7c-0.5,0-0.8,0.4-0.6,1.2    L351.2,66l20.7,0.5L376.8,35.5z" />
        <path fill="#fcfcfd" d="M166.5,55.3c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.3,0.1c-2.4,3.5-6.3,5.6-10.6,5.5    c-9.2,0-13.6-9.2-13.6-21.4c0-11.1,3.6-20.9,13.8-20.9c4.2-0.2,8.1,1.9,10.4,5.3c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.1-0.1,0.1-0.2    V3.1c0-0.5-0.3-0.9-0.7-1.1c-3.5-1.2-7.3-1.8-11-1.8c-20.4,0-33.5,17.9-33.5,40.5c-0.1,6.7,1.2,13.3,3.8,19.4l41.4,1.1L166.5,55.3    z" />
        <path fill="#fcfcfd" d="M30.8,78.7c0,0.6,0.3,1,0.8,1h17.3c0.5,0,0.8-0.4,0.8-1V61.4l-19-0.5L30.8,78.7z" />
        <path fill="#fcfcfd" d="M0.8,79.7H18c0.5,0,0.9-0.4,0.9-1V60.5L0,60.1v18.6C0,79.3,0.3,79.7,0.8,79.7z" />
        <path fill="#fcfcfd" d="M53.2,79.7h17.3c0.5,0,0.8-0.4,0.9-0.8l2.2-9.6H93l2.2,9.6c0.1,0.5,0.5,0.8,0.9,0.8h17.3    c0.6,0,0.8-0.5,0.6-1.3l-4.4-15.6l-52.3-1.4l-4.9,16.9C52.3,79.2,52.6,79.7,53.2,79.7z" />
        <path fill="#fcfcfd" d="M203.5,79c0.1,0.4,0.5,0.7,0.9,0.7h18.1c0.6,0,0.9-0.5,0.6-1.3l-5.8-12.8l-19.5-0.5L203.5,79z" />
        <path fill="#fcfcfd" d="M166.4,79.7h16c0.5,0,0.8-0.4,0.8-1V64.8l-17.6-0.5v14.3C165.6,79.3,165.9,79.7,166.4,79.7z" />
        <path fill="#fcfcfd" d="M223.3,69.3c6.2,8.1,15.8,12.9,26,12.9c10.3,0,19.9-5,24.1-15l-48.7-1.3l-1.4,2C223,68.3,223,68.9,223.3,69.3    z" />
        <path fill="#fcfcfd" d="M274.9,79.7h17.3c0.5,0,0.9-0.4,1-0.8l2.2-9.6h19.4l2.2,9.6c0.1,0.5,0.5,0.8,1,0.8h17.2    c0.6,0,0.8-0.5,0.6-1.3l-2.8-9.8l-55.6-1.4l-3.2,11.2C274,79.2,274.4,79.7,274.9,79.7z" />
        <path fill="#fcfcfd" d="M374.2,78.8c0.1,0.5,0.4,0.9,0.9,0.9h14.7c0.5,0,0.8-0.3,0.9-0.9l2.4-8.6l-20.3-0.5L374.2,78.8z" />
        <path fill="#fcfcfd" d="M345.9,79.7h14.7c0.5,0,0.8-0.4,0.9-0.9l1.5-9.3l-20.7-0.5l2.8,9.9C345.1,79.4,345.4,79.7,345.9,79.7z" />
        <path fill="#fcfcfd" d="M146.1,82.6c3.6,0,7.2-0.5,10.6-1.6c0.5-0.2,0.8-0.6,0.8-1.1V64.1l-41.4-1.1C121.4,75,131.9,82.6,146.1,82.6z    " />
        <path fill="#fcfcfd" d="M26,103.8h-6.5v-3c0-0.5-0.3-0.8-0.9-0.8h-1.3c-0.6,0-0.9,0.3-0.9,0.8v13.3c0,0.5,0.3,0.8,0.9,0.8h1.4    c0.6,0,0.9-0.3,0.9-0.8v-3.9h-1.9v-4H26v8.4c0,3-1.8,4.5-5.5,4.5h-5.3c-3.7,0-5.5-1.5-5.5-4.5v-14.3c0-3,1.8-4.5,5.5-4.5h5.3    c3.7,0,5.5,1.5,5.5,4.5L26,103.8z" />
        <path fill="#fcfcfd" d="M95.8,119l-0.8-3.5h-5.8l-0.8,3.5h-6.5l6.2-23.2h8l6.2,23.2H95.8z M90.3,111h3.8l-1.8-8.4L90.3,111z" />
        <path fill="#fcfcfd" d="M164.7,119h-5.9V95.8h7.1l4,12.4l3.9-12.4h7.1V119h-5.8v-14.7l-3,9.8h-4.5l-3-9.7L164.7,119z" />
        <path fill="#fcfcfd" d="M238.9,95.8h6.6V119h-6.6V95.8z" />
        <path fill="#fcfcfd" d="M314.4,95.8h6V119h-5.6l-5.4-12.2V119h-6V95.8h5.6l5.4,12.4L314.4,95.8z" />
        <path fill="#fcfcfd" d="M394.5,103.8H388v-3c0-0.5-0.3-0.8-0.9-0.8h-1.4c-0.6,0-0.9,0.3-0.9,0.8v13.3c0,0.5,0.3,0.8,0.9,0.8h1.3    c0.6,0,0.9-0.3,0.9-0.8v-3.9h-1.9v-4h8.5v8.4c0,3-1.8,4.5-5.5,4.5h-5.3c-3.7,0-5.5-1.5-5.5-4.5v-14.3c0-3,1.8-4.5,5.5-4.5h5.3    c3.7,0,5.5,1.5,5.5,4.5L394.5,103.8z" />
      </g>
    </g>
  </svg>
);

IconProviderHacksawBig.propTypes = {
  className: PropTypes.string,
};
IconProviderHacksawBig.defaultProps = {
  className: null,
};
