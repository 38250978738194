import React from 'react';
import PropTypes from 'prop-types';

export const IconLangNo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#cclip-icon-lang)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path d="M0.41333 15.1305C1.10764 17.7067 2.63788 19.9395 4.6956 21.5206V15.1305H0.41333Z" fill="#D80027" />
      <path
        d="M10.9565 23.9544C11.3005 23.984 11.6484 24 12 24C17.5444 24 22.2096 20.2396 23.5866 15.1305H10.9565V23.9544Z"
        fill="#D80027"
      />
      <path
        d="M23.5866 8.86955C22.2096 3.76036 17.5444 0 12 0C11.6484 0 11.3005 0.0160312 10.9565 0.0456562V8.86955H23.5866Z"
        fill="#D80027"
      />
      <path d="M4.6956 2.47946C2.63788 4.06056 1.10764 6.29335 0.41333 8.86955H4.6956V2.47946Z" fill="#D80027" />
      <path
        d="M23.8984 10.4348H9.39136H9.39131V0.285248C8.28234 0.531154 7.23098 0.929732 6.26086 1.45909V10.4347V10.4348H0.101578C0.0347813 10.9472 0 11.4695 0 12C0 12.5305 0.0347813 13.0529 0.101578 13.5652H6.26081H6.26086V22.5409C7.23098 23.0702 8.28234 23.4689 9.39131 23.7147V13.5654V13.5653H23.8984C23.9651 13.0529 24 12.5305 24 12C24 11.4695 23.9651 10.9472 23.8984 10.4348Z"
        fill="#0052B4"
      />
    </g>
  </svg>
);

IconLangNo.propTypes = {
  className: PropTypes.string,
};

IconLangNo.defaultProps = {
  className: null,
};
