import React from 'react';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormSupport } from 'components/forms/form-support/form-support';

import { MODAL_IDS } from '../../../constants';

export const SupportModal = () => (
  <ModalWindow
    id={MODAL_IDS.SUPPORT}
    coreProps={{ id: MODAL_IDS.SUPPORT }}
    fade
  >
    <FormSupport />
  </ModalWindow>
);
