import React from 'react';
import PropTypes from 'prop-types';

export const IconLangSe = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFDA44"
      />
      <path
        d="M9.3914 10.4348H23.8985C23.1315 4.54697 18.0969 0 12 0C11.1041 0 10.2313 0.0990469 9.39136 0.285234V10.4348H9.3914Z"
        fill="#0052B4"
      />
      <path
        d="M6.26084 10.4347V1.45908C2.96581 3.25692 0.607016 6.55462 0.101562 10.4348H6.26084V10.4347Z"
        fill="#0052B4"
      />
      <path
        d="M6.2608 13.5652H0.101562C0.607016 17.4454 2.96581 20.7431 6.26084 22.5409L6.2608 13.5652Z"
        fill="#0052B4"
      />
      <path
        d="M9.39136 13.5653V23.7148C10.2313 23.901 11.1041 24 12 24C18.0969 24 23.1315 19.453 23.8985 13.5652H9.39136V13.5653Z"
        fill="#0052B4"
      />
    </g>
  </svg>
);

IconLangSe.propTypes = {
  className: PropTypes.string,
};

IconLangSe.defaultProps = {
  className: null,
};
