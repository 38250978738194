import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderMerkurBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="66"
    height="24"
    viewBox="0 0 66 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_443_2)">
      <path
        d="M27.2495 4.50845H29.0529L30.4049 7.21353L31.796 4.50845H33.5328L33.8584 10.4947H32.241L32.0772 6.80126L30.8848 9.05602H29.8584L28.6956 6.83615L28.5328 10.4915H26.9228L27.2495 4.50845Z"
        fill="white"
      />
      <path
        d="M35.0032 4.50845H39.5973V5.99577H36.6723V6.7537H39.2326V8.15433H36.6723V9.0074H39.6839V10.4947H35.0032V4.50845Z"
        fill="white"
      />
      <path
        d="M40.7484 4.50845H43.3679C44.9123 4.50845 45.814 5.40063 45.814 6.58985C45.8225 6.94716 45.7365 7.30039 45.5646 7.61375C45.3927 7.9271 45.1411 8.18945 44.8351 8.3742L46.1786 10.4947H44.2505L43.2717 8.75792C43.1564 8.75792 43.0222 8.75793 42.8879 8.74841C42.7175 8.73932 42.5477 8.72026 42.3795 8.69133V10.4947H40.7484V4.50845ZM44.0677 6.62896C44.0677 6.1871 43.7611 5.90909 43.2431 5.90909H42.3795V7.30021C42.6652 7.34383 42.9541 7.36293 43.2431 7.35729C43.7706 7.35729 44.0677 7.06025 44.0677 6.62896Z"
        fill="white"
      />
      <path
        d="M53.2294 7.463V4.50845H54.9461V7.52114C54.9461 8.58562 55.2918 9.02748 56.0402 9.02748C56.7886 9.02748 57.1617 8.58562 57.1617 7.52114V4.50845H58.8795V7.463C58.8795 9.3816 57.9101 10.6004 56.0497 10.6004C54.1311 10.6004 53.2294 9.42072 53.2294 7.463Z"
        fill="white"
      />
      <path
        d="M60.0486 4.50845H62.6681C64.2125 4.50845 65.1142 5.40063 65.1142 6.58985C65.1228 6.94716 65.0368 7.30039 64.8649 7.61375C64.693 7.9271 64.4413 8.18945 64.1353 8.3742L65.4789 10.4947H63.5507L62.5719 8.75792C62.4567 8.75792 62.3224 8.75793 62.1882 8.74841C62.0177 8.73932 61.8479 8.72026 61.6797 8.69133V10.4947H60.0486V4.50845ZM63.3679 6.62896C63.3679 6.1871 63.0613 5.90909 62.5433 5.90909H61.6797V7.30021C61.9654 7.34383 62.2544 7.36293 62.5433 7.35729C63.0708 7.35729 63.3679 7.06025 63.3679 6.62896Z"
        fill="white"
      />
      <path
        d="M47.0169 4.50845H48.7241L48.7315 6.65962C48.8908 6.67832 49.051 6.68785 49.2114 6.68816C49.9419 6.68816 50.4799 5.80761 50.5751 4.50951H52.2664C52.2199 5.89006 51.797 6.89217 50.9672 7.55391L52.7178 10.4947H50.6554L49.2812 8.21141C49.0943 8.21614 48.9074 8.2066 48.722 8.18287V10.4947H47.0169V4.50845Z"
        fill="white"
      />
      <path d="M65.4873 12.3731H26.9228V13.1226H65.4873V12.3731Z" fill="#009DE0" />
      <path
        d="M12.5793 18.2759C16.0267 18.2759 18.8214 15.4812 18.8214 12.0338C18.8214 8.58642 16.0267 5.79175 12.5793 5.79175C9.13188 5.79175 6.33721 8.58642 6.33721 12.0338C6.33721 15.4812 9.13188 18.2759 12.5793 18.2759Z"
        fill="#D5B40F"
      />
      <path
        d="M19.1638 12.8732L24.3753 9.53383L18.6903 9.19662L22.2389 4.56448L16.7421 7.00529L17.9704 2.06131L14.8383 4.91543L14.482 0.350952L11.6607 5.28541L8.71247 0.918605L9.15751 6.31818L4.40063 2.82981L6.53488 7.7981L2.04334 6.82875L6.11099 10.74L0.402748 11.8584L5.3203 13.5592L1.09197 16.0856L6.71353 15.3975L3.21353 19.9218L8.54123 17.371L7.45877 23.4028L10.8414 19.3087L12.1564 23.2886L13.5613 19.296L17.0063 23.6512L16.3848 17.3425L21.0793 20.3816L18.9154 15.0243L23.9493 14.8964L19.1638 12.8732ZM12.5793 5.98732C14.1825 5.98927 15.7195 6.62709 16.853 7.76084C17.9866 8.89458 18.6241 10.4317 18.6258 12.0349V12.0507C18.6218 13.245 18.2641 14.4113 17.5978 15.4024C16.9315 16.3935 15.9864 17.165 14.882 17.6194C13.7776 18.0738 12.5632 18.1908 11.3924 17.9557C10.2215 17.7205 9.14653 17.1436 8.30324 16.298C7.45994 15.4523 6.88611 14.3758 6.65419 13.2042C6.42227 12.0327 6.54266 10.8187 7.00017 9.71555C7.45767 8.61239 8.23178 7.66951 9.22474 7.00596C10.2177 6.34242 11.385 5.98795 12.5793 5.98732Z"
        fill="url(#paint0_radial_443_2)"
      />
      <path
        d="M12.593 6.24524C13.7381 6.24545 14.8573 6.58518 15.8093 7.22148C16.7613 7.85778 17.5032 8.76208 17.9412 9.82001C18.3792 10.8779 18.4937 12.042 18.2702 13.165C18.0467 14.288 17.4952 15.3196 16.6855 16.1291C15.8757 16.9387 14.8441 17.49 13.7211 17.7134C12.598 17.9367 11.434 17.8219 10.3761 17.3837C9.31827 16.9455 8.41411 16.2034 7.77798 15.2513C7.14186 14.2992 6.80233 13.1799 6.80233 12.0349C6.80401 10.4997 7.41467 9.0279 8.5003 7.94247C9.58593 6.85703 11.0578 6.24664 12.593 6.24524Z"
        fill="url(#paint1_radial_443_2)"
      />
      <path
        d="M12.593 6.24524C13.7381 6.24545 14.8573 6.58518 15.8093 7.22148C16.7613 7.85778 17.5032 8.76208 17.9412 9.82001C18.3792 10.8779 18.4937 12.042 18.2702 13.165C18.0467 14.288 17.4952 15.3196 16.6855 16.1291C15.8757 16.9387 14.8441 17.49 13.7211 17.7134C12.598 17.9367 11.434 17.8219 10.3761 17.3837C9.31827 16.9455 8.41411 16.2034 7.77798 15.2513C7.14186 14.2992 6.80233 13.1799 6.80233 12.0349C6.80401 10.4997 7.41467 9.0279 8.5003 7.94247C9.58593 6.85703 11.0578 6.24664 12.593 6.24524Z"
        fill="url(#paint2_radial_443_2)"
      />
      <path
        d="M12.593 6.24524C13.7381 6.24545 14.8573 6.58518 15.8093 7.22148C16.7613 7.85778 17.5032 8.76208 17.9412 9.82001C18.3792 10.8779 18.4937 12.042 18.2702 13.165C18.0467 14.288 17.4952 15.3196 16.6855 16.1291C15.8757 16.9387 14.8441 17.49 13.7211 17.7134C12.598 17.9367 11.434 17.8219 10.3761 17.3837C9.31827 16.9455 8.41411 16.2034 7.77798 15.2513C7.14186 14.2992 6.80233 13.1799 6.80233 12.0349C6.80401 10.4997 7.41467 9.0279 8.5003 7.94247C9.58593 6.85703 11.0578 6.24664 12.593 6.24524Z"
        fill="url(#paint3_radial_443_2)"
      />
      <path
        d="M19.1638 12.8732L24.3753 9.53383L18.6903 9.19662L22.2389 4.56448L16.7421 7.00529L17.9704 2.06131L14.8383 4.91543L14.482 0.350952L11.6607 5.28541L8.71247 0.918605L9.15751 6.31818L4.40063 2.82981L6.53488 7.7981L2.04334 6.82875L6.11099 10.74L0.402748 11.8584L5.3203 13.5592L1.09197 16.0856L6.71353 15.3975L3.21353 19.9218L8.54123 17.371L7.45877 23.4028L10.8414 19.3087L12.1564 23.2886L13.5613 19.296L17.0063 23.6512L16.3848 17.3425L21.0793 20.3816L18.9154 15.0243L23.9493 14.8964L19.1638 12.8732ZM12.5793 5.98732C14.1825 5.98927 15.7195 6.62709 16.853 7.76084C17.9866 8.89458 18.6241 10.4317 18.6258 12.0349V12.0507C18.6218 13.245 18.2641 14.4113 17.5978 15.4024C16.9315 16.3935 15.9864 17.165 14.882 17.6194C13.7776 18.0738 12.5632 18.1908 11.3924 17.9557C10.2215 17.7205 9.14653 17.1436 8.30324 16.298C7.45994 15.4523 6.88611 14.3758 6.65419 13.2042C6.42227 12.0327 6.54266 10.8187 7.00017 9.71555C7.45767 8.61239 8.23178 7.66951 9.22474 7.00596C10.2177 6.34242 11.385 5.98795 12.5793 5.98732Z"
        fill="url(#paint4_radial_443_2)"
      />
      <path
        d="M16.8647 13.8658C16.5493 13.5646 16.3213 13.1836 16.2051 12.7632C16.1755 12.667 16.1121 12.6829 16.1121 12.6829C16.0433 12.6966 16.0719 12.7727 16.0719 12.7727C16.1149 12.9639 16.1714 13.1519 16.241 13.3351C12.2622 14.1808 8.68605 13.2505 8.68605 13.2505C8.79234 13.0776 8.88426 12.8962 8.96089 12.7082C8.98414 12.6216 8.93975 12.6025 8.93975 12.6025C8.87315 12.5793 8.85307 12.6522 8.85307 12.6522C8.4778 13.3721 7.95137 13.6364 7.95137 13.6364C7.95137 13.6364 7.84567 13.6956 7.88266 13.7484C7.88266 13.7484 7.90909 13.7918 8.037 13.7431C8.21499 13.645 8.38406 13.5314 8.54228 13.4038C8.84335 13.9201 9.19535 14.4049 9.59302 14.851C10.0052 15.3156 10.5143 15.6841 11.0846 15.9302C11.5941 16.1998 12.2896 16.2093 12.2896 16.2093C15.0666 16.3531 16.3256 13.5391 16.3256 13.5391C16.4453 13.7068 16.5925 13.853 16.7611 13.9715C16.9292 14.0169 16.8647 13.8658 16.8647 13.8658ZM12.4133 16.0053C10.0962 15.9789 8.81924 13.4683 8.81924 13.4683L9 13.5063C10.2792 13.8032 11.5912 13.9343 12.9038 13.8964C12.3996 13.9154 14.3436 13.9123 15.8911 13.593L16.0962 13.5529C16.0962 13.5529 14.8742 16.0349 12.4133 16.0053Z"
        fill="#20201E"
      />
      <path
        d="M11.5592 11.9831C11.6036 11.9831 12.4049 11.9947 12.4049 11.9947C12.63 11.9598 12.5243 12.2315 12.5243 12.2315C12.4461 12.3288 12.5391 12.3584 12.5391 12.3584C12.6702 12.3584 12.7114 12.0951 12.7114 12.0951C12.7452 11.8087 12.5074 11.8531 12.5074 11.8531L11.6279 11.8393C10.7696 11.7674 10.8214 10.907 10.8214 10.907C10.8214 10.3446 11.3594 9.85518 11.3594 9.85518C11.7822 9.46089 12.5507 9.23996 12.5507 9.23996C12.6564 9.19239 12.6195 9.16702 12.6195 9.16702C12.5962 9.08668 12.1776 9.23468 12.1776 9.23468C12.1776 9.23468 10.722 9.65751 10.6279 10.8753C10.6279 10.8753 10.5529 11.8848 11.5613 11.9884"
        fill="#20201E"
      />
      <path
        d="M7.76004 10.0666C7.76004 10.0666 8.25793 9.05921 9.09937 9.27803C9.94081 9.49684 9.93658 10.5222 9.93658 10.5222C9.93658 10.5222 10.0835 9.3108 9.14165 9.06978C8.08563 8.80022 7.76004 10.0666 7.76004 10.0666Z"
        fill="#20201E"
      />
      <path
        d="M15.4947 9.40803C16.7146 9.50423 16.7146 10.8467 16.7146 10.8467C16.7146 10.8467 16.5233 9.66702 15.4947 9.57822C14.7833 9.51374 14.389 10.3996 14.389 10.3996C14.389 10.3996 14.611 9.33509 15.4947 9.40803Z"
        fill="#20201E"
      />
      <path
        d="M8.81184 10.0592C8.21671 10.0655 7.93658 11.8467 8.63531 11.9112C9.33404 11.9757 9.54969 10.0021 8.81184 10.0592Z"
        fill="#20201E"
      />
      <path
        d="M8.64694 10.3203C8.58808 10.3621 8.53937 10.4165 8.50442 10.4796C8.46947 10.5428 8.44918 10.613 8.44503 10.685C8.42508 10.799 8.41132 10.9141 8.40381 11.0296C8.42307 11.0433 8.44484 11.053 8.46788 11.0583C8.49092 11.0636 8.51476 11.0642 8.53806 11.0603C8.59435 11.0444 8.64589 11.015 8.68816 10.9746C8.66852 10.8761 8.67471 10.7741 8.70613 10.6787C8.73049 10.5621 8.76589 10.4481 8.81184 10.3383C8.78795 10.3265 8.7622 10.319 8.73573 10.3161C8.70609 10.3131 8.67617 10.3145 8.64694 10.3203Z"
        fill="white"
      />
      <path
        d="M15.9112 11.0296C15.8985 10.7897 15.8414 10.5349 15.7082 10.4355C15.6727 10.4069 15.6345 10.3817 15.5941 10.3605C15.5562 10.3494 15.5177 10.3406 15.4789 10.3341C14.87 10.296 14.5867 12.2241 15.2008 12.2939C15.6448 12.3446 15.9493 11.5856 15.9112 11.0296Z"
        fill="#20201E"
      />
      <path
        d="M15.3277 10.5708C15.2688 10.6126 15.2201 10.667 15.1852 10.7302C15.1502 10.7933 15.1299 10.8635 15.1258 10.9355C15.1059 11.0492 15.0921 11.1639 15.0846 11.2791C15.1036 11.2931 15.1254 11.3032 15.1484 11.3087C15.1715 11.3141 15.1955 11.3148 15.2188 11.3108C15.2751 11.295 15.3266 11.2655 15.3689 11.2252C15.3493 11.1266 15.3555 11.0246 15.3869 10.9292C15.4112 10.8126 15.4466 10.6986 15.4926 10.5888C15.4687 10.577 15.443 10.5695 15.4165 10.5666C15.3868 10.5636 15.3569 10.5651 15.3277 10.5708Z"
        fill="white"
      />
      <path
        d="M19.1638 12.8732L24.3753 9.53383L18.6903 9.19662L22.2389 4.56448L16.7421 7.00529L17.9704 2.06131L14.8383 4.91543L14.482 0.350952L11.6607 5.28541L8.71247 0.918605L9.15751 6.31818L4.40063 2.82981L6.53488 7.7981L2.04334 6.82875L6.11099 10.74L0.402748 11.8584L5.3203 13.5592L1.09197 16.0856L6.71353 15.3975L3.21353 19.9218L8.54123 17.371L7.45877 23.4028L10.8414 19.3087L12.1564 23.2886L13.5613 19.296L17.0063 23.6512L16.3848 17.3425L21.0793 20.3816L18.9154 15.0243L23.9493 14.8964L19.1638 12.8732ZM12.5793 5.98732C14.1825 5.98927 15.7195 6.62709 16.853 7.76084C17.9866 8.89458 18.6241 10.4317 18.6258 12.0349V12.0507C18.6218 13.245 18.2641 14.4113 17.5978 15.4024C16.9315 16.3935 15.9864 17.165 14.882 17.6194C13.7776 18.0738 12.5632 18.1908 11.3924 17.9557C10.2215 17.7205 9.14653 17.1436 8.30324 16.298C7.45994 15.4523 6.88611 14.3758 6.65419 13.2042C6.42227 12.0327 6.54266 10.8187 7.00017 9.71555C7.45767 8.61239 8.23178 7.66951 9.22474 7.00596C10.2177 6.34242 11.385 5.98795 12.5793 5.98732Z"
        fill="url(#paint5_radial_443_2)"
      />
      <path
        d="M2.38478 7.01056L6.7167 7.94396L6.36152 7.65115L1.7019 6.64798L2.38478 7.01056Z"
        fill="url(#paint6_linear_443_2)"
      />
      <path
        d="M14.4027 0.701903L14.7442 5.13319L14.9218 4.68605L14.5613 0L14.4027 0.701903Z"
        fill="url(#paint7_linear_443_2)"
      />
      <path
        d="M11.6681 5.48415L11.6533 5.08458L8.574 0.524323L8.85095 1.31291L11.6681 5.48415Z"
        fill="url(#paint8_linear_443_2)"
      />
      <path
        d="M14.7442 5.13319L17.7791 2.37315L18.1512 1.74841L14.9218 4.68605L14.7442 5.13319Z"
        fill="url(#paint9_linear_443_2)"
      />
      <path
        d="M4.65539 3.14164L4.15539 2.51056L6.36152 7.65115L6.7167 7.94396L4.65539 3.14164Z"
        fill="url(#paint10_linear_443_2)"
      />
      <path
        d="M9.28224 6.54016L9.03277 6.09513L4.15539 2.51056L4.65539 3.14164L9.28224 6.54016Z"
        fill="url(#paint11_linear_443_2)"
      />
      <path
        d="M9.28224 6.54018L8.85095 1.31291L8.574 0.524323L9.03277 6.09515L9.28224 6.54018Z"
        fill="url(#paint12_linear_443_2)"
      />
      <path
        d="M14.4028 0.701903L14.5613 0L11.6533 5.08457L11.6681 5.48414L14.4028 0.701903Z"
        fill="url(#paint13_linear_443_2)"
      />
      <path
        d="M18.9376 12.8922L19.389 12.8541L24.7061 9.44714L24.0444 9.62051L18.9376 12.8922Z"
        fill="url(#paint14_linear_443_2)"
      />
      <path
        d="M17.7791 2.37315L16.5856 7.19345L16.8943 6.82558L18.1512 1.74841L17.7791 2.37315Z"
        fill="url(#paint15_linear_443_2)"
      />
      <path
        d="M24.0444 9.62049L24.7061 9.44713L18.8954 9.10675L18.4852 9.29385L24.0444 9.62049Z"
        fill="url(#paint16_linear_443_2)"
      />
      <path
        d="M24.4355 14.9905L23.4524 14.8034L18.7548 14.9228L19.0655 15.1268L24.4355 14.9905Z"
        fill="url(#paint17_linear_443_2)"
      />
      <path
        d="M19.389 12.8541L18.9376 12.8922L23.4524 14.8034L24.4355 14.9905L19.389 12.8541Z"
        fill="url(#paint18_linear_443_2)"
      />
      <path
        d="M18.4852 9.29386L18.8954 9.10676L22.5751 4.30338L21.9028 4.83297L18.4852 9.29386Z"
        fill="url(#paint19_linear_443_2)"
      />
      <path
        d="M21.9028 4.83297L22.5751 4.30338L16.8943 6.82557L16.5856 7.19344L21.9028 4.83297Z"
        fill="url(#paint20_linear_443_2)"
      />
      <path
        d="M8.68182 17.1871L8.40063 17.556L7.2833 23.7812L7.63425 23.0243L8.68182 17.1871Z"
        fill="url(#paint21_linear_443_2)"
      />
      <path
        d="M18.7548 14.9228L20.851 20.111L21.297 20.6522L19.0655 15.1268L18.7548 14.9228Z"
        fill="url(#paint22_linear_443_2)"
      />
      <path
        d="M10.8784 19.093L7.63425 23.0243L7.2833 23.7812L10.7981 19.5233L10.8784 19.093Z"
        fill="url(#paint23_linear_443_2)"
      />
      <path
        d="M12.1586 22.962L12.1522 23.6152L13.5973 19.5127L13.5243 19.0804L12.1586 22.962Z"
        fill="url(#paint24_linear_443_2)"
      />
      <path
        d="M10.8784 19.093L10.7981 19.5233L12.1522 23.6152L12.1586 22.9619L10.8784 19.093Z"
        fill="url(#paint25_linear_443_2)"
      />
      <path
        d="M13.5243 19.0804L13.5973 19.5127L17.1459 24V23.999L16.8647 23.3023L13.5243 19.0804Z"
        fill="url(#paint26_linear_443_2)"
      />
      <path
        d="M16.2526 17.1353L16.5063 17.5507L21.297 20.6522L20.851 20.111L16.2526 17.1353Z"
        fill="url(#paint27_linear_443_2)"
      />
      <path
        d="M16.8647 23.3023L17.1459 23.999L16.5063 17.5507L16.2526 17.1353L16.8647 23.3023Z"
        fill="url(#paint28_linear_443_2)"
      />
      <path
        d="M6.9482 15.2611L1.57294 15.9186L0.604652 16.2505L6.47146 15.5328L6.9482 15.2611Z"
        fill="url(#paint29_linear_443_2)"
      />
      <path
        d="M5.57188 13.5328L0.810782 11.8858L0 11.8288L5.07294 13.5835L5.57188 13.5328Z"
        fill="url(#paint30_linear_443_2)"
      />
      <path
        d="M6.33087 10.8034L5.89112 10.6744L0 11.8287L0.810782 11.8858L6.33087 10.8034Z"
        fill="url(#paint31_linear_443_2)"
      />
      <path
        d="M3.56871 19.6343L2.8573 20.2093L8.40064 17.556L8.68182 17.1871L3.56871 19.6343Z"
        fill="url(#paint32_linear_443_2)"
      />
      <path
        d="M5.57188 13.5328L5.07294 13.5835L0.604652 16.2505L1.57294 15.9186L5.57188 13.5328Z"
        fill="url(#paint33_linear_443_2)"
      />
      <path
        d="M3.56871 19.6343L6.94821 15.2611L6.47146 15.5328L2.8573 20.2093L3.56871 19.6343Z"
        fill="url(#paint34_linear_443_2)"
      />
      <path
        d="M6.33087 10.8034L2.38478 7.01056L1.7019 6.64798L5.89112 10.6744L6.33087 10.8034Z"
        fill="url(#paint35_linear_443_2)"
      />
      <path
        d="M29.7125 20.3721C29.1684 20.3841 28.6293 20.2668 28.1395 20.0296C27.7054 19.8166 27.3414 19.4834 27.0909 19.0698C26.8363 18.6342 26.7075 18.1365 26.7188 17.6321C26.7092 17.1298 26.8474 16.6358 27.1163 16.2114C27.3872 15.7966 27.7697 15.4669 28.2199 15.26C28.7189 15.0285 29.2639 14.9129 29.814 14.9218C30.287 14.9103 30.7574 14.9952 31.1966 15.1712C31.5715 15.3292 31.9034 15.5742 32.1649 15.8858L31.7125 16.3129C31.4932 16.0801 31.2244 15.8994 30.926 15.7844C30.5694 15.6503 30.1905 15.5858 29.8097 15.5941C29.3723 15.5822 28.9381 15.6719 28.5412 15.8562C28.2051 16.0138 27.9228 16.2667 27.7294 16.5835C27.5414 16.9006 27.4448 17.2635 27.4503 17.6321C27.4454 18.0028 27.5404 18.3679 27.7252 18.6892C27.911 19.0046 28.1822 19.2611 28.5074 19.4292C28.8791 19.6175 29.2917 19.7105 29.7082 19.6998C30.0518 19.7043 30.3936 19.6499 30.7188 19.5391C31.0055 19.4439 31.2612 19.273 31.4588 19.0444C31.6532 18.8069 31.7724 18.5169 31.8013 18.2114H30.0211V17.5518H32.5581V17.8309C32.5695 18.3012 32.4494 18.7654 32.2114 19.1712C31.9743 19.5571 31.6287 19.8645 31.2178 20.055C30.7464 20.2731 30.2318 20.3816 29.7125 20.3721Z"
        fill="white"
      />
      <path
        d="M33.7125 20.2791L36.3087 15.0021H37.1713L39.7336 20.2791H38.9218L38.2114 18.7822H35.2178L34.5159 20.2791H33.7125ZM35.5391 18.1057H37.8985L36.723 15.6617L35.5391 18.1057Z"
        fill="white"
      />
      <path
        d="M41.129 20.2791L41.518 15.0021H42.2199L44.2579 18.3087L46.3002 15.0021H47.0021L47.3911 20.2791H46.6512L46.3552 16.1776L44.5581 19.0021H43.9493L42.1522 16.2791L41.8605 20.2791H41.129Z"
        fill="white"
      />
      <path d="M49.5772 20.2791V15.0021H50.3171V20.2791H49.5772Z" fill="white" />
      <path
        d="M52.7061 20.2791V15.0021H53.4461L57.0994 19.222V15.0021H57.8182V20.2791H57.0825L53.4292 16.0592V20.2791H52.7061Z"
        fill="white"
      />
      <path
        d="M62.6343 20.3721C62.0902 20.3841 61.5511 20.2668 61.0613 20.0296C60.6269 19.8169 60.2629 19.4837 60.0127 19.0698C59.7581 18.6342 59.6293 18.1365 59.6406 17.6321C59.6322 17.1294 59.7719 16.6353 60.0423 16.2114C60.3129 15.7964 60.6955 15.4666 61.1459 15.26C61.6449 15.0285 62.1899 14.9129 62.74 14.9218C63.213 14.9103 63.6834 14.9952 64.1226 15.1712C64.4974 15.3295 64.8292 15.5744 65.0909 15.8858L64.6343 16.3171C64.4146 16.0848 64.1459 15.9042 63.8478 15.7886C63.4912 15.6546 63.1123 15.59 62.7315 15.5983C62.2958 15.5857 61.8632 15.674 61.4672 15.8562C61.1311 16.0138 60.8488 16.2667 60.6554 16.5835C60.4674 16.9006 60.3708 17.2635 60.3763 17.6321C60.3709 18.0028 60.4659 18.3681 60.6512 18.6892C60.837 19.0046 61.1082 19.2611 61.4334 19.4292C61.805 19.6176 62.2177 19.7106 62.6343 19.6998C62.9778 19.7042 63.3196 19.6498 63.6448 19.5391C63.9315 19.4439 64.1872 19.273 64.3848 19.0444C64.5792 18.8069 64.6984 18.5169 64.7273 18.2114H62.9387V17.5518H65.4841V17.8309C65.4951 18.3012 65.375 18.7652 65.1374 19.1712C64.9003 19.5571 64.5547 19.8645 64.1438 20.055C63.6711 20.2738 63.155 20.3822 62.6343 20.3721Z"
        fill="white"
      />
    </g>
  </svg>

);

IconProviderMerkurBig.propTypes = {
  className: PropTypes.string,
};

IconProviderMerkurBig.defaultProps = {
  className: '',
};
