import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconMifinity = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="42"
    viewBox="0 0 80 42"
    fill="none"
  >
    <path
      d="M59.4109 10.5918C59.4761 10.6798 59.5362 10.7714 59.5909 10.8662C59.4459 10.2598 59.2231 9.6742 58.9279 9.12396C56.4952 4.7001 49.736 6.02421 42.9928 11.0346C41.2159 12.3518 39.6202 13.8937 38.2461 15.6211C39.0848 16.0726 39.8445 16.71 40.5778 17.253C41.0267 17.578 41.5026 17.8646 42.0004 18.1096C42.4278 17.6189 42.8713 17.134 43.3368 16.6578C49.3437 10.5178 56.9885 7.39333 59.4109 10.5918Z"
      fill="url(#paint0_linear_8822_28519)"
    />
    <path
      d="M59.8343 14.342L59.9602 14.3057C60.1753 12.9221 60.089 11.7243 59.5898 10.8662C59.8556 12.0048 59.9381 13.1779 59.8343 14.342Z"
      fill="url(#paint1_linear_8822_28519)"
    />
    <g style={{ 'mix-blend-mode': 'soft-light' }}>
      <path
        d="M59.5765 10.8446C59.5213 10.7498 59.4607 10.6582 59.395 10.5702L59.3672 10.5397C59.4214 10.7053 59.4697 10.878 59.5136 11.0523C59.5868 11.1767 59.6509 11.3063 59.7053 11.4399C59.6716 11.2366 59.6263 11.0392 59.5765 10.8446Z"
        fill="#E7E6E6"
      />
      <path
        d="M42.9254 11.3092C49.6466 6.26106 56.4 4.8992 58.8516 9.30854C59.0657 9.69831 59.2411 10.1077 59.3756 10.531L59.4034 10.5615C59.4687 10.6495 59.5288 10.7411 59.5835 10.8359C59.4366 10.2313 59.2112 9.64807 58.9131 9.10092C56.4629 4.69158 49.708 6.05199 42.9883 11.1016C40.5217 12.9371 38.422 15.2129 36.7969 17.8122L36.835 17.8615C38.4468 15.3284 40.5106 13.108 42.9254 11.3092Z"
        fill="#E7E6E6"
      />
    </g>
    <path
      d="M41.0047 15.6676C40.3812 15.1464 39.7503 14.6513 39.1678 14.0908C37.9973 15.4613 36.7051 16.7247 35.3066 17.8657C29.2909 22.8021 22.8097 24.4296 20.8308 21.5143C20.8001 21.4693 20.7723 21.4214 20.7445 21.3691C20.3692 20.8174 20.0869 20.2089 19.9087 19.5673C19.5798 18.337 19.6016 17.0402 19.9717 15.8215C19.0554 17.6639 18.8007 19.4875 19.3789 21.0482C20.9187 25.1672 27.7379 25.8612 34.6143 22.5974C37.8183 21.0773 40.469 18.9677 42.2254 16.726C41.8185 16.3717 41.4248 16.0131 41.0047 15.6676Z"
      fill="url(#paint2_linear_8822_28519)"
    />
    <path
      d="M26.4262 9.51025C26.1042 9.53574 25.7864 9.5986 25.4792 9.69754L25.4982 9.74255L25.4426 9.71642C24.115 10.6543 24.0711 10.6602 25.4967 9.74255C24.6596 10.259 23.8753 10.8551 23.1548 11.5226C21.9812 12.6494 20.985 13.945 20.1997 15.3657C20.1177 15.5109 20.0372 15.6677 19.9626 15.8201C19.5925 17.0389 19.5707 18.3356 19.8996 19.5659C20.0786 20.2098 20.3624 20.8204 20.7398 21.3735C20.2265 20.4696 19.9506 19.452 19.9377 18.4146C19.9626 15.8201 22.1771 12.3893 23.6788 11.1073C24.5292 10.3814 25.3284 10.1041 26.0354 10.0431C26.1339 9.84456 26.2661 9.66432 26.4262 9.51025Z"
      fill="url(#paint3_linear_8822_28519)"
    />
    <path
      style={{ 'mix-blend-mode': 'multiply' }}
      opacity="0.71"
      d="M32.1641 20.1611C34.9817 18.4189 37.5197 16.0335 38.3115 15.0171L38.7682 14.522C39.1253 14.1881 39.5688 13.5057 39.9201 13.1849C40.8174 12.7232 42.4874 11.5646 44.2365 10.4118C44.9888 9.91671 45.7456 9.43033 46.173 9.20093L48.2221 12.5707C46.9414 13.3954 43.6613 16.1409 42.5035 17.1194C42.1376 17.4272 42.5738 16.7507 42.2313 17.0701L39.7781 19.2479C38.0915 20.6649 36.2178 21.847 34.2089 22.7614L32.1641 20.1611Z"
      fill="url(#paint4_linear_8822_28519)"
    />
    <path
      d="M58.4687 8.45469C63.4744 17.8483 52.0841 22.2605 46.1079 15.7765C43.5904 12.639 43.3123 11.0724 41.7403 9.4739C35.0264 3 25.3471 7.02314 20.3164 15.1362C20.9839 14.342 25.2373 9.53053 31.4199 10.2971C36.1988 10.8895 36.9819 16.9583 44.0514 20.5401C52.0738 24.6053 66.4984 19.1027 58.4687 8.45469Z"
      fill="url(#paint5_linear_8822_28519)"
    />
    <g style={{ 'mix-blend-mode': 'darken' }} opacity="0.57">
      <path
        d="M44.2643 20.2993C37.1933 16.7175 36.4117 10.6472 31.6328 10.0548C25.7342 9.31727 21.5934 13.6729 20.6406 14.7575C20.6406 14.7575 20.6406 14.7691 20.6406 14.7749C21.895 13.4058 25.882 9.62216 31.4162 10.3089C36.1951 10.9013 36.9782 16.9716 44.0477 20.5533C49.0154 23.0694 56.4363 21.9152 59.4617 18.1447C56.3163 21.7134 49.1194 22.7573 44.2643 20.2993Z"
        fill="#1887B2"
      />
    </g>
    <g style={{ 'mix-blend-mode': 'soft-light' }}>
      <path
        d="M58.7149 8.76262C58.6329 8.65082 58.5538 8.53903 58.469 8.42578C63.3708 17.6263 52.5469 22.0444 46.4844 16.1324C52.4752 22.2244 63.4074 17.908 58.7149 8.76262Z"
        fill="white"
      />
    </g>
    <path
      d="M25.1787 28.0463C25.1385 28.03 25.0969 28.0174 25.0543 28.0086C24.7177 27.9229 23.8835 27.8954 23.6142 28.1131L23.5994 28.1262C23.5545 28.1671 23.5182 28.2165 23.4926 28.2714C22.7104 29.3013 21.9872 30.3739 21.3264 31.4844L19.311 28.2786L19.3036 28.2627C19.2769 28.2073 19.2389 28.1579 19.1923 28.1175L19.1791 28.1059C18.9449 27.9128 18.2439 27.9186 17.8619 27.9839C17.7713 27.9934 17.6822 28.0144 17.597 28.0463C17.5228 28.0774 17.4592 28.1292 17.4142 28.1955C17.3693 28.2618 17.3448 28.3396 17.3438 28.4195V35.1867C17.3438 35.3217 17.4067 35.5583 17.8283 35.6222C17.9684 35.641 18.1098 35.6497 18.2513 35.6484C18.544 35.6484 19.1601 35.6484 19.1601 35.1867V31.2884C19.2918 31.4945 19.5422 31.8982 19.8189 32.4049C20.2033 33.1105 20.4613 33.5717 20.593 33.7885L20.6707 33.9337C20.7398 34.0377 20.8276 34.1283 20.9297 34.2008C21.15 34.3301 21.412 34.3707 21.6615 34.3141C21.7233 34.2976 21.781 34.269 21.8313 34.2299C21.9433 34.1542 22.0421 34.061 22.1241 33.954L22.2998 33.6477C22.3847 33.5015 22.5837 33.1356 22.8969 32.5501C23.2101 31.9645 23.4482 31.5381 23.6112 31.271C23.6112 31.271 23.6112 32.0042 23.6112 32.0448V35.1954C23.6112 35.6571 24.2245 35.6571 24.5187 35.6571C24.8128 35.6571 25.4262 35.6571 25.4262 35.1954V28.4253C25.4268 28.3451 25.4036 28.2664 25.3596 28.1991C25.3156 28.1318 25.2528 28.0787 25.1787 28.0463Z"
      fill="#2D9DD3"
    />
    <path
      d="M27.3838 30.738C26.8056 30.738 26.7266 30.8411 26.7266 30.9529V35.2737C26.7266 35.384 26.8056 35.4871 27.3838 35.4871C27.9619 35.4871 28.0424 35.384 28.0424 35.2737V30.95C28.0424 30.8411 27.9575 30.738 27.3838 30.738Z"
      fill="#2D9DD3"
    />
    <path
      d="M27.384 35.7412C27.0913 35.7412 26.4766 35.7412 26.4766 35.2795V30.95C26.4766 30.4883 27.0898 30.4883 27.384 30.4883C27.6782 30.4883 28.293 30.4883 28.293 30.95V35.2708C28.293 35.7412 27.6797 35.7412 27.384 35.7412ZM26.9757 35.2141C27.2467 35.2567 27.5228 35.2567 27.7939 35.2141V31.0182C27.5228 30.9756 27.2467 30.9756 26.9757 31.0182V35.2141Z"
      fill="#2D9DD3"
    />
    <path
      d="M34.0218 28.2932H30.2265C29.8284 28.3062 29.625 28.3425 29.625 28.5051V35.2738C29.625 35.3841 29.704 35.4872 30.2792 35.4872C30.8544 35.4872 30.9349 35.3841 30.9349 35.2738V32.4775H33.6471C33.8564 32.4775 33.9911 32.2423 33.9911 31.8778C33.9911 31.5134 33.8564 31.2768 33.6471 31.2768H30.9349V29.4982H34.0218C34.2311 29.4982 34.3658 29.263 34.3658 28.8986C34.3658 28.5342 34.2311 28.2932 34.0218 28.2932Z"
      fill="#2D9DD3"
    />
    <path
      d="M30.2834 35.7413C29.9907 35.7413 29.3789 35.7413 29.3789 35.2796V28.5051C29.3789 28.0696 29.8868 28.0565 30.2219 28.0464H34.0275C34.3012 28.0464 34.6218 28.2685 34.6218 28.8957C34.6218 29.5229 34.3012 29.7436 34.0275 29.7436H31.1865V31.03H33.6499C33.9236 31.03 34.2441 31.2521 34.2441 31.8793C34.2441 32.5065 33.9236 32.7272 33.6499 32.7272H31.1865V35.2752C31.1865 35.7413 30.5762 35.7413 30.2834 35.7413ZM29.878 35.2143C30.1461 35.2568 30.4193 35.2568 30.6874 35.2143V32.2307H33.6499C33.6762 32.2219 33.745 32.1174 33.745 31.8779C33.745 31.6383 33.6762 31.5338 33.6513 31.5236H30.6874V29.2471H34.0246C34.0407 29.2471 34.1197 29.1527 34.1197 28.8942C34.1197 28.6358 34.0407 28.5458 34.026 28.54H30.2293C30.1115 28.5408 29.994 28.5515 29.878 28.5719V35.2143Z"
      fill="#2D9DD3"
    />
    <path
      d="M43.9432 28.2932C43.3666 28.2932 43.2875 28.405 43.2875 28.5139V32.9827L40.1816 28.4195L40.1669 28.4021C40.0206 28.2569 39.2097 28.2815 39.0253 28.3585C38.9908 28.3681 38.9603 28.3883 38.9382 28.4162C38.9161 28.4441 38.9035 28.4784 38.9023 28.5139V35.2651C38.9023 35.3362 38.9023 35.4857 39.5566 35.4857C40.2109 35.4857 40.2123 35.3406 40.2123 35.2651V30.8049L43.3197 35.358C43.3929 35.4582 43.6622 35.493 43.9242 35.493C44.097 35.4971 44.2696 35.4796 44.438 35.4407C44.5697 35.4001 44.5975 35.3246 44.5975 35.2694V28.5182C44.5975 28.405 44.5199 28.2932 43.9432 28.2932Z"
      fill="#2D9DD3"
    />
    <path
      d="M43.923 35.7413C43.3536 35.7413 43.1911 35.6048 43.115 35.5032L40.4599 31.6121V35.2709C40.4599 35.7384 39.8495 35.7384 39.5553 35.7384C39.2611 35.7384 38.6523 35.7384 38.6523 35.2709V28.5197C38.653 28.4351 38.68 28.3529 38.7295 28.2841C38.779 28.2153 38.8486 28.1632 38.9289 28.1349C39.1162 28.058 40.0691 27.9636 40.3413 28.2322L40.3867 28.2874L43.0345 32.1697V28.5138C43.0345 28.0449 43.6683 28.0449 43.9391 28.0449C44.2098 28.0449 44.8421 28.0449 44.8421 28.5138V35.2651C44.839 35.3596 44.8048 35.4506 44.7449 35.5242C44.6849 35.5978 44.6024 35.6501 44.5099 35.673C44.3179 35.7202 44.1207 35.7431 43.923 35.7413ZM43.5087 35.1968C43.6428 35.2372 43.783 35.2539 43.923 35.2462C44.0648 35.2486 44.2065 35.2369 44.346 35.2113V28.5748C44.0782 28.5282 43.8043 28.5282 43.5365 28.5748V33.7856L39.9915 28.585C39.7142 28.5325 39.4297 28.5296 39.1514 28.5763V35.2041C39.4191 35.2507 39.693 35.2507 39.9608 35.2041V29.9948L43.5087 35.1968Z"
      fill="#2D9DD3"
    />
    <path
      d="M46.9867 28.2903C46.4086 28.2903 46.3281 28.3933 46.3281 28.5051V35.2738C46.3281 35.3841 46.4086 35.4872 46.9867 35.4872C47.5649 35.4872 47.6454 35.3841 47.6454 35.2738V28.5051C47.6439 28.3933 47.5722 28.2903 46.9867 28.2903Z"
      fill="#2D9DD3"
    />
    <path
      d="M46.9856 35.7414C46.6929 35.7414 46.0781 35.7414 46.0781 35.2797V28.5052C46.0781 28.0435 46.6914 28.0435 46.9856 28.0435C47.2798 28.0435 47.8931 28.0435 47.8931 28.5052V35.2739C47.8916 35.7414 47.2783 35.7414 46.9856 35.7414ZM46.5758 35.2143C46.8473 35.2569 47.1239 35.2569 47.3954 35.2143V28.5706C47.1239 28.528 46.8473 28.528 46.5758 28.5706V35.2143Z"
      fill="#2D9DD3"
    />
    <path
      d="M36.4828 28.2903C35.8974 28.2903 35.8242 28.3933 35.8242 28.5051V35.2738C35.8242 35.3841 35.9032 35.4872 36.4828 35.4872C37.0625 35.4872 37.1415 35.3841 37.1415 35.2738V28.5051C37.1415 28.3933 37.061 28.2903 36.4828 28.2903Z"
      fill="#2D9DD3"
    />
    <path
      d="M36.4855 35.7414C36.1928 35.7414 35.5781 35.7414 35.5781 35.2797V28.5052C35.5781 28.0435 36.1913 28.0435 36.4855 28.0435C36.7797 28.0435 37.393 28.0435 37.393 28.5052V35.2739C37.393 35.7414 36.7797 35.7414 36.4855 35.7414ZM36.0757 35.2143C36.3472 35.2569 36.6238 35.2569 36.8954 35.2143V28.5706C36.6238 28.528 36.3472 28.528 36.0757 28.5706V35.2143Z"
      fill="#2D9DD3"
    />
    <path
      d="M54.6562 28.2932H49.3416C49.2304 28.2932 49.125 28.3716 49.125 28.9422C49.125 29.5127 49.2304 29.5911 49.3416 29.5911H51.3454V35.2738C51.3454 35.3841 51.4244 35.4872 51.9996 35.4872C52.5749 35.4872 52.6539 35.3841 52.6539 35.2738V29.5911H54.6562C54.7689 29.5911 54.8728 29.5127 54.8728 28.9422C54.8728 28.3716 54.7689 28.2932 54.6562 28.2932Z"
      fill="#2D9DD3"
    />
    <path
      d="M52.0014 35.7413C51.7086 35.7413 51.0968 35.7413 51.0968 35.2796V29.838H49.3404C48.875 29.838 48.875 29.2325 48.875 28.9422C48.875 28.6518 48.875 28.0449 49.3404 28.0449H54.655C55.1219 28.0449 55.1219 28.6518 55.1219 28.9422C55.1219 29.2325 55.1219 29.838 54.655 29.838H52.8986V35.2738C52.9045 35.7413 52.2941 35.7413 52.0014 35.7413ZM51.5959 35.2142C51.8645 35.2568 52.1382 35.2568 52.4068 35.2142V29.3443H54.5979C54.6409 29.0779 54.6409 28.8064 54.5979 28.54H49.4092C49.3663 28.8064 49.3663 29.0779 49.4092 29.3443H51.5959V35.2142Z"
      fill="#2D9DD3"
    />
    <path
      d="M62.2515 28.36C62.0085 28.2757 61.0893 28.254 60.9166 28.4688L59.2612 31.0256L57.6014 28.4717C57.4404 28.2598 56.527 28.2757 56.2841 28.3585C56.1465 28.4035 56.1172 28.4805 56.1172 28.5385V28.5734L58.6055 32.4223V35.2491C58.6055 35.3101 58.6055 35.4945 59.2641 35.4945C59.9228 35.4945 59.9228 35.3101 59.9228 35.2491V32.4223L62.411 28.5748V28.5385C62.411 28.4819 62.3832 28.4064 62.2515 28.36Z"
      fill="#2D9DD3"
    />
    <path
      d="M59.2605 35.7413C59.0351 35.7413 58.3516 35.7413 58.3516 35.2491V32.4905L55.8633 28.6416V28.5342C55.8652 28.4378 55.8992 28.3447 55.9602 28.2695C56.0211 28.1944 56.1054 28.1414 56.2 28.1189C56.44 28.0391 57.5246 27.9549 57.7998 28.3193L57.81 28.3338L59.2605 30.5639L60.7242 28.3106C61.0169 27.952 62.1088 28.0434 62.3342 28.1233C62.4259 28.1478 62.5071 28.201 62.5657 28.2751C62.6242 28.3491 62.657 28.4401 62.6592 28.5342V28.6431L60.1709 32.492V35.2505C60.168 35.7413 59.4845 35.7413 59.2605 35.7413ZM58.8507 35.2012C59.1201 35.2612 59.3995 35.2612 59.6689 35.2012V32.3497L62.1132 28.5748C61.8585 28.5211 61.2511 28.5371 61.1135 28.6242L59.2649 31.48L57.4075 28.6271C57.2699 28.5414 56.6757 28.5255 56.4137 28.5777L58.8507 32.3526V35.2012Z"
      fill="#2D9DD3"
    />
    <path
      d="M27.5987 28.1074H27.1113C26.7176 28.1074 26.3984 28.424 26.3984 28.8145V29.311C26.3984 29.7015 26.7176 30.0181 27.1113 30.0181H27.5987C27.9923 30.0181 28.3115 29.7015 28.3115 29.311V28.8145C28.3115 28.424 27.9923 28.1074 27.5987 28.1074Z"
      fill="#2D9DD3"
    />
  </svg>
);

IconMifinity.propTypes = {
  className: PropTypes.string,
};

IconMifinity.defaultProps = {
  className: '',
};
