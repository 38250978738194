import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderSmartSoft = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 225 225"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M191.091 133.86C191.091 138.52 191.001 143.19 191.111 147.85C191.291 155.62 187.901 161.23 181.161 164.95C162.531 175.25 143.911 185.57 125.291 195.89C123.541 196.86 121.781 197.79 120.061 198.79C113.271 202.75 106.531 202.75 99.7406 198.74C87.1306 191.3 74.4906 183.92 61.8706 176.51C54.4106 172.13 46.9506 167.75 39.5306 163.31C34.8306 160.5 34.4206 157.87 37.9406 153.7C44.8306 145.54 57.8806 141.89 69.0706 149.19C76.6106 154.11 84.3606 158.58 92.8906 161.61C106.341 166.38 119.341 165.62 131.881 158.69C141.581 153.33 146.591 144.95 147.111 133.99C147.511 125.45 143.641 119.2 135.861 115.69C126.931 111.65 117.871 107.89 108.861 104.01C103.721 101.79 99.9306 98.1704 98.1006 92.8504C95.6306 85.6704 97.4206 79.2304 102.831 74.1604C108.211 69.1104 114.761 67.4804 121.761 70.4804C128.481 73.3604 135.011 76.6804 141.581 79.8804C153.541 85.7004 165.491 91.5504 177.421 97.4304C186.491 101.9 191.121 109.19 191.091 119.37C191.071 124.2 191.091 129.03 191.091 133.86Z"
      fill="#D31C2E"
    />
    <path
      d="M183.89 61.9101C183.27 70.9601 176.07 78.2001 167.67 79.0301C162.31 79.5601 157.53 78.2301 153.1 75.0701C144.51 68.9401 135.22 64.1801 124.93 61.5201C112.1 58.2101 100.1 60.4001 89.0797 67.4101C79.5797 73.4401 75.8597 82.6001 77.0597 93.5701C77.7697 100.02 81.8097 104.47 87.7097 107.03C94.4197 109.94 101.22 112.63 107.98 115.43C110.9 116.64 113.87 117.76 116.68 119.19C124.08 122.96 127.66 130.68 125.77 138.4C123.96 145.81 117.24 151.16 109.25 151.04C106.18 150.99 102.86 150.48 100.1 149.22C81.9697 140.9 63.8897 132.48 45.9597 123.73C37.9997 119.85 33.8597 113.07 33.8697 104.01C33.8797 94.8501 33.9197 85.6901 33.8597 76.5301C33.8197 69.1701 37.0297 63.7201 43.3897 60.1901C64.1797 48.6501 84.9897 37.1401 105.82 25.6801C112.17 22.1901 118.55 22.3001 124.81 26.0101C143.13 36.8701 161.44 47.7301 179.73 58.6401C181.25 59.5501 182.55 60.8301 183.9 61.9001L183.89 61.9101Z"
      fill="#D31C2E"
    />
  </svg>
);

IconProviderSmartSoft.propTypes = {
  className: PropTypes.string,
};

IconProviderSmartSoft.defaultProps = {
  className: '',
};
