import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withUser, withLocale } from 'core/hocs';
import { addScriptElementToDOM, isEmptyOrNil } from 'core/helpers';
import { USER_FIELDS, LOCALE_KEYS } from 'core/constants';
import { IconLiveChat } from 'components/icons/icon-live-chat/icon-live-chat';
import classNames from 'classnames';

import { SPORTSBOOK_POST_MESSAGE_TYPES } from '../../../constants';

import './zendesk-chat.scss';

export const openZendeskChatWidget = () => {
  if (window.zE) {
    window.zE('webWidget', 'open');
  }
};

class ZendeskChatUI extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    licenseKey: PropTypes.string.isRequired,
    group: PropTypes.string,
    additionalParams: PropTypes.shape(),
    isButtonHidden: PropTypes.bool,
    userData: PropTypes.shape(),
    locale: PropTypes.string.isRequired,
    onChatScriptLoaded: PropTypes.func,
    onChatScriptLoadingError: PropTypes.func,
    isUserLoggedIn: PropTypes.bool.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    group: null,
    isButtonHidden: false,
    additionalParams: null,
    userData: {},
    onChatScriptLoaded: () => {},
    onChatScriptLoadingError: () => {},
  };

  componentDidMount() {
    this.loadChatApi();
    window.addEventListener('message', this.onIframeMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onIframeMessage);
  }

  onIframeMessage = ({ data }) => {
    const { type } = data || {};

    if (type === SPORTSBOOK_POST_MESSAGE_TYPES.OPEN_CHAT) {
      this.onClick();
    }
  };

  setDefaultSettings = () => {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          zIndex: 1049,
          offset: {
            horizontal: '-10px',
            vertical: '0px',
            mobile: {
              horizontal: '-10px',
              vertical: '0px',
            },
          },
        },
      });
    }
  };

  chatScriptIsLoadedWithSuccess = () => {
    const { locale } = this.props;

    if (window.zE) {
      window.zE('webWidget', 'setLocale', locale || LOCALE_KEYS.EN);
    }

    this.setDefaultSettings();

    this.props.onChatScriptLoaded(window.zE);
  };

  chatScriptIsLoadedWithError = () => {
    this.props.onChatScriptLoadingError();
  };

  loadChatApi = () => {
    const { licenseKey } = this.props;

    if (isEmptyOrNil(licenseKey)) {
      throw new Error('`licenseKey` is REQUIRED for adding the Zendesk widget');
    }

    addScriptElementToDOM({
      src: `https://static.zdassets.com/ekr/snippet.js?key=${licenseKey}`,
      attributes: {
        id: 'ze-snippet',
        'data-script-zendesk': '',
        // Disables Cloudflare Rocket Loader script
        'data-cfasync': 'false',
      },
      onLoadEventListener: this.chatScriptIsLoadedWithSuccess,
      onErrorEventListener: this.chatScriptIsLoadedWithError,
    });
  };

  onClick = () => {
    openZendeskChatWidget();
  };

  render() {
    const { isButtonHidden, isUserLoggedIn } = this.props;

    if (isButtonHidden) {
      return null;
    }

    /*
      ATTENTION!
      In order to show chat widget's DEFAULT button - add `return null` here
      and comment out `iframe#launcher` style in app.scss file
    */

    return (
      <div
        className={classNames(
          'custom-chat-button custom-chat-button-zendesk d-flex justify-content-center align-items-center position-fixed',
          { 'no-logged-in': !isUserLoggedIn },
        )}
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
      >
        <IconLiveChat />
      </div>
    );
  }
}

export const ZendeskChat = withLocale(withUser(ZendeskChatUI, [USER_FIELDS.USER_DATA, USER_FIELDS.IS_USER_LOGGED_IN]));
