import React from 'react';
import PropTypes from 'prop-types';

export const IconDeposit = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0.800049C11.2921 0.800049 10.6627 1.11258 10.2235 1.60005H8.00159C7.43599 1.60005 7.04744 2.17212 7.25784 2.69692L8.00002 4.80005H16L16.7422 2.69692C16.9526 2.17212 16.5641 1.60005 15.9985 1.60005H13.7766C13.3373 1.11258 12.708 0.800049 12 0.800049ZM8.00002 6.40005C8.00002 6.40005 2.40002 11.2 2.40002 16.8001C2.40002 20 4.80002 21.6 6.40002 21.6H12H17.6C19.2 21.6 21.6 20 21.6 16.8001C21.6 11.2 16 6.40005 16 6.40005H8.00002ZM11.2 9.20005H12.8V10.4922C13.88 10.7338 14.6023 11.4697 14.6375 12.4641H13.2891C13.2227 11.9233 12.7194 11.5625 12.0266 11.5625C11.3098 11.5625 10.836 11.8948 10.836 12.4172C10.836 12.8396 11.1617 13.0828 11.9641 13.2532L12.7906 13.4282C14.1674 13.7178 14.7594 14.3126 14.7594 15.3751C14.7594 16.5191 14.0256 17.286 12.8 17.5188V18.8001H11.2V17.5219C10.0232 17.3011 9.28385 16.5787 9.24065 15.5219H10.625C10.6914 16.0771 11.2522 16.4329 12.0594 16.4329C12.8042 16.4329 13.3313 16.0723 13.3313 15.5547C13.3313 15.1179 12.99 14.8668 12.15 14.686L11.2578 14.4954C10.0098 14.2346 9.3969 13.5847 9.3969 12.5407C9.3969 11.4903 10.092 10.7418 11.2 10.4954V9.20005Z"
      fill="#01FA6C"
    />
  </svg>
);

IconDeposit.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

IconDeposit.defaultProps = {
  className: '',
  onClick: () => {},
};
