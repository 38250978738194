import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconLiveChat = ({ className, color }) => (
  <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M0 30C0 13.4315 13.4316 0 30.0001 0C46.5686 0 60 13.4315 60 29.9999C60 46.5684 46.5686 60 30 60C13.4315 60 0 46.5685 0 30Z" />
    <path d="M28.2034 34.8738C33.6518 34.8738 38.0858 30.4406 38.0858 24.9914C38.0858 24.379 38.0279 23.7868 37.9103 23.1963C36.8963 17.5664 31.6811 14.3455 26.4067 14.3455C20.4964 14.3454 14.7275 18.4773 14.7275 24.9914C14.7275 28.3147 16.033 30.0072 17.0823 31.367C17.8017 32.2997 18.3211 32.9734 18.3211 33.9754C18.3211 35.1343 17.2139 36.5048 16.7857 36.9346C16.5312 37.1925 16.4541 37.5786 16.5945 37.9138C16.7331 38.2489 17.0594 38.4675 17.4226 38.4675C19.9283 38.4675 22.1339 37.004 23.1377 34.7519C23.5693 34.816 24.0816 34.8739 24.6098 34.8739L28.2034 34.8738ZM41.6795 41.1626C41.6795 40.1606 42.1988 39.4868 42.9183 38.5542C43.9675 37.1944 45.273 35.502 45.273 32.1786C45.273 28.332 43.0512 24.9692 39.7485 23.3496C39.8293 23.8817 39.8826 24.4244 39.8826 24.9914C39.8826 31.4311 34.6432 36.6705 28.2035 36.6705C28.2035 36.6705 24.2959 36.6662 24.1327 36.6583C23.9367 36.9538 23.72 37.232 23.4895 37.4965C25.264 40.2613 28.3439 42.0609 31.7972 42.0609H35.3908C35.8505 42.0609 36.3295 42.0206 36.8613 41.9346C37.8632 44.1893 40.0706 45.6545 42.578 45.6545C42.9412 45.6545 43.2676 45.436 43.4062 45.1008C43.5466 44.7657 43.4694 44.3796 43.2149 44.1217C42.7866 43.692 41.6795 42.3216 41.6795 41.1626Z" fill="white" />
  </svg>
);

IconLiveChat.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconLiveChat.defaultProps = {
  className: null,
  color: colors.primary,
};
