/* eslint-disable max-len */
import * as R from 'ramda';
import { isEmptyOrNil } from 'core/helpers';

const FILTER_DTO_FIELDS = {
  MAX_REAL_BALANCE_DATA: 'maxRealBalanceData',
  DEFAULT_RESTRICTED_METHODS: 'defaultRestrictedMethods',
  MAX_REAL_BALANCE_EXCEED_RESTRICTED_METHODS: 'maxRealBalanceExceedRestrictedMethods',
};

const getTransactionsNumberPerMethod = (transactions = [], method = '') =>
  R.compose(R.length, R.filter(R.propEq('paymentMethod', method)))(transactions || []);

export const getRestrictedPaymentMethodsByDtoRules = ({
  transactions = [],
  filterDto = {},
  currency = '',
  realBalance,
}) => {
  if (isEmptyOrNil(filterDto)) {
    return [];
  }

  return Object.keys(filterDto).reduce((acc, method) => {
    const transactionsNumber = getTransactionsNumberPerMethod(transactions, method);
    const targetTransactionsNumber = transactionsNumber > 1 ? '>1' : transactionsNumber;
    const filterDtoItem = R.pathOr({}, [method, targetTransactionsNumber], filterDto);
    const maxRealBalancePerCurrency = filterDtoItem[FILTER_DTO_FIELDS.MAX_REAL_BALANCE_DATA] || {};
    const defaultRestrictedMethods = filterDtoItem[FILTER_DTO_FIELDS.DEFAULT_RESTRICTED_METHODS] || [];
    const maxRealBalanceRestrictedMethods = filterDtoItem[FILTER_DTO_FIELDS.MAX_REAL_BALANCE_EXCEED_RESTRICTED_METHODS] || [];

    if (!isEmptyOrNil(maxRealBalancePerCurrency)) {
      const maxBalanceTreshold = maxRealBalancePerCurrency[currency];

      if (R.isNil(maxBalanceTreshold)) {
        return acc.concat(defaultRestrictedMethods);
      }

      const targetAllowedMethods = realBalance > maxBalanceTreshold ? maxRealBalanceRestrictedMethods : defaultRestrictedMethods;

      return acc.concat(targetAllowedMethods);
    }

    return acc.concat(defaultRestrictedMethods);
  }, []);
};

export const filterPaymentMethodsByDtoRules = ({
  paymentMethods = [],
  transactions = [],
  filterDto = {},
  currency = '',
  realBalance,
}) => {
  const restrictedMethods = getRestrictedPaymentMethodsByDtoRules({
    transactions,
    filterDto,
    currency,
    realBalance,
  });

  return (paymentMethods || []).filter(item => !restrictedMethods.includes(item.paymentMethod));
};
