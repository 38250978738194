import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withModalActions } from 'core/hocs';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormConfirmOtp } from 'components/forms/form-confirm-otp/form-confirm-otp';

import { MODAL_CONFIRM_OTP_CLASS, MODAL_IDS } from '../../../constants';

export class ConfirmOtpModalUI extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
  };

  onClosed = () => {
    if (document.querySelector('.wrap-auth-modal')) {
      this.props.openModal(MODAL_IDS.SIGN_IN);
    }
  };

  render() {
    return (
      <ModalWindow
        id={MODAL_IDS.CONFIRM_OTP}
        coreProps={{ id: MODAL_IDS.CONFIRM_OTP }}
        classForDocumentBody={MODAL_CONFIRM_OTP_CLASS}
        onClosed={this.onClosed}
        bodyClassName="pt-5_5 px-2 pb-3"
        fade
      >
        <FormConfirmOtp />
      </ModalWindow>
    );
  }
}

export const ConfirmOtpModal = withModalActions(ConfirmOtpModalUI);

