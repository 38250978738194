import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconMifinityDirect = ({ className }) => (
  <svg
    className={classNames('footer-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="42"
    viewBox="0 0 80 42"
    fill="none"
  >
    <path
      d="M20.4897 6.30304C20.45 6.28318 20.4119 6.28318 20.3524 6.26333C20 6.16573 19.1017 6.14587 18.8271 6.38244C18.7692 6.442 18.7493 6.46185 18.7096 6.5396C18.7096 6.5396 17.3216 8.38914 16.4233 10.0021L14.2727 6.5396V6.51975C14.233 6.4602 14.2148 6.42215 14.1552 6.36259L14.1354 6.34274C13.8823 6.12602 13.1395 6.14587 12.7491 6.20543C12.6316 6.22528 12.534 6.24513 12.4761 6.26499C12.2809 6.36259 12.2031 6.54126 12.2031 6.65872V13.9394C12.2031 14.0767 12.261 14.3332 12.711 14.4109C12.8285 14.4308 12.984 14.4308 13.161 14.4308C13.4737 14.4308 14.1189 14.4308 14.1189 13.9394V9.76719C14.2562 9.98391 14.5291 10.4174 14.8219 10.9683C15.2322 11.7359 15.5068 12.2272 15.6425 12.4638L15.7202 12.6209C15.7781 12.7185 15.8774 12.8178 15.9932 12.9154C16.1686 13.0527 16.5209 13.0924 16.7558 13.0329C16.8137 13.013 16.8733 12.9932 16.9312 12.9353C17.1066 12.798 17.2042 12.6987 17.2439 12.6408C17.2836 12.5812 17.3415 12.4638 17.4391 12.3066C17.5367 12.1495 17.7319 11.7557 18.0644 11.1254C18.3969 10.4951 18.6517 10.0435 18.8271 9.74734V13.9593C18.8271 14.4506 19.4722 14.4506 19.7849 14.4506C20.0976 14.4506 20.7428 14.4506 20.7428 13.9593V6.67691C20.7626 6.55946 20.7031 6.38244 20.488 6.30304H20.4897Z"
      fill="#2D9DD3"
    />
    <path
      d="M22.8368 8.92017C22.5241 8.92017 21.8789 8.92017 21.8789 9.4115V14.0552C21.8789 14.5465 22.5241 14.5465 22.8368 14.5465C23.1494 14.5465 23.7946 14.5465 23.7946 14.0552V9.4115C23.7946 8.92017 23.1494 8.92017 22.8368 8.92017Z"
      fill="#2D9DD3"
    />
    <path
      d="M29.8741 6.2832H25.8475C25.4769 6.2832 24.9492 6.30306 24.9492 6.77454V14.0553C24.9492 14.5466 25.5944 14.5466 25.9071 14.5466C26.2197 14.5466 26.8649 14.5466 26.8649 14.0553V11.2809H29.4639C29.7567 11.2809 30.0892 11.0444 30.0892 10.376C30.0892 9.70766 29.7567 9.47109 29.4639 9.47109H26.8649V8.09303H29.8758C30.1686 8.09303 30.5011 7.85647 30.5011 7.18812C30.5011 6.51977 30.1686 6.2832 29.8758 6.2832H29.8741Z"
      fill="#2D9DD3"
    />
    <path
      d="M40.391 6.26343C40.0982 6.26343 39.4333 6.26343 39.4333 6.77462V10.7102L36.6192 6.53805L36.5794 6.47849C36.2866 6.20222 35.2693 6.30148 35.0741 6.38089C34.8988 6.4603 34.7812 6.61746 34.7812 6.79447V14.0553C34.7812 14.5664 35.4265 14.5664 35.7392 14.5664C36.0518 14.5664 36.6969 14.5664 36.6969 14.0553V10.1196L39.4927 14.2918C39.5705 14.4093 39.7476 14.5482 40.3531 14.5482C40.5681 14.5482 40.841 14.5284 40.9981 14.4887C41.2132 14.4291 41.3505 14.2521 41.3505 14.0553V6.77627C41.3505 6.26509 40.6855 6.26509 40.3927 6.26509L40.391 6.26343Z"
      fill="#2D9DD3"
    />
    <path
      d="M43.5946 6.2832C43.2819 6.2832 42.6367 6.2832 42.6367 6.77454V14.0553C42.6367 14.5466 43.2819 14.5466 43.5946 14.5466C43.9072 14.5466 44.5524 14.5466 44.5524 14.0553V6.77619C44.5524 6.28486 43.9072 6.28486 43.5946 6.28486V6.2832Z"
      fill="#2D9DD3"
    />
    <path
      d="M32.4734 6.2832C32.1608 6.2832 31.5156 6.2832 31.5156 6.77454V14.0553C31.5156 14.5466 32.1608 14.5466 32.4734 14.5466C32.7861 14.5466 33.4313 14.5466 33.4313 14.0553V6.77619C33.4313 6.28486 32.7861 6.28486 32.4734 6.28486V6.2832Z"
      fill="#2D9DD3"
    />
    <path
      d="M51.7271 6.2832H46.0974C45.6094 6.2832 45.6094 6.93335 45.6094 7.24768C45.6094 7.562 45.6094 8.21215 46.0974 8.21215H47.9536V14.0569C47.9536 14.5482 48.5988 14.5482 48.9114 14.5482C49.2241 14.5482 49.8693 14.5482 49.8693 14.0569V8.21215H51.7254C52.2135 8.21215 52.2135 7.562 52.2135 7.24768C52.2135 6.93335 52.2135 6.2832 51.7254 6.2832H51.7271Z"
      fill="#2D9DD3"
    />
    <path
      d="M59.8605 6.38245C59.6256 6.28484 58.4725 6.18558 58.1598 6.57931L56.6163 8.99959L55.0729 6.59916L55.053 6.57931C54.7602 6.18558 53.6071 6.28484 53.3524 6.3626C53.1373 6.442 53 6.61902 53 6.81588V6.95319L55.6386 11.0857V14.037C55.6386 14.5681 56.3616 14.5681 56.5965 14.5681C56.8513 14.5681 57.5742 14.5681 57.5742 14.0172V11.0659L60.2128 6.93334V6.81588C60.2128 6.61902 60.0755 6.46185 59.8605 6.38245Z"
      fill="#2D9DD3"
    />
    <path
      d="M23.0713 6.36255H22.5436C22.1333 6.36255 21.8008 6.69672 21.8008 7.11031V7.6612C21.8008 8.07478 22.1333 8.40895 22.5436 8.40895H23.0713C23.4816 8.40895 23.8141 8.07478 23.8141 7.6612V7.11031C23.8141 6.69672 23.4816 6.36255 23.0713 6.36255Z"
      fill="#2D9DD3"
    />
    <path
      d="M58.4204 35.8343H11.8826C11.6907 35.8343 11.5352 35.6788 11.5352 35.4869V17.6301C11.5352 17.4382 11.6907 17.2827 11.8826 17.2827H58.4204C58.7414 17.2827 59.0507 17.4018 59.289 17.6169L67.9725 25.4518C68.6309 26.0424 68.6309 27.073 67.9725 27.6636L59.289 35.4985C59.0507 35.7136 58.7414 35.8327 58.4204 35.8327V35.8343Z"
      fill="#7D5E00"
    />
    <path
      d="M13.125 29.6572H14.5808C14.8687 29.6572 15.0838 29.6688 15.226 29.6936C15.3683 29.7184 15.494 29.768 15.6065 29.8441C15.719 29.9202 15.8117 30.0211 15.8861 30.1485C15.9605 30.2759 15.9986 30.4165 15.9986 30.5737C15.9986 30.7441 15.9523 30.9012 15.8613 31.0435C15.7686 31.1858 15.6446 31.2933 15.4874 31.3645C15.7091 31.429 15.8795 31.5398 15.9986 31.6953C16.1177 31.8508 16.1773 32.0345 16.1773 32.2446C16.1773 32.41 16.1392 32.5705 16.0615 32.7276C15.9837 32.8848 15.8795 33.0089 15.7455 33.1032C15.6115 33.1975 15.4477 33.2537 15.2525 33.2752C15.1301 33.2884 14.834 33.2967 14.3658 33.3H13.1267V29.6605L13.125 29.6572ZM13.8596 30.2627V31.1047H14.341C14.6272 31.1047 14.8058 31.1014 14.8753 31.0915C15.001 31.0766 15.1003 31.0336 15.1714 30.9608C15.2442 30.888 15.279 30.7937 15.279 30.6763C15.279 30.5588 15.2475 30.4728 15.1863 30.4016C15.1235 30.3322 15.0325 30.2891 14.91 30.2743C14.8373 30.266 14.6272 30.261 14.2814 30.261H13.8596V30.2627ZM13.8596 31.7102V32.683H14.5395C14.8042 32.683 14.9729 32.6763 15.044 32.6598C15.1532 32.6399 15.2426 32.592 15.3104 32.5142C15.3799 32.4381 15.413 32.3339 15.413 32.2049C15.413 32.0957 15.3865 32.003 15.3336 31.9269C15.2806 31.8508 15.2045 31.7962 15.1036 31.7598C15.0027 31.7251 14.786 31.7086 14.4518 31.7086H13.8579L13.8596 31.7102Z"
      fill="white"
    />
    <path
      d="M20.0785 33.2967H19.2795L18.9618 32.4695H17.506L17.205 33.2967H16.4258L17.8435 29.6572H18.6211L20.0785 33.2967ZM18.7253 31.8574L18.224 30.5059L17.7327 31.8574H18.7253Z"
      fill="white"
    />
    <path
      d="M20.4766 33.2967V29.6572H21.1913L22.6818 32.0874V29.6572H23.3651V33.2967H22.6273L21.1599 30.9228V33.2967H20.4766Z"
      fill="white"
    />
    <path
      d="M24.1484 33.2967V29.6572H24.883V31.2735L26.3686 29.6572H27.3562L25.9847 31.075L27.4306 33.2967H26.4794L25.4785 31.5878L24.883 32.1966V33.2967H24.1484Z"
      fill="white"
    />
    <path d="M30.045 33.2967V30.2726H28.9648V29.6572H31.8582V30.2726H30.7812V33.2967H30.0467H30.045Z" fill="white" />
    <path
      d="M32.3359 33.2967V29.6572H33.8827C34.2715 29.6572 34.5544 29.6903 34.7314 29.7548C34.9084 29.821 35.049 29.9368 35.1549 30.1039C35.2608 30.271 35.3137 30.4629 35.3137 30.6779C35.3137 30.9509 35.2327 31.1759 35.0722 31.3545C34.9117 31.5332 34.6718 31.644 34.3526 31.6904C34.5114 31.783 34.6421 31.8856 34.7463 31.9964C34.8505 32.1073 34.9895 32.3041 35.1648 32.587L35.6098 33.2967H34.7314L34.2004 32.5043C34.0118 32.2214 33.8827 32.0427 33.8132 31.9699C33.7438 31.8955 33.6693 31.8459 33.5916 31.8177C33.5138 31.7896 33.3897 31.7764 33.221 31.7764H33.0721V33.2967H32.3376H32.3359ZM33.0705 31.1957H33.6147C33.9671 31.1957 34.1871 31.1808 34.2748 31.1511C34.3625 31.1213 34.432 31.07 34.4816 30.9972C34.5312 30.9244 34.556 30.8334 34.556 30.7242C34.556 30.6018 34.523 30.5026 34.4584 30.4281C34.3923 30.352 34.3013 30.3057 34.1822 30.2858C34.1226 30.2776 33.9439 30.2726 33.6462 30.2726H33.0721V31.1957H33.0705Z"
      fill="white"
    />
    <path
      d="M39.2856 33.2967H38.4865L38.1689 32.4695H36.7131L36.412 33.2967H35.6328L37.0506 29.6572H37.8281L39.2856 33.2967ZM37.9323 31.8574L37.4311 30.5059L36.9397 31.8574H37.9323Z"
      fill="white"
    />
    <path
      d="M39.6875 33.2967V29.6572H40.4022L41.8928 32.0874V29.6572H42.576V33.2967H41.8382L40.3708 30.9228V33.2967H39.6875Z"
      fill="white"
    />
    <path
      d="M43.1641 32.1121L43.8787 32.0426C43.9218 32.2825 44.0094 32.4595 44.1401 32.572C44.2725 32.6845 44.4495 32.7408 44.6728 32.7408C44.9094 32.7408 45.0881 32.6911 45.2072 32.5902C45.3263 32.4893 45.3875 32.3735 45.3875 32.2395C45.3875 32.1535 45.3627 32.0807 45.3114 32.0195C45.2601 31.9583 45.1724 31.907 45.0467 31.8623C44.9607 31.8325 44.7638 31.7796 44.4578 31.7035C44.064 31.6059 43.7878 31.4851 43.6289 31.3429C43.4056 31.1427 43.2931 30.8978 43.2931 30.61C43.2931 30.4247 43.346 30.251 43.4503 30.0905C43.5545 29.9284 43.7067 29.806 43.9052 29.7216C44.1037 29.6372 44.342 29.5942 44.6215 29.5942C45.0781 29.5942 45.4222 29.6951 45.6538 29.8953C45.8854 30.0955 46.0062 30.3635 46.0178 30.6977L45.2833 30.7291C45.2518 30.5422 45.184 30.4082 45.0814 30.3254C44.9789 30.2427 44.8234 30.203 44.6166 30.203C44.4098 30.203 44.2361 30.2477 44.1153 30.3354C44.0376 30.3916 43.9979 30.4661 43.9979 30.562C43.9979 30.648 44.0342 30.7225 44.107 30.7837C44.1997 30.8614 44.4247 30.9425 44.782 31.0269C45.1393 31.1112 45.404 31.1989 45.5761 31.2883C45.7481 31.3792 45.8821 31.5017 45.9781 31.6588C46.074 31.816 46.1237 32.0079 46.1237 32.2378C46.1237 32.4463 46.0658 32.6415 45.95 32.8235C45.8342 33.0054 45.6704 33.1411 45.4586 33.2288C45.2469 33.3165 44.9822 33.3611 44.6662 33.3611C44.2063 33.3611 43.8523 33.2553 43.6058 33.0418C43.3593 32.8284 43.212 32.5191 43.1641 32.1121Z"
      fill="white"
    />
    <path
      d="M46.7461 33.2967V29.6572H49.2408V30.2726H47.4806V31.1345H49.0009V31.7499H47.4806V33.2967H46.7461Z"
      fill="white"
    />
    <path
      d="M49.8477 33.2967V29.6572H52.5475V30.2726H50.5839V31.0799H52.4119V31.6937H50.5839V32.6846H52.617V33.2984H49.8477V33.2967Z"
      fill="white"
    />
    <path
      d="M53.2422 33.2967V29.6572H54.789C55.1777 29.6572 55.4606 29.6903 55.6376 29.7548C55.8146 29.8193 55.9553 29.9368 56.0611 30.1039C56.167 30.271 56.2199 30.4629 56.2199 30.6779C56.2199 30.9509 56.1389 31.1759 55.9784 31.3545C55.8179 31.5332 55.5781 31.644 55.2588 31.6904C55.4176 31.783 55.5483 31.8856 55.6525 31.9964C55.7567 32.1073 55.8957 32.3041 56.0711 32.587L56.5161 33.2967H55.6376L55.1066 32.5043C54.918 32.2214 54.789 32.0427 54.7195 31.9699C54.65 31.8955 54.5755 31.8459 54.4978 31.8177C54.42 31.7896 54.296 31.7764 54.1272 31.7764H53.9783V33.2967H53.2438H53.2422ZM53.9767 31.1957H54.521C54.8733 31.1957 55.0934 31.1808 55.181 31.1511C55.2687 31.1213 55.3382 31.07 55.3878 30.9972C55.4375 30.9244 55.4623 30.8334 55.4623 30.7242C55.4623 30.6018 55.4292 30.5026 55.3647 30.4281C55.3001 30.352 55.2075 30.3057 55.0884 30.2858C55.0288 30.2776 54.8502 30.2726 54.5524 30.2726H53.9783V31.1957H53.9767Z"
      fill="white"
    />
    <path
      d="M13.2266 27.5479V19.498H17.1937C18.0903 19.498 18.8794 19.6601 19.5627 19.986C20.2443 20.3119 20.7769 20.7735 21.1607 21.3723C21.5446 21.9695 21.7348 22.6825 21.7348 23.5114C21.7348 24.3402 21.5429 25.0664 21.1607 25.6669C20.7769 26.2691 20.2443 26.7323 19.5627 27.0582C18.8794 27.3841 18.0903 27.5463 17.1937 27.5463H13.2266V27.5479ZM15.9414 25.432H17.0795C17.4633 25.432 17.7975 25.3592 18.0854 25.2137C18.3732 25.0681 18.5965 24.8514 18.7587 24.5635C18.9208 24.2757 19.0002 23.9249 19.0002 23.5114C19.0002 23.0978 18.9191 22.7603 18.7587 22.4758C18.5982 22.1912 18.3732 21.9778 18.0854 21.8322C17.7975 21.6866 17.4633 21.6139 17.0795 21.6139H15.9414V25.432Z"
      fill="white"
    />
    <path d="M22.7266 27.5479V19.498H25.4414V27.5479H22.7266Z" fill="white" />
    <path
      d="M26.75 27.5479V19.498H30.6261C31.3772 19.498 32.0273 19.6204 32.5749 19.8652C33.1225 20.1101 33.546 20.4608 33.8454 20.9174C34.1449 21.374 34.2938 21.9116 34.2938 22.5337C34.2938 23.1557 34.1432 23.69 33.8454 24.1384C33.546 24.5867 33.1225 24.9291 32.5749 25.1673C32.0273 25.4056 31.3772 25.5247 30.6261 25.5247H28.2571L29.4648 24.4097V27.5496H26.75V27.5479ZM29.4631 24.7074L28.2555 23.4766H30.4524C30.8279 23.4766 31.1059 23.3923 31.2862 23.2235C31.4665 23.0548 31.5558 22.8248 31.5558 22.5337C31.5558 22.2425 31.4649 22.0125 31.2862 21.8438C31.1059 21.6751 30.8279 21.5907 30.4524 21.5907H28.2555L29.4631 20.3599V24.7058V24.7074ZM31.5691 27.5479L29.5905 24.6148H32.4657L34.4675 27.5479H31.5691Z"
      fill="white"
    />
    <path
      d="M37.9848 25.5015H42.0098V27.5479H35.3164V19.498H41.8592V21.5444H37.9832V25.4999L37.9848 25.5015ZM37.8012 22.489H41.3894V24.4444H37.8012V22.489Z"
      fill="white"
    />
    <path
      d="M47.2209 27.7331C46.5774 27.7331 45.9802 27.6305 45.4326 27.4287C44.885 27.2252 44.4086 26.9357 44.0066 26.5602C43.6046 26.1847 43.2919 25.7397 43.0686 25.2268C42.8452 24.714 42.7344 24.1465 42.7344 23.5245C42.7344 22.9025 42.8452 22.3367 43.0686 21.8222C43.2903 21.3094 43.6029 20.8644 44.0066 20.4888C44.4102 20.1133 44.885 19.8238 45.4326 19.6203C45.9802 19.4168 46.5774 19.3159 47.2209 19.3159C48.01 19.3159 48.7098 19.4532 49.3203 19.7295C49.9291 20.0058 50.4336 20.4045 50.8323 20.9256L49.1184 22.4542C48.8802 22.1547 48.6188 21.9231 48.331 21.7593C48.0431 21.5956 47.7189 21.5112 47.3599 21.5112C47.077 21.5112 46.8189 21.5575 46.589 21.6502C46.359 21.7428 46.1622 21.8768 45.9967 22.0522C45.8313 22.2292 45.7039 22.4409 45.6113 22.6907C45.5186 22.9405 45.4723 23.2185 45.4723 23.5245C45.4723 23.8306 45.5186 24.1085 45.6113 24.3583C45.7039 24.6081 45.8313 24.8198 45.9967 24.9969C46.1622 25.1739 46.359 25.3079 46.589 25.3989C46.8189 25.4899 47.0753 25.5362 47.3599 25.5362C47.7205 25.5362 48.0448 25.4535 48.331 25.2897C48.6188 25.1242 48.8802 24.8926 49.1184 24.5932L50.8323 26.1235C50.4336 26.6363 49.9291 27.0333 49.3203 27.3129C48.7115 27.5925 48.0117 27.7331 47.2209 27.7331Z"
      fill="white"
    />
    <path d="M53.3027 27.5479V21.6023H50.9453V19.498H58.3749V21.6023H56.0175V27.5479H53.3027Z" fill="white" />
  </svg>
);

IconMifinityDirect.propTypes = {
  className: PropTypes.string,
};

IconMifinityDirect.defaultProps = {
  className: '',
};
