import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSwinttBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="120" height="49" viewBox="0 0 120 49" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M62.0619 25.6505C60.5362 25.1973 59.1353 24.7646 59.1353 23.3836C59.1353 22.2089 60.2063 21.6528 61.4022 21.6528C62.4946 21.6528 63.711 22.1465 64.4324 23.0743L66.0815 21.3024C64.9268 19.9008 63.2159 19.2 61.5257 19.2C58.9494 19.2 56.5583 20.7868 56.5583 23.6722C56.5583 26.4953 58.826 27.3202 60.8249 27.9175C62.4329 28.4126 63.9174 28.7836 63.9174 30.0816C63.9174 31.1122 62.9897 31.813 61.6498 31.813C60.3716 31.813 58.9289 31.174 58.0018 29.9788L56.2285 31.8329C57.6095 33.5232 59.6091 34.2857 61.4639 34.2857C64.1231 34.2857 66.5142 32.699 66.5142 29.7929C66.5142 26.9074 64.1231 26.2478 62.0619 25.6512V25.6505ZM78.6479 30.9648L76.3275 24H73.8438L71.5035 30.9648L69.183 24H66.5142L70.3535 34.2857H72.6527L75.0754 27.4416L77.4973 34.2857H79.8178L83.6571 24H80.8649L78.6479 30.9648Z" fill="#fcfcfd" />
    <mask id="icon-swintt-a" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="121" height="49">
      <path d="M0 48.6857H120.686V0H0V48.6857Z" fill="white" />
    </mask>
    <g mask="url(#icon-swintt-a)">
      <path fillRule="evenodd" clipRule="evenodd" d="M85.0288 34.2857H87.7717V24H85.0288V34.2857ZM86.7431 18.5143C85.781 18.5143 85.0288 19.2569 85.0288 20.2395C85.0288 21.2002 85.781 21.9429 86.7431 21.9429C87.7045 21.9429 88.4574 21.2002 88.4574 20.2395C88.4574 19.2569 87.7045 18.5143 86.7431 18.5143ZM95.6341 23.3143C94.1831 23.3143 92.9186 23.9897 92.2754 25.1074V23.568H89.8288V34.2857H92.3378V28.5257C92.3378 26.5426 93.6229 25.5929 94.8674 25.5929C96.0078 25.5929 96.9198 26.3527 96.9198 27.829V34.2857H99.4288V27.4073C99.4288 24.707 97.7495 23.3143 95.6341 23.3143ZM106.693 32.0085C105.725 32.0085 104.967 31.4441 104.967 30.2736V25.8027H108.633L107.319 23.5467H104.967V19.2H102.401V23.5461H99.4288L100.743 25.8021H102.401V30.6501C102.401 33.0322 104.105 34.2857 106.083 34.2857C107.051 34.2857 108.103 33.9723 109.029 33.3243L108.355 31.4654C107.829 31.8199 107.219 32.0085 106.693 32.0085ZM115.608 32.0085C114.639 32.0085 113.882 31.4441 113.882 30.2736V25.8027H117.547L116.234 23.5467H113.882V19.2H111.315V23.5461H108.343L109.657 25.8021H111.315V30.6501C111.315 33.0322 113.019 34.2857 114.997 34.2857C115.965 34.2857 117.017 33.9723 117.943 33.3243L117.27 31.4654C116.744 31.8199 116.133 32.0085 115.608 32.0085Z" fill="#fcfcfd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M119.335 24.6823H118.986V24.2983H119.33C119.479 24.2983 119.579 24.384 119.579 24.4903C119.579 24.5931 119.479 24.6823 119.335 24.6823V24.6823ZM119.946 24.4546C119.946 24.1886 119.7 24 119.351 24H118.628V25.3714H118.986V24.9381H119.268L119.572 25.3714H120L119.638 24.8667C119.825 24.7845 119.946 24.6288 119.946 24.4553" fill="#fcfcfd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37.8507 0H18.923L9.5184 16.1266L9.57806 16.2288L14.195 24.1461L14.1943 24.1481H14.1963V24.1488L18.9271 32.2608L18.9264 32.2629L18.1995 31.2158L13.7835 24.8578L13.2919 24.1502V24.1481H4.84526L0 32.4569L9.46354 48.6857H28.3906L37.8549 32.4569L37.8528 32.4535L37.9639 32.2629H37.9557L33.2242 24.1502L33.2256 24.1481H33.2297L28.499 16.0354L28.4997 16.0341L34.1342 24.1461V24.1481H42.5835H42.6891L42.6926 24.1536L47.3143 16.2288L37.8507 0Z" fill="#E84E1C" />
    </g>
  </svg>
);

IconProviderSwinttBig.propTypes = {
  className: PropTypes.string
};

IconProviderSwinttBig.defaultProps = {
  className: ''
};
