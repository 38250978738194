import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconDesktopSearch = ({ className, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4107 12.8318C20.4107 15.9709 17.8659 18.5157 14.7268 18.5157C11.5877 18.5157 9.04293 15.9709 9.04293 12.8318C9.04293 9.6927 11.5877 7.14795 14.7268 7.14795C17.8659 7.14795 20.4107 9.6927 20.4107 12.8318ZM23.4107 12.8318C23.4107 17.6278 19.5228 21.5157 14.7268 21.5157C13.0107 21.5157 11.4108 21.0179 10.0638 20.1588L6.56066 23.6619C5.97487 24.2477 5.02513 24.2477 4.43934 23.6619C3.85355 23.0762 3.85355 22.1264 4.43934 21.5406L7.84769 18.1323C6.71607 16.6658 6.04293 14.8274 6.04293 12.8318C6.04293 8.03585 9.93083 4.14795 14.7268 4.14795C19.5228 4.14795 23.4107 8.03585 23.4107 12.8318Z"
      fill="white"
      color={color}
    />
  </svg>
);

IconDesktopSearch.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconDesktopSearch.defaultProps = {
  className: '',
  color: colors.gray40,
};
