import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { withModalActions } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { MODAL_IDS } from '../../../constants';

import './sign-in-buttons.scss';

export class SignInButtonsUI extends Component {
  static propTypes = {
    openModalAndCloseOthers: PropTypes.func.isRequired,
    isBottomPosition: PropTypes.bool,
  };

  static defaultProps = {
    isBottomPosition: false
  };

  openSignInModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_IN);
  };

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);
  };

  render() {
    const { isBottomPosition } = this.props;

    return (
      <div className={classNames('sign-in-buttons', {
        'is-bottom position-fixed w-100 pb-0_75 pb-sm-0 d-md-none': isBottomPosition,
        'is-header d-flex justify-content-center': !isBottomPosition,
      })}
      >
        <FormattedTag
          tag={Button}
          id="sign-up"
          color="primary"
          className={classNames({ 'mr-2_5 letter-spacing-default py-1 py-lg-1_25': !isBottomPosition }, { 'sign-up-button py-1_5 w-50': isBottomPosition })}
          onClick={this.openSignUpModal}
        />

        <FormattedTag
          tag={Button}
          id="sign-in"
          outline
          color="white"
          className={classNames({ 'mr-2_5 letter-spacing-default py-1 py-lg-1_25': !isBottomPosition }, { 'sign-in-button py-1_5 w-50': isBottomPosition })}
          onClick={this.openSignInModal}
        />
      </div>
    );
  }
}

export const SignInButtons = withModalActions(SignInButtonsUI);
