import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';

import {
  FormCompleteResetPassword as FormCompleteResetPasswordCore, FormattedTag, ChangeRoute,
} from 'core/components';
import { withCoreComponent, withRecaptcha } from 'core/hocs';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';

import { HISTORY_STATE_ACTIONS, MODAL_IDS } from '../../../constants';

import './form-complete-reset-password.scss';

export class FormCompleteResetPasswordUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isFormFilled: PropTypes.bool.isRequired,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(e);
  }

  render() {
    const {
      fields,
      isInProgress,
      isSuccess,
      isFormFilled,
    } = this.props;

    if (isSuccess) {
      return (
        <ChangeRoute
          to="/"
          state={{
            action: HISTORY_STATE_ACTIONS.OPEN_MODAL,
            modalId: MODAL_IDS.SIGN_IN,
          }}
        />
      );
    }

    return (
      <div className="pb-sm-3 pb-lg-0 w-100 complete-reset-password-container">
        <Form onSubmit={this.onSubmit} className="position-relative mb-13 mt-5 mt-md-10 d-flex justify-content-center w-100 rounded complete-reset-password-form mx-auto">
          <div className="d-flex flex-column align-items-center complete-reset-password-form-inner px-2">
            <FormattedTag
              className="mt-3 choose-new-password-title font-family-oswald text-uppercase font-weight-bold text-center mx-2 mx-sm-0"
              id="form.forgot-password.choose-new-password"
            />

            {/* <FormattedTag id="form.forgot-password.enter-new-password" className="text-center mx-4" /> */}

            <div className="d-flex flex-column w-100 mt-2_5 mb-1">
              <FormElement
                coreProps={{ item: fields.newPassword }}
                size="lg"
                autoFocus
              />
              <FormElement
                coreProps={{ item: fields.passwordConfirmation }}
                size="lg"
              />
            </div>

            <FormattedTag
              tag={ButtonWithLoader}
              isLoading={isInProgress}
              disabled={!isFormFilled}
              color="primary"
              className="mb-5 password-update-button w-100"
              type="submit"
              id="form.forgot-password.update-button"
            />
          </div>
        </Form>
      </div>
    );
  }
}

export const FormCompleteResetPassword = withCoreComponent(
  FormCompleteResetPasswordCore,
  withRecaptcha(FormCompleteResetPasswordUI)
);
