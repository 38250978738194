import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { KEYBOARD_KEY_CODE } from 'core/constants';
import { canUseDOM, isEmptyOrNil } from 'core/helpers';
import { withCoreComponent, withUserAgent } from 'core/hocs';
import { GamesSearch as GamesSearchCore, FormattedTag } from 'core/components';

import colors from 'customizations/js/color-variables';
import { withInfiniteScroll } from 'hocs/with-infinite-scroll';
import { IconClose } from 'components/icons/icon-close/icon-close';
import { IconDesktopSearch } from 'components/icons/icon-desktop-search/icon-desktop-search';

import { GamesSearchList } from './games-search-list';
import {
  MODAL_SHOWN_CLASS,
  MODAL_OPEN_CLASS,
} from '../../constants';

import './games-search.scss';

const SEARCH_PAGE_FIXED = 'search-page-fixed';

const GamesSearchListInfinite = withInfiniteScroll(GamesSearchList);

class GamesSearchUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    searchGames: PropTypes.func.isRequired,
    openGame: PropTypes.func.isRequired,
    toggleFavoriteGame: PropTypes.func.isRequired,
    showMore: PropTypes.func.isRequired,
    setRecentlySearched: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    games: PropTypes.arrayOf(PropTypes.shape()),
    recommendedGames: PropTypes.arrayOf(PropTypes.shape()),
    recentlySearched: PropTypes.arrayOf(PropTypes.shape()),
    isModal: PropTypes.bool,
    className: PropTypes.string,
    // deviceType: PropTypes.string.isRequired,
    isFromMobileHeader: PropTypes.bool,
  };

  static defaultProps = {
    games: [],
    recentlySearched: [],
    recommendedGames: [],
    isModal: false,
    className: null,
    isFromMobileHeader: false,
  };

  input = createRef();
  gamesSearchList = createRef();
  suggestion = createRef();

  state = {
    searchValue: '',
    suggestionPositionLeft: 0,
  };

  componentDidMount() {
    const { isModal } = this.props;

    if (isModal) {
      document.body.classList.add(MODAL_SHOWN_CLASS, MODAL_OPEN_CLASS);
    }

    if (this.input.current) {
      this.input.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isModal, games, recentlySearched } = this.props;
    const { searchValue } = this.state;
    const { searchValue: prevSearchValue } = prevState;

    const isGamesEmpty = isEmptyOrNil(games);
    const isRecentlySearchedGamesEmpty = isEmptyOrNil(recentlySearched);

    if (!isModal) {
      if (isRecentlySearchedGamesEmpty || (isGamesEmpty && searchValue.length)) {
        document.body.classList.add(SEARCH_PAGE_FIXED);
      }

      if (!isGamesEmpty || !isRecentlySearchedGamesEmpty) {
        document.body.classList.remove(SEARCH_PAGE_FIXED);
      }
    }

    if (searchValue !== prevSearchValue) {
      this.setSuggestionPositionLeft();
    }
  }

  componentWillUnmount() {
    const { isModal } = this.props;

    if (isModal) {
      document.body.classList.remove(MODAL_SHOWN_CLASS, MODAL_OPEN_CLASS);
    } else {
      document.body.classList.remove(SEARCH_PAGE_FIXED);
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    const { searchGames } = this.props;

    searchGames(value);

    this.setState({
      searchValue: value,
    });
  }

  onKeyUp = (e) => {
    const { openGame, games, searchGames } = this.props;

    if (e.keyCode === KEYBOARD_KEY_CODE.RIGHT_ARROW && games.length) {
      const { title } = games[0];

      searchGames(title);

      this.setState({
        searchValue: title,
      });
    }

    if (e.keyCode === KEYBOARD_KEY_CODE.ENTER && games.length) {
      const { gameId, producer } = games[0];

      openGame({ gameId, producer });
      this.onOpenGame({ gameId, producer });
    }
  }

  onOpenGame = ({ gameId, producer }) => {
    const { setRecentlySearched, onClose } = this.props;

    setRecentlySearched({ gameId, producer });
    onClose();
  }

  getGameSuggestion = () => {
    const { games } = this.props;
    const { searchValue } = this.state;

    if (games.length && searchValue) {
      const { title, producer } = games[0];
      const val = (searchValue || '').toLowerCase();
      const titleVal = (title || '').toLowerCase();
      const producerVal = (producer || '').toLowerCase();

      if (titleVal.startsWith(val)) {
        return {
          suggestion: this.prepareSuggestionValue(searchValue, title),
          suggestionAsPlaceholder: true,
        };
      }

      if (titleVal.includes(val.trim()) || producerVal.includes(val.trim())) {
        return {
          suggestion: title,
          suggestionAsPlaceholder: false,
        };
      }
    }

    return {
      suggestion: null,
      suggestionAsPlaceholder: true,
    };
  }

  prepareSuggestionValue = (searchValue, title) => `${searchValue}${title.slice(searchValue.length)}`;

  setSuggestionInitialPosition = () => canUseDOM() && window.matchMedia('(min-width: 1024px)').matches ? 66 : 50

  setSuggestionPositionLeft = () => {
    const { current } = this.suggestion;

    if (current) {
      // initial position for desktop - 66, for mobile - 50; 4 - margin to carret
      const calculatedPosition = this.setSuggestionInitialPosition() + 4;

      this.setState({ suggestionPositionLeft: calculatedPosition + current.getBoundingClientRect().width });
    }
  };

  // onFocus = () => {
  //   const { deviceType } = this.props;
  //
  //   if (deviceType === MOBILE) {
  //     this.navigation = document.querySelector('.casino-navigation');
  //     this.cookies = document.querySelector('.cookies-policy');
  //
  //     hideNavigationOnInputFocus(this.cookies, this.navigation);
  //   }
  // }

  // onBlur = () => {
  //   const { props: { deviceType }, navigation, cookies } = this;
  //
  //   showNavigationOnInputBlur(deviceType, cookies, navigation);
  // };

  render() {
    const {
      onClose,
      games,
      showMore,
      recentlySearched,
      isUserLoggedIn,
      openGame,
      toggleFavoriteGame,
      isModal,
      className,
      recommendedGames,
      isFromMobileHeader,
    } = this.props;
    const { searchValue, suggestionPositionLeft } = this.state;
    const { suggestion, suggestionAsPlaceholder } = this.getGameSuggestion();

    return (
      <>
        {isModal && (
          <div
            className="games-search-overlay position-fixed bg-white-5"
            role="button"
            tabIndex="0"
            onClick={onClose}
            onKeyPress={onClose}
          />
        )}

        <div className={classNames('games-search', className, {
          'is-modal position-fixed mx-auto mt-md-4 rounded': isModal,
          'is-search-page': !isModal,
          'is-from-mobile-header': isFromMobileHeader,
        })}
        >
          <div className="games-search-header d-flex align-items-center mb-2 mt-2 bg-secondary-dark">
            <div className="games-search-container w-100 pl-2 pr-1_5 pl-md-2_5 pr-md-1_75 d-flex align-items-center position-relative">
              <IconDesktopSearch
                color={colors.primary}
                className="games-search-icon-search flex-shrink-0"
              />

              <input
                ref={this.input}
                type="search"
                onChange={this.onChange}
                onKeyUp={this.onKeyUp}
                value={searchValue}
                className="games-search-input d-flex flex-fill m-0 p-0 ml-1_25 mr-2 mx-md-2 border-0 bg-transparent text-white"
              />

              <div
                style={{ left: suggestionAsPlaceholder ? this.setSuggestionInitialPosition() : suggestionPositionLeft }}
                className={classNames('games-search-suggestion d-flex m-0 text-gray-10-50 position-absolute', {
                  'text-small': !suggestionAsPlaceholder,
                  'text-gray-30-50': !searchValue,
                })}
              >
                {searchValue
                  ? (
                    <Fragment>
                      {!suggestionAsPlaceholder && <span className="mr-0_5">&#8212;</span>}
                      {suggestion}
                    </Fragment>
                  )
                  : <FormattedMessage id="games.search.placeholder" />}
                <div ref={this.suggestion} className="games-search-suggestion-aside invisible position-absolute h3">{searchValue}</div>
              </div>

              <div
                role="button"
                tabIndex="0"
                onClick={onClose}
                onKeyPress={onClose}
              >
                <IconClose className="games-search-icon-close" />
              </div>
            </div>
          </div>

          {!!searchValue && !suggestion && (
            <FormattedTag tag="div" className="mt-1_5 text-center text-gray-10" id="games.no-games" />
          )}

          {!!searchValue && (
            <Fragment>
              {canUseDOM() && window.matchMedia('(min-width: 960px)').matches
                ? (
                  <GamesSearchListInfinite
                    games={games}
                    loadNext={showMore}
                    targetRef={this.gamesSearchList}
                    isUserLoggedIn={isUserLoggedIn}
                    openGame={openGame}
                    toggleFavoriteGame={toggleFavoriteGame}
                    onOpenGame={this.onOpenGame}
                  />
                )
                : (
                  <GamesSearchListInfinite
                    games={games}
                    loadNext={showMore}
                    isUserLoggedIn={isUserLoggedIn}
                    openGame={openGame}
                    toggleFavoriteGame={toggleFavoriteGame}
                    onOpenGame={this.onOpenGame}
                  />
                )}
            </Fragment>
          )}

          {!searchValue && !isEmptyOrNil(recentlySearched) && (
            <GamesSearchList
              games={recentlySearched}
              isRecentlySearched
              isUserLoggedIn={isUserLoggedIn}
              openGame={openGame}
              toggleFavoriteGame={toggleFavoriteGame}
              onOpenGame={this.onOpenGame}
            />
          )}

          {!searchValue && isEmptyOrNil(recentlySearched) && !isEmptyOrNil(recommendedGames) && (
            <GamesSearchList
              games={recommendedGames}
              isRecommendedGames
              isUserLoggedIn={isUserLoggedIn}
              openGame={openGame}
              toggleFavoriteGame={toggleFavoriteGame}
              onOpenGame={this.onOpenGame}
            />
          )}
        </div>
      </>
    );
  }
}

export const GamesSearch = withUserAgent(
  withCoreComponent(GamesSearchCore, GamesSearchUI)
);
