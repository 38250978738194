import React from 'react';
import PropTypes from 'prop-types';

export const IconLangPt = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip-icon-lang)">
      <path
        d="M0 12C0 17.1595 3.2565 21.5581 7.82602 23.2536L8.86955 12L7.82602 0.746399C3.2565 2.44196 0 6.84043 0 12Z"
        fill="#6DA544"
      />
      <path
        d="M23.9999 12C23.9999 5.37262 18.6273 0 11.9999 0C10.5321 0 9.12596 0.264047 7.82593 0.746391V23.2536C9.12596 23.736 10.5321 24 11.9999 24C18.6273 24 23.9999 18.6274 23.9999 12Z"
        fill="#D80027"
      />
      <path
        d="M7.82599 16.1739C10.1312 16.1739 11.9999 14.3052 11.9999 12C11.9999 9.69483 10.1312 7.82611 7.82599 7.82611C5.52081 7.82611 3.6521 9.69483 3.6521 12C3.6521 14.3052 5.52081 16.1739 7.82599 16.1739Z"
        fill="#FFDA44"
      />
      <path
        d="M5.47827 9.91302V12.5217C5.47827 13.8184 6.5294 14.8695 7.8261 14.8695C9.1228 14.8695 10.1739 13.8184 10.1739 12.5217V9.91302H5.47827Z"
        fill="#D80027"
      />
      <path
        d="M7.82608 13.3043C7.39455 13.3043 7.04346 12.9532 7.04346 12.5217V11.4783H8.60866V12.5218C8.60866 12.9532 8.25757 13.3043 7.82608 13.3043Z"
        fill="#F0F0F0"
      />
    </g>
  </svg>
);

IconLangPt.propTypes = {
  className: PropTypes.string,
};

IconLangPt.defaultProps = {
  className: null,
};
