import React from 'react';
import PropTypes from 'prop-types';

export const IconInPlay = ({ className }) => (
  <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.60001 18.7H15.4V20.9H6.60001V18.7Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.2 2.19995C0.984974 2.19995 0 3.18492 0 4.39995V13.2C0 14.415 0.984974 15.4 2.2 15.4H19.8C21.0151 15.4 22 14.415 22 13.2V4.39995C22 3.18492 21.0151 2.19995 19.8 2.19995H2.2ZM8.525 12.6105L14.3 8.79995L8.525 4.98944V12.6105Z"
      fill="white"
    />
  </svg>
);

IconInPlay.propTypes = {
  className: PropTypes.string,
};

IconInPlay.defaultProps = {
  className: null,
};
