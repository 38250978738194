import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withUser, withModalActions, withLocale } from 'core/hocs';
import { RouteLink } from 'core/components';
import { USER_FIELDS } from 'core/constants';

import { GamesSearchPortal } from 'components/games-search/games-search-portal';
import { IconMenu } from 'components/icons/icon-menu/icon-menu';
import { IconLogoSmall } from 'components/icons/icon-logo/icon-logo-small';
import { IconDeposit } from 'components/icons/icon-deposit/icon-deposit';
import { IconSearch } from 'components/icons/icon-search/icon-search';
// import { SignInButtons } from '../sign-in-buttons/sign-in-buttons';
import { MODAL_IDS, SIDE_MODAL_IDS } from '../../../constants';

import './casino-header-mobile.scss';

export class CasinoHeaderMobileUI extends Component {
  static propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
    isSignUpCompleted: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    history: PropTypes.shape({
      prevLocation: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    // isSportsPage: PropTypes.bool,
    recommendedGames: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    // isSportsPage: false,
    recommendedGames: [],
  };

  state = {
    isSearchOpen: false
  }

  openProfileModal = () => {
    const { openModal } = this.props;
    openModal(MODAL_IDS.PROFILE);
  };

  openSideModalBar = () => {
    const { openModal } = this.props;
    openModal(SIDE_MODAL_IDS.SIDE_MODAL_BAR);
  };

  openSignInModal = () => {
    const { openModal } = this.props;
    openModal(MODAL_IDS.SIGN_IN);
  };

  openModal = () => {
    const { openModal, isSignUpCompleted } = this.props;

    openModal(isSignUpCompleted
      ? MODAL_IDS.DEPOSIT
      : MODAL_IDS.COMPLETE_SIGN_UP);
  };

  onSearchOpen = () => this.setState({ isSearchOpen: true })
  onSearchClose = () => this.setState({ isSearchOpen: false })

  render() {
    const { isUserLoggedIn, recommendedGames } = this.props;
    const { isSearchOpen } = this.state;

    return (
      <>
        <div>
          <IconMenu
            onClick={this.openSideModalBar}
            className="cursor-pointer mr-1_5"
          />
        </div>

        <div className="w-100">
          <RouteLink to="/">
            <IconLogoSmall />
          </RouteLink>
        </div>

        <div className="d-flex align-items-center">
          {isUserLoggedIn && (
            <IconDeposit
              className="mr-2 cursor-pointer"
              onClick={this.openModal}
            />
          )}

          <IconSearch onClick={this.onSearchOpen} className="mr-0_75 cursor-pointer" />

          {isSearchOpen && (
            <GamesSearchPortal
              recommendedGames={recommendedGames}
              onClose={this.onSearchClose}
              isModal
              isFromMobileHeader
            />
          )}
        </div>
      </>
    );
  }
}

export const CasinoHeaderMobile = withModalActions(
  withRouter(
    withUser(
      withLocale(CasinoHeaderMobileUI), [USER_FIELDS.IS_USER_LOGGED_IN, USER_FIELDS.IS_SIGN_UP_COMPLETED]
    )
  )
);
