import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderMercur = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5793 19.2759C17.0267 19.2759 19.8213 16.4812 19.8213 13.0338C19.8213 9.58642 17.0267 6.79175 13.5793 6.79175C10.1319 6.79175 7.3372 9.58642 7.3372 13.0338C7.3372 16.4812 10.1319 19.2759 13.5793 19.2759Z"
      fill="#D5B40F"
    />
    <path
      d="M20.1639 13.8732L25.3753 10.5338L19.6903 10.1966L23.2389 5.56448L17.7421 8.00529L18.9704 3.06131L15.8383 5.91543L15.482 1.35095L12.6607 6.28541L9.71248 1.91861L10.1575 7.31818L5.40064 3.82981L7.53489 8.7981L3.04335 7.82875L7.111 11.74L1.40276 12.8584L6.3203 14.5592L2.09197 17.0856L7.71354 16.3975L4.21354 20.9218L9.54123 18.371L8.45878 24.4028L11.8414 20.3087L13.1565 24.2886L14.5613 20.296L18.0064 24.6512L17.3848 18.3425L22.0793 21.3816L19.9154 16.0243L24.9493 15.8964L20.1639 13.8732ZM13.5793 6.98732C15.1825 6.98928 16.7195 7.62709 17.853 8.76084C18.9866 9.89458 19.6241 11.4317 19.6258 13.0349V13.0507C19.6218 14.245 19.2641 15.4113 18.5978 16.4024C17.9315 17.3935 16.9864 18.165 15.882 18.6194C14.7776 19.0738 13.5632 19.1908 12.3924 18.9557C11.2215 18.7205 10.1465 18.1436 9.30324 17.298C8.45995 16.4523 7.88612 15.3758 7.6542 14.2042C7.42228 13.0327 7.54267 11.8187 8.00018 10.7156C8.45768 9.61239 9.23178 8.66951 10.2247 8.00596C11.2177 7.34242 12.385 6.98795 13.5793 6.98732Z"
      fill="url(#icon-provider-mercur-paint0_radial_443_62)"
    />
    <path
      d="M13.593 7.24524C14.7381 7.24545 15.8573 7.58518 16.8093 8.22148C17.7612 8.85778 18.5032 9.76208 18.9412 10.82C19.3792 11.8779 19.4937 13.042 19.2702 14.165C19.0467 15.288 18.4952 16.3196 17.6855 17.1291C16.8757 17.9387 15.8441 18.49 14.7211 18.7134C13.598 18.9367 12.434 18.8219 11.3761 18.3837C10.3183 17.9455 9.41411 17.2034 8.77798 16.2513C8.14185 15.2992 7.80232 14.1799 7.80232 13.0349C7.804 11.4997 8.41466 10.0279 9.5003 8.94247C10.5859 7.85703 12.0578 7.24664 13.593 7.24524Z"
      fill="url(#icon-provider-mercur-paint1_radial_443_62)"
    />
    <path
      d="M13.593 7.24524C14.7381 7.24545 15.8573 7.58518 16.8093 8.22148C17.7612 8.85778 18.5032 9.76208 18.9412 10.82C19.3792 11.8779 19.4937 13.042 19.2702 14.165C19.0467 15.288 18.4952 16.3196 17.6855 17.1291C16.8757 17.9387 15.8441 18.49 14.7211 18.7134C13.598 18.9367 12.434 18.8219 11.3761 18.3837C10.3183 17.9455 9.41411 17.2034 8.77798 16.2513C8.14185 15.2992 7.80232 14.1799 7.80232 13.0349C7.804 11.4997 8.41466 10.0279 9.5003 8.94247C10.5859 7.85703 12.0578 7.24664 13.593 7.24524Z"
      fill="url(#icon-provider-mercur-paint2_radial_443_62)"
    />
    <path
      d="M13.593 7.24524C14.7381 7.24545 15.8573 7.58518 16.8093 8.22148C17.7612 8.85778 18.5032 9.76208 18.9412 10.82C19.3792 11.8779 19.4937 13.042 19.2702 14.165C19.0467 15.288 18.4952 16.3196 17.6855 17.1291C16.8757 17.9387 15.8441 18.49 14.7211 18.7134C13.598 18.9367 12.434 18.8219 11.3761 18.3837C10.3183 17.9455 9.41411 17.2034 8.77798 16.2513C8.14185 15.2992 7.80232 14.1799 7.80232 13.0349C7.804 11.4997 8.41466 10.0279 9.5003 8.94247C10.5859 7.85703 12.0578 7.24664 13.593 7.24524Z"
      fill="url(#icon-provider-mercur-paint3_radial_443_62)"
    />
    <path
      d="M20.1639 13.8732L25.3753 10.5338L19.6903 10.1966L23.2389 5.56448L17.7421 8.00529L18.9704 3.06131L15.8383 5.91543L15.482 1.35095L12.6607 6.28541L9.71248 1.91861L10.1575 7.31818L5.40064 3.82981L7.53489 8.7981L3.04335 7.82875L7.111 11.74L1.40276 12.8584L6.3203 14.5592L2.09197 17.0856L7.71354 16.3975L4.21354 20.9218L9.54123 18.371L8.45878 24.4028L11.8414 20.3087L13.1565 24.2886L14.5613 20.296L18.0064 24.6512L17.3848 18.3425L22.0793 21.3816L19.9154 16.0243L24.9493 15.8964L20.1639 13.8732ZM13.5793 6.98732C15.1825 6.98928 16.7195 7.62709 17.853 8.76084C18.9866 9.89458 19.6241 11.4317 19.6258 13.0349V13.0507C19.6218 14.245 19.2641 15.4113 18.5978 16.4024C17.9315 17.3935 16.9864 18.165 15.882 18.6194C14.7776 19.0738 13.5632 19.1908 12.3924 18.9557C11.2215 18.7205 10.1465 18.1436 9.30324 17.298C8.45995 16.4523 7.88612 15.3758 7.6542 14.2042C7.42228 13.0327 7.54267 11.8187 8.00018 10.7156C8.45768 9.61239 9.23178 8.66951 10.2247 8.00596C11.2177 7.34242 12.385 6.98795 13.5793 6.98732Z"
      fill="url(#icon-provider-mercur-paint4_radial_443_62)"
    />
    <path
      d="M17.8647 14.8658C17.5493 14.5646 17.3213 14.1836 17.2051 13.7632C17.1755 13.667 17.1121 13.6829 17.1121 13.6829C17.0433 13.6966 17.0719 13.7727 17.0719 13.7727C17.1149 13.9639 17.1714 14.1519 17.241 14.3351C13.2622 15.1808 9.68605 14.2505 9.68605 14.2505C9.79235 14.0776 9.88427 13.8962 9.9609 13.7082C9.98415 13.6216 9.93975 13.6025 9.93975 13.6025C9.87316 13.5793 9.85307 13.6522 9.85307 13.6522C9.47781 14.3721 8.95138 14.6364 8.95138 14.6364C8.95138 14.6364 8.84567 14.6956 8.88267 14.7484C8.88267 14.7484 8.9091 14.7918 9.03701 14.7431C9.215 14.645 9.38407 14.5314 9.54229 14.4038C9.84336 14.9201 10.1954 15.4049 10.593 15.851C11.0052 16.3156 11.5143 16.6841 12.0846 16.9302C12.5941 17.1998 13.2896 17.2093 13.2896 17.2093C16.0666 17.3531 17.3256 14.5391 17.3256 14.5391C17.4453 14.7068 17.5925 14.853 17.7611 14.9715C17.9292 15.0169 17.8647 14.8658 17.8647 14.8658ZM13.4133 17.0053C11.0962 16.9789 9.81925 14.4683 9.81925 14.4683L10 14.5063C11.2792 14.8032 12.5912 14.9343 13.9038 14.8964C13.3996 14.9154 15.3436 14.9123 16.8911 14.593L17.0962 14.5529C17.0962 14.5529 15.8742 17.0349 13.4133 17.0053Z"
      fill="#20201E"
    />
    <path
      d="M12.5592 12.9831C12.6036 12.9831 13.4049 12.9947 13.4049 12.9947C13.63 12.9598 13.5243 13.2315 13.5243 13.2315C13.4461 13.3288 13.5391 13.3584 13.5391 13.3584C13.6702 13.3584 13.7114 13.0951 13.7114 13.0951C13.7452 12.8087 13.5074 12.8531 13.5074 12.8531L12.6279 12.8393C11.7696 12.7674 11.8214 11.907 11.8214 11.907C11.8214 11.3446 12.3594 10.8552 12.3594 10.8552C12.7822 10.4609 13.5507 10.24 13.5507 10.24C13.6564 10.1924 13.6195 10.167 13.6195 10.167C13.5962 10.0867 13.1776 10.2347 13.1776 10.2347C13.1776 10.2347 11.722 10.6575 11.6279 11.8753C11.6279 11.8753 11.5529 12.8848 12.5613 12.9884"
      fill="#20201E"
    />
    <path
      d="M8.76004 11.0666C8.76004 11.0666 9.25793 10.0592 10.0994 10.278C10.9408 10.4968 10.9366 11.5222 10.9366 11.5222C10.9366 11.5222 11.0835 10.3108 10.1416 10.0698C9.08562 9.80022 8.76004 11.0666 8.76004 11.0666Z"
      fill="#20201E"
    />
    <path
      d="M16.4947 10.408C17.7146 10.5042 17.7146 11.8467 17.7146 11.8467C17.7146 11.8467 17.5233 10.667 16.4947 10.5782C15.7833 10.5137 15.389 11.3996 15.389 11.3996C15.389 11.3996 15.611 10.3351 16.4947 10.408Z"
      fill="#20201E"
    />
    <path
      d="M9.81184 11.0592C9.21671 11.0655 8.93658 12.8467 9.63531 12.9112C10.334 12.9757 10.5497 11.0021 9.81184 11.0592Z"
      fill="#20201E"
    />
    <path
      d="M9.64694 11.3203C9.58808 11.3621 9.53937 11.4165 9.50442 11.4796C9.46947 11.5428 9.44918 11.613 9.44503 11.685C9.42508 11.799 9.41132 11.9141 9.40381 12.0296C9.42307 12.0433 9.44484 12.053 9.46788 12.0583C9.49092 12.0636 9.51476 12.0642 9.53806 12.0603C9.59435 12.0444 9.64589 12.015 9.68816 11.9746C9.66852 11.8761 9.67471 11.7741 9.70613 11.6787C9.73049 11.5621 9.76589 11.4481 9.81184 11.3383C9.78795 11.3265 9.7622 11.319 9.73573 11.3161C9.70609 11.3131 9.67617 11.3145 9.64694 11.3203Z"
      fill="white"
    />
    <path
      d="M16.9112 12.0296C16.8985 11.7897 16.8414 11.5349 16.7083 11.4355C16.6727 11.4069 16.6345 11.3817 16.5941 11.3605C16.5562 11.3494 16.5177 11.3406 16.4789 11.3341C15.87 11.296 15.5867 13.2241 16.2009 13.2939C16.6448 13.3446 16.9493 12.5856 16.9112 12.0296Z"
      fill="#20201E"
    />
    <path
      d="M16.3277 11.5708C16.2688 11.6126 16.2201 11.667 16.1852 11.7302C16.1502 11.7933 16.1299 11.8635 16.1258 11.9355C16.1059 12.0492 16.0921 12.1639 16.0846 12.2791C16.1036 12.2931 16.1254 12.3032 16.1484 12.3087C16.1715 12.3141 16.1955 12.3148 16.2188 12.3108C16.2751 12.295 16.3266 12.2655 16.3689 12.2252C16.3493 12.1266 16.3555 12.0246 16.3869 11.9292C16.4112 11.8126 16.4466 11.6986 16.4926 11.5888C16.4687 11.577 16.443 11.5695 16.4165 11.5666C16.3868 11.5636 16.3569 11.5651 16.3277 11.5708Z"
      fill="white"
    />
    <path
      d="M20.1639 13.8732L25.3753 10.5338L19.6903 10.1966L23.2389 5.56448L17.7421 8.00529L18.9704 3.06131L15.8383 5.91543L15.482 1.35095L12.6607 6.28541L9.71248 1.91861L10.1575 7.31818L5.40064 3.82981L7.53489 8.7981L3.04335 7.82875L7.111 11.74L1.40276 12.8584L6.3203 14.5592L2.09197 17.0856L7.71354 16.3975L4.21354 20.9218L9.54123 18.371L8.45878 24.4028L11.8414 20.3087L13.1565 24.2886L14.5613 20.296L18.0064 24.6512L17.3848 18.3425L22.0793 21.3816L19.9154 16.0243L24.9493 15.8964L20.1639 13.8732ZM13.5793 6.98732C15.1825 6.98928 16.7195 7.62709 17.853 8.76084C18.9866 9.89458 19.6241 11.4317 19.6258 13.0349V13.0507C19.6218 14.245 19.2641 15.4113 18.5978 16.4024C17.9315 17.3935 16.9864 18.165 15.882 18.6194C14.7776 19.0738 13.5632 19.1908 12.3924 18.9557C11.2215 18.7205 10.1465 18.1436 9.30324 17.298C8.45995 16.4523 7.88612 15.3758 7.6542 14.2042C7.42228 13.0327 7.54267 11.8187 8.00018 10.7156C8.45768 9.61239 9.23178 8.66951 10.2247 8.00596C11.2177 7.34242 12.385 6.98795 13.5793 6.98732Z"
      fill="url(#icon-provider-mercur-paint5_radial_443_62)"
    />
    <path
      d="M3.38478 8.01056L7.7167 8.94396L7.36152 8.65115L2.7019 7.64798L3.38478 8.01056Z"
      fill="url(#icon-provider-mercur-paint6_linear_443_62)"
    />
    <path
      d="M15.4028 1.7019L15.7442 6.13319L15.9218 5.68605L15.5613 1L15.4028 1.7019Z"
      fill="url(#icon-provider-mercur-paint7_linear_443_62)"
    />
    <path
      d="M12.6681 6.48415L12.6533 6.08458L9.57401 1.52432L9.85096 2.31291L12.6681 6.48415Z"
      fill="url(#icon-provider-mercur-paint8_linear_443_62)"
    />
    <path
      d="M15.7442 6.13319L18.7791 3.37315L19.1512 2.74841L15.9218 5.68605L15.7442 6.13319Z"
      fill="url(#icon-provider-mercur-paint9_linear_443_62)"
    />
    <path
      d="M5.6554 4.14164L5.1554 3.51056L7.36153 8.65115L7.71671 8.94396L5.6554 4.14164Z"
      fill="url(#icon-provider-mercur-paint10_linear_443_62)"
    />
    <path
      d="M10.2822 7.54016L10.0328 7.09513L5.1554 3.51056L5.6554 4.14164L10.2822 7.54016Z"
      fill="url(#icon-provider-mercur-paint11_linear_443_62)"
    />
    <path
      d="M10.2823 7.54018L9.85096 2.31291L9.57401 1.52432L10.0328 7.09515L10.2823 7.54018Z"
      fill="url(#icon-provider-mercur-paint12_linear_443_62)"
    />
    <path
      d="M15.4027 1.7019L15.5613 1L12.6533 6.08457L12.6681 6.48414L15.4027 1.7019Z"
      fill="url(#icon-provider-mercur-paint13_linear_443_62)"
    />
    <path
      d="M19.9376 13.8922L20.389 13.8541L25.7061 10.4471L25.0444 10.6205L19.9376 13.8922Z"
      fill="url(#icon-provider-mercur-paint14_linear_443_62)"
    />
    <path
      d="M18.7791 3.37315L17.5856 8.19345L17.8943 7.82558L19.1512 2.74841L18.7791 3.37315Z"
      fill="url(#icon-provider-mercur-paint15_linear_443_62)"
    />
    <path
      d="M25.0444 10.6205L25.7061 10.4471L19.8953 10.1068L19.4852 10.2939L25.0444 10.6205Z"
      fill="url(#icon-provider-mercur-paint16_linear_443_62)"
    />
    <path
      d="M25.4355 15.9905L24.4524 15.8034L19.7548 15.9228L20.0655 16.1268L25.4355 15.9905Z"
      fill="url(#icon-provider-mercur-paint17_linear_443_62)"
    />
    <path
      d="M20.389 13.8541L19.9376 13.8922L24.4524 15.8034L25.4355 15.9905L20.389 13.8541Z"
      fill="url(#icon-provider-mercur-paint18_linear_443_62)"
    />
    <path
      d="M19.4852 10.2939L19.8953 10.1068L23.5751 5.30338L22.9027 5.83297L19.4852 10.2939Z"
      fill="url(#icon-provider-mercur-paint19_linear_443_62)"
    />
    <path
      d="M22.9028 5.83297L23.5751 5.30338L17.8943 7.82557L17.5856 8.19344L22.9028 5.83297Z"
      fill="url(#icon-provider-mercur-paint20_linear_443_62)"
    />
    <path
      d="M9.68181 18.1871L9.40063 18.556L8.28329 24.7812L8.63425 24.0243L9.68181 18.1871Z"
      fill="url(#icon-provider-mercur-paint21_linear_443_62)"
    />
    <path
      d="M19.7548 15.9228L21.851 21.111L22.297 21.6522L20.0655 16.1268L19.7548 15.9228Z"
      fill="url(#icon-provider-mercur-paint22_linear_443_62)"
    />
    <path
      d="M11.8784 20.093L8.63425 24.0243L8.28329 24.7812L11.7981 20.5233L11.8784 20.093Z"
      fill="url(#icon-provider-mercur-paint23_linear_443_62)"
    />
    <path
      d="M13.1586 23.962L13.1522 24.6152L14.5973 20.5127L14.5243 20.0804L13.1586 23.962Z"
      fill="url(#icon-provider-mercur-paint24_linear_443_62)"
    />
    <path
      d="M11.8784 20.093L11.7981 20.5233L13.1522 24.6152L13.1586 23.9619L11.8784 20.093Z"
      fill="url(#icon-provider-mercur-paint25_linear_443_62)"
    />
    <path
      d="M14.5243 20.0804L14.5973 20.5127L18.1459 25V24.999L17.8647 24.3023L14.5243 20.0804Z"
      fill="url(#icon-provider-mercur-paint26_linear_443_62)"
    />
    <path
      d="M17.2526 18.1353L17.5063 18.5507L22.297 21.6522L21.8509 21.111L17.2526 18.1353Z"
      fill="url(#icon-provider-mercur-paint27_linear_443_62)"
    />
    <path
      d="M17.8647 24.3023L18.1459 24.999L17.5063 18.5507L17.2526 18.1353L17.8647 24.3023Z"
      fill="url(#icon-provider-mercur-paint28_linear_443_62)"
    />
    <path
      d="M7.9482 16.2611L2.57293 16.9186L1.60464 17.2505L7.47145 16.5328L7.9482 16.2611Z"
      fill="url(#icon-provider-mercur-paint29_linear_443_62)"
    />
    <path
      d="M6.57188 14.5328L1.81078 12.8858L1 12.8288L6.07294 14.5835L6.57188 14.5328Z"
      fill="url(#icon-provider-mercur-paint30_linear_443_62)"
    />
    <path
      d="M7.33087 11.8034L6.89112 11.6744L1 12.8287L1.81078 12.8858L7.33087 11.8034Z"
      fill="url(#icon-provider-mercur-paint31_linear_443_62)"
    />
    <path
      d="M4.56872 20.6343L3.8573 21.2093L9.40064 18.556L9.68182 18.1871L4.56872 20.6343Z"
      fill="url(#icon-provider-mercur-paint32_linear_443_62)"
    />
    <path
      d="M6.57188 14.5328L6.07293 14.5835L1.60464 17.2505L2.57293 16.9186L6.57188 14.5328Z"
      fill="url(#icon-provider-mercur-paint33_linear_443_62)"
    />
    <path
      d="M4.56872 20.6343L7.94821 16.2611L7.47146 16.5328L3.8573 21.2093L4.56872 20.6343Z"
      fill="url(#icon-provider-mercur-paint34_linear_443_62)"
    />
    <path
      d="M7.33087 11.8034L3.38478 8.01056L2.7019 7.64798L6.89112 11.6744L7.33087 11.8034Z"
      fill="url(#icon-provider-mercur-paint35_linear_443_62)"
    />
  </svg>
);

IconProviderMercur.propTypes = {
  className: PropTypes.string,
};

IconProviderMercur.defaultProps = {
  className: '',
};
