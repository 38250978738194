import loadable from '@loadable/component';
import { langPrefix } from 'core/helpers';
import { withLogin } from 'hocs/with-login';
import { PageNotFoundRouter } from 'core/pages';

import SignUp from './pages/sign-up/sign-up';
import SignUpConfirmation from './pages/sign-up-confirmation/sign-up-confirmation';
import ResetPassword from './pages/reset-password/reset-password';
import { App } from './app';
import { PAGE_NAMES, LOCATION_PATH_PREV } from './constants';

const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/page-not-found/page-not-found'));
const RestrictedCountry = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/restricted-country/restricted-country'));
const Maintenance = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/maintenance/maintenance'));

const routes = [
  {
    component: App,
    routes: [
      {
        path: [langPrefix(''), langPrefix('cashier')],
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "home" */ './pages/home/home')),
        page: PAGE_NAMES.HOME,
        hasNavigation: true,
      },
      {
        path: langPrefix('sign-up'),
        exact: true,
        noFooter: true,
        component: SignUp, // should not be loadable
      },
      {
        path: langPrefix('sign-up-confirmation'),
        exact: true,
        noFooter: true,
        component: SignUpConfirmation, // should not be loadable
      },
      {
        path: langPrefix('reset-password'),
        exact: true,
        noSidebar: true,
        noHeader: true,
        component: ResetPassword, // should not be loadable
        page: PAGE_NAMES.RESET_PASSWORD,
      },
      {
        path: langPrefix('profile'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "profile" */ './pages/profile/profile'))),
        page: PAGE_NAMES.PROFILE,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/gifts'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "gifts" */ './pages/gifts/gifts'))),
        page: PAGE_NAMES.GIFTS,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/withdrawal'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "withdrawal" */ './pages/withdrawal/withdrawal'))),
        page: PAGE_NAMES.WITHDRAWAL,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/deposit'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "deposit" */ './pages/deposit/deposit'))),
        page: PAGE_NAMES.DEPOSIT,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/limits'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "limits" */ './pages/limits/limits'))),
        page: PAGE_NAMES.LIMITS,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/verification'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "verification" */ './pages/verification/verification'))),
        page: PAGE_NAMES.VERIFICATION,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/play-history'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "play-history" */ './pages/play-history/play-history'))),
        page: PAGE_NAMES.PLAY_HISTORY,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('promos'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "promos" */ './pages/main-promo/promo')),
        page: PAGE_NAMES.PROMOS,
        hasNavigation: true,
      },
      {
        path: langPrefix('bonus/:id'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "bonus" */ './pages/bonus/bonus')),
        page: PAGE_NAMES.BONUS,
        hasNavigation: true,
        mobileBackRoute: LOCATION_PATH_PREV,
      },
      {
        path: langPrefix('payments'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "payments" */ './pages/payments/payments')),
        page: PAGE_NAMES.PAYMENTS,
        hasNavigation: true,
        mobileBackRoute: LOCATION_PATH_PREV,
      },
      {
        path: langPrefix('wallet'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "wallet" */ './pages/wallet/wallet'))),
        page: PAGE_NAMES.WALLET,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('profile/banking-history'),
        exact: true,
        component: withLogin(loadable(() => import(/* webpackChunkName: "banking-history" */ './pages/banking-history/banking-history'))),
        page: PAGE_NAMES.BANKING_HISTORY,
        noFooter: true,
        hasNavigation: true,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('game/:producer/:title'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "game" */ './pages/game/game')),
        page: PAGE_NAMES.GAME,
        // noHeader: true,
      },
      {
        path: langPrefix('games/:category?'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "games" */ './pages/games/games')),
        page: PAGE_NAMES.GAMES,
        hasNavigation: true,
        mobileBackRoute: '/games',
      },
      {
        path: langPrefix('game-tags/:tag'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "games" */ './pages/game-tags/game-tags')),
        page: PAGE_NAMES.GAME_TAGS,
        hasNavigation: true,
        mobileBackRoute: '/games',
      },
      // {
      //   path: langPrefix('loyalty'),
      //   exact: true,
      //   component: loadable(() => import(/* webpackChunkName: "loyalty" */ './pages/loyalty/loyalty')),
      //   page: PAGE_NAMES.LOYALTY,
      //   hasNavigation: true,
      //   mobileBackRoute: LOCATION_PATH_PREV,
      // },
      // {
      //   path: langPrefix('tournaments'),
      //   exact: true,
      //   component: loadable(() => import(/* webpackChunkName: "tournaments" */ './pages/tournaments/tournaments')),
      //   page: PAGE_NAMES.TOURNAMENTS,
      //   hasNavigation: true,
      //   mobileBackRoute: LOCATION_PATH_PREV,
      // },
      {
        path: langPrefix('tournament/:id/:name'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "tournament" */ './pages/tournament/tournament')),
        page: PAGE_NAMES.TOURNAMENT,
        hasNavigation: true,
        mobileBackRoute: LOCATION_PATH_PREV,
      },
      // {
      //   path: langPrefix('search'),
      //   exact: true,
      //   component: loadable(() => import(/* webpackChunkName: "search" */ './pages/search/search')),
      //   page: PAGE_NAMES.SEARCH,
      //   hasNavigation: true,
      //   noFooter: true,
      // },
      {
        path: langPrefix('terms'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "terms" */ './pages/terms/terms')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.TERMS,
      },
      {
        path: langPrefix('responsible-gaming'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "responsible-gaming" */ './pages/responsible-gaming/responsible-gaming')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.TERMS,
      },
      {
        path: langPrefix('bonus-terms'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "bonus-terms" */ './pages/bonus-terms/bonus-terms')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.BONUS_TERMS,
      },
      {
        path: langPrefix('anti-money-laundering'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "aml" */ './pages/anti-money-laundering/anti-money-laundering')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.AML,
      },
      {
        path: langPrefix('refund-policy'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "refund-policy" */ './pages/refund-policy/refund-policy')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.REFUND,
      },
      {
        path: langPrefix('privacy-policy'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "privacy-policy" */ './pages/privacy-policy/privacy-policy')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.PRIVACY,
      },
      {
        path: langPrefix('self-exclusion'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "self-exclusion" */ './pages/self-exclusion/self-exclusion')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.SELF_EXCLUSION,
      },
      {
        path: langPrefix('dispute-resolution'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "dispute-resolution" */ './pages/dispute-resolution/dispute-resolution')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.DISPUTE_RESOLUTION,
      },
      {
        path: langPrefix('fairness-and-rng'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "fairness-and-rng" */ './pages/fairness-and-rng/fairness-and-rng')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.FAIRNESS_AND_RNG,
      },
      {
        path: langPrefix('kyc'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "KYC" */ './pages/kyc/kyc')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.KYC,
      },
      {
        path: langPrefix('about'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "about" */ './pages/about/about')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.ABOUT,
      },
      {
        path: langPrefix('affiliate'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "affiliate" */ './pages/affiliate/affiliate')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.AFFILIATE,
      },
      {
        path: langPrefix('provider/:id'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "provider" */ './pages/provider/provider')),
        hasNavigation: true,
        page: PAGE_NAMES.PROVIDER,
        mobileBackRoute: '/',
      },
      {
        path: langPrefix('category/:id'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "category" */ './pages/category/category')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.CATEGORY,
      },
      {
        path: langPrefix('faq'),
        exact: true,
        component: loadable(() => import(/* webpackChunkName: "faq" */ './pages/faq/faq')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.FAQ,
      },
      {
        path: langPrefix('from-pwa'),
        component: loadable(() => import(/* webpackChunkName: "from-pwa" */ './pages/from-pwa/from-pwa')),
        page: PAGE_NAMES.FROM_PWA,
        noHeader: true,
        noSidebar: true,
        noFooter: true,
        noChat: true,
      },
      {
        path: langPrefix('sportsbook'),
        component: loadable(() => import(/* webpackChunkName: "sportsbook" */ './pages/sportsbook/sportsbook')),
        exact: true,
        noHeader: true,
        noSidebar: true,
        noFooter: true,
        page: PAGE_NAMES.SPORTSBOOK,
      },
      {
        path: langPrefix('in-play'),
        component: loadable(() => import(/* webpackChunkName: "in-play" */ './pages/in-play/in-play')),
        exact: true,
        noHeader: true,
        noSidebar: true,
        noFooter: true,
        page: PAGE_NAMES.IN_PLAY,
      },
      {
        path: langPrefix('vip'),
        component: loadable(() => import(/* webpackChunkName: "vip" */ './pages/vip/vip')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.VIP,
      },
      {
        path: langPrefix('license'),
        component: loadable(() => import(/* webpackChunkName: "vip" */ './pages/license/license')),
        hasNavigation: true,
        mobileBackRoute: '/',
        page: PAGE_NAMES.LICENSE,
      },
      {
        path: langPrefix('*'),
        component: PageNotFoundRouter,
        noHeader: true,
        noSidebar: true,
        noFooter: true,
        noChat: true,
      },
    ],
  },
];

export default {
  routes,
  pageNotFound: PageNotFound,
  restrictedCountry: RestrictedCountry,
  maintenance: Maintenance,
};
