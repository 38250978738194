import React from 'react';
import PropTypes from 'prop-types';

export const IconStatusApproved = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 24C8.47715 24 4 19.5228 4 14C4 8.47715 8.47715 4 14 4C19.5228 4 24 8.47715 24 14C24 19.5228 19.5228 24 14 24ZM19.7074 10.5488C20.0979 10.9393 20.0979 11.5725 19.7074 11.963L13.7074 17.963C13.5737 18.0966 13.4117 18.1845 13.2407 18.2267C12.9117 18.3082 12.5493 18.2204 12.2921 17.9632L8.79214 14.463C8.40163 14.0724 8.40165 13.4393 8.79219 13.0488C9.18272 12.6582 9.81589 12.6583 10.2064 13.0488L12.9997 15.8423L18.2931 10.5488C18.6837 10.1583 19.3168 10.1583 19.7074 10.5488Z"
      fill="#01FA6C"
    />
  </svg>
);

IconStatusApproved.propTypes = {
  className: PropTypes.string,
};
IconStatusApproved.defaultProps = {
  className: null,
};
