import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEgtJackpotBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="70%"
    height="70%"
    viewBox="0 0 420 148"
  >
    <g id="EGTD_logo_sign_with_font" transform="translate(-455.182 -226)">
      <g id="egtLogo" transform="translate(455.182 228)">
        <circle id="whitebackground" cx="69" cy="69" r="69" transform="translate(3 3)" fill="#fff" />
        <g id="EGTLogoShapes" transform="translate(7.667 7.164)">
          <path
            id="Path_24"
            data-name="Path 24"
            d="M410.268,234.163c-21.059,7.018-41.387,10.451-58.337,10.5a63.4,63.4,0,0,0,64.744,15.275c22.988-7.66,38.917-27.294,43.364-49.98C446.728,219.193,429.614,227.716,410.268,234.163Z"
            transform="translate(-332.669 -133.523)"
            fill="#cf001c"
          />
          <path
            id="Path_25"
            data-name="Path 25"
            d="M447.639,210.669l-1.718,4.164h3.437Z"
            transform="translate(-369.315 -132.732)"
            fill="#cf001c"
          />
          <path
            id="Path_26"
            data-name="Path 26"
            d="M371.5,210.026h-2.291v6.744H371.5a3.383,3.383,0,1,0,0-6.744Z"
            transform="translate(-339.759 -132.52)"
            fill="#cf001c"
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M375.837,111.8c19.842-6.447,39.051-9.867,55.522-10.42a66.228,66.228,0,0,0-110.1,37.09C335.356,128.3,354.24,118.82,375.837,111.8Z"
            transform="translate(-321.263 -83.829)"
            fill="#cf001c"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M411.452,189.012a174.26,174.26,0,0,0,38.85-18.169,67.308,67.308,0,0,0-2.627-29.125,66.238,66.238,0,0,0-14.777-25.3,172.85,172.85,0,0,0-41.789,8.627A178.651,178.651,0,0,0,356.2,140.776h12.976v5H355.313v5.849h12.24v4.848h-12.24v6.234h14.349v5H349.25V145.2c-18.2,12.425-28.268,26.418-24.8,37.33C330.071,200.2,369.021,203.1,411.452,189.012Zm-28.3-4.991H380.7V173.144h2.455Zm11.319-8.826h-3.394v8.826h-2.455V175.2h-3.394v-2.051h9.243Zm21.684,8.826h-7.773V173.144h2.455v8.826h5.318ZM399.87,140.776h22.972v5.079H414.4v21.834h-6.1V145.855h-8.436Zm1.906,32.368,4.742,10.877h-2.576L403,181.69h-4.924l-.939,2.331h-2.515l4.728-10.877Zm-28.125-26.077a13.093,13.093,0,0,1,5.178-4.963,15.442,15.442,0,0,1,7.457-1.789,16.025,16.025,0,0,1,6.288,1.193,12.473,12.473,0,0,1,4.745,3.463l-3.916,3.694a8.972,8.972,0,0,0-6.817-3.04A9.155,9.155,0,0,0,382.1,146.7a7.642,7.642,0,0,0-3.05,3.04,9.749,9.749,0,0,0,0,8.965,7.8,7.8,0,0,0,3.031,3.059,8.833,8.833,0,0,0,4.425,1.1,9.723,9.723,0,0,0,4.745-1.154v-7.89h5.574v10.928a15.8,15.8,0,0,1-4.971,2.539,18.934,18.934,0,0,1-5.687.885,15.138,15.138,0,0,1-7.382-1.789,13.156,13.156,0,0,1-5.14-4.963,14.744,14.744,0,0,1,0-14.353Zm-5.309,28.619a5.274,5.274,0,0,1,2.083-2,6.194,6.194,0,0,1,3-.722,6.418,6.418,0,0,1,2.531.482,5.018,5.018,0,0,1,1.909,1.4l-1.576,1.492a3.606,3.606,0,0,0-2.743-1.227,3.665,3.665,0,0,0-1.8.435,3.078,3.078,0,0,0-1.228,1.227,3.952,3.952,0,0,0,0,3.62,3.148,3.148,0,0,0,1.22,1.235,3.542,3.542,0,0,0,1.78.443,3.9,3.9,0,0,0,1.909-.466v-3.186h2.243v4.413a6.336,6.336,0,0,1-2,1.026,7.59,7.59,0,0,1-2.288.357,6.074,6.074,0,0,1-2.97-.722,5.3,5.3,0,0,1-2.069-2,5.973,5.973,0,0,1,0-5.8Zm-5.811-2.541h2.455v10.877h-2.455Zm-3.341,8.3a4.979,4.979,0,0,1-2.061,1.9,6.628,6.628,0,0,1-3.053.676H349.25V173.144h4.819a6.628,6.628,0,0,1,3.053.676,4.982,4.982,0,0,1,2.061,1.9,5.927,5.927,0,0,1,0,5.718Z"
            transform="translate(-322.197 -97.068)"
            fill="#cf001c"
          />
        </g>
      </g>
      <g id="egtDigital" transform="translate(627.042 249.001)">
        <path
          id="egt"
          d="M139.87,85.924h29.913V93.31H149.01v8.59h18.373v7.109H149.01v9.14h21.512v7.386H139.87Zm76.076.092h34.53v7.478H237.827v32.037h-9.14V93.4H216.038V86.016Zm-39.423,9.233a19.038,19.038,0,0,1,7.755-7.294,25.74,25.74,0,0,1,20.589-.831,18.921,18.921,0,0,1,7.109,5.078l-5.909,5.447a13.76,13.76,0,0,0-10.248-4.432,14.493,14.493,0,0,0-6.74,1.57,11.772,11.772,0,0,0-4.616,4.432,13.076,13.076,0,0,0-1.662,6.647,12.81,12.81,0,0,0,1.662,6.555,11.831,11.831,0,0,0,4.524,4.524,13.133,13.133,0,0,0,6.647,1.57,14.865,14.865,0,0,0,7.109-1.662V105.22h8.4v16.065a21.883,21.883,0,0,1-7.478,3.693,30.421,30.421,0,0,1-8.586,1.293A22.89,22.89,0,0,1,184,123.685a19.647,19.647,0,0,1-7.755-7.294,20.36,20.36,0,0,1-2.77-10.525,23.717,23.717,0,0,1,3.047-10.617Z"
          transform="translate(-139.87 -85.368)"
          fill="#fff"
        />
        <path
          id="digital"
          d="M265.649,187.791h-9.114v-39.31h9.114Zm41.926-32.039H295.043v32.039h-9.114V155.753H273.4v-7.5h34.179Zm80.207,32.039h-28.71v-39.31h9.114V180.52H388.01v7.271ZM334.69,148.254l17.545,39.31h-9.57l-3.418-8.407H321.019l-3.418,8.407h-9.57l17.545-39.31ZM210.734,157.57a18.376,18.376,0,0,1,7.747-7.271,22.5,22.5,0,0,1,11.165-2.727,24.347,24.347,0,0,1,9.342,1.818,20.112,20.112,0,0,1,7.064,5l-5.924,5.453a13.485,13.485,0,0,0-10.254-4.544,12.436,12.436,0,0,0-6.608,1.591,10.968,10.968,0,0,0-4.557,4.544,12.345,12.345,0,0,0-1.595,6.59,12.831,12.831,0,0,0,1.595,6.589,12.231,12.231,0,0,0,11.165,6.135,14.083,14.083,0,0,0,7.064-1.591V167.568h8.2v15.906a19.444,19.444,0,0,1-7.519,3.636,27.175,27.175,0,0,1-8.431,1.363,21.659,21.659,0,0,1-10.937-2.727,19.843,19.843,0,0,1-7.747-7.271,20.539,20.539,0,0,1-2.734-10.452,19.927,19.927,0,0,1,2.961-10.45Zm-21.647-9.316H198.2v39.31h-9.114Zm-12.3,29.993a17.454,17.454,0,0,1-7.747,6.817,25.338,25.338,0,0,1-11.393,2.5H139.87v-39.31h17.773a26.276,26.276,0,0,1,11.393,2.5,18.71,18.71,0,0,1,7.747,6.817,20.911,20.911,0,0,1,0,20.677Zm153.35-20.9-6.38,15h12.532Zm-172.718-1.591h-8.2v24.313h8.2a13.7,13.7,0,0,0,9.342-3.181,11.922,11.922,0,0,0,3.418-8.862,11.631,11.631,0,0,0-3.418-8.862,12.8,12.8,0,0,0-9.346-3.411Z"
          transform="translate(-139.87 -87.382)"
          fill="#fff"
        />
      </g>
      <rect id="container" width="420" height="148" transform="translate(455.182 226)" fill="none" />
    </g>
  </svg>

);

IconProviderEgtJackpotBig.propTypes = {
  className: PropTypes.string,
};
IconProviderEgtJackpotBig.defaultProps = {
  className: null,
};
