import React from 'react';
import PropTypes from 'prop-types';

export const IconEye = ({ isOpen, className }) =>
  isOpen ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3999 6.38184C7.73393 6.38184 3.9248 14.0001 3.9248 14.0001C3.9248 14.0001 7.73393 21.6183 14.3999 21.6183C21.0659 21.6183 24.875 14.0001 24.875 14.0001C24.875 14.0001 21.0659 6.38184 14.3999 6.38184ZM17.3397 14.3823C17.3397 16.0064 16.0231 17.323 14.399 17.323C12.7749 17.323 11.4583 16.0064 11.4583 14.3823C11.4583 12.7582 12.7749 11.4416 14.399 11.4416C16.0231 11.4416 17.3397 12.7582 17.3397 14.3823Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.254 18.6817C23.607 16.536 24.875 14.0001 24.875 14.0001C24.875 14.0001 21.0659 6.38184 14.3999 6.38184C12.787 6.38184 11.3413 6.82787 10.0764 7.50408L14.0361 11.4638C14.155 11.4492 14.2761 11.4416 14.399 11.4416C16.0231 11.4416 17.3397 12.7582 17.3397 14.3823C17.3397 14.5052 17.3322 14.6263 17.3175 14.7452L21.254 18.6817ZM12.5481 12.0971C11.8832 12.6363 11.4583 13.4597 11.4583 14.3823C11.4583 16.0064 12.7749 17.323 14.399 17.323C15.3217 17.323 16.1451 16.8981 16.6842 16.2333L20.0875 19.6365C18.534 20.7685 16.6263 21.6183 14.3999 21.6183C7.73393 21.6183 3.9248 14.0001 3.9248 14.0001C3.9248 14.0001 5.63598 10.5777 8.77162 8.32066L12.5481 12.0971Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9168 24.1355L4.23911 6.45779L5.65332 5.04358L23.331 22.7212L21.9168 24.1355Z"
        fill="#B6BBBD"
      />
    </svg>
  );

IconEye.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

IconEye.defaultProps = {
  isOpen: true,
  className: '',
};
