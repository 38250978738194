import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchRoutes } from 'react-router-config';
import loadable from '@loadable/component';
import * as R from 'ramda';
import classNames from 'classnames';
import { parse as parseQueryString } from 'qs';
import Container from 'reactstrap/lib/Container';
import { App as AppCore } from 'core/app';
import { FormattedTag } from 'core/components';
import {
  withCoreComponent, withLocale, withUser, withModalActions,
} from 'core/hocs';
import { getEnv } from 'core/helpers';
import { DEVICES_TYPES } from 'core/constants';

import { withPwaInstall } from 'hocs/with-pwa-install';
import { IconsDefs } from 'components/icons/icons-defs';
import { Header } from 'components/header/header';
import { SupportModal } from 'components/modals/support-modal/support-modal';
import { LinkErrorModal } from 'components/modals/link-error-modal/link-error-modal';
import { DepositModal } from 'components/modals/deposit-modal/deposit-modal';
import { NewLevelModal } from 'components/modals/new-level-modal/new-level-modal';
import { CompleteSignUpModal } from 'components/modals/complete-sign-up-modal/complete-sign-up-modal';
import { ResetPasswordModal } from 'components/modals/reset-password-modal/reset-password-modal';
import { GlobalNotificationsStack } from 'components/global-notifications-stack/global-notifications-stack';
import { Sidebar } from 'components/sidebar/sidebar';
import { Footer } from 'components/footer/footer';
import { CasinoNavigation } from 'components/casino-navigation/casino-navigation';
import { RecaptchaLoader } from 'components/recaptcha-loader/recaptcha-loader';
import { GlobalEventsHandler } from 'components/global-events-handler/global-events-handler';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { LocationTracker } from 'components/location-tracker/location-tracker';
import { ConfirmOtpModal } from 'components/modals/confirm-otp-modal/confirm-otp-modal';
import { SetupOtpModal } from 'components/modals/setup-otp-modal/setup-otp-modal';
import { RemoveOtpModal } from 'components/modals/remove-otp-modal/remove-otp-modal';
import { CookiesPolicy } from 'components/cookies-policy/cookies-policy';
import { MobileGameContainer } from 'components/mobile-game-container/mobile-game-container';
import { PwaPaymentContainer } from 'components/pwa-payment-container/pwa-payment-container';
import { PwaInstallGuide } from 'components/pwa-install-guide/pwa-install-guide';
import { SignInModal } from 'components/modals/sign-in-modal/sign-in-modal';
import { SignUpModal } from 'components/modals/sign-up-modal/sign-up-modal';
import { PromoInfoModal } from 'src/components/modals/promo-info-modal/promo-info-modal';
import { SocialAuthTracker } from 'components/social-auth-tracker/social-auth-tracker';
import { FreeSpinsNotifier } from 'components/free-spins-notifier/free-spins-notifier';
import { ConfirmPhoneModal } from 'components/modals/confirm-phone-modal/confirm-phone-modal';
import { SideModalBar } from 'components/side-modal-bar/side-modal-bar';
import { ProfileTabs } from 'components/profile-tabs/profile-tabs';
import { SignInButtons } from 'components/header/sign-in-buttons/sign-in-buttons';
// import { LiveChatIncChat } from './components/live-chats/live-chat-inc-chat/live-chat-inc-chat';
// import { GameWinnerCarouselMobile } from 'components/game-winner-list/game-winner-carousel-mobile';
import { ZendeskChat } from './components/live-chats/zendesk-chat/zendesk-chat';

import {
  PAGE_NAMES,
  // LIVECHAT_INC_GROUPS,
  MODAL_IDS,
} from './constants';

import './locales';

import './app.scss';

const {
  LOYALTY,
  GAME,
  TOURNAMENT,
  RESET_PASSWORD,
  HOME,
  WALLET,
  TOURNAMENTS,
  GAMES,
  PROVIDER,
  CATEGORY,
  PAYMENTS,
  BANKING_HISTORY,
  SPORTSBOOK,
  IN_PLAY,
  FAQ,
  PROFILE,
  CASHIER,
} = PAGE_NAMES;

const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './pages/page-not-found/page-not-found'));

const LANDING_PAGE_ROUTE = getEnv('PROMO_LANDING_PAGE_ROUTE');
export class AppUI extends Component {
  static propTypes = {
    renderRoutes: PropTypes.func.isRequired,
    route: PropTypes.shape({
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
          exact: PropTypes.bool,
        }),
      ),
    }).isRequired,
    is404: PropTypes.bool.isRequired,
    deviceType: PropTypes.string.isRequired,
    history: PropTypes.shape().isRequired,
    // locale: PropTypes.string.isRequired,
    isPwa: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { history, isPwa, } = this.props;

    const queryParams = parseQueryString(history?.location?.search, { ignoreQueryPrefix: true });

    if (Object.keys(queryParams).includes('payment_id') && !isPwa) {
      window.close();
    }
  }

  componentDidUpdate(prevProps) {
    const { isUserLoggedIn, openModal } = this.props;
    const { isUserLoggedIn: isUserLoggedInPrev } = prevProps;

    if (!isUserLoggedInPrev && isUserLoggedIn) {
      openModal(MODAL_IDS.PROMO_INFO);
    }
  }

  render() {
    const {
      renderRoutes,
      route: { routes },
      is404,
      history,
      // locale,
      deviceType,
      isPwa,
      isUserLoggedIn,
    } = this.props;

    const [routeInfo] = matchRoutes(routes, history.location.pathname);
    const currentRoute = R.propOr({}, 'route', routeInfo);

    const {
      page: pageName,
      noSidebar: hideSidebar,
      noHeader: hideHeader,
      noChat: hideChat,
      noFooter,
      hasNavigation,
      mobileBackRoute,
    } = currentRoute;

    // const { isDomainBarOpen } = this.context;

    const routeParams = R.pathOr({}, ['match', 'params'], routeInfo);
    const isLoyaltyPage = pageName === LOYALTY;
    const isGamePage = pageName === GAME;
    const isGamesPage = pageName === GAMES;
    const isHomePage = pageName === HOME;
    const isTournamentPage = pageName === TOURNAMENT;
    const isTournamentsPage = pageName === TOURNAMENTS;
    const isResetPasswordPage = pageName === RESET_PASSWORD;
    const isWalletPage = pageName === WALLET;
    const isProviderPage = pageName === PROVIDER;
    const isCategoryPage = pageName === CATEGORY;
    const isPaymentsPage = pageName === PAYMENTS;
    const isSportsbookPage = [SPORTSBOOK, IN_PLAY].includes(pageName);
    const isFaqPage = pageName === FAQ;
    const isBankingHistoryPage = pageName === BANKING_HISTORY;
    const isCashierPage = pageName === CASHIER;
    const isSignUpLandPage = pageName === LANDING_PAGE_ROUTE;

    // const liveChatGroup = LIVECHAT_INC_GROUPS[locale];

    const isMobile = deviceType === DEVICES_TYPES.MOBILE;
    const isGamePageOnMobile = isMobile && isGamePage;

    const isInProfile = R.includes(PROFILE, history.location.pathname);

    if (is404) {
      return (<PageNotFound />);
    }

    return (
      <>
        <GlobalNotificationsStack />

        {(!hideSidebar && !isGamePageOnMobile) && <Sidebar pageName={pageName} mobileBackRoute={mobileBackRoute} />}

        <SkipServerRender skip>
          {!isPwa
            && !isGamePage
            && !isResetPasswordPage && (
              <PwaInstallGuide />
          )}
        </SkipServerRender>

        {/* for correct opening mobile game in iframe by direct link */}
        {/* <MobileGameContainer /> should mount before <Game /> */}
        {(isPwa || isMobile) && (
          <MobileGameContainer
            isMobile={isMobile}
            isPwa={isPwa}
            isGamePage={isGamePage}
          />
        )}

        {!hideHeader && (
          <Header
            pageName={pageName}
            mobileBackRoute={mobileBackRoute}
            params={routeParams.category}
            isUserLoggedIn={isUserLoggedIn}
            className={classNames({
              'main-content-overlap bg-blue-light': [HOME, GAMES, GAME].includes(pageName),
            })}
          />
        )}

        <div>

          {/* <SkipServerRender skip> */}
          {/*  {!isGamePage */}
          {/*    && !isInProfile */}
          {/*    && !isSportsbookPage */}
          {/*    && !isResetPasswordPage */}
          {/*    && <GameWinnerCarouselMobile locale={locale} />} */}
          {/* </SkipServerRender> */}

          <Container
            fluid
            className={classNames('d-flex flex-column main-container position-relative flex-grow-1', {
              'is-main-page': !isLoyaltyPage && !isGamePage && !isHomePage && !isTournamentPage && !isGamesPage,
              'px-sm-2_5 px-lg-5': !isLoyaltyPage && !isGamePage && !isTournamentPage && !isHomePage && !isGamesPage && !isWalletPage && !isBankingHistoryPage && !isSportsbookPage,
              'px-0': isLoyaltyPage || isGamePage || isTournamentPage || isHomePage || isWalletPage || isGamesPage || isSportsbookPage,
              'hide-sidebar': hideSidebar || isGamePageOnMobile,
              'has-navigation': hasNavigation && noFooter,
              // 'has-content-under-header': isHomePage || isTournamentPage || isGamesPage,
              'px-sm-5': isWalletPage || isBankingHistoryPage,
            })}
          >
            {isInProfile && (
              <div>
                <FormattedTag className="h3 d-block mt-2_5 mt-sm-4 mt-lg-4_5 mb-0_75 text-uppercase" id="profile.my-account" />

                <ProfileTabs pageName={pageName} />
              </div>
            )}
            {renderRoutes(routes)}
          </Container>
        </div>

        {!noFooter && (
          <Footer className={classNames('px-2 px-sm-2_5 px-lg-5', {
            'mt-6': !isLoyaltyPage && !isTournamentsPage && !isGamesPage && !isCategoryPage && !isProviderPage && !isPaymentsPage && !isGamePage && !isFaqPage,
            'has-navigation': hasNavigation,
            'is-logged-out': !isUserLoggedIn,
            'pt-3_5': isLoyaltyPage,
            // 'px-2 px-sm-2_5 px-lg-5': !isResetPasswordPage,
            // 'reset-password-page-footer mx-auto px-2_5 d-none d-sm-block': isResetPasswordPage,
            'hide-sidebar': hideSidebar,
            'mt-5': isProviderPage || isCategoryPage || isPaymentsPage,
          })}
          />
        )}

        <SkipServerRender skip>
          <SocialAuthTracker />
          <SignInModal />
          <SignUpModal />
          <PromoInfoModal />
          <SupportModal />

          <SideModalBar
            pageName={pageName}
            params={routeParams.category}
            isSportsbookPage={isSportsbookPage}
          />

          <CompleteSignUpModal />
          <ResetPasswordModal />
          <LinkErrorModal />
          <DepositModal />
          <NewLevelModal />
          <FreeSpinsNotifier />
          <ConfirmPhoneModal />

          <ConfirmOtpModal />
          <SetupOtpModal />
          <RemoveOtpModal />

          {!isUserLoggedIn && !isSportsbookPage && !isCashierPage && !isSignUpLandPage && !isResetPasswordPage && (
            <SignInButtons isBottomPosition />
          )}

          {hasNavigation && (
            <CasinoNavigation
              pageName={pageName}
              params={routeParams.category}
            />
          )}

          {!hideChat && (
            <>
              <ZendeskChat
                isButtonHidden={isSportsbookPage}
                licenseKey="38d7e7df-d930-4bed-8510-28a3b1040945"
              />
            </>
          )}

          <RecaptchaLoader />

          <LocationTracker pageName={pageName} />

          {!isPwa && <CookiesPolicy hasNavigation={hasNavigation} noSidebar={hideSidebar} />}
          {isPwa && <PwaPaymentContainer />}
        </SkipServerRender>

        <GlobalEventsHandler pageName={pageName} />
        <IconsDefs />
      </>
    );
  }
}

export const App = withCoreComponent(
  AppCore,
  withLocale(
    withPwaInstall(
      withUser(
        withModalActions(AppUI),
      ),
    ),
  ),
);
