import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Form from 'reactstrap/lib/Form';
import classNames from 'classnames';
import { FormProcessDeposit as FormProcessDepositCore, FormattedTag } from 'core/components';
import {
  withCoreComponent, withLocale, withFormDataActions, withUser
} from 'core/hocs';
import { getConfig, isSumValueValid, isEmptyOrNil } from 'core/helpers';
import { FORM_FIELDS, FORM_ACTIONS, USER_FIELDS } from 'core/constants';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';
import { PaymentPreviousStep } from 'components/deposit/deposit-new/payment-previous-step/payment-previous-step';
import { DepositMethod } from 'components/deposit/deposit-new/deposit-method/deposit-method';
import { PaymentSum } from 'components/deposit/deposit-new/payment-sum/payment-sum';
import { BonusSelection } from 'components/deposit/deposit-new/bonus-selection/bonus-selection';
import { PaymentMethodCard } from 'components/payment-method-card/payment-method-card';
import { formatAmountWithCurrency } from '../../../helpers/numbers';
import { GA } from '../../../helpers/ga';
import { YM } from '../../../helpers/ym';
import { CURRENCY_SYMBOLS } from '../../../constants';

const DEFAULT_BONUS_OPTION = getConfig('PROCESS_DEPOSIT.DEFAULT_BONUS_OPTION');
const STEPS = { START: 0, FINISH: 1 };

const specialFieldsList = [
  FORM_FIELDS.AMOUNT,
  FORM_FIELDS.BONUS_PLAN,
];

export class FormProcessDepositUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    userData: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    currentAmount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number]).isRequired,
    setAmount: PropTypes.func.isRequired,
    userCurrency: PropTypes.string.isRequired,
    minimumDeposit: PropTypes.number,
    maximumDeposit: PropTypes.number,
    activeDepositMethod: PropTypes.string.isRequired,
    depositBonuses: PropTypes.arrayOf(PropTypes.shape({})),
    handlerPaymentChangeForMobile: PropTypes.func.isRequired,
    handlerPaymentChangeForDesktop: PropTypes.func.isRequired,
    handlerStep: PropTypes.func.isRequired,
    setBonusPlanId: PropTypes.func.isRequired,
    currentBonusPlanId: PropTypes.string,
    getDepositBonuses: PropTypes.func.isRequired,
    checkErrors: PropTypes.func.isRequired,
    depositBonusesIsInProgress: PropTypes.bool.isRequired,
    isModal: PropTypes.bool.isRequired,
    updateModalAlignment: PropTypes.func,
  };

  static defaultProps = {
    currentBonusPlanId: '',
    depositBonuses: [],
    minimumDeposit: null,
    maximumDeposit: null,
    updateModalAlignment: null,
  };

  state = {
    step: STEPS.START,
    fieldsInState: null,
    optionalFields: {},
    isNecessaryFinishStep: false,
    // eslint-disable-next-line react/no-unused-state
    isUserMobileSet: false,
  };

  static getDerivedStateFromProps(props, prevState) {
    const {
      fields,
      setFormData,
      userData,
      formData,
    } = props;
    let state = {};
    const { mobilePhone } = userData || {};

    const { fieldsInState, isUserMobileSet } = prevState;

    if (fields.mobile && !formData[FORM_FIELDS.MOBILE] && mobilePhone && !isUserMobileSet) {
      setFormData(FORM_ACTIONS.PROCESS_DEPOSIT, { [FORM_FIELDS.MOBILE]: mobilePhone });

      state = {
        ...state,
        isUserMobileSet: true,
      };
    }

    if (!R.equals(fields, fieldsInState) && !R.isNil(fields)) {
      const optionalFields = R.reject(field => R.includes(R.prop('name', field), specialFieldsList), fields);

      state = {
        ...state,
        fieldsInState: fields,
        optionalFields,
        isNecessaryFinishStep: !R.isEmpty(optionalFields),
      };
    }

    return isEmptyOrNil(state) ? null : state;
  }

  goToFinish = () => {
    const { checkErrors } = this.props;
    const errorSpecialFieldsList = checkErrors(specialFieldsList);

    if (R.isEmpty(errorSpecialFieldsList)) {
      this.setState({ step: STEPS.FINISH });
    }
  };

  goToStart = () => {
    this.setState({ step: STEPS.START });
  };

  handleChange = (val) => {
    const {
      getDepositBonuses,
      activeDepositMethod,
      maximumDeposit,
      currentAmount,
    } = this.props;

    if (val === '') {
      return null;
    }

    // isSumValueValid check the symbol entered by the user.
    // If val not a number (possibly a fractional number with two decimal places) will happen return
    if (!isSumValueValid(val) || val > maximumDeposit) {
      return false;
    }

    if (val !== String(currentAmount)) {
      getDepositBonuses({
        depositAmount: val,
        paymentMethod: activeDepositMethod,
      });
    }

    return null;
  };

  sumChangeHandler = (val) => {
    const { setAmount } = this.props;

    setAmount(val);
    this.handleChange(val);
  };

  defineCurrentBonus = (bonus) => {
    const { depositBonuses } = this.props;

    if (isEmptyOrNil(depositBonuses) || bonus === DEFAULT_BONUS_OPTION) {
      return null;
    }

    return R.find(R.propEq('bonusPlanId', bonus))(depositBonuses);
  };

  buttonContentWithoutBonus = (titleIntlKey) => {
    const { locale, userCurrency, currentAmount } = this.props;

    return (
      <Fragment>
        <FormattedTag id={titleIntlKey} isHtml />
        <span>&#32;</span>
        {currentAmount !== 0 && (
          <span className="text-uppercase">
            {formatAmountWithCurrency({
              amount: currentAmount,
              currency: userCurrency,
              isIsoFormat: true,
              locale,
            })}
          </span>
        )}
      </Fragment>
    );
  }

  getFieldsElements = () => {
    const { optionalFields } = this.state;

    return Object.values(optionalFields).map(item =>
      <FormElement key={item.name} coreProps={{ item }} />);
  };

  onSubmit = (e) => {
    const { onSubmit } = this.props;
    GA.event({ category: 'deposit', action: 'click', label: 'DepSecond' });
    YM.event('DepSecond');
    onSubmit(e);
  };

  render() {
    const {
      fields,
      isInProgress,
      currentAmount,
      userCurrency,
      activeDepositMethod,
      handlerPaymentChangeForMobile,
      handlerPaymentChangeForDesktop,
      handlerStep,
      minimumDeposit,
      maximumDeposit,
      currentBonusPlanId,
      setBonusPlanId,
      depositBonuses,
      depositBonusesIsInProgress,
      updateModalAlignment,
      isModal,
    } = this.props;

    const { step, isNecessaryFinishStep } = this.state;

    const formWrapperClasses = 'pt-3 pt-sm-2_5';

    return (
      <Form onSubmit={this.onSubmit} className={classNames('form-process-deposit mx-sm-3 w-100', { 'is-modal': isModal })}>
        {step === STEPS.FINISH && isNecessaryFinishStep && (
          <PaymentPreviousStep
            handlerClick={this.goToStart}
            messageId={`profile.deposit-new.payment-method.${activeDepositMethod}`}
          />
        )}

        <div className={formWrapperClasses}>
          {step === STEPS.START && (
            <Fragment>
              <div className="d-flex justify-content-between align-items-center mb-3 mb-sm-0">
                <PaymentPreviousStep handlerClick={handlerStep} />

                <PaymentMethodCard paymentMethod={activeDepositMethod} isIconOnly />
              </div>

              <div className="mt-3_5">
                {this.getFieldsElements()}
              </div>

              <FormElement
                className={classNames('position-relative', {
                  'pl-5': CURRENCY_SYMBOLS[userCurrency]?.length > 1,
                })}
                coreProps={{
                  item: fields.amount,
                  handleChange: this.handleChange,
                }}
                leftContent={CURRENCY_SYMBOLS[userCurrency]}
              />

              <PaymentSum
                currency={userCurrency}
                sumChangeHandler={this.sumChangeHandler}
                className="my-3"
                sumValue={currentAmount}
                minimumPayment={minimumDeposit}
                maximumPayment={maximumDeposit}
                isDeposit
              />

              <BonusSelection
                bonusTypes={fields.requestedBonusPlanId.options}
                depositBonuses={depositBonuses}
                className="mt-3"
                bonusActive={currentBonusPlanId}
                changeBonusHandler={setBonusPlanId}
                currency={userCurrency}
                isInProgress={depositBonusesIsInProgress}
                isModal={isModal}
                updateModalAlignment={updateModalAlignment}
              />

              <FormElement
                coreProps={{ item: fields.requestedBonusPlanId }}
                classNames="d-none"
              />
            </Fragment>
          )}

          {step === STEPS.FINISH && isNecessaryFinishStep && (
            <Fragment>
              <DepositMethod
                className="d-none d-sm-flex mb-sm-3"
                methodType={activeDepositMethod}
                handleButtonClickMobile={handlerPaymentChangeForMobile}
                handleButtonClickDesktop={handlerPaymentChangeForDesktop}
              />

              {this.getFieldsElements()}

              <div className="d-none d-sm-block">
                <PaymentSum
                  currency={userCurrency}
                  sumChangeHandler={this.sumChangeHandler}
                  className="my-3"
                  sumValue={currentAmount}
                  minimumDeposit={minimumDeposit}
                  maximumDeposit={maximumDeposit}
                />

                <FormElement
                  className="position-relative"
                  coreProps={{ item: fields.amount }}
                  handleChange={this.handleChange}
                  rightContent={userCurrency}
                />

                <BonusSelection
                  bonusTypes={fields.requestedBonusPlanId.options}
                  depositBonuses={depositBonuses}
                  className="mt-3"
                  bonusActive={currentBonusPlanId}
                  changeBonusHandler={setBonusPlanId}
                  currency={userCurrency}
                  isInProgress={depositBonusesIsInProgress}
                  isModal={isModal}
                />
                <FormElement
                  coreProps={{ item: fields.requestedBonusPlanId }}
                  classNames="d-none"
                />
              </div>
            </Fragment>
          )}

          {step === STEPS.START && (
            <Fragment>
              <ButtonWithLoader
                isLoading={isInProgress}
                className="w-100 mt-1_5 rounded-lg bg-green text-primary-dark"
                color="primary"
                type="submit"
              >
                <FormattedTag id="deposit" isHtml />
              </ButtonWithLoader>
            </Fragment>
          )}

          {step === STEPS.FINISH && isNecessaryFinishStep && (
            <ButtonWithLoader
              isLoading={isInProgress}
              className="w-100 mt-1_5 rounded-lg bg-green text-primary-dark"
              color="primary"
              type="submit"
            >
              <FormattedTag id="deposit" isHtml />
            </ButtonWithLoader>
          )}
        </div>
      </Form>
    );
  }
}

export const FormProcessDeposit = withFormDataActions(withLocale(withCoreComponent(
  FormProcessDepositCore,
  withUser(FormProcessDepositUI, [USER_FIELDS.USER_DATA])
)));
