import React from 'react';
import PropTypes from 'prop-types';

export const IconPromos = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path
      d="M8.12969 21.6172H11.8703V5.37915H8.12969V21.6172ZM14.6611 4.13005C15.2598 3.23609 15.6109 2.16242 15.6109 1.00733C15.6109 0.662094 15.3321 0.382812 14.9875 0.382812C12.8188 0.382812 10.9339 1.62151 10 3.42987C9.06604 1.62151 7.1811 0.382812 5.01252 0.382812C4.66792 0.382812 4.38906 0.662164 4.38906 1.00733C4.38906 2.16242 4.7402 3.23609 5.33891 4.13005H14.6611ZM13.1172 21.6172H17.4812C17.8259 21.6172 18.1047 21.3378 18.1047 20.9927V12.8736H13.1172V21.6172H13.1172ZM1.89529 20.9927C1.89529 21.3379 2.17415 21.6172 2.51875 21.6172H6.88284V12.8736H1.89536V20.9927H1.89529ZM0.648438 6.00366V11C0.648438 11.3452 0.927297 11.6245 1.2719 11.6245H6.88284V5.37915H1.2719C0.927297 5.37915 0.648438 5.65843 0.648438 6.00366ZM18.7281 5.37915H13.1172V11.6245H18.7281C19.0727 11.6245 19.3516 11.3452 19.3516 11V6.00366C19.3516 5.65843 19.0727 5.37915 18.7281 5.37915Z"
      fill="white"
    />
  </svg>
);

IconPromos.propTypes = {
  className: PropTypes.string,
};

IconPromos.defaultProps = {
  className: null,
};
