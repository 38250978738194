/* eslint-disable global-require */
// `ISO 3166-1 alpha-2` code standard (in lower-cased format) should be used for locales and countries
module.exports = {
  DEFAULT_CURRENCY: 'EUR',

  DEFAULT_COUNTRY: 'mx',

  DEFAULT_LOCALE: 'en',
  SEARCH_BOT_LOCALE: 'en',
  TARGET_USER_BROWSER_PREFERRED_LOCALES_LIST: ['en'],

  BRAND_LOCALES: {
    EN: 'en',
    DE: 'de',
    FR: 'fr',
    IT: 'it',
    DE_CH: 'de-ch',
    EN_GB: 'en-gb',
    NB: 'nb',
    FI: 'fi',
    SE: 'se',
    EN_CA: 'en-ca',
    PT: 'pt',
    ES: 'es',
    DE_AT: 'de-at',
  },

  DEFAULT_THEME: 'light',

  /**
   * {[iso2Code]: [currencyCode]}
   */
  DEFAULT_CURRENCIES_BY_COUNTRY: {
    // SE: 'SEK',
  },

  /**
   * {[iso2Code]: [locale]}
   */
  TARGET_USER_LOCALE_BY_COUNTRY_IP: {
    MX: 'es',
  },

  PROCESS_DEPOSIT: {
    // If "NONE" is passed then no bonus will be created.
    // If this is not passed at all or "DEFAULT" is passed then the most appropriate bonus will be selected.
    DEFAULT_BONUS_OPTION: 'NONE',
  },

  FORMS: {
    FIELDS: require('./forms'),
  },

  // TODO: maybe remove to another place
  VERIFICATION: {
    MAX_SIZE: 10 * 1024 * 1024, // 10mb
    ACCEPT: 'image/jpeg, image/png',
  },

  MIN_POINTS_TO_EXCHANGE: 100,

  // Values for CPs to be multiple of. If not set the default is 1.
  MIN_STEP_TO_EXCHANGE_PER_CURRENCY: {
    MXN: 100,
    USD: 100,
  },

  // Used only for local development
  DEFAULT_COUNTRY_INFO_DEV: {
    iso2Code: 'TR',
    name: 'Türkiye',
    phonePrefix: '90',
  }
};
/* eslint-enable global-require */
